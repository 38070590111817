import React, { useState } from 'react';
import './style.css';
import { Accordion, Column, Disconnected, Text } from '@mattilsynet/mt-ui';
export const AccordionBarOffline = () => {
  const [isOpen, setIsOpen] = useState(false);
  const renderHeader = () => {
    return <div className="accordion-bar-header-container" data-sentry-component="renderHeader" data-sentry-source-file="index.tsx">
        <div className="accordion-bar-header-empty-icon"></div>
        <Disconnected className="accordion-bar-header-icon-size" data-sentry-element="Disconnected" data-sentry-source-file="index.tsx"></Disconnected>
        <Text size="normal" weight="regular" data-sentry-element="Text" data-sentry-source-file="index.tsx">
          Applikasjonen er offline
        </Text>
      </div>;
  };
  return <Accordion header={renderHeader()} onClick={() => setIsOpen(!isOpen)} open={isOpen} paddingChildren={[1, 2, 2, 2]} small stickyPosition={52} backgroundColor={'#FBF5CB'} data-sentry-element="Accordion" data-sentry-component="AccordionBarOffline" data-sentry-source-file="index.tsx">
      <Column className={'info-text'} spacing={1} data-sentry-element="Column" data-sentry-source-file="index.tsx">
        <Text size="normal" weight="bold" data-sentry-element="Text" data-sentry-source-file="index.tsx">
          Du har ingen internettforbindelse.
        </Text>
        <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">
          Du kan fortsatt starte et forberedt tilsyn og gjøre registreringer.
        </Text>
        <Column data-sentry-element="Column" data-sentry-source-file="index.tsx">
          <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">Du kan ikke:</Text>
          <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">- Forberede et nytt tilsyn</Text>
          <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">- Legge til sak eller tilsynsobjekt</Text>
          <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">- Velge type tilsyn</Text>
          <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">- Hente bilder fra galleriet</Text>
          <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">- Opprette tilsynskvitteringen i arkivet </Text>
          <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">- Fordele tilsynskvittering til kollega</Text>
          <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">
            - Se sammenstillingen til en arkivert tilsynskvittering
          </Text>
          <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">
            - Ferdigstille og sende ut tilsynskvitteringen
          </Text>
        </Column>
        <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">
          For å gjøre dette trenger applikasjonen internettforbindelse.
        </Text>
      </Column>
    </Accordion>;
};