import { IImage } from '../../features/bilder/types';
import { useKvitteringId } from '../../common/kvittering-context';
import React, { ChangeEvent, useState } from 'react';
import { IMAGE_DESCRIPTION_MAX_LENGTH } from '../../constants';
import { getErrorInfo } from '../../validators/image-errors';
import { Row, TextArea } from '@mattilsynet/mt-ui';
import { useUpdateBildeBeskrivelse } from '../../features/bilder';
import validate from '../../validators/text-input-validators';
interface IImageDescriptionEditor {
  bildeMetadata: IImage;
  onFocus?: () => void;
  onBlur?: () => void;
}
export const ImageDescriptionEditor = ({
  bildeMetadata,
  onFocus,
  onBlur
}: IImageDescriptionEditor) => {
  const {
    id,
    description
  } = bildeMetadata;
  const kvitteringId = useKvitteringId();
  const [beskrivelse, setBeskrivelse] = useState(description);
  const {
    mutate: updateBeskrivelse
  } = useUpdateBildeBeskrivelse(kvitteringId);
  const onChangeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (id) {
      setBeskrivelse(e.target.value);
      updateBeskrivelse({
        bildeMetadata: {
          ...bildeMetadata,
          description: e.target.value
        }
      });
    }
  };
  const infoTextDescription = `${beskrivelse?.length ?? 0} av ${IMAGE_DESCRIPTION_MAX_LENGTH} tegn`;
  const [descriptionDanger, descriptionWarning, descriptionErrorText] = getErrorInfo(validate.validateDescription(beskrivelse));
  return <Row onFocus={onFocus} onBlur={onBlur} data-sentry-element="Row" data-sentry-component="ImageDescriptionEditor" data-sentry-source-file="index.tsx">
      <TextArea defaultRows={1} onChange={onChangeDescription} value={beskrivelse ?? description ?? ''} maxLength={IMAGE_DESCRIPTION_MAX_LENGTH} infoText={infoTextDescription} label="Bildetittel" placeholder="Beskriv kort hvor bildet er tatt og hva bildet er ment å illustrere" errorText={descriptionErrorText} danger={descriptionDanger} warning={descriptionWarning} autoGrow data-sentry-element="TextArea" data-sentry-source-file="index.tsx" />
    </Row>;
};