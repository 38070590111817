import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { observasjonerActions, observasjonerSelectors } from './store';
import { IObservasjon } from './store/types';
import { useTypedSelector } from '../../../../common/custom-hooks';
import { sortByKey } from '../../../../common/sorting';
export const useObservasjonHooks = (funnId: string | undefined, isAmFunn: boolean, shouldFetchNew?: boolean) => {
  const [hasFetched, setHasFetched] = useState(false);
  const observasjon = useTypedSelector(observasjonerSelectors.getById(funnId), shallowEqual);
  const dispatch = useDispatch();
  const fetchObservasjonList = useCallback(() => funnId ? dispatch(observasjonerActions.fetchId(funnId, isAmFunn)) : undefined, [dispatch, funnId, isAmFunn]);
  const shouldFetch = funnId && (!observasjon || shouldFetchNew && !hasFetched);
  useEffect(() => {
    if (shouldFetch && !observasjon?.loading && !observasjon?.error) {
      fetchObservasjonList();
      setHasFetched(true);
    }
  }, [funnId, observasjon, fetchObservasjonList, shouldFetch]);
  const sortKey: keyof IObservasjon = 'opprettet';
  const observasjonList = sortByKey(sortKey)(observasjon?.data?.observasjonList || []);
  const observasjonLoadingStatus = {
    loading: observasjon?.loading,
    error: observasjon?.error
  };
  return {
    observasjonList,
    observasjonLoadingStatus,
    fetchObservasjonList
  };
};