import React, { ReactNode } from 'react';
import { Row } from '@mattilsynet/mt-ui';
import { ModalWrapper } from '../../components/modal-wrapper';
import { ModalButtonsContainer } from '../../components/modal-buttons-container';
import { modalContentPadding } from '../../components/modal-buttons-container/constants';
import { ModalButton } from '../../components/modal-button';
interface IDeleteModalStorProps {
  isOpen: boolean;
  onCancel: () => void;
  onDelete: () => void;
  title?: string;
  description?: string | ReactNode;
  confirmText?: string;
  cancelText?: string;
}
export const DeleteModalLarge = ({
  isOpen,
  onCancel,
  onDelete,
  title = 'Er du sikker?',
  description = '',
  confirmText = 'Ja',
  cancelText = 'Avbryt'
}: IDeleteModalStorProps) => <ModalWrapper fullscreenMobile isOpen={isOpen} onCancel={onCancel} title={title} data-sentry-element="ModalWrapper" data-sentry-component="DeleteModalLarge" data-sentry-source-file="index.tsx">
    <div id="delete-modal-large">
      <Row className="description" spacing={2} padding={modalContentPadding} align={'flex-start'} data-sentry-element="Row" data-sentry-source-file="index.tsx">
        {description}
      </Row>
      <ModalButtonsContainer data-sentry-element="ModalButtonsContainer" data-sentry-source-file="index.tsx">
        <ModalButton onClick={onCancel} secondary data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">
          {cancelText}
        </ModalButton>

        <ModalButton onClick={onDelete} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">{confirmText}</ModalButton>
      </ModalButtonsContainer>
    </div>
  </ModalWrapper>;