import * as R from 'ramda'
import { AnyAction } from 'redux'
import { Epic, combineEpics } from 'redux-observable'
import {
  catchError,
  filter,
  mergeMap,
  map,
  of,
  withLatestFrom,
  merge,
} from 'rxjs'
import {
  tidligereTilsynskvitteringerActionTypes,
  tidligereTilsynskvitteringerActions,
} from '../store'
import { AjaxResponse } from 'rxjs/ajax'
import {
  ITidligereTilsynskvittering,
  ITidligereTilsynskvitteringerResponse,
  TilsynskvitteringStatus,
} from '../types'
import { IStoreState } from '../../../../../reducers/types'
import { ENDPOINT } from '../../../../../constants'
import { ICommonApi } from '../../../../../api'
import { isOfType } from '../../../../../common/redux/helpers'
import { toast } from '../../../../toast'

const fetchTidligereTilsynskvitteringerEpic =
  (commonApi: ICommonApi): Epic<AnyAction, AnyAction, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(
        isOfType(
          tidligereTilsynskvitteringerActionTypes.FETCH_TIDLIGERE_TILSYNSKVITTERINGER
        )
      ),
      withLatestFrom(state$),
      filter(([{ tilsynsobjektIds }]) => R.not(R.isEmpty(tilsynsobjektIds))),
      mergeMap(([{ tilsynsobjektIds }, state]) => {
        return of(state).pipe(
          commonApi.post(
            `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/tilsynsobjekter/info-tidligere-tilsyn`,
            state,

            tilsynsobjektIds
          ),
          map(
            (res: AjaxResponse<any>) =>
              res.response._embedded.tilsynsobjektKvitteringDTOList[0]
          ),
          map((data: ITidligereTilsynskvitteringerResponse) => {
            const filteredTilsynskvitteringer = data.tilsynskvitteringer.filter(
              (tilsynskvittering: ITidligereTilsynskvittering) =>
                tilsynskvittering.status === TilsynskvitteringStatus.FERDIGSTILT
            )

            return tidligereTilsynskvitteringerActions.fetchTidligereTilsynskvitteringerOk(
              { ...data, tilsynskvitteringer: filteredTilsynskvitteringer }
            )
          }),
          catchError((error) =>
            merge(
              of(
                toast.actions.showToast({
                  text: 'Kunne ikke hente tidligere tilsynskvitteringer.',
                  timeoutSeconds: 5,
                  dismissible: true,
                  type: 'DANGER',
                })
              ),
              of(
                tidligereTilsynskvitteringerActions.fetchTidligereTilsynskvitteringerFail(
                  error
                )
              )
            )
          )
        )
      })
    )

export default (commonApi: ICommonApi) =>
  combineEpics(fetchTidligereTilsynskvitteringerEpic(commonApi))
