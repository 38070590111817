import { actions, actionTypes, reducer, selectors } from './store'
import epic from './epic'
import { hooks } from './hooks'
import { kodeverkToTreeComponentObject } from './utils/kodeverkToTreeComponentObject'
export type { IKodeverkData } from './store/types'

const kodeverk = {
  actions,
  actionTypes,
  reducer,
  selectors,
  epic,
  hooks,
  kodeverkToTreeComponentObject,
}

export { kodeverk }
