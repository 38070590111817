import React, { ChangeEvent, useCallback, useState } from 'react';
import { Column, Row, RowItem, IconButton, Cross } from '@mattilsynet/mt-ui';
import Textarea from '@mattilsynet/mt-ui/dist/elements/text-area';
import './style.css';
import { IVeiledning } from '../../../../ducks/kvittering/veiledning/types';
import { useUpdateVeiledning } from '../..';
import { useKvitteringId } from '../../../../common/kvittering-context';
import { ITilsynsobjekt } from '../../../tilsynsobjekter/types';
interface IVeiledningItemProps {
  tilsynsobjekt: ITilsynsobjekt;
  veiledning: IVeiledning;
  onRemoveVeiledning: (veiledning: IVeiledning) => void;
  danger: boolean;
  autoFocus?: boolean;
}
const VeiledningItem = ({
  tilsynsobjekt,
  veiledning,
  onRemoveVeiledning,
  danger,
  autoFocus
}: IVeiledningItemProps) => {
  const [veiledningText, setVeiledningText] = useState(veiledning.tekst);
  const kvitteringId = useKvitteringId();
  const {
    mutate: onUpdateVeiledning
  } = useUpdateVeiledning(kvitteringId, tilsynsobjekt);
  const onChangeDescription = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    const tekst = event.currentTarget.value;
    setVeiledningText(tekst);
    onUpdateVeiledning({
      veiledning: {
        ...veiledning,
        tekst
      }
    });
  }, [veiledning, setVeiledningText, onUpdateVeiledning]);
  const errorText = danger && !veiledningText ? 'Veiledningen mangler beskrivelse' : '';
  return <Column fluid spacing={1} data-sentry-element="Column" data-sentry-component="VeiledningItem" data-sentry-source-file="index.tsx">
      <Row className="veiledning-item-text-area" data-sentry-element="Row" data-sentry-source-file="index.tsx">
        <RowItem flex={1} data-sentry-element="RowItem" data-sentry-source-file="index.tsx">
          <Textarea onChange={onChangeDescription} placeholder="Beskrivelse av veiledning" value={veiledningText} label="Beskrivelse av veiledning" danger={Boolean(errorText)} errorText={errorText} autoFocus={autoFocus} data-sentry-element="Textarea" data-sentry-source-file="index.tsx" />
        </RowItem>
        <IconButton onClick={() => onRemoveVeiledning(veiledning)} icon={<Cross innerColor="white" />} className="veiledning-item-close-icon" ariaLabel="Ny veiledning" data-sentry-element="IconButton" data-sentry-source-file="index.tsx" />
      </Row>
    </Column>;
};
export { VeiledningItem };