import React, { useCallback } from 'react';
import { Column, ContrastBox, LoadingDots, TextLink, Row, Text } from '@mattilsynet/mt-ui';
import './style.css';
import { ITilsynsobjekt } from '../../tilsynsobjekter/types';
import { KontrollpunktListEdit } from './kontrollpunkt-list-edit';
import { offlineOnOpenTidligereKontrollpunkter, useSingleToast } from '../../../common/toast';
import { useTypedSelector } from '../../../common/custom-hooks';
import { useDispatch } from 'react-redux';
import { tidligereKontrollpunkterActions } from '../../tidligere-kontrollpunkter/tidligere-kontrollpunkter-state';
import { compareSorteringsrekkefoelge } from '../helpers';
interface KontrollpunkterSectionProps {
  isLoading: boolean;
  tilsynsobjekter: ITilsynsobjekt[];
  openTidligereKontrollpunkter?: () => void;
}
export const KontrollpunkterSection = ({
  isLoading,
  tilsynsobjekter,
  openTidligereKontrollpunkter
}: KontrollpunkterSectionProps) => {
  const dispatch = useDispatch();
  const singleToast = useSingleToast();
  const isOffline = useTypedSelector(state => state.ui.offline);
  const onOpenTidligereKontrollpunkter = useCallback((tilsynsobjektId: string) => {
    if (isOffline) {
      return singleToast.showToast(offlineOnOpenTidligereKontrollpunkter());
    }
    if (openTidligereKontrollpunkter) {
      dispatch(tidligereKontrollpunkterActions.setCurrentTilsynsobjektId(tilsynsobjektId));
      openTidligereKontrollpunkter();
    }
  }, [isOffline, singleToast, dispatch, openTidligereKontrollpunkter]);
  if (isLoading && window.navigator.onLine) {
    return <LoadingDots />;
  }
  return <section className="kontrollpunkter-section" data-sentry-component="KontrollpunkterSection" data-sentry-source-file="kontrollpunkt-section.tsx">
      <Column fluid spacing={2} data-sentry-element="Column" data-sentry-source-file="kontrollpunkt-section.tsx">
        {tilsynsobjekter.map(({
        idstring,
        tilsynsobjektId,
        id,
        navn,
        aktivitetsBeskrivelse,
        kontrollpunkter
      }) => {
        const key = idstring ?? (tilsynsobjektId || id);
        return <ContrastBox noBorders backgroundColor="white" key={key} className="kontrollpunkter-section__contrastbox">
                <Text as="h3" size="heading3" weight="bold">
                  {`Kontrollpunkter for ${navn} (${aktivitetsBeskrivelse})`}
                </Text>

                <KontrollpunktListEdit kontrollpunkter={kontrollpunkter?.slice().sort(compareSorteringsrekkefoelge)} tilsynsobjektId={Number(id)} />

                {openTidligereKontrollpunkter && <Row center>
                    <TextLink onClick={() => onOpenTidligereKontrollpunkter(id ?? '')}>
                      Hent fra tidligere tilsynskvittering
                    </TextLink>
                  </Row>}
              </ContrastBox>;
      })}
      </Column>
    </section>;
};