import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Row, IconButton, ExitIcon, Column } from '@mattilsynet/mt-ui';
import { ModalWrapper } from '../modal-wrapper';
import { ModalButtonsContainer } from '../modal-buttons-container';
import { ModalButton } from '../modal-button';
import { modalContentPaddingLarge } from '../modal-buttons-container/constants';
import { useTKNavigate } from '../../common/navigation';
import { useDispatch } from 'react-redux';
import { kvitteringActions } from '../../ducks/kvittering/actions';
const GoToForsiden = () => {
  const navigate = useTKNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const withMargin = useMediaQuery({
    query: '(min-width: 720px)'
  });
  const dispatch = useDispatch();
  const onReturnToHomepage = () => {
    dispatch(kvitteringActions.setCurrentKvitteringId(''));
    navigate('/');
  };
  return <>
      <Row margin={[withMargin ? 1.5 : 0, 0]} className="forsiden-user-menu" data-sentry-element="Row" data-sentry-source-file="index.tsx">
        <IconButton onClick={onReturnToHomepage} icon={<ExitIcon />} ariaLabel="Til forsiden" textColor="white" small data-sentry-element="IconButton" data-sentry-source-file="index.tsx">
          <p>Til forsiden</p>
        </IconButton>
      </Row>

      {/* Leaving this, if caused to be troublesome not having it */}
      <ModalWrapper onCancel={() => setIsModalOpen(false)} isOpen={isModalOpen} fullscreenMobile data-sentry-element="ModalWrapper" data-sentry-source-file="index.tsx">
        <Column padding={modalContentPaddingLarge} spacing={5} margin={3} data-sentry-element="Column" data-sentry-source-file="index.tsx">
          <Column spacing={4} margin={[0, 2]} align="center" data-sentry-element="Column" data-sentry-source-file="index.tsx">
            <Row data-sentry-element="Row" data-sentry-source-file="index.tsx">
              Du er i ferd med å forlate utfyllingen av tilsynskvitteringen.
            </Row>
            <Row data-sentry-element="Row" data-sentry-source-file="index.tsx">Det du har fylt ut er lagret og du kan fortsette senere.</Row>
            <Row data-sentry-element="Row" data-sentry-source-file="index.tsx">
              <b>Er du sikker på at du vil forlate utfyllingen?</b>
            </Row>
          </Column>
        </Column>
        <ModalButtonsContainer data-sentry-element="ModalButtonsContainer" data-sentry-source-file="index.tsx">
          <ModalButton secondary onClick={() => setIsModalOpen(false)} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">
            Nei, jeg vil fortsette utfyllingen
          </ModalButton>
          <ModalButton onClick={() => navigate('/')} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">
            Ja, jeg vil forlate utfyllingen
          </ModalButton>
        </ModalButtonsContainer>
      </ModalWrapper>
    </>;
};
export default GoToForsiden;