import React from 'react';
import { StartTilsynView } from './view';
import { useParams } from 'react-router';
import { KvitteringIdProvider } from '../../common/kvittering-context';
import { createViewContext } from '../../features/views';
const [ViewsProvider, useViewsContext] = createViewContext<'default' | 'fullversjonImage' | 'tidligereKontrollpunkter'>('default');
export { useViewsContext };
const StartTilsyn = () => {
  const {
    id: kvitteringId
  } = useParams();
  return <KvitteringIdProvider value={kvitteringId!} data-sentry-element="KvitteringIdProvider" data-sentry-component="StartTilsyn" data-sentry-source-file="index.tsx">
      <ViewsProvider data-sentry-element="ViewsProvider" data-sentry-source-file="index.tsx">
        <StartTilsynView data-sentry-element="StartTilsynView" data-sentry-source-file="index.tsx" />
      </ViewsProvider>
    </KvitteringIdProvider>;
};
export default StartTilsyn;