import { useTypedSelector } from '../common/custom-hooks';
import { IStoreState } from '../reducers/types';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useNetworkConnectionToast } from '../utils/network';
import { AppHeader, Row } from '@mattilsynet/mt-ui';
import GoToForsiden from '../components/go-to-forsiden';
import TopMenuElements from '../containers/top-menu-elements';
import { ActiveTogglesView } from '../modals/active-toggles/active-toggles-view';
import { AccordionBarOffline } from '../components/accordion-bar-offline';
import { Toast } from '../features/toast';
import React from 'react';
import { SynkStatus } from '../components/synk-status';
import { useParams } from 'react-router';
import { ErrorBoundary } from '../features/error-boundary/ErrorBoundary';
import { UpdateNotification } from '../components/update-notification';
import { uiSelectors } from '../ducks/ui/selectors';
import { CaptureType } from '../sentry';
export const Layout = () => {
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline);
  const env = useTypedSelector(state => state.ui.environment);
  const shouldShowUpdateNotification = useTypedSelector(uiSelectors.getHasUpdated);
  const location = useLocation();
  const {
    id: kvitteringId
  } = useParams();
  const [searchParams] = useSearchParams();
  const currentSlide = searchParams.get('currentSlide');
  const isInKvittering = location.pathname.includes('start-tilsyn');
  useNetworkConnectionToast();
  return <>
      <Row data-sentry-element="Row" data-sentry-source-file="layout.tsx">
        <AppHeader isOffline={isOffline} appName="Tilsynskvittering" env={env} right={<>
              {isInKvittering ? <GoToForsiden /> : <TopMenuElements />}
              {env !== 'prod' && <ActiveTogglesView />}
            </>} data-sentry-element="AppHeader" data-sentry-source-file="layout.tsx" />
      </Row>
      {shouldShowUpdateNotification && <UpdateNotification />}
      {isOffline && <AccordionBarOffline />}
      {isInKvittering && kvitteringId && currentSlide === '4' && <SynkStatus kvitteringId={kvitteringId} />}

      <ErrorBoundary captureType={CaptureType.ROUTE} data-sentry-element="ErrorBoundary" data-sentry-source-file="layout.tsx">
        <Outlet data-sentry-element="Outlet" data-sentry-source-file="layout.tsx" />
      </ErrorBoundary>

      <Toast.FixedToastArea id="common-toastarea" data-sentry-element="unknown" data-sentry-source-file="layout.tsx" />
    </>;
};