import * as R from 'ramda'
import { IProveanalysesvarActions, IProveanalysesvarState } from '../types'
import { addLoadingStatusToList } from './helpers'
import { ROOT_STORE_NAME } from '../../../store'
import { IStoreState } from '../../../../../reducers/types'
import { commonRedux } from '../../../../../common/redux'

export const STORE_NAME = '@tilsyn/proveanalysesvar'
const reducerId = 'tilsynsobjektId'

export const proveanalysesvarActionTypes = {
  FETCH_LIST_BY_IDS: `${STORE_NAME}/FETCH_LIST_BY_IDS` as const,
  FETCH_LIST_BY_IDS_OK: `${STORE_NAME}/FETCH_LIST_BY_IDS_OK` as const,
  FETCH_LIST_BY_IDS_FAIL: `${STORE_NAME}/FETCH_LIST_BY_IDS_FAIL` as const,
}

export const proveanalysesvarSelectors = {
  getById: (id: string) => (state: IStoreState) =>
    state[ROOT_STORE_NAME][STORE_NAME].data[id],
}

export const proveanalysesvarActions = {
  fetchListByIds: (ids: string[]) => ({
    type: proveanalysesvarActionTypes.FETCH_LIST_BY_IDS,
    ids,
  }),

  fetchListByIdsOk: (data) => ({
    type: proveanalysesvarActionTypes.FETCH_LIST_BY_IDS_OK,
    data,
  }),

  fetchListByIdsFail: (ids: string[], error: string) => ({
    type: proveanalysesvarActionTypes.FETCH_LIST_BY_IDS_FAIL,
    ids,
    error,
  }),
}

const customReducer = commonRedux.createReducer<
  IProveanalysesvarState,
  IProveanalysesvarActions
>(commonRedux.initialListState, {
  [proveanalysesvarActionTypes.FETCH_LIST_BY_IDS]: (state, { ids }) =>
    R.reduce(
      (acc, id) => addLoadingStatusToList(id, true, undefined, acc),
      state,
      ids
    ),

  [proveanalysesvarActionTypes.FETCH_LIST_BY_IDS_OK]: (state, { data }) =>
    R.reduce(
      (acc, newData) => commonRedux.addDataToList(reducerId, newData, acc),
      state,
      data
    ),

  [proveanalysesvarActionTypes.FETCH_LIST_BY_IDS_FAIL]: (
    state,
    { ids, error }
  ) =>
    R.reduce(
      (acc, id) => addLoadingStatusToList(id, false, error, acc),
      state,
      ids
    ),
})

export const proveanalysesvarReducer = {
  [STORE_NAME]: customReducer,
}
