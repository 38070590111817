import React, { Dispatch, FC, createContext, useContext, useReducer, ReactNode, useMemo } from 'react';
type ViewState<T extends string> = T;
interface ViewContext<T extends string> {
  view: T;
  viewDispatch: Dispatch<T>;
}

/**
 * @returns \[ViewsProvider, useViewsContext\]
 *
 * Wrap your content with ViewsProvider
 *
 * Use useViewsContext whenever you want to access
 * the view state or change the view state with dispatch
 */
export const createViewContext = <T extends string = 'default',>(initialView: T) => {
  const reducer = (_: ViewState<T>, action: T) => action;
  const Context = createContext<ViewContext<T>>({
    view: initialView,
    viewDispatch: () => null
  });
  const ViewsProvider: FC<{
    children: ReactNode;
  }> = ({
    children
  }) => {
    const [view, viewDispatch] = useReducer(reducer, initialView);
    const viewContext = useMemo(() => ({
      view,
      viewDispatch
    }), [view, viewDispatch]);
    return <Context.Provider value={viewContext} data-sentry-element="unknown" data-sentry-component="ViewsProvider" data-sentry-source-file="index.tsx">{children}</Context.Provider>;
  };
  const useViewsContext = () => useContext(Context) as {
    view: T;
    viewDispatch: Dispatch<T>;
  };
  return [ViewsProvider, useViewsContext] as const;
};