import { CommonReduxTypes, commonRedux } from '../../common/redux'
import { produce } from 'immer'
import { IStoreState } from '../../reducers/types'

export interface ITidligereKontrollpunkterState {
  currentTilsynsobjektId: string
}

export {
  tidligereKontrollpunkterActions,
  tidligereKontrollpunkterReducer,
  tidligereKontrollpunkterSelectors,
}

const initialState = { currentTilsynsobjektId: '' }

const STORE_NAME = 'tidligereKontrollpunkter'

const tidligereKontrollpunkterActionTypes = {
  SET_CURRENT_TILSYNSOBJEKT_ID:
    `${STORE_NAME}/SET_CURRENT_TILSYNSOBJEKT_ID` as const,
  RESET: `${STORE_NAME}/RESET` as const,
} as const

const tidligereKontrollpunkterActions = {
  setCurrentTilsynsobjektId: (tilsynsobjektId: string) => ({
    type: tidligereKontrollpunkterActionTypes.SET_CURRENT_TILSYNSOBJEKT_ID,
    tilsynsobjektId,
  }),

  reset: () => ({
    type: tidligereKontrollpunkterActionTypes.RESET,
  }),
}

type TidligereKontrollpunkterActions =
  CommonReduxTypes.IGetActionTypesFromObject<
    typeof tidligereKontrollpunkterActions
  >

const tidligereKontrollpunkterReducer = commonRedux.createReducer<
  ITidligereKontrollpunkterState,
  TidligereKontrollpunkterActions
>(initialState, {
  [tidligereKontrollpunkterActionTypes.SET_CURRENT_TILSYNSOBJEKT_ID]: (
    state,
    { tilsynsobjektId }
  ) =>
    produce(state, (draft) => {
      draft.currentTilsynsobjektId = tilsynsobjektId
    }),

  [tidligereKontrollpunkterActionTypes.RESET]: (state) =>
    produce(state, (draft) => {
      draft.currentTilsynsobjektId = ''
    }),
})

const tidligereKontrollpunkterSelectors = {
  selectTilsynsobjektId: (state: IStoreState) =>
    state.tidligereKontrollpunkter.currentTilsynsobjektId,
}
