import { combineReducers } from 'redux'
import { tilsynsobjektReducer } from './ducks/tilsynsobjekt/store'
import { tidligereTilsynskvitteringerReducer } from './ducks/tidligere-tilsynskvitteringer/store'
import { brreg } from '../brreg'
import { faktabeskrivelserTidligereTilsynReducer } from './ducks/faktabeskrivelser-tidligere-tilsyn/store'
import { amFunnReducer } from './ducks/am-funn/store'
import { observasjonerReducer } from './ducks/observasjoner/store'
import { orgenhetReducer } from './ducks/orgenhet'
import { kassasjonerReducer } from './ducks/kassasjoner/store'
import { pmFunnReducer } from './ducks/pm-funn/store'
import { funnHistorikkReducer } from './ducks/funn-historikk/store'
import { produsentMeldingsHistorikkReducer } from './ducks/produsent-meldings-historikk/store'
import { proveanalysesvarReducer } from './ducks/proveanalysesvar/store'
import { restriksjonerReducer } from './ducks/restriksjoner/store'

export const ROOT_STORE_NAME = '@tilsynsobjekt'

export const reducer = {
  [ROOT_STORE_NAME]: combineReducers({
    ...amFunnReducer,
    ...observasjonerReducer,
    ...orgenhetReducer,
    ...kassasjonerReducer,
    ...pmFunnReducer,
    ...funnHistorikkReducer,
    ...produsentMeldingsHistorikkReducer,
    ...proveanalysesvarReducer,
    ...restriksjonerReducer,
    ...tilsynsobjektReducer,
    ...tidligereTilsynskvitteringerReducer,
    ...faktabeskrivelserTidligereTilsynReducer,
  }),
  ...brreg.reducer,
}
