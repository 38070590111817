import React, { useEffect, useState } from 'react';
import { useTypedSelector } from '../../../common/custom-hooks';
import { IStoreState } from '../../../reducers/types';
import { ERROR_FETCHING_SAMMENSTILLING, SAVING } from '../../../ducks/kvittering/types';
import { useDispatch } from 'react-redux';
import { kvitteringActions } from '../../../ducks/kvittering/actions';
import { optionsBilderGjennomgaat, optionsRegistrertInnholdGjennomgaatt } from '../sammenstilling-options';
import { BegrunnelseModal } from '../../../modals';
import { getDefaultBegrunnelse, SammenstillingEdit, SammenstillingError, SammenstillingLoading } from '../sammenstilling-common';
import { isUuid } from '../../../common/string-utils';
import { useKvitteringId } from '../../../common/kvittering-context';
import { useKvittering, useTilsynstypeBeskrivelse } from '../../../features/kvitteringer';
import { useAndreDeltakere, useMattilsynetDeltakere, useVirksomhetDeltakere } from '../../../features/deltakere';
import { ENDPOINT } from '../../../constants';
import { useGalleryImages } from '../../../features/bilder';
interface ISammenstillingSlideProps {
  shouldValidate: boolean;
}
const SammenstillingSlide = ({
  shouldValidate
}: ISammenstillingSlideProps) => {
  const dispatch = useDispatch();
  const kvitteringId = useKvitteringId();
  const {
    data: kvittering,
    isLoading,
    isError,
    refetch
  } = useKvittering(kvitteringId);
  const [allBilder, refetchBildeMetadataList] = useGalleryImages(kvitteringId ?? '');
  const {
    data: typeBeskrivelse
  } = useTilsynstypeBeskrivelse(kvittering?.typeTilsyn ?? '');
  const {
    data: tempVirksomhetDeltakere
  } = useVirksomhetDeltakere(`${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${kvitteringId}/virksomhet-deltagere`, {
    enabled: kvittering !== undefined
  });
  const {
    data: tempMattilsynetDeltakere
  } = useMattilsynetDeltakere(`${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${kvitteringId}/mattilsynet-deltagere`, {
    enabled: kvittering !== undefined
  });
  const {
    data: tempAndreDeltakere
  } = useAndreDeltakere(`${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${kvitteringId}/andre-deltagere`, {
    enabled: kvittering !== undefined
  });
  const [isBegrunnelseBildetModalOpen, setIsBegrunnelseBildetModalOpen] = useState(false);
  const [isBegrunnelseRegistrertInnholdGjennomgaattModalOpen, setIsBegrunnelseRegistrertInnholdGjennomgaattModalOpen] = useState(false);
  const [isInnholdRadioDirty, setIsInnholdRadioDirty] = useState(false);
  const [isBildetRadioDirty, setIsBildetRadioDirty] = useState(false);
  const [shouldShowLoading, setShouldShowLoading] = useState(true);
  const [hasBilderGjennomgaattBegrunnelse, setHasBilderGjennomgaattBegrunnelse] = useState(false);
  const [hasRegistrertInnholdGjennomgaattBegrunnelse, setHasRegistrertInnholdGjennomgaattBegrunnelse] = useState(false);
  const kvitteringOld = useTypedSelector((state: IStoreState) => state.kvittering.selectedKvittering.data);
  const oldKvitteringStatus = useTypedSelector((state: IStoreState) => state.kvittering.selectedKvittering.status);
  const loading = useTypedSelector((state: IStoreState) => state.kvittering.loading);
  const {
    faktaarkUtlevert,
    registrertInnholdGjennomgaatt,
    registrertInnholdGjennomgaattBegrunnelse,
    bilderGjennomgaattBegrunnelse,
    bilderGjennomgaatt
  } = kvitteringOld;
  const currentKvitteringStatus = useTypedSelector((state: IStoreState) => state.kvittering.selectedKvittering.status);
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline);
  useEffect(() => {
    setHasBilderGjennomgaattBegrunnelse(bilderGjennomgaattBegrunnelse !== '' && !isUuid(bilderGjennomgaattBegrunnelse));
    setHasRegistrertInnholdGjennomgaattBegrunnelse(registrertInnholdGjennomgaattBegrunnelse !== '' && !isUuid(registrertInnholdGjennomgaattBegrunnelse));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bilderGjennomgaattBegrunnelse, registrertInnholdGjennomgaattBegrunnelse]);
  useEffect(() => {
    if (!isOffline) {
      dispatch(kvitteringActions.fetchSammenstilling());
      refetchBildeMetadataList();
    }
  }, [dispatch, isOffline, refetchBildeMetadataList]);
  useEffect(() => {
    if (kvitteringOld.registrertInnholdGjennomgaatt) setIsInnholdRadioDirty(false);
    if (isUuid(kvitteringOld.registrertInnholdGjennomgaattBegrunnelse)) setIsInnholdRadioDirty(true);
    if (kvitteringOld.bilderGjennomgaatt) setIsInnholdRadioDirty(false);
    if (isUuid(kvitteringOld.bilderGjennomgaattBegrunnelse)) setIsBildetRadioDirty(true);
  }, [kvitteringOld, refetch]);
  useEffect(() => {
    if (oldKvitteringStatus !== SAVING) {
      refetch();
    }
  }, [refetch, oldKvitteringStatus]);
  const onChangeFaktaarket = (value: string) => {
    setShouldShowLoading(false);
    dispatch(kvitteringActions.updateCurrentKvittering({
      faktaarkUtlevert: value === '1'
    }));
  };
  const onChangeBilderGjennomgaatt = (value: string) => {
    setShouldShowLoading(false);
    dispatch(kvitteringActions.updateCurrentKvittering({
      bilderGjennomgaatt: value === '1',
      bilderGjennomgaattBegrunnelse: value === '1' ? undefined : bilderGjennomgaattBegrunnelse
    }));
  };
  const onChangeBilderGjennomgaattBegrunnelse = (value: string) => {
    setShouldShowLoading(false);
    dispatch(kvitteringActions.updateCurrentKvittering({
      bilderGjennomgaattBegrunnelse: value
    }, 1000));
  };
  const onChangeRegistrertInnholdGjennomgaatt = (value: string) => {
    setShouldShowLoading(false);
    dispatch(kvitteringActions.updateCurrentKvittering({
      registrertInnholdGjennomgaatt: value === '1',
      registrertInnholdGjennomgaattBegrunnelse: value === '1' ? undefined : registrertInnholdGjennomgaattBegrunnelse
    }));
  };
  const onChangeRegistrertInnholdGjennomgaattBegrunnelse = (value: string) => {
    setShouldShowLoading(false);
    dispatch(kvitteringActions.updateCurrentKvittering({
      registrertInnholdGjennomgaattBegrunnelse: value
    }, 1000));
  };
  if (currentKvitteringStatus === SAVING && shouldShowLoading && loading || isLoading) {
    return <SammenstillingLoading />;
  }
  if (currentKvitteringStatus === ERROR_FETCHING_SAMMENSTILLING || isError) {
    return <SammenstillingError status={ERROR_FETCHING_SAMMENSTILLING} errorAction={() => {
      dispatch(kvitteringActions.fetchSammenstilling());
      void refetch();
    }} />;
  }
  return <>
      {isBegrunnelseBildetModalOpen && <BegrunnelseModal isOpen={isBegrunnelseBildetModalOpen} begrunnelse={getDefaultBegrunnelse(bilderGjennomgaattBegrunnelse, optionsBilderGjennomgaat)} hasContent={hasBilderGjennomgaattBegrunnelse} onCancel={() => {
      setHasBilderGjennomgaattBegrunnelse(bilderGjennomgaattBegrunnelse !== '');
      setIsBegrunnelseBildetModalOpen(false);
    }} onUpdate={onChangeBilderGjennomgaattBegrunnelse} />}
      {isBegrunnelseRegistrertInnholdGjennomgaattModalOpen && <BegrunnelseModal isOpen={isBegrunnelseRegistrertInnholdGjennomgaattModalOpen} begrunnelse={getDefaultBegrunnelse(registrertInnholdGjennomgaattBegrunnelse, optionsRegistrertInnholdGjennomgaatt)} hasContent={hasRegistrertInnholdGjennomgaattBegrunnelse} onCancel={() => {
      setHasRegistrertInnholdGjennomgaattBegrunnelse(registrertInnholdGjennomgaattBegrunnelse !== '');
      setIsBegrunnelseRegistrertInnholdGjennomgaattModalOpen(false);
    }} onUpdate={onChangeRegistrertInnholdGjennomgaattBegrunnelse} />}
      {kvittering && <SammenstillingEdit kvittering={kvittering} allBilder={allBilder} typeBeskrivelse={[typeBeskrivelse ?? '']} shouldValidate={shouldValidate} tempVirksomhetDeltakere={tempVirksomhetDeltakere} tempMattilsynetDeltakere={tempMattilsynetDeltakere} tempAndreDeltakere={tempAndreDeltakere}
    // Faktaarket
    faktaarkUtlevert={faktaarkUtlevert} onChangeFaktaaket={val => onChangeFaktaarket(val)}
    // Bilder Gjennomgått
    bilderGjennomgaatt={bilderGjennomgaatt} bilderGjennomgaattBegrunnelse={bilderGjennomgaattBegrunnelse} isBildetRadioDirty={isBildetRadioDirty} onChangeBilderGjennomgaatt={val => onChangeBilderGjennomgaatt(val)} onChangeBilderGjennomgaattBegrunnelse={val => onChangeBilderGjennomgaattBegrunnelse(val)} setIsBegrunnelseBildetModalOpen={() => setIsBegrunnelseBildetModalOpen(true)}
    // Registrert Innhold
    registrertInnholdGjennomgaatt={registrertInnholdGjennomgaatt} registrertInnholdGjennomgaattBegrunnelse={registrertInnholdGjennomgaattBegrunnelse} isInnholdRadioDirty={isInnholdRadioDirty} onChangeRegistrertInnholdGjennomgaatt={val => onChangeRegistrertInnholdGjennomgaatt(val)} onChangeRegistrertInnholdGjennomgaattBegrunnelse={val => onChangeRegistrertInnholdGjennomgaattBegrunnelse(val)} setIsBegrunnelseRegistrertInnholdGjennomgaattModalOpen={() => setIsBegrunnelseRegistrertInnholdGjennomgaattModalOpen(true)} />}
    </>;
};
export { SammenstillingSlide };