import { fetchWithIndexHelpers } from './fetchWithIndexHelper'
import * as helpers from './helpers'
import * as CommonReduxTypes from './types'
import { createReducer } from './create-reducer'

export { CommonReduxTypes }
export const commonRedux = {
  fetchWithIndexHelpers,
  createReducer,
  ...helpers,
}
