import { combineEpics, Epic } from 'redux-observable'
import { AnyAction } from 'redux'
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import {
  faktabeskrivelserTidligereTilsynActions,
  faktabeskrivelserTidligereTilsynActionTypes,
} from './store'
import { of } from 'rxjs'
import {
  IFaktabeskrivelserTidligereTilsynResponse,
  IFaktabeskrivelseTidligereTilsyn,
} from './types'
import { IStoreState } from '../../../../reducers/types'
import { ICommonApi } from '../../../../api'
import { ENDPOINT } from '../../../../constants'
import { isOfType } from '../../../../common/redux/helpers'

const fetchFaktabeskrivelserTidligereTilsynEpic =
  (commonApi: ICommonApi): Epic<AnyAction, AnyAction, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(
        isOfType(
          faktabeskrivelserTidligereTilsynActionTypes.FETCH_FAKTABESKRIVELSER_TIDLIGERE_TILSYN
        )
      ),
      withLatestFrom(state$),
      mergeMap(([{ tilsynsobjektId }, state]) => {
        return of(state).pipe(
          commonApi.get(
            `${ENDPOINT.TILSYNSOBJEKTINFO}/v1/adhocbeskrivelser?filter.tilsynsobjektId=${tilsynsobjektId}`,
            state
          ),
          map((res: any) => res._embedded?.adhocbeskrivelse ?? []),
          map((data: IFaktabeskrivelseTidligereTilsyn[]) => {
            const transformedData: IFaktabeskrivelserTidligereTilsynResponse = {
              tilsynsobjektId,
              faktabeskrivelserTidligereTilsyn: data,
            }

            return faktabeskrivelserTidligereTilsynActions.fetchTidligereTilsynskvitteringerOk(
              transformedData
            )
          })
        )
      })
    )

export default (commonApi: ICommonApi) =>
  combineEpics(fetchFaktabeskrivelserTidligereTilsynEpic(commonApi))
