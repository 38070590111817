import React, { useState, useEffect, ReactNode, useCallback } from 'react';
import { LoadingSpinner, Row } from '@mattilsynet/mt-ui';
import './style.css';
import { swipeHelper } from '../../common/swipe';
export const swipeToEnum = {
  NONE: 'NONE',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  DOWN: 'DOWN',
  UP: 'UP'
};
export const useSlider = (totalSlides: number, initialSlide = 0): [currentSlide: number, goToNext: () => void, goToPrevious: () => void, goToSlide: (position: number) => void, swipeTo: string, onSwipe: number] => {
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const [swipeTo, setSwipeTo] = useState(swipeToEnum.NONE);
  const [onSwipe, setOnSwipe] = useState(Math.random);
  const goToNext = useCallback(() => {
    if (currentSlide === totalSlides - 1) return;
    setCurrentSlide(currentSlide => currentSlide + 1);
  }, [currentSlide, totalSlides]);
  const goToPrevious = useCallback(() => {
    if (currentSlide === 0) return;
    setCurrentSlide(currentSlide => currentSlide - 1);
  }, [currentSlide]);
  const goToSlide = useCallback((position: number) => {
    setCurrentSlide(position);
  }, []);
  const element = document.getElementById('slider');
  useEffect(() => {
    if (element) {
      swipeHelper(element, (swipeDir: keyof typeof swipeToEnum) => {
        if (swipeDir !== swipeToEnum.NONE) {
          setSwipeTo(swipeDir);
          setOnSwipe(Math.random());
        }
      });
    }
  }, [element]);
  return [currentSlide, goToNext, goToPrevious, goToSlide, swipeTo, onSwipe];
};
interface ISliderProps {
  slides: ReactNode[];
  newSlide: number;
  loadingSlide?: boolean;
}
export const Slider = ({
  slides,
  newSlide,
  loadingSlide
}: ISliderProps) => {
  const [currentSlide, setCurrentSlide] = useState(newSlide);
  const [x, setX] = useState(0);
  useEffect(() => {
    setX(newSlide * -100);
    const timeOut = setTimeout(() => {
      setCurrentSlide(newSlide);
    }, 550);
    return () => clearTimeout(timeOut);
  }, [newSlide]);
  const renderContent = () => {
    if (loadingSlide) {
      return <Row minHeight="calc(100vh - 300px)" center>
          <LoadingSpinner title="Henter kvitteringen..." small />
        </Row>;
    }
    return <Row className="slider" id="slider" data-sentry-element="Row" data-sentry-component="renderContent" data-sentry-source-file="index.tsx">
        {slides.map((slide, index) => {
        return <Row className="slide-item" key={index} style={{
          transform: `translateX(${x}%)`
        }}>
              {[currentSlide, newSlide].includes(index) ? slide : null}
            </Row>;
      })}
      </Row>;
  };
  return renderContent();
};