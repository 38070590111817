import { combineEpics, Epic } from 'redux-observable'
import {
  catchError,
  concat,
  filter,
  map,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs'
import { IStoreState } from '../../reducers/types'
import { ICommonApi } from '../../api'
import {
  hentKvitteringBegrunnelserActions,
  hentKvitteringBegrunnelserActionTypes,
} from './actions'
import { IBegrunnelseItem, IHentKvitteringBegrunnelserActions } from './types'
import { isOfType } from '../../common/redux/helpers'

const ROOT_URL = '/api/tilsynskvittering-api/v1'

export const fetchKvitteringBegrunnelserEpic =
  (
    commonApi: ICommonApi
  ): Epic<
    IHentKvitteringBegrunnelserActions,
    IHentKvitteringBegrunnelserActions,
    IStoreState
  > =>
  (action$, state$) =>
    action$.pipe(
      filter(
        isOfType(
          hentKvitteringBegrunnelserActionTypes.FETCH_KVITTERING_BEGRUNNELSER
        )
      ),
      withLatestFrom(state$),
      switchMap(([, state]) => {
        return of(state).pipe(
          commonApi.get(
            `${ROOT_URL}/tilsynskvitteringer/overtagelse/overtagelsegrunner`,
            state
          ),
          map((data) => {
            const begrunnelser: IBegrunnelseItem[] =
              data?._embedded?.overtagelseBegrunnelseList ?? []
            return hentKvitteringBegrunnelserActions.fetchKvitteringBegrunnelserSuccess(
              begrunnelser
            )
          }),
          catchError((error) =>
            concat(
              of(
                hentKvitteringBegrunnelserActions.fetchKvitteringBegrunnelserFail(
                  error.message
                )
              )
            )
          )
        )
      })
    )

export default (commonApi: ICommonApi) =>
  combineEpics(fetchKvitteringBegrunnelserEpic(commonApi))
