import React, { useState, useEffect, Fragment } from 'react';
import { Row, Column, ShadowBox, TextLink, AddCircle } from '@mattilsynet/mt-ui';
import { TilsynsobjektItem } from '../tilsynsobjekt-item';
import { IVirksomhetItem, IVirksomhetTilsynsobjekt, ADRESSE_ERROR } from '../../ducks/virksomhet-search/types';
import { useSelector } from 'react-redux';
import { IStoreState } from '../../reducers/types';
import './style.css';
import { virksomhetSearch } from '../../ducks/virksomhet-search';
import { AdresseAccordion } from '../adresse-accordion';
import { MidlertidigTilsynsobjektItem } from '../midlertidig-tilsynsobjekt-item';
import { IAktivitet, IMidlertidigTilsynsobjektVirksomhet } from '../../ducks/kvittering/types';
import { ITilsynsobjekt } from '../../features/tilsynsobjekter/types';
import { useKvittering } from '../../features/kvitteringer';
import { kvitteringSelectors } from '../../ducks/kvittering/selectors';
interface IVirksomehetCardItemProps {
  onSelectTilsynsobjekt: (activity, isSelected: boolean, orgId: string) => void;
  onSelectMidlertidigTilsynsobjekt: (activity, isSelected: boolean, orgId: string) => void;
  item: IVirksomhetItem;
  onClickMidlertidigTilsynsobjekt: (navn, nummer) => void;
  midlertidigTilsynsobjektAktivitet?: IAktivitet;
  midlertidigTilsynsobjektVirksomhet?: IMidlertidigTilsynsobjektVirksomhet;
  onResetMidlertidigTilsynsojekt: () => void;
  unselectedMidlertidigTilsynsobjekt?: ITilsynsobjekt;
  openTilsynsobjektConfirmDeleteModal: (tilsynsobjekt: ITilsynsobjekt) => void;
}
export const VirksomhetCardItem = ({
  onSelectTilsynsobjekt,
  onSelectMidlertidigTilsynsobjekt,
  item,
  onClickMidlertidigTilsynsobjekt,
  midlertidigTilsynsobjektAktivitet,
  midlertidigTilsynsobjektVirksomhet,
  onResetMidlertidigTilsynsojekt,
  unselectedMidlertidigTilsynsobjekt,
  openTilsynsobjektConfirmDeleteModal
}: IVirksomehetCardItemProps) => {
  const currentKvitteringId = useSelector(kvitteringSelectors.getCurrentKvitteringId);
  const virksomhetSearchState = useSelector((state: IStoreState) => state.virksomhetSearch);
  const {
    data: kvittering
  } = useKvittering(currentKvitteringId);
  const [isEnabled, setIsEnabled] = useState(true);
  const orgOrPerson = item.organisasjonsnummer ? `Org nr underenhet: ${item.organisasjonsnummer}` : `Privatperson`;
  useEffect(() => {
    const isEnabled = !kvittering?.virksomhetsNavn || kvittering?.virksomhetsNavn === item.navn;
    const isEnabledIfMidlertidig = (!!unselectedMidlertidigTilsynsobjekt && kvittering?.tilsynsobjekter?.every(t => t.midlertidig)) ?? false;
    setIsEnabled(isEnabled || isEnabledIfMidlertidig);
  }, [item, kvittering, unselectedMidlertidigTilsynsobjekt]);
  useEffect(() => {
    if (kvittering?.tilsynsobjekter?.length === 0) return;
    if (kvittering?.tilsynsobjekter?.some(tilsynsobjekt => tilsynsobjekt.midlertidig && kvittering?.virksomhetsNavn === midlertidigTilsynsobjektVirksomhet?.navn && kvittering?.virksomhetsOrgNr === midlertidigTilsynsobjektVirksomhet?.nummer && midlertidigTilsynsobjektAktivitet?.value === tilsynsobjekt.aktivitetsId)) {
      onResetMidlertidigTilsynsojekt();
    }
  }, []);
  const sortByNavn = (a: IVirksomhetTilsynsobjekt, b: IVirksomhetTilsynsobjekt): 1 | -1 | 0 => {
    const x = a.navn.toLowerCase();
    const y = b.navn.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  };
  const renderLeggTilMidlertidigTilsynsobjekt = (item: {
    navn: string;
    organisasjonsnummer: string;
  }, isSelected: boolean) => {
    return <Column justify={'center'} align={'center'} margin={[2.5, 0, 0, 0]} spacing={1} data-sentry-element="Column" data-sentry-component="renderLeggTilMidlertidigTilsynsobjekt" data-sentry-source-file="index.tsx">
        <TextLink onClick={() => onClickMidlertidigTilsynsobjekt(item.navn, item.organisasjonsnummer)} disabled={!isEnabled} leftIcon={<AddCircle />} data-sentry-element="TextLink" data-sentry-source-file="index.tsx">
          {isSelected ? 'Endre midlertidig tilsynsobjekt' : 'Legg til midlertidig tilsynsobjekt'}
        </TextLink>
      </Column>;
  };
  const renderMidlertidigTilsynsobjektItem = (isAlreadySelected, midlertidigTilsynsobjekt, organisasjonsnummer, index, key?) => <Fragment key={key}>
      <MidlertidigTilsynsobjektItem onSelectTilsynsobjekt={onSelectMidlertidigTilsynsobjekt} isAlreadySelected={isAlreadySelected} midlertidigTilsynsobjekt={midlertidigTilsynsobjekt} isEnabled={isEnabled} organisasjonsnummer={organisasjonsnummer} index={index} virksomhet={item} aktivitet={midlertidigTilsynsobjektAktivitet} openTilsynsobjektConfirmDeleteModal={openTilsynsobjektConfirmDeleteModal} data-sentry-element="MidlertidigTilsynsobjektItem" data-sentry-source-file="index.tsx" />
      {isAlreadySelected && renderLeggTilMidlertidigTilsynsobjekt(item, isAlreadySelected)}
    </Fragment>;
  const renderMidlertidigTilsynsobjekt = item => {
    if (item.navn === midlertidigTilsynsobjektVirksomhet?.navn && item.organisasjonsnummer === midlertidigTilsynsobjektVirksomhet?.nummer) {
      return renderMidlertidigTilsynsobjektItem(false, {}, item.organisasjonsnummer, item.tilsynsobjekter.length);
    } else if (!!item.organisasjonsnummer && (!kvittering?.tilsynsobjekter?.some(t => !!t.midlertidig) || !!unselectedMidlertidigTilsynsobjekt)) {
      return renderLeggTilMidlertidigTilsynsobjekt(item, false);
    }
  };
  const sortedTilsynsobjekter = [...item.tilsynsobjekter].sort(sortByNavn);
  return <ShadowBox data-sentry-element="ShadowBox" data-sentry-component="VirksomhetCardItem" data-sentry-source-file="index.tsx">
      <Column padding={[4, 0]} data-sentry-element="Column" data-sentry-source-file="index.tsx">
        <Row id="virksomhet-card-item" data-sentry-element="Row" data-sentry-source-file="index.tsx">
          <Column fluid align="center" spacing={0.5} data-sentry-element="Column" data-sentry-source-file="index.tsx">
            <Row className="name" data-sentry-element="Row" data-sentry-source-file="index.tsx">
              <p>{item.navn}</p>
            </Row>
            <Row className="orgNr" data-sentry-element="Row" data-sentry-source-file="index.tsx">
              <p>{orgOrPerson}</p>
            </Row>
            <AdresseAccordion item={item} orgNr={item.organisasjonsnummer} fetchAddress={item.organisasjonsnummer ? virksomhetSearch.actions.searchVirksomhetsAdresse({
            search: item.organisasjonsnummer
          }) : virksomhetSearch.actions.searchPersonAdresse({
            search: item.tilsynsobjekter[0] ? item.tilsynsobjekter[0].idstring : ''
          })} hasError={!!virksomhetSearchState.error && virksomhetSearchState.error === ADRESSE_ERROR} data-sentry-element="AdresseAccordion" data-sentry-source-file="index.tsx" />
          </Column>
        </Row>
        {item.tilsynsobjekter.length > 0 && sortedTilsynsobjekter.map((tilsynsobjekt: IVirksomhetTilsynsobjekt, index) => {
        const isAlreadySelected = kvittering?.tilsynsobjekter?.some(({
          tilsynsobjektId
        }) => tilsynsobjektId === tilsynsobjekt.idstring) ?? false;
        return <TilsynsobjektItem key={tilsynsobjekt.idstring} tilsynsobjekt={tilsynsobjekt} onSelectTilsynsobjekt={onSelectTilsynsobjekt} isEnabled={isEnabled || isAlreadySelected} isAlreadySelected={isAlreadySelected} orgNr={item.organisasjonsnummer} index={index} openTilsynsobjektConfirmDeleteModal={openTilsynsobjektConfirmDeleteModal} />;
      })}
        {kvittering?.tilsynsobjekter?.filter(t => t.midlertidig && t.virksomhetsNummer === item.organisasjonsnummer && t.virksomhetsNavn === item.navn && midlertidigTilsynsobjektVirksomhet?.navn !== item.navn && midlertidigTilsynsobjektVirksomhet?.nummer !== item.organisasjonsnummer).map(t => {
        const isAlreadySelected = kvittering?.tilsynsobjekter.some(({
          virksomhetsNavn,
          virksomhetsNummer
        }) => virksomhetsNavn === item.navn && virksomhetsNummer === item.organisasjonsnummer && unselectedMidlertidigTilsynsobjekt?.virksomhetsNummer !== virksomhetsNummer && unselectedMidlertidigTilsynsobjekt?.virksomhetsNavn !== virksomhetsNavn);
        return renderMidlertidigTilsynsobjektItem(isAlreadySelected, t, item.organisasjonsnummer, item.tilsynsobjekter.length, t.tilsynsobjektId || t.id);
      })}
        {renderMidlertidigTilsynsobjekt(item)}
      </Column>
    </ShadowBox>;
};