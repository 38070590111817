import React, { useState, useEffect, useRef, useContext } from 'react';
import { Row, RowItem, Column, Button, ThemeContext } from '@mattilsynet/mt-ui';
import './style.css';
import { IIdentitetItem, IVirksomhetTilsynsobjekt } from '../../ducks/virksomhet-search/types';
import { Avdelingstilhorighet } from '../../features/avdelingstilhorighet';
import { useTilsynsobjekter } from '../../features/tilsynsobjekter';
import { useKvitteringId } from '../../common/kvittering-context';
import { ITilsynsobjekt } from '../../features/tilsynsobjekter/types';
interface ITilsynsobjektItemProps {
  onSelectTilsynsobjekt: any;
  tilsynsobjekt: IVirksomhetTilsynsobjekt;
  isEnabled: boolean;
  isAlreadySelected?: boolean;
  orgNr: string;
  index: number;
  openTilsynsobjektConfirmDeleteModal: (tilsynsobjekt: ITilsynsobjekt) => void;
}
export const TilsynsobjektItem = ({
  onSelectTilsynsobjekt,
  tilsynsobjekt,
  isEnabled,
  isAlreadySelected,
  orgNr,
  index,
  openTilsynsobjektConfirmDeleteModal
}: ITilsynsobjektItemProps) => {
  const [isSelected, setIsSelected] = useState(() => isAlreadySelected);
  const isMounted = useRef(false);
  const theme = useContext(ThemeContext);
  const kvitteringId = useKvitteringId();
  const {
    data: tilsynsobjekter
  } = useTilsynsobjekter(kvitteringId);
  useEffect(() => {
    if (isMounted.current) {
      onSelectTilsynsobjekt(tilsynsobjekt, isSelected, orgNr);
    } else {
      isMounted.current = true;
    }
  }, [isSelected]);
  useEffect(() => {
    if (tilsynsobjekter?.length === 0) {
      setIsSelected(false);
    }
  }, [tilsynsobjekter?.length]);
  const onClickTilsynsobjekt = () => {
    const tilsynsobjektWithKontrollpunkt = tilsynsobjekter?.find(t => t.tilsynsobjektId === tilsynsobjekt.idstring && t.kontrollpunkter.length > 0);
    if (tilsynsobjektWithKontrollpunkt) {
      openTilsynsobjektConfirmDeleteModal(tilsynsobjektWithKontrollpunkt);
    }
    if (!tilsynsobjektWithKontrollpunkt) {
      setIsSelected(isSelected => !isSelected);
    }
  };
  const isFirst = tilsynsobjekter?.[0]?.tilsynsobjektId === tilsynsobjekt.idstring;
  return <Row id="tilsynsobjekt-item" padding={3} smColumn backgroundColor={index % 2 ? theme.white : theme.gray7} data-sentry-element="Row" data-sentry-component="TilsynsobjektItem" data-sentry-source-file="index.tsx">
      <RowItem flex={3} data-sentry-element="RowItem" data-sentry-source-file="index.tsx">
        <Column spacing={1} align="center" fluid data-sentry-element="Column" data-sentry-source-file="index.tsx">
          <p className="activity-header">{tilsynsobjekt.navn}</p>
          <p className="activity-subtitle">
            {tilsynsobjekt.aktivitet.beskrivelse}
          </p>
          {tilsynsobjekt.identiteter?.map((identitetstype: IIdentitetItem) => <Column spacing={0.5} align="center" key={identitetstype.verdi}>
              <Row>
                <p className="activity-info">
                  {identitetstype.identitetstype.beskrivelse}:{' '}
                  {identitetstype.verdi}
                </p>
              </Row>
            </Column>)}
        </Column>
      </RowItem>

      <RowItem flex={1} className="button-container" alignSelf={'center'} data-sentry-element="RowItem" data-sentry-source-file="index.tsx">
        <Button secondary={!isSelected} disabled={!isEnabled} fill onClick={onClickTilsynsobjekt} data-sentry-element="Button" data-sentry-source-file="index.tsx">
          {isSelected ? 'Tilsynsobjekt valgt' : 'Velg tilsynsobjekt'}
        </Button>
      </RowItem>

      {isFirst && isSelected && <Avdelingstilhorighet />}
    </Row>;
};