import React, { useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { IThemeColors } from '@mattilsynet/mt-ui/dist/assets/colors';
import { ThemeContext } from '@mattilsynet/mt-ui';

// TODO: Remove when this issue is fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2452
// eslint-disable-next-line
interface IStyledStatusIndicator {
  color: string;
}
interface IStatusIndicator {
  checked: boolean;
  ok: boolean;
}
const getInfo = (theme: IThemeColors, checked: boolean, ok: boolean) => {
  let text = 'Sjekker';
  let color;
  if (checked) {
    if (ok) {
      text = 'OK';
      color = theme.signalGreen;
    } else {
      text = 'Feil';
      color = theme.signalRed;
    }
  }
  return {
    text,
    color
  };
};
const StyledStatusIndicator = styled.span<IStyledStatusIndicator>`
  &.StatusIndicator {
    font-weight: bold;
    color: ${({
  color
}) => color};
  }
`;
export const StatusIndicator = ({
  ok,
  checked
}: IStatusIndicator) => {
  const theme = useContext(ThemeContext);
  const {
    text,
    color
  } = getInfo(theme, checked, ok);
  const statusIndicatorClass = classNames('StatusIndicator');
  return <StyledStatusIndicator color={color} className={statusIndicatorClass} data-sentry-element="StyledStatusIndicator" data-sentry-component="StatusIndicator" data-sentry-source-file="index.tsx">
      {text}
    </StyledStatusIndicator>;
};