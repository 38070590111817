import { combineEpics, Epic } from 'redux-observable'
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators'
import { of } from 'rxjs'
import { tilsynsobjektActions, tilsynsobjektActionsTypes } from '../store'
import { ITilsynsobjektActions } from '../types'
import { ICommonApi } from '../../../../../api'
import { IStoreState } from '../../../../../reducers/types'
import { ENDPOINT } from '../../../../../constants'
import { commonRedux } from '../../../../../common/redux'

const { isOfType } = commonRedux

export const fetchTilsynsobjektByIdEpic =
  (
    commonApi: ICommonApi
  ): Epic<ITilsynsobjektActions, ITilsynsobjektActions, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(tilsynsobjektActionsTypes.FETCH_ID)),
      withLatestFrom(state$),
      mergeMap(([{ id }, state]) =>
        of(state).pipe(
          commonApi.get(
            `${ENDPOINT.TILSYNSOBJEKT}/tilsynsobjekter/id/${id}`,
            state
          ),
          map((data) => tilsynsobjektActions.fetchIdOk(data)),
          catchError((err) =>
            of(tilsynsobjektActions.fetchIdFail(id, err.message))
          )
        )
      )
    )

export default (commonApi) =>
  combineEpics(fetchTilsynsobjektByIdEpic(commonApi))
