import React, { ReactElement } from 'react';
import { IconButton, Text, Grid, GridItem, Row } from '@mattilsynet/mt-ui';
import './style.css';
import { NavigatorSelectionBorder } from '../navigator-selection-border';
interface IBottomPageNav {
  pages: {
    title: string;
    icon: ReactElement;
  }[];
  currentPage: number;
  goToPage: (page: number) => void;
}
export const BottomPageNav = ({
  pages,
  currentPage,
  goToPage
}: IBottomPageNav) => {
  const onClickPage = (index: number) => () => goToPage(index);
  return <Grid className="navigator-grid" data-sentry-element="Grid" data-sentry-component="BottomPageNav" data-sentry-source-file="index.tsx">
      <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]} data-sentry-element="GridItem" data-sentry-source-file="index.tsx">
        <Row padding={1} className="navigator-grid__row" data-sentry-element="Row" data-sentry-source-file="index.tsx">
          {pages.map(({
          icon,
          title
        }, index) => {
          const isSelected = index === currentPage;
          const navigate = onClickPage(index);
          return <NavigatorSelectionBorder key={title} goTo={navigate} active={isSelected}>
                <Row justify="center" align="center">
                  <IconButton icon={icon} selectedColor="white" textColor="var(--text-color)" onClick={navigate} ariaLabel={title} selected={isSelected} large />

                  <Text weight="semibold">{title}</Text>
                </Row>
              </NavigatorSelectionBorder>;
        })}
        </Row>
      </GridItem>
    </Grid>;
};