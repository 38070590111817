import React from 'react';
import { TextLink, GridItem, Row, Grid, ShadowBox, Accordion, Column, Text, Header, SelectList } from '@mattilsynet/mt-ui';
interface ISettingsViewProps {
  goBack: () => void;
  openAccordions;
  toggleOpenAccordions: (id: string) => () => void;
  onUpdateLanguage: (language: string) => void;
  currentLanguage: string;
}
export const SettingsView = ({
  goBack,
  openAccordions,
  toggleOpenAccordions,
  onUpdateLanguage,
  currentLanguage
}: ISettingsViewProps) => {
  const languageOptions = [{
    label: 'Bokmål',
    value: 'nb'
  }, {
    label: 'Nynorsk',
    value: 'nn'
  }];
  return <Grid id="settings" data-sentry-element="Grid" data-sentry-component="SettingsView" data-sentry-source-file="view.tsx">
      <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]} data-sentry-element="GridItem" data-sentry-source-file="view.tsx">
        <Row data-sentry-element="Row" data-sentry-source-file="view.tsx">
          <TextLink leftArrow onClick={goBack} data-sentry-element="TextLink" data-sentry-source-file="view.tsx">
            Tilbake
          </TextLink>
        </Row>
      </GridItem>
      <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]} data-sentry-element="GridItem" data-sentry-source-file="view.tsx">
        <Column padding={[3, 2]} spacing={3} data-sentry-element="Column" data-sentry-source-file="view.tsx">
          <Header as="h1" size="heading2" data-sentry-element="Header" data-sentry-source-file="view.tsx">
            Personlige innstillinger
          </Header>
          <ShadowBox data-sentry-element="ShadowBox" data-sentry-source-file="view.tsx">
            <Accordion onClick={toggleOpenAccordions('maal')} open={openAccordions.includes('maal')} title="Velg målform" paddingChildren={[2, 1, 2, 1]} data-sentry-element="Accordion" data-sentry-source-file="view.tsx">
              <Text size="normal" weight="regular" margin={[0, 0, 2, 0]} data-sentry-element="Text" data-sentry-source-file="view.tsx">
                Dokumentet blir opprettet med den målformen du velger, mens i
                selve løsningen er det kun bokmål.
              </Text>
              <SelectList dataList={languageOptions} onClick={selectedValue => onUpdateLanguage(selectedValue)} selected={[currentLanguage]} data-sentry-element="SelectList" data-sentry-source-file="view.tsx" />
            </Accordion>
          </ShadowBox>
        </Column>
      </GridItem>
    </Grid>;
};