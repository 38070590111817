import { of, merge } from 'rxjs'
import { AnyAction } from 'redux'
import { combineEpics, Epic } from 'redux-observable'
import {
  catchError,
  map,
  mergeMap,
  withLatestFrom,
  filter,
} from 'rxjs/operators'
import * as R from 'ramda'
import { proveanalysesvarActions, proveanalysesvarActionTypes } from '../store'
import { AjaxResponse } from 'rxjs/ajax'
import { ICommonApi } from '../../../../../api'
import { IStoreState } from '../../../../../reducers/types'
import { ENDPOINT } from '../../../../../constants'
import { commonRedux } from '../../../../../common/redux'
import { toast } from '../../../../toast'

const { isOfType } = commonRedux

export const fetchProveAnalyseSvarByIdListEpic =
  (commonApi: ICommonApi): Epic<AnyAction, AnyAction, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(proveanalysesvarActionTypes.FETCH_LIST_BY_IDS)),
      withLatestFrom(state$),
      filter(([{ ids }]) => R.not(R.isEmpty(ids))),
      mergeMap(([{ ids }, state]) => {
        return of(state).pipe(
          commonApi.post(
            `${ENDPOINT.TILSYNSOBJEKT}/v2/tilsynsobjekter/proeveanalysesvar`,
            state,
            ids
          ),
          map(
            (resp: AjaxResponse<any>) =>
              resp.response._embedded.tilsynsobjektIdProeveanalysesvarList
          ),
          map((data) => proveanalysesvarActions.fetchListByIdsOk(data)),
          catchError((err) =>
            merge(
              of(
                toast.actions.showToast({
                  text: 'Kunne ikke hente prøveanalysesvar.',
                  timeoutSeconds: 5,
                  dismissible: true,
                  type: 'DANGER',
                })
              ),
              of(proveanalysesvarActions.fetchListByIdsFail(ids, err.message))
            )
          )
        )
      })
    )

export default (commonApi: ICommonApi) =>
  combineEpics(fetchProveAnalyseSvarByIdListEpic(commonApi))
