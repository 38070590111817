import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { TabBar, Table, TableRow, TableCell, Text, TextLink, LoadingSpinner, ErrorBox, Row, Column } from '@mattilsynet/mt-ui';
import { funnHistorikkActions, funnHistorikkSelectors } from '../../ducks/funn-historikk/store';
import { IHistoriskFunn, IHistoriskFunnMedKonsekvens, IHistoriskObservasjon } from '../../ducks/funn-historikk/store/types';
import { useMediaQuery } from 'react-responsive';
import { useTilsynsobjektHooks } from '../../ducks/tilsynsobjekt';
import { AKTIVITET_SVINEHOLD } from '../../constants';
import { useTypedSelector } from '../../../../common/custom-hooks';
import { getSortableTableHeader, sortByPath } from '../../../../common/sorting';
import { kassasjonerActions, kassasjonerSelectors } from '../../ducks/kassasjoner/store';
interface IObservasjonFunnListItem {
  observasjon: IHistoriskObservasjon;
  funn: IHistoriskFunnMedKonsekvens;
}
interface IObservasjonGroupedByDiagnoseListItem {
  type: 'AM' | 'PM' | 'NØD';
  diagnose: IKjoettkontrollFunnRemote.Kode;
  observasjoner: IObservasjonFunnListItem[];
  registreringer: number;
}
const ObservasjonFunnTable = ({
  observasjonFunnList
}: {
  observasjonFunnList: IObservasjonFunnListItem[];
}) => {
  const isWideScreen = useMediaQuery({
    query: '(min-width: 1024px)'
  });
  const [readMoreRows, setReadMoreRows] = useState<string[]>([]);
  const [sortColumn, setSortColumn] = useState('funn.slakteDato');
  const [sortArrow, setSortArrow] = useState<'DOWN' | 'UP'>('DOWN');
  const [listSorted, setListSorted] = useState<IObservasjonFunnListItem[]>(observasjonFunnList);
  useEffect(() => {
    const updatedListSorted = sortByPath(sortColumn.split('.'), sortArrow !== 'UP')(observasjonFunnList);
    setListSorted(updatedListSorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observasjonFunnList.length, sortColumn, sortArrow]);
  const numberOfCharacters = isWideScreen ? 40 : 80;
  const RowOrColumn = (props: any) => isWideScreen ? <Row {...props} /> : <Column {...props} />;
  const tableHeader = getSortableTableHeader([{
    display: 'Dato',
    sortKey: 'funn.slakteDato',
    sortable: true
  }, {
    display: 'Type',
    sortKey: 'funn.kontekst',
    sortable: true
  }, {
    display: 'Konsekvens',
    sortKey: 'funn.konsekvens',
    sortable: true
  }, {
    display: 'Diagnose',
    sortKey: 'observasjon.diagnose.beskrivelse',
    sortable: true
  }, {
    display: 'Observasjon'
  }], sortColumn, sortArrow, setSortColumn, setSortArrow);
  return <Table header={tableHeader} data-sentry-element="Table" data-sentry-component="ObservasjonFunnTable" data-sentry-source-file="index.tsx">
      {listSorted.map(({
      observasjon,
      funn
    }: IObservasjonFunnListItem) => {
      const key = `${funn.funnId}_${observasjon.beskrivelse}`;
      const readMore = readMoreRows.includes(key);
      const showReadMore = observasjon.beskrivelse?.length && observasjon.beskrivelse?.length > numberOfCharacters;
      return <TableRow key={key}>
            <TableCell>{dayjs(funn.slakteDato).format('DD.MM.YY')}</TableCell>
            <TableCell>{funn.kontekst}</TableCell>
            <TableCell>{funn.konsekvens}</TableCell>
            <TableCell>{observasjon.diagnose?.beskrivelse}</TableCell>
            <TableCell>
              {readMore ? <RowOrColumn justify="space-between">
                  <Text as="div" size="normal">
                    {observasjon.beskrivelse}
                  </Text>

                  <Row justify="center">
                    <TextLink whiteSpace="no-wrap" margin={isWideScreen ? [0, 0, 0, 1] : [1, 0, 0, 0]} onClick={() => setReadMoreRows(readMoreRows.filter(row => row !== key))}>
                      Vis mindre
                    </TextLink>
                  </Row>
                </RowOrColumn> : <RowOrColumn justify="space-between">
                  <Text as="div" size="normal">
                    {showReadMore ? observasjon.beskrivelse?.substring(0, numberOfCharacters) + ' ...' : observasjon.beskrivelse}
                  </Text>
                  {showReadMore && <Row justify="center">
                      <TextLink whiteSpace="nowrap" margin={isWideScreen ? [0, 0, 0, 1] : [1, 0, 0, 0]} onClick={() => setReadMoreRows([...readMoreRows, key])}>
                        Vis mer
                      </TextLink>
                    </Row>}
                </RowOrColumn>}
            </TableCell>
          </TableRow>;
    })}
    </Table>;
};
const GroupedByDiagnoseTable = ({
  groupedByDiagnoseList
}: {
  groupedByDiagnoseList: IObservasjonGroupedByDiagnoseListItem[];
}) => {
  const [readMoreRows, setReadMoreRows] = useState<string[]>([]);
  const isWideScreen = useMediaQuery({
    query: '(min-width: 1024px)'
  });
  const [sortColumn, setSortColumn] = useState('registreringer');
  const [sortArrow, setSortArrow] = useState<'DOWN' | 'UP'>('DOWN');
  const [listSorted, setListSorted] = useState<IObservasjonGroupedByDiagnoseListItem[]>([]);
  useEffect(() => {
    const updatedListSorted = sortByPath(sortColumn.split('.'), sortArrow !== 'UP')(groupedByDiagnoseList);
    setListSorted(updatedListSorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedByDiagnoseList.length, sortColumn, sortArrow]);
  const tableHeader = getSortableTableHeader([{
    display: 'Type',
    sortKey: 'type',
    sortable: true
  }, {
    display: 'Diagnose',
    sortKey: 'diagnose.beskrivelse',
    sortable: true
  }, {
    display: 'Antall registreringer',
    sortKey: 'registreringer',
    sortable: true
  }, {
    display: ''
  }], sortColumn, sortArrow, setSortColumn, setSortArrow);
  return <Table header={tableHeader} data-sentry-element="Table" data-sentry-component="GroupedByDiagnoseTable" data-sentry-source-file="index.tsx">
      {listSorted.map(({
      diagnose,
      type,
      observasjoner,
      registreringer
    }: IObservasjonGroupedByDiagnoseListItem) => {
      const key = diagnose.kode;
      const readMore = readMoreRows.includes(key);
      return <React.Fragment key={key}>
              <TableRow key={key} header={tableHeader.map(item => item.display)}>
                <TableCell>{type}</TableCell>
                <TableCell>{diagnose.beskrivelse}</TableCell>
                <TableCell>{registreringer}</TableCell>
                <TableCell>
                  <span>
                    {readMore ? <TextLink onClick={() => setReadMoreRows(readMoreRows.filter(row => row !== key))}>
                        Vis mindre
                      </TextLink> : <TextLink onClick={() => setReadMoreRows([...readMoreRows, key])}>
                        Vis mer
                      </TextLink>}
                  </span>
                </TableCell>
              </TableRow>

              {readMore && <>
                  {isWideScreen && <TableRow key={`${key}_observasjoner_header`}>
                      <TableCell bold>Dato</TableCell>
                      <TableCell bold>Konsekvens</TableCell>
                      <TableCell bold colSpan={2}>
                        Observasjon
                      </TableCell>
                    </TableRow>}
                  {observasjoner.map(({
            observasjon,
            funn
          }: IObservasjonFunnListItem, index: number) => <TableRow key={`${key}_observasjoner_${index}`} header={['Dato', 'Konsekvens', 'Observasjon']}>
                        <TableCell>
                          {dayjs(funn.slakteDato).format('DD.MM.YY')}
                        </TableCell>
                        <TableCell>{funn.konsekvens}</TableCell>
                        <TableCell colSpan={2}>
                          {observasjon.beskrivelse}
                        </TableCell>
                      </TableRow>)}
                </>}
            </React.Fragment>;
    })}
    </Table>;
};
interface IProdusentFunnHistorikkProps {
  tilsynsobjektId: string;
}
export const ProdusentFunnHistorikk = ({
  tilsynsobjektId
}: IProdusentFunnHistorikkProps) => {
  const {
    tilsynsobjekt
  } = useTilsynsobjektHooks(tilsynsobjektId);
  const isAktivitetSvinehold = tilsynsobjekt?.aktivitet?.id === AKTIVITET_SVINEHOLD;
  const [selectedTab, setSelectedTab] = useState(isAktivitetSvinehold ? 'diagnose' : 'detaljer');
  const {
    data: produsentFunnHistorikkData,
    loading,
    error
  } = useTypedSelector(funnHistorikkSelectors.getById(tilsynsobjektId));
  const kassasjoner = useTypedSelector(kassasjonerSelectors.getKassasjoner);
  const historiskFunnList: IHistoriskFunn[] = produsentFunnHistorikkData?.historiskFunnList || [];
  const [historiskeFunnMedKonsekvens, setHistoriskeFunnMedKonsekvens] = useState<IHistoriskFunnMedKonsekvens[]>(historiskFunnList);
  const dispatch = useDispatch();
  const fetchHistorikk = useCallback(() => {
    dispatch(funnHistorikkActions.fetchId(tilsynsobjektId));
  }, [dispatch, tilsynsobjektId]);
  useEffect(() => {
    fetchHistorikk();
  }, [fetchHistorikk]);
  useEffect(() => {
    const funnIds = historiskFunnList.map(funn => funn.funnId);
    if (funnIds.length > 0) {
      dispatch(kassasjonerActions.fetchList(funnIds));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historiskFunnList.length]);
  useEffect(() => {
    if (historiskFunnList.length > 0) {
      const historiskeFunnMedKonsekvensUpdated = historiskFunnList?.map(funn => {
        const kassasjon = kassasjoner?.find(kassasjon => kassasjon.hovedfunnId === funn.funnId);
        return kassasjon ? {
          ...funn,
          konsekvens: kassasjon.helkassasjon ? 'Helkassasjon' : 'Delkassasjon'
        } : {
          ...funn
        };
      });
      setHistoriskeFunnMedKonsekvens(historiskeFunnMedKonsekvensUpdated);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kassasjoner.length, historiskFunnList.length]);
  const tabOptions = [{
    display: 'Detaljer',
    value: 'detaljer'
  }, {
    display: 'Gruppert på diagnose',
    value: 'diagnose'
  }];
  const observasjonFunnList: IObservasjonFunnListItem[] = historiskeFunnMedKonsekvens.reduce((acc: IObservasjonFunnListItem[], item: IHistoriskFunn) => {
    const obsArr = (item?.observasjoner || []).map((observasjon: IHistoriskObservasjon) => ({
      observasjon,
      funn: item
    }));
    return [...acc, ...obsArr];
  }, []);
  const groupedByDiagnose = observasjonFunnList.reduce((acc, item: IObservasjonFunnListItem) => {
    const diagnose = item.observasjon.diagnose;
    const diagnoseKode = diagnose?.kode || '';
    if (!acc[diagnoseKode]) {
      acc[diagnoseKode] = {
        diagnose,
        type: item.funn.kontekst,
        observasjoner: [item],
        registreringer: 1
      };
    } else {
      acc[diagnoseKode].observasjoner.push(item);
      acc[diagnoseKode].registreringer = acc[diagnoseKode].observasjoner.length;
    }
    return acc;
  }, {});
  const groupedByDiagnoseList: IObservasjonGroupedByDiagnoseListItem[] = Object.values(groupedByDiagnose);
  if (error && !produsentFunnHistorikkData) {
    return <ErrorBox errorAction={fetchHistorikk} errorActionText="Last innhold på nytt" errorText="Kunne ikke hente historikk" />;
  }
  if (loading || !produsentFunnHistorikkData) {
    return <LoadingSpinner title="Laster AM- PM-historikk..." />;
  }
  if (observasjonFunnList.length === 0) {
    return <p>Produsenten har ingen avsluttede funn siste 3 år.</p>;
  }
  return <div data-sentry-component="ProdusentFunnHistorikk" data-sentry-source-file="index.tsx">
      <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">AM- og PM-registreringer de siste 3 årene.</Text>

      <Row margin={[2, 0]} data-sentry-element="Row" data-sentry-source-file="index.tsx">
        <TabBar options={tabOptions} selectedValue={selectedTab} onClick={selected => setSelectedTab(selected)} forceShowAsDropdown={false} size="normal" data-sentry-element="TabBar" data-sentry-source-file="index.tsx" />
      </Row>

      {selectedTab === 'detaljer' && <ObservasjonFunnTable key={JSON.stringify(observasjonFunnList)} observasjonFunnList={observasjonFunnList} />}

      {selectedTab === 'diagnose' && <GroupedByDiagnoseTable groupedByDiagnoseList={groupedByDiagnoseList} />}
    </div>;
};