import React, { useCallback, useContext, useState } from 'react';
import { CheckboxItem, Checkmark, Column, ErrorBox, LoadingSpinner, Row, Text, ThemeContext, WarningIcon } from '@mattilsynet/mt-ui';
import { ERROR, IKvitteringSavingStatus, kvitteringStatusEnum, OK } from '../../ducks/kvittering/types';
import { useMediaQuery } from 'react-responsive';
import { useTypedSelector } from '../../common/custom-hooks';
import { IStoreState } from '../../reducers/types';
import { arkiveringTemporaryDisabled, copySaknummer, offlineInFerdigstillingProcess, offlineOnArchiveKvittering, useSingleToast } from '../../common/toast';
import { ModalWrapper } from '../../components/modal-wrapper';
import FerdigstillingOkContent from '../../components/ferdigstilling-ok-content';
import './style.css';
import { CopyToClipboardButton } from '../../components/copy-to-clipboard';
import { ModalButtonsContainer } from '../../components/modal-buttons-container';
import { ModalButton } from '../../components/modal-button';
import { modalContentPaddingLarge } from '../../components/modal-buttons-container/constants';
import { isArkivertStatus, isFerdigstiltStatus } from '../../utils/kvittering';
import { useTKNavigate } from '../../common/navigation';
import { InformationBox } from '../../components/information-box';
import { AdresseAccordion } from '../../components/adresse-accordion';
import { kvitteringActions } from '../../ducks/kvittering/actions';
import { ADRESSE_ERROR } from '../../ducks/virksomhet-search/types';
import { TOGGLES, useSafeUnleashWithoutAnnoyingBugs } from '../../common/unleashToggles';
import { useDispatch } from 'react-redux';
import { ISakState } from '../../ducks/sak/types';
interface IArchiveModalProps {
  onCancel: (shouldRefreshData: boolean) => void;
  isOpen: boolean;
  onArchive: (nextStatus?: string) => void;
  archiveStatus?: IKvitteringSavingStatus;
  sakNumber?: string;
  currentSak: ISakState;
  tilsynskvitteringId: string;
  isLoading: boolean;
  existingHjemmelDesc?: string;
  existingHjemmelName?: string;
  chosenHjemmelDesc?: string;
  chosenHjemmelName?: string;
  hjemmelChosen?: boolean;
}
export const ArchiveModal = ({
  onCancel,
  isOpen,
  onArchive,
  archiveStatus,
  sakNumber,
  currentSak,
  isLoading,
  existingHjemmelDesc,
  existingHjemmelName,
  chosenHjemmelDesc,
  chosenHjemmelName,
  hjemmelChosen
}: IArchiveModalProps) => {
  const navigate = useTKNavigate();
  const singleToast = useSingleToast();
  const {
    isEnabled: isTKArkiveringEnabled,
    isLoading: isTKArkiveringLoading
  } = useSafeUnleashWithoutAnnoyingBugs(TOGGLES.tkArkivering);
  const error = useTypedSelector((state: IStoreState) => state.kvittering.error);
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline);
  const theme = useContext(ThemeContext);
  const [userContinued, setUserContinued] = useState(false);
  const [hasCheckedMottaker, setHasCheckedMottaker] = useState(false);
  const currentKvittering = useTypedSelector(state => state.kvittering.selectedKvittering);
  const [isArchiving, setIsArchiving] = useState(false);
  const dispatch = useDispatch();
  const onCopySaknummer = useCallback(() => singleToast.showToast(copySaknummer()), [singleToast]);
  const sakToDisplay = sakNumber || `${currentKvittering.data.noarksakAar}/${currentKvittering.data.noarksakSekvensnummer}`;
  const onFerdigstilling = useCallback(() => {
    if (isOffline) {
      singleToast.showToast(offlineInFerdigstillingProcess());
      return;
    }
    if (!isTKArkiveringEnabled) {
      singleToast.showToast(arkiveringTemporaryDisabled());
      return;
    }
    setIsArchiving(true);
    onArchive(kvitteringStatusEnum.FERDIGSTILT);
  }, [isOffline, isTKArkiveringEnabled, onArchive, singleToast]);
  const archive = useCallback(() => {
    if (isOffline) {
      singleToast.showToast(offlineOnArchiveKvittering());
      return;
    }
    if (!isTKArkiveringEnabled) {
      singleToast.showToast(arkiveringTemporaryDisabled());
      return;
    }
    setIsArchiving(true);
    onArchive();
  }, [isOffline, isTKArkiveringEnabled, onArchive, singleToast]);
  const onCancelNotContinue = (refresh: boolean) => {
    setUserContinued(false);
    return onCancel(refresh);
  };
  const onCloseModal = () => {
    setUserContinued(false);
    if (archiveStatus === OK) {
      navigate('/');
    }
    return onCancel(archiveStatus === ERROR);
  };
  const marginHorizontal = useMediaQuery({
    query: '(min-width: 767px)'
  }) ? 14 : 5;
  const onError = useCallback(() => onCancel(true), [onCancel]);
  const onArchived = () => {
    dispatch(kvitteringActions.clearCurrentKvitteringId());
    navigate('/');
  };
  const renderModalContent = () => {
    if (isLoading && isArkivertStatus(currentKvittering?.data?.status)) {
      return <LoadingSpinner title="Ferdigstiller og sender ut tilsynskvitteringen..." small />;
    }
    if (isLoading) {
      return <Row center>
          <LoadingSpinner title="Oppretter tilsynskvitteringen i arkivet.." small />
        </Row>;
    }
    if (archiveStatus === ERROR || !!currentSak.error) {
      return <ErrorBox errorText="Noe gikk galt. Lukk vinduet og prøv igjen" errorAction={onError} errorActionText="Lukk vinduet" />;
    }
    if (!userContinued && chosenHjemmelName && chosenHjemmelName?.length > 0 && hjemmelChosen && existingHjemmelName && existingHjemmelDesc !== chosenHjemmelDesc) {
      return <Column spacing={2}>
          <Row margin={[0, marginHorizontal]} justify="center">
            <WarningIcon color={theme.signalRed} size="large" />
          </Row>
          <Row margin={[0, marginHorizontal]}>
            <Text size="heading3" weight="bold">
              Saken du har valgt å opprette tilsynskvitteringen på er unntatt
              offentlighet med en annen hjemmel enn du har valgt.
            </Text>
          </Row>
          <Column spacing={1}>
            <Row margin={[0, marginHorizontal]}>
              <Text size="normal" weight="bold">
                Saken er unntatt offentlighet med følgende hjemmel:
              </Text>
            </Row>
            <Row margin={[0, marginHorizontal]}>
              <Text size="normal">{existingHjemmelName}</Text>
            </Row>
            <Row margin={[0, marginHorizontal]}>
              <Text size="small">{existingHjemmelDesc}</Text>
            </Row>
          </Column>
          <Column spacing={1}>
            <Row margin={[0, marginHorizontal]}>
              <Text size="normal" weight="bold">
                Du har valgt følgende hjemmel:
              </Text>
            </Row>
            <Row margin={[0, marginHorizontal]}>
              <Text size="normal">{chosenHjemmelName}</Text>
            </Row>
            <Row margin={[0, marginHorizontal]}>
              <Text size="small">{chosenHjemmelDesc}</Text>
            </Row>
          </Column>
          <Column spacing={1}>
            <Row margin={[0, marginHorizontal]}>
              <Text size="heading3" weight="bold">
                Vil du likevel velge denne hjemmelen?
              </Text>
            </Row>
          </Column>
          <ModalButtonsContainer>
            <ModalButton allowMultiLine onClick={() => setUserContinued(true)}>
              Ja, jeg vil velge denne hjemmelen
            </ModalButton>
            <ModalButton secondary onClick={() => onCancelNotContinue(false)}>
              Avbryt
            </ModalButton>
          </ModalButtonsContainer>
        </Column>;
    }
    if (!userContinued && !hjemmelChosen && existingHjemmelName && existingHjemmelName?.length > 0) {
      return <Column spacing={2}>
          <Row margin={[0, marginHorizontal]} justify="center">
            <WarningIcon color={theme.signalRed} size="large" />
          </Row>
          <Row margin={[0, marginHorizontal]}>
            <Text size="heading3" weight="bold">
              Saken du har valgt å opprette tilsynskvitteringen på er unntatt
              offentlighet
            </Text>
          </Row>
          <Row margin={[0, marginHorizontal]}>
            <Text size="heading3" weight="bold">
              Stemmer det at du likevel ikke ønsker å skjerme mottaker eller
              unnta dokument og/eller bilder fra offentlighet?
            </Text>
          </Row>
          <ModalButtonsContainer>
            <ModalButton onClick={() => setUserContinued(true)}>
              Ja, det stemmer
            </ModalButton>

            <ModalButton secondary onClick={() => onCancelNotContinue(false)}>
              Avbryt
            </ModalButton>
          </ModalButtonsContainer>
        </Column>;
    }
    if (archiveStatus === OK && sakToDisplay) {
      return <>
          <Row margin={[0, marginHorizontal]} align="center">
            <span style={{
            display: 'table',
            margin: 'auto'
          }}>
              <Checkmark color={theme.signalGreen} className="archive-checkmark" />
            </span>
          </Row>
          <Row margin={[0, marginHorizontal]}>
            <Text size="heading3" weight="bold">
              Tilsynskvitteringen ble opprettet i arkivet
            </Text>
          </Row>
          <Row margin={[0, marginHorizontal]}>
            <Text size="normal">
              Saksnummer: {sakToDisplay}
              &nbsp;
              <CopyToClipboardButton onCopied={onCopySaknummer} value={sakToDisplay} />
            </Text>
          </Row>
          <Row margin={[0, marginHorizontal]}>
            <Text>
              Tilsynskvitteringen ligger nå på forsiden. Derfra kan du sende den
              ut til virksomheten når alle bilder er lagt til.
            </Text>
          </Row>
          <ModalButtonsContainer>
            <ModalButton onClick={onArchived}>Ferdig</ModalButton>
          </ModalButtonsContainer>
        </>;
    }
    if (isFerdigstiltStatus(currentKvittering?.data?.status)) {
      return <FerdigstillingOkContent saksnummer={sakNumber} />;
    }
    return <>
        <Row margin={[0, marginHorizontal]} data-sentry-element="Row" data-sentry-source-file="index.tsx">
          <Text size="heading3" weight="bold" data-sentry-element="Text" data-sentry-source-file="index.tsx">
            Vil du ferdigstille og sende ut tilsynskvitteringen?
          </Text>
        </Row>
        <Row margin={[0, marginHorizontal]} data-sentry-element="Row" data-sentry-source-file="index.tsx">
          <Column spacing={1} style={{
          width: '100%'
        }} data-sentry-element="Column" data-sentry-source-file="index.tsx">
            <InformationBox variant="info" header={currentKvittering.data.virksomhetsNavn} data-sentry-element="InformationBox" data-sentry-source-file="index.tsx">
              <AdresseAccordion item={currentKvittering.data} orgNr={currentKvittering.data.virksomhetsOrgNr} fetchAddress={kvitteringActions.fetchAdresse(currentKvittering.data)} hasError={!!error && error === ADRESSE_ERROR} isDefaultOpen data-sentry-element="AdresseAccordion" data-sentry-source-file="index.tsx" />
              <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">
                {currentKvittering?.data?.adresse?.gateadresse}
              </Text>
            </InformationBox>
            <CheckboxItem selected={hasCheckedMottaker} onClick={() => setHasCheckedMottaker(!hasCheckedMottaker)} data-sentry-element="CheckboxItem" data-sentry-source-file="index.tsx">
              Jeg bekrefter at mottaker er korrekt
            </CheckboxItem>
          </Column>
        </Row>
        <Row margin={[0, marginHorizontal]} data-sentry-element="Row" data-sentry-source-file="index.tsx">
          {!!sakNumber && !currentSak.sakClosed && currentKvittering?.data?.status === kvitteringStatusEnum.PAABEGYNT ? <Text size="normal">
              {`Du er i ferd med å opprette tilsynskvitteringen i arkivet på sak ${sakNumber}`}
            </Text> : <Text size="normal">
              Du er i ferd med å opprette tilsynskvitteringen på{' '}
              <b>en ny sak</b> i arkivet.
            </Text>}
        </Row>
        <Column spacing={4} data-sentry-element="Column" data-sentry-source-file="index.tsx">
          <Row margin={[0, marginHorizontal]} data-sentry-element="Row" data-sentry-source-file="index.tsx">
            <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">
              Tilsynskvitteringen blir arkivert i Elements og sendt til
              virksomheten.
            </Text>
          </Row>
          <Row margin={[0, marginHorizontal]} data-sentry-element="Row" data-sentry-source-file="index.tsx">
            <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">
              Når tilsynskvitteringen blir arkivert så kan man ikke lenger gjøre
              endringer.
            </Text>
          </Row>
          <Row padding={modalContentPaddingLarge} margin={[0, marginHorizontal]} data-sentry-element="Row" data-sentry-source-file="index.tsx">
            <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">
              Skal en kollega legge til bilder? Trykk på knappen &quot;Opprett i
              arkivet&quot; slik at en kollega kan legge til bilder før
              tilsynskvitteringen sendes ut.
            </Text>
          </Row>
          <Column spacing={2} align="center" data-sentry-element="Column" data-sentry-source-file="index.tsx">
            {isArchiving ? <LoadingSpinner title="" small /> : <ModalButtonsContainer>
                <ModalButton secondary onClick={() => onCancelNotContinue(false)}>
                  Avbryt
                </ModalButton>
                <ModalButton secondary onClick={archive} disabled={!hasCheckedMottaker || isTKArkiveringLoading}>
                  Opprett i arkivet
                </ModalButton>
                <ModalButton onClick={onFerdigstilling} disabled={!hasCheckedMottaker || isTKArkiveringLoading}>
                  Ferdigstill og send ut
                </ModalButton>
              </ModalButtonsContainer>}
          </Column>
        </Column>
      </>;
  };
  return <ModalWrapper onCancel={onCloseModal} isOpen={isOpen} fullscreenMobile className="archive-modal" data-sentry-element="ModalWrapper" data-sentry-component="ArchiveModal" data-sentry-source-file="index.tsx">
      <Column spacing={5} margin={[2, 0]} minHeight="unset" data-sentry-element="Column" data-sentry-source-file="index.tsx">
        {renderModalContent()}
      </Column>
    </ModalWrapper>;
};