import { ICommonApi } from '../../../api'
import { combineEpics, Epic } from 'redux-observable'
import { IStoreState } from '../../../reducers/types'
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { userSelectors } from '../../user/selectors'
import { concat, of } from 'rxjs'
import { ansatteActions, ansatteActionTypes } from '../index'
import { IAnsatteActions } from '../types'
import { sortByKey } from '../../../common/sorting'
import { createQueryStringFromObject } from '../../../common/createQueryStringFromObject'
import { isOfType } from '../../../common/redux/helpers'

export const fetchAnsatteEpic =
  (
    commonApi: ICommonApi
  ): Epic<IAnsatteActions, IAnsatteActions, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(ansatteActionTypes.FETCH_ANSATTE)),
      withLatestFrom(state$),
      switchMap(([, state]) => {
        const avdeling = userSelectors.getAvdeling(state)
        const pageSize = 10000
        const queries = createQueryStringFromObject('?')({
          'page.size': pageSize,
          'page.number': 0,
        })

        return of(state).pipe(
          commonApi.get(
            `/api/orgenhet-api/kontorer/${avdeling.id}/ansatte${queries}`,
            state
          ),
          map((data) => {
            const ansattList = data?._embedded?.ansattList
            const sortedAnsatte = sortByKey('navn', false)(ansattList)
            return ansatteActions.fetchAnsatteOk(sortedAnsatte, avdeling)
          }),
          catchError((err) =>
            concat(of(ansatteActions.fetchAnsatteFail(err.message)))
          )
        )
      })
    )

export const fetchAllAnsatteEpic =
  (
    commonApi: ICommonApi
  ): Epic<IAnsatteActions, IAnsatteActions, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(ansatteActionTypes.FETCH_ALL_ANSATTE)),
      withLatestFrom(state$),
      switchMap(([, state]) => {
        const avdeling = userSelectors.getAvdeling(state)
        const pageSize = 10000
        const queries = createQueryStringFromObject('?')({
          'page.size': pageSize,
          'page.number': 0,
        })

        return of(state).pipe(
          commonApi.get(`/api/orgenhet-api/ansatte${queries}`, state),
          map((data) => {
            const ansattList = data?._embedded?.ansattList
            const sortedAnsatte = sortByKey('navn', false)(ansattList)
            return ansatteActions.fetchAnsatteOk(sortedAnsatte, avdeling)
          }),
          catchError((err) =>
            concat(of(ansatteActions.fetchAnsatteFail(err.message)))
          )
        )
      })
    )

export default (commonApi: ICommonApi) =>
  combineEpics(fetchAnsatteEpic(commonApi), fetchAllAnsatteEpic(commonApi))
