import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '../store';
import * as R from 'ramda';
import { IKoderverkNames, IKodeverkQueries } from '../store/types';
export const hooks = {
  useKodeverk: (kodeverkName: IKoderverkNames, queries?: IKodeverkQueries) => {
    const kodeverkResult = useSelector(selectors.getKodeverk(kodeverkName));
    const dispatch = useDispatch();
    const fetchKodeverk = useCallback(() => dispatch(actions.fetch(kodeverkName, queries)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, kodeverkName, queries?.filter, queries?.rootCode]);
    useEffect(() => {
      if (kodeverkName && !kodeverkResult?.loading && (R.isNil(kodeverkResult) && !kodeverkResult?.error || !R.equals(queries, kodeverkResult?.queries))) {
        fetchKodeverk();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kodeverkName, kodeverkResult, fetchKodeverk, queries?.rootCode, queries?.filter]);
    return {
      ...kodeverkResult,
      fetchKodeverk
    };
  }
};