import { IHentetKvittering, IKvitteringData, ITilsynstype } from './types'
import { IAnsatt } from '../ansatte/types'

export const kvitteringActionTypes = {
  SET_CURRENT_KVITTERING_FROM_ID:
    'current-kvittering/SET_CURRENT_KVITTERING_FROM_ID' as const,
  SET_CURRENT_KVITTERING_FROM_ID_FAILED:
    'current-kvittering/SET_CURRENT_KVITTERING_FROM_ID_FAILED' as const,
  SET_CURRENT_KVITTERING: 'current-kvittering/SET_CURRENT_KVITTERING' as const,

  CLEAR_CURRENT_KVITTERING:
    'current-kvittering/CLEAR_CURRENT_KVITTERING' as const,
  CLEAR_ERROR: 'current-kvittering/CLEAR_ERROR' as const,
  FETCH_SAMMENSTILLING: 'kvittering/FETCH_SAMMENSTILLING' as const,
  FETCH_SAMMENSTILLING_SUCCESS:
    'kvittering/FETCH_SAMMENSTILLING_SUCCESS' as const,
  FETCH_SAMMENSTILLING_FAILED:
    'kvittering/FETCH_SAMMENSTILLING_FAILED' as const,
  SET_STATUS: 'current-kvittering/SET_STATUS' as const,
  UPDATE_CURRENT_KVITTERING:
    'current-kvittering/UPDATE_CURRENT_KVITTERING' as const,
  UPDATE_CURRENT_KVITTERING_SUCCESS:
    'current-kvittering/UPDATE_CURRENT_KVITTERING_SUCCESS' as const,
  UPDATE_CURRENT_KVITTERING_FAILED:
    'current-kvittering/UPDATE_CURRENT_KVITTERING_FAILED' as const,
  FETCH_ADRESSE: 'kvittering/FETCH_ADRESSE' as const,
  FETCH_ADRESSE_SUCCESS: 'kvittering/FETCH_ADRESSE_SUCCESS' as const,
  FETCH_ADRESSE_FAILED: 'kvittering/FETCH_ADRESSE_FAILED' as const,
  SET_TYPE_TILSYN: 'current-kvittering/SET_TYPE_TILSYN' as const,
  FETCH_VIRKSOMHET_ORGANISASJONSFORM:
    'arkivering-status/FETCH_VIRKSOMHET_ORGANISASJONSFORM' as const,
  FETCH_VIRKSOMHET_ORGANISASJONSFORM_SUCCESS:
    'arkivering-status/FETCH_VIRKSOMHET_ORGANISASJONSFORM_SUCCESS' as const,
  FETCH_VIRKSOMHET_ORGANISASJONSFORM_FAILED:
    'arkivering-status/FETCH_VIRKSOMHET_ORGANISASJONSFORM_FAILED' as const,
  INITIAL_STATE: 'kvittering/INITIAL_STATE' as const,
  ADD_KVITTERING: 'kvittering/ADD_KVITTERING' as const,
  ADD_KVITTERING_SUCCESS: 'kvittering/ADD_KVITTERING_SUCCESS' as const,
  ADD_KVITTERING_FAILED: 'kvittering/ADD_KVITTERING_FAILED' as const,
  FETCH_KVITTERINGER: 'kvittering/FETCH_KVITTERINGER' as const,
  FETCH_KVITTERINGER_SUCCESS: 'kvittering/FETCH_KVITTERINGER_SUCCESS' as const,
  FETCH_KVITTERINGER_FAILED: 'kvittering/FETCH_KVITTERINGER_FAILED' as const,
  DELETE_KVITTERING: 'kvittering/DELETE_KVITTERING' as const,
  DELETE_KVITTERING_FAILED: 'kvittering/DELETE_KVITTERING_FAILED' as const,
  ARKIVER_KVITTERING: 'kvittering/ARKIVER_KVITTERING' as const,
  FERDIGSTILL_KVITTERING: 'kvittering/FERDIGSTILL_KVITTERING' as const,
  FETCH_KVITTERING_BY_ID: 'kvittering/FETCH_KVITTERING_BY_ID' as const,
  FETCH_KVITTERING_BY_ID_SUCCESS:
    'kvittering/FETCH_KVITTERING_BY_ID_SUCCESS' as const,
  FETCH_KVITTERING_BY_ID_FAILED:
    'kvittering/FETCH_KVITTERING_BY_ID_FAILED' as const,
  UPDATE_KVITTERING: 'kvittering/UPDATE_KVITTERING' as const,
  UPDATE_KVITTERING_SUCCESS: 'kvittering/UPDATE_KVITTERING_SUCCESS' as const,
  UPDATE_KVITTERING_FAILED: 'kvittering/UPDATE_KVITTERING_FAILED' as const,
  FETCH_TILSYNSTYPER: 'kvittering/FETCH_TILSYNSTYPER' as const,
  FETCH_TILSYNSTYPER_SUCCESS: 'kvittering/FETCH_TILSYNSTYPER_SUCCESS' as const,
  FETCH_TILSYNSTYPER_FAILED: 'kvittering/FETCH_TILSYNSTYPER_FAILED' as const,
  FETCH_KVITTERINGER_FRA_KOLLEGA:
    `kvittering/FETCH_KVITTERINGER_FRA_KOLLEGA` as const,
  FETCH_KVITTERINGER_FRA_KOLLEGA_SUCCESS:
    `kvittering/FETCH_KVITTERINGER_FRA_KOLLEGA_SUCCESS` as const,
  FETCH_KVITTERINGER_FRA_KOLLEGA_FAIL:
    `kvittering/FETCH_KVITTERINGER_FRA_KOLLEGA_FAIL` as const,
  FORDEL_TIL_KOLLEGA: 'kvittering/FORDEL_TIL_KOLLEGA' as const,
  FORDEL_TIL_KOLLEGA_SUCCESS: 'kvittering/FORDEL_TIL_KOLLEGA_SUCCESS' as const,
  FORDEL_TIL_KOLLEGA_FAILED: 'kvittering/FORDEL_TIL_KOLLEGA_FAILED' as const,
  HENT_FRA_KOLLEGA: 'kvittering/HENT_FRA_KOLLEGA' as const,
  HENT_FRA_KOLLEGA_SUCCESS: 'kvittering/HENT_FRA_KOLLEGA_SUCCESS' as const,
  HENT_FRA_KOLLEGA_FAILED: 'kvittering/HENT_FRA_KOLLEGA_FAILED' as const,
  INIT: 'kvittering/INIT' as const,
  CLEAN_SYNC_STATE: 'kvittering/CLEAN_SYNC_STATE' as const,
  UPDATE_GENERELL_INFORMASJON:
    'kvittering/UPDATE_GENERELL_INFORMASJON' as const,
  SET_CURRENT_KVITTERING_ID: 'kvittering/SET_CURRENT_KVITTERING_ID' as const,
  CLEAR_CURRENT_KVITTERING_ID:
    'kvittering/CLEAR_CURRENT_KVITTERING_ID' as const,
}

export const kvitteringActions = {
  initialState: () => ({
    type: kvitteringActionTypes.INITIAL_STATE,
  }),
  fetchKvitteringer: () => ({
    type: kvitteringActionTypes.FETCH_KVITTERINGER,
  }),
  fetchKvitteringerFailed: (error: string) => ({
    type: kvitteringActionTypes.FETCH_KVITTERINGER_FAILED,
    error,
  }),

  fetchKvitteringerSuccess: (payload: IKvitteringData[]) => ({
    type: kvitteringActionTypes.FETCH_KVITTERINGER_SUCCESS,
    payload,
  }),

  addKvittering: (payload, payloadType) => ({
    type: kvitteringActionTypes.ADD_KVITTERING,
    payload,
    payloadType,
  }),

  addKvitteringSuccess: (id: string) => ({
    type: kvitteringActionTypes.ADD_KVITTERING_SUCCESS,
    id,
  }),

  addKvitteringFailed: (error: string) => ({
    type: kvitteringActionTypes.ADD_KVITTERING_FAILED,
    error,
  }),

  fetchKvitteringById: (id: string) => ({
    type: kvitteringActionTypes.FETCH_KVITTERING_BY_ID,
    id,
  }),

  fetchKvitteringByIdSuccess: (payload) => ({
    type: kvitteringActionTypes.FETCH_KVITTERING_BY_ID_SUCCESS,
    payload,
  }),

  fetchKvitteringByIdFailed: (error: string) => ({
    type: kvitteringActionTypes.FETCH_KVITTERING_BY_ID_FAILED,
    error,
  }),

  deleteKvittering: (kvittering: IKvitteringData) => ({
    type: kvitteringActionTypes.DELETE_KVITTERING,
    kvittering,
  }),

  arkiverKvittering: (id?: string) => ({
    type: kvitteringActionTypes.ARKIVER_KVITTERING,
    id,
  }),

  ferdigstillKvittering: (id?: string) => ({
    type: kvitteringActionTypes.FERDIGSTILL_KVITTERING,
    id,
  }),

  deleteKvitteringFailed: (error: string) => ({
    type: kvitteringActionTypes.DELETE_KVITTERING_FAILED,
    error,
  }),

  updateKvittering: (kvittering: IKvitteringData, blockToast?: boolean) => ({
    type: kvitteringActionTypes.UPDATE_KVITTERING,
    kvittering,
    blockToast,
  }),

  updateKvitteringSuccess: (payload, kvitteringId?: string) => ({
    type: kvitteringActionTypes.UPDATE_KVITTERING_SUCCESS,
    payload,
    kvitteringId,
  }),

  updateKvitteringFailed: (error) => ({
    type: kvitteringActionTypes.UPDATE_KVITTERING_FAILED,
    error,
  }),

  clearCurrentKvittering: () => ({
    type: kvitteringActionTypes.CLEAR_CURRENT_KVITTERING,
  }),
  setCurrentKvitteringFromId: (
    tilsynskvitteringId: string,
    expand?: string[]
  ) => ({
    type: kvitteringActionTypes.SET_CURRENT_KVITTERING_FROM_ID,
    tilsynskvitteringId,
    expand,
  }),
  setCurrentKvitteringFromIdFailed: (error: string) => ({
    type: kvitteringActionTypes.SET_CURRENT_KVITTERING_FROM_ID_FAILED,
    error,
  }),

  setCurrentKvittering: (kvittering) => ({
    type: kvitteringActionTypes.SET_CURRENT_KVITTERING,
    kvittering,
  }),
  clearError: () => ({
    type: kvitteringActionTypes.CLEAR_ERROR,
  }),

  setStatus: (status) => ({
    type: kvitteringActionTypes.SET_STATUS,
    status,
  }),

  fetchSammenstilling: () => ({
    type: kvitteringActionTypes.FETCH_SAMMENSTILLING,
  }),

  fetchSammenstillingSuccess: (kvittering) => ({
    type: kvitteringActionTypes.FETCH_SAMMENSTILLING_SUCCESS,
    kvittering,
  }),

  fetchSammenstillingFailed: (error) => ({
    type: kvitteringActionTypes.FETCH_SAMMENSTILLING_FAILED,
    error,
  }),

  updateCurrentKvittering: (payload: Partial<IKvitteringData>, delay = 0) => ({
    type: kvitteringActionTypes.UPDATE_CURRENT_KVITTERING,
    payload,
    delay,
  }),

  updateCurrentKvitteringSuccess: () => ({
    type: kvitteringActionTypes.UPDATE_CURRENT_KVITTERING_SUCCESS,
  }),

  updateCurrentKvitteringFailed: (error) => ({
    type: kvitteringActionTypes.UPDATE_CURRENT_KVITTERING_FAILED,
    error,
  }),

  fetchAdresse: (kvittering: IKvitteringData) => ({
    type: kvitteringActionTypes.FETCH_ADRESSE,
    kvittering,
  }),

  fetchAdresseSuccess: (payload: IKvitteringData) => ({
    type: kvitteringActionTypes.FETCH_ADRESSE_SUCCESS,
    payload,
  }),

  fetchAdresseFailed: (error: string) => ({
    type: kvitteringActionTypes.FETCH_ADRESSE_FAILED,
    error,
  }),

  fetchTilsynstyper: () => ({
    type: kvitteringActionTypes.FETCH_TILSYNSTYPER,
  }),

  fetchTilsynstyperSuccess: (tilsynstyper: ITilsynstype[]) => ({
    type: kvitteringActionTypes.FETCH_TILSYNSTYPER_SUCCESS,
    tilsynstyper,
  }),

  fetchTilsynstyperFailed: (error: string) => ({
    type: kvitteringActionTypes.FETCH_TILSYNSTYPER_FAILED,
    error,
  }),

  setTypeTilsyn: (typeTilsyn: string, typeTilsynBeskrivelse?: string) => ({
    type: kvitteringActionTypes.SET_TYPE_TILSYN,
    typeTilsyn,
    typeTilsynBeskrivelse,
  }),

  fetchVirksomhetOrganisasjonsForm: (id?: string, orgNr?: string) => ({
    type: kvitteringActionTypes.FETCH_VIRKSOMHET_ORGANISASJONSFORM,
    id,
    orgNr,
  }),

  fetchVirksomhetOrganisasjonsFormSuccess: (orgFormKode: string) => ({
    type: kvitteringActionTypes.FETCH_VIRKSOMHET_ORGANISASJONSFORM_SUCCESS,
    orgFormKode: orgFormKode,
  }),

  fetchVirksomhetOrganisasjonsFormFailed: (error: string) => ({
    type: kvitteringActionTypes.FETCH_VIRKSOMHET_ORGANISASJONSFORM_FAILED,
    error,
  }),

  fordelTilKollega: (kvittering: IKvitteringData, ansatt: IAnsatt) => ({
    type: kvitteringActionTypes.FORDEL_TIL_KOLLEGA,
    kvittering,
    ansatt,
  }),
  fordelTilKollegaSuccess: () => ({
    type: kvitteringActionTypes.FORDEL_TIL_KOLLEGA_SUCCESS,
  }),
  fordelTilKollegaFailed: (error: string) => ({
    type: kvitteringActionTypes.FORDEL_TIL_KOLLEGA_FAILED,
    error,
  }),
  hentFraKollega: (
    begrunnelse: string,
    kollega: string,
    kvitteringIds: string[]
  ) => ({
    type: kvitteringActionTypes.HENT_FRA_KOLLEGA,
    begrunnelse,
    kvitteringIds,
    kollega,
  }),
  hentFraKollegaSuccess: () => ({
    type: kvitteringActionTypes.HENT_FRA_KOLLEGA_SUCCESS,
  }),
  hentFraKollegaFailed: (error: string) => ({
    type: kvitteringActionTypes.HENT_FRA_KOLLEGA_FAILED,
    error,
  }),
  cleanSyncState: () => ({
    type: kvitteringActionTypes.CLEAN_SYNC_STATE,
  }),
  updateGenerellInformasjon: (kvittering: IKvitteringData) => ({
    type: kvitteringActionTypes.UPDATE_GENERELL_INFORMASJON,
    kvittering,
  }),
  fetchKvitteringerFraKollega: (userId: string) => ({
    type: kvitteringActionTypes.FETCH_KVITTERINGER_FRA_KOLLEGA,
    userId,
  }),
  fetchKvitteringerFraKollegaSuccess: (kvitteringer: IHentetKvittering[]) => ({
    type: kvitteringActionTypes.FETCH_KVITTERINGER_FRA_KOLLEGA_SUCCESS,
    kvitteringer: kvitteringer,
  }),
  fetchKvitteringerFraKollegaFail: (error: string) => ({
    type: kvitteringActionTypes.FETCH_KVITTERINGER_FRA_KOLLEGA_FAIL,
    error: error,
  }),
  setCurrentKvitteringId: (kvitteringId: string) => ({
    type: kvitteringActionTypes.SET_CURRENT_KVITTERING_ID,
    kvitteringId,
  }),
  clearCurrentKvitteringId: () => ({
    type: kvitteringActionTypes.CLEAR_CURRENT_KVITTERING_ID,
  }),
}
