import { formatKvittering, formatKvitteringToValidIds } from './utils'
import {
  OK,
  ADRESSE_ERROR,
  IKvitteringActions,
  ERROR,
  IKvitteringData,
  SAVING,
  kvitteringStatusEnum,
} from './types'
import {
  fetchKvitteringById$,
  kvitteringDeleteSuccessToast,
  kvitteringDeleteFailedToast,
  getFordelingInformasjon,
  kvitteringFerdigstillFailedToast,
  kvitteringArkivereFailedToast,
} from './helper'
import { v1 as uuidV1 } from 'uuid'
import { ofType, combineEpics, StateObservable, Epic } from 'redux-observable'
import { AnyAction } from 'redux'
import {
  withLatestFrom,
  mergeMap,
  map,
  catchError,
  switchMap,
  debounceTime,
  filter,
  debounce,
  groupBy,
} from 'rxjs/operators'
import { of, Observable, concat, merge, EMPTY, interval } from 'rxjs'
import { kvitteringActions, kvitteringActionTypes } from './actions'
import { IStoreState } from '../../reducers/types'
import { ICommonApi } from '../../api'
import { dangerToast, successToast } from '../../common/toast'
import { createQueryStringFromObject } from '../../common/createQueryStringFromObject'
import { isUuid } from '../../common/string-utils'
import { notifications } from '../../features/notifications'
import { navigate, goBack } from '../../common/navigation'
import { ENDPOINT } from '../../constants'

export const ROOT_URL_TILSYNKVITTERING = '/api/tilsynskvittering-api/v1'
export const ROOT_URL_VIRKSOMHET = '/api/virksomhet-api'
export const ROOT_URL_TILSYNSOBJEKT = '/api/tilsynsobjekt-api'

export const fetchKvitteringerEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.FETCH_KVITTERINGER),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      mergeMap(([, state]) => {
        const NOT_FERDIGSTILTE_KVITTERINGER_QUERIES =
          createQueryStringFromObject('?')({
            'sort.direction': 'DESC',
            'sort.property': 'oppdatert',
            'filter.not.status': [
              kvitteringStatusEnum.FERDIGSTILT,
              kvitteringStatusEnum.FERDIGSTILLING_PAAGAAR,
            ],
            'page.size': '100',
          })
        const NOT_FERDIGSTILTE_KVITTERINGER_URL = `${ENDPOINT.TILSYNSKVITTERING}/completetilsynskvitteringer/${NOT_FERDIGSTILTE_KVITTERINGER_QUERIES}`

        const FERDIGSTILTE_KVITTERINGER_QUERIES = createQueryStringFromObject(
          '?'
        )({
          'sort.direction': 'DESC',
          'sort.property': 'oppdatert',
          'filter.has.status': [
            kvitteringStatusEnum.FERDIGSTILT,
            kvitteringStatusEnum.FERDIGSTILLING_PAAGAAR,
          ],
          'page.size': '50',
        })
        const FERDIGSTILTE_KVITTERINGER_URL = `${ENDPOINT.TILSYNSKVITTERING}/completetilsynskvitteringer/${FERDIGSTILTE_KVITTERINGER_QUERIES}`

        return of(state).pipe(
          commonApi.get(NOT_FERDIGSTILTE_KVITTERINGER_URL, state),
          map((resp: any) =>
            resp._embedded ? resp._embedded.kvitteringer : []
          ),
          mergeMap((notFerdigstilteKvitteringer) => {
            return of(state).pipe(
              commonApi.get(FERDIGSTILTE_KVITTERINGER_URL, state),
              map((resp: any) =>
                resp._embedded ? resp._embedded.kvitteringer : []
              ),
              mergeMap((ferdigstilteKvitteringer) => {
                const allKvitteringer = ferdigstilteKvitteringer.concat(
                  notFerdigstilteKvitteringer
                )

                return getFordelingInformasjon(
                  commonApi,
                  state
                )(allKvitteringer)
              }),
              catchError((err) =>
                of(kvitteringActions.fetchKvitteringerFailed(err.message))
              )
            )
          }),
          catchError((err) =>
            of(kvitteringActions.fetchKvitteringerFailed(err.message))
          )
        )
      })
    )

export const addKvitteringEpic =
  (commonApi: ICommonApi): Epic<IKvitteringActions, AnyAction, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      ofType(kvitteringActionTypes.ADD_KVITTERING),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      debounceTime(1000),
      filter(
        ([, { kvittering }]) =>
          !kvittering.selectedKvittering.data._links?.self?.href
      ),
      switchMap(([{ payload, payloadType }, state]) => {
        const data = payloadType === 'sak' || 'typeTilsyn' ? payload : {}
        return of(state).pipe(
          commonApi.post(
            `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer`,
            state,
            data
          ),
          mergeMap((response) => {
            const kvitteringId = response.xhr
              .getResponseHeader('location')
              .match(/(\d+)$/)
              ?.pop()
            const URL = `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${kvitteringId}/?expand=tilsynsobjekter`
            return of(state).pipe(
              commonApi.get(URL, state),
              mergeMap((resp) => {
                return merge(
                  of(kvitteringActions.setCurrentKvittering(resp)),
                  of(kvitteringActions.setStatus(OK)),
                  of(kvitteringActions.fetchKvitteringer())
                )
              })
            )
          }),
          catchError((err) => {
            return concat(
              of(kvitteringActions.addKvitteringFailed(err.message)),
              of(kvitteringActions.setStatus(ERROR))
            )
          })
        )
      })
    )

export const deleteKvitteringEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.DELETE_KVITTERING),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      mergeMap(([{ kvittering }, state]) => {
        const URL = `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${kvittering.id}`

        return of(state).pipe(
          commonApi.delete(URL, state),
          mergeMap(() => {
            return of(state).pipe(
              mergeMap(() => {
                return concat(of(kvitteringDeleteSuccessToast))
              })
            )
          }),
          catchError(() => {
            return concat(
              of(kvitteringDeleteFailedToast),
              of(kvitteringActions.fetchKvitteringer())
            )
          })
        )
      })
    )

export const arkiverKvitteringEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.ARKIVER_KVITTERING),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      mergeMap(([{ id }, state]) => {
        const URL = `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${id}/arkiver`
        return of(state).pipe(
          commonApi.put(URL, state),
          mergeMap(() =>
            concat(
              of(kvitteringActions.fetchKvitteringById(id)),
              of(kvitteringActions.updateCurrentKvitteringSuccess()),
              sendBrukerundersoekelseNotification(state)
            )
          ),
          catchError(() => {
            goBack()
            return concat(
              of(kvitteringArkivereFailedToast),
              of(kvitteringActions.fetchKvitteringer()),
              of(kvitteringActions.clearCurrentKvitteringId())
            )
          })
        )
      })
    )

export const ferdigstillKvitteringEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.FERDIGSTILL_KVITTERING),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      mergeMap(([{ id }, state]) => {
        const fromStatus = state.kvittering.selectedKvittering.data.status
        const URL = `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${id}/ferdigstill`
        return of(state).pipe(
          commonApi.put(URL, state),
          mergeMap(() =>
            concat(
              of(kvitteringActions.fetchKvitteringById(id)),
              of(kvitteringActions.updateCurrentKvitteringSuccess()),
              sendBrukerundersoekelseNotification(state, fromStatus)
            )
          ),
          catchError(() => {
            goBack()
            return concat(
              of(kvitteringFerdigstillFailedToast),
              of(kvitteringActions.fetchKvitteringer()),
              of(kvitteringActions.clearCurrentKvitteringId())
            )
          })
        )
      })
    )

const sendBrukerundersoekelseNotification = (
  state: IStoreState,
  fromStatus?: string
) => {
  const userName = state?.user?.authUser?.username
  const { mobilForUndersoekelse, virksomhetsNavn } =
    state.kvittering.selectedKvittering.data
  if (
    !(
      mobilForUndersoekelse &&
      mobilForUndersoekelse.length > 0 &&
      (fromStatus === undefined ||
        fromStatus === null ||
        fromStatus !== 'ARKIVERT')
    )
  ) {
    return EMPTY
  }

  const mobileNotificationUUID = uuidV1()
  return of(
    notifications.actions.post({
      id: mobileNotificationUUID,
      title: 'Et telefonnummer ble registrert på tilsynet',
      body: `Du registrerte telefonnummeret
            ${mobilForUndersoekelse} på tilsynet hos
            ${virksomhetsNavn} for bruk i
            brukerundersøkelse`,
      status: 'info',
      local: true,
      topic: 'tilsynskvittering.link',
      notificationReceiver: userName,
      withMoreInfo: {
        link: {
          text: 'Gå til utsending av brukerundersøkelse',
          url: 'https://horizons.confirmit.eu/wix/p768917564798.aspx',
          helperText:
            'Mobilnummeret blir kopiert når du går til utsending av brukerundersøkelsen.',
          action: {
            type: 'copyToClipboard',
            payload: mobilForUndersoekelse,
          },
        },
      },
    })
  )
}

export const fetchKvitteringByIdEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.FETCH_KVITTERING_BY_ID),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      mergeMap(([{ id }, state]) => {
        return of(state).pipe(
          fetchKvitteringById$(commonApi, state)(id),
          mergeMap((kvittering: any) =>
            concat(
              of(kvitteringActions.setCurrentKvittering(kvittering)),
              of(kvitteringActions.fetchKvitteringByIdSuccess(kvittering))
            )
          ),
          catchError((err) => {
            return of(kvitteringActions.fetchKvitteringByIdFailed(err.message))
          })
        )
      })
    )

export const updateKvitteringEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.UPDATE_KVITTERING),
      withLatestFrom(state$),
      mergeMap(([{ kvittering, blockToast }, state]) => {
        const url = `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${kvittering.id}`
        const getPayload = (payload) => {
          if (isUuid(payload?.bilderGjennomgaattBegrunnelse)) {
            return {
              bilderGjennomgaattBegrunnelse: null,
            }
          }
          if (isUuid(payload?.registrertInnholdGjennomgaattBegrunnelse)) {
            return {
              registrertInnholdGjennomgaattBegrunnelse: null,
            }
          }
          return payload
        }

        return of(state).pipe(
          commonApi.put(url, state, {
            ...kvittering,
            ...getPayload(kvittering),
            tilsynsobjekter: null,
            kontrollpunktGrupper: null,
          }),
          map((payload) =>
            kvitteringActions.updateKvitteringSuccess(payload, kvittering.id)
          ),
          catchError((err) => {
            if (blockToast) {
              return merge(
                of(kvitteringActions.updateKvitteringFailed(err.message))
              )
            } else {
              return merge(
                of(kvitteringActions.updateKvitteringFailed(err.message)),
                of(dangerToast())
              )
            }
          })
        )
      })
    )

export const fetchSammenstillingEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.FETCH_SAMMENSTILLING),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      debounce(([, { kvittering }]) =>
        kvittering.selectedTilsynsobjekt.status === SAVING ||
        kvittering.selectedKvittering.status === SAVING
          ? interval(2000)
          : interval(0)
      ),
      mergeMap(([, state]) => {
        const kvitteringId = state.kvittering.selectedKvittering.data.id
        const url = `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${kvitteringId}/sammenstilling`
        return of(state).pipe(
          commonApi.get(url, state),
          map((resp: any) => resp || {}),
          map((kvittering: any) =>
            kvitteringActions.fetchSammenstillingSuccess(
              formatKvittering(kvittering)
            )
          ),
          catchError((err) =>
            of(kvitteringActions.fetchSammenstillingFailed(err.message))
          )
        )
      })
    )

export const updateCurrentKvitteringEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.UPDATE_CURRENT_KVITTERING),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      filter(
        ([, { kvittering }]) =>
          kvittering.selectedKvittering.data?._links?.self?.href
      ),
      debounce(([{ delay }]) => interval(delay)),
      mergeMap(([{ payload }, state]) => {
        const { kvittering } = state
        const URL = `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${kvittering.selectedKvittering.data.id}`
        const getPayload = (payload) => {
          if (isUuid(payload?.bilderGjennomgaattBegrunnelse)) {
            return {
              bilderGjennomgaattBegrunnelse: null,
            }
          }
          if (isUuid(payload?.registrertInnholdGjennomgaattBegrunnelse)) {
            return {
              registrertInnholdGjennomgaattBegrunnelse: null,
            }
          }
          return payload
        }
        return of(state).pipe(
          commonApi.put(URL, state, {
            ...formatKvitteringToValidIds(kvittering.selectedKvittering.data),
            ...getPayload(payload),
          }),
          mergeMap(() => {
            const shouldGetLatestKvitteringData = payload?.status === 'ARKIVERT'
            const id = kvittering.selectedKvittering.data.id!
            return concat(
              shouldGetLatestKvitteringData
                ? of(kvitteringActions.fetchKvitteringById(id))
                : EMPTY,
              of(kvitteringActions.updateCurrentKvitteringSuccess())
            )
          }),
          catchError((err) => {
            return concat(
              of(kvitteringActions.updateCurrentKvitteringFailed(err.message)),
              of(dangerToast())
            )
          })
        )
      })
    )

export const fetchKvitteringFromIdAndUpdateCurrentKvitteringEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.SET_CURRENT_KVITTERING_FROM_ID),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      mergeMap(([{ tilsynskvitteringId, expand }, state]) => {
        const URL = `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${tilsynskvitteringId}`
        const URLwithQueries = URL.concat(
          createQueryStringFromObject('?')({ expand })
        )
        return of(state).pipe(
          commonApi.get(URLwithQueries, state),
          map((resp) => kvitteringActions.setCurrentKvittering(resp)),
          catchError((err) => {
            if (err.status === 404) {
              navigate('/')
              return concat(
                of(
                  kvitteringActions.updateCurrentKvitteringFailed(err.message)
                ),
                of(dangerToast('Kvitteringen finnes ikke'))
              )
            } else {
              return concat(
                of(
                  kvitteringActions.updateCurrentKvitteringFailed(err.message)
                ),
                of(dangerToast())
              )
            }
          })
        )
      })
    )

const fetchVirksomhetAdresse = (
  commonApi: ICommonApi,
  state: IStoreState,
  kvittering: IKvitteringData
) => {
  const url = `${ROOT_URL_VIRKSOMHET}/virksomheter/orgnummer?filter.orgnummer=${kvittering.virksomhetsOrgNr}`

  return of(state).pipe(
    commonApi.get(url, state),
    map((resp: any) => resp._embedded?.virksomheter?.[0] ?? []),
    map((adresse) =>
      kvitteringActions.fetchAdresseSuccess({
        ...kvittering,
        beliggenhetsadresse: adresse.beliggenhetsadresse ?? {},
        postadresse: adresse.postadresse ?? {},
        overordnetOrgNr: adresse.overordnetInfo?.organisasjonsnummer,
      })
    ),
    catchError(() => of(kvitteringActions.fetchAdresseFailed(ADRESSE_ERROR)))
  )
}

const fetchPrivatpersonAdresse = (
  commonApi: ICommonApi,
  state: IStoreState,
  kvittering: IKvitteringData
) => {
  const url = `${ROOT_URL_TILSYNSOBJEKT}/tilsynsobjekter/${kvittering.tilsynsobjekter[0].tilsynsobjektId}/virksomhet`

  return of(state).pipe(
    commonApi.get(url, state),
    map((resp: any) => resp.adresse ?? []),
    map((adresse) =>
      kvitteringActions.fetchAdresseSuccess({
        ...kvittering,
        adresse: adresse ?? {},
      })
    ),
    catchError(() => of(kvitteringActions.fetchAdresseFailed(ADRESSE_ERROR)))
  )
}

export const fetchAdresseEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.FETCH_ADRESSE),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      mergeMap(([{ kvittering }, state]) => {
        const isVirksomhet = kvittering.virksomhetsOrgNr
        if (isVirksomhet) {
          return fetchVirksomhetAdresse(commonApi, state, kvittering)
        }

        return fetchPrivatpersonAdresse(commonApi, state, kvittering)
      })
    )

export const fetchTilsynstyperEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.FETCH_TILSYNSTYPER),
      withLatestFrom(state$),
      mergeMap(([, state]) => {
        return of(state).pipe(
          commonApi.get(
            `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/tilsynstyper`,
            state
          ),
          map((resp: any) =>
            resp._embedded ? resp._embedded.tilsynstypeList : []
          ),
          mergeMap((tilsynstyper) => {
            return merge(
              of(kvitteringActions.fetchTilsynstyperSuccess(tilsynstyper))
            )
          }),
          catchError((err) =>
            of(kvitteringActions.fetchTilsynstyperFailed(err))
          )
        )
      })
    )

export const setTypeTilsynEpic =
  () =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.SET_TYPE_TILSYN),
      withLatestFrom(state$),
      mergeMap(([{ typeTilsyn, typeTilsynBeskrivelse }, state]) => {
        return !!state.kvittering.selectedKvittering.data.id
          ? of(
              kvitteringActions.updateCurrentKvittering({
                typeTilsyn,
                typeTilsynBeskrivelse,
              })
            )
          : of(kvitteringActions.addKvittering({ typeTilsyn }, 'typeTilsyn'))
      })
    )

export const fetchVirksomhetOrganisasjonsFormEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.FETCH_VIRKSOMHET_ORGANISASJONSFORM),
      withLatestFrom(state$),
      mergeMap(([{ id, orgNr }, state]) => {
        const tilsynobjektObs = of(state).pipe(
          commonApi.get(
            `${ROOT_URL_TILSYNSOBJEKT}/tilsynsobjekter/id/${id}`,
            state
          )
        )

        const observable = id !== undefined ? tilsynobjektObs : of({})

        return observable.pipe(
          mergeMap((tilsynsobjekt) => {
            if (!tilsynsobjekt?.orgNr && !orgNr) {
              return EMPTY
            }

            const organisasjonsnummer = tilsynsobjekt.orgNr || orgNr

            return of(state).pipe(
              commonApi.get(
                `${ROOT_URL_VIRKSOMHET}/virksomheter/orgnummer/${organisasjonsnummer}`,
                state
              ),
              mergeMap((resp) => {
                return of(
                  kvitteringActions.fetchVirksomhetOrganisasjonsFormSuccess(
                    resp.organisasjonsformKode
                  )
                )
              })
            )
          }),
          catchError((err) =>
            of(
              kvitteringActions.fetchVirksomhetOrganisasjonsFormFailed(
                err.message
              )
            )
          )
        )
      })
    )

export const fordelTilKollegaEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.FORDEL_TIL_KOLLEGA),
      withLatestFrom(state$),
      mergeMap(([{ kvittering, ansatt }, state]) => {
        const URL = `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/fordeling/${kvittering?.id}/${ansatt.brukernavn}`
        return of(state).pipe(
          commonApi.post(URL, state, {}),
          mergeMap(() => {
            return concat(
              of(kvitteringActions.fordelTilKollegaSuccess()),
              of(
                successToast(
                  `Tilsynskvitteringen ble fordelt til ${ansatt.navn}`
                )
              ),
              of(kvitteringActions.fetchKvitteringer())
            )
          }),
          catchError((error) =>
            concat(
              of(kvitteringActions.fordelTilKollegaFailed(error.message)),
              of(dangerToast(`Kunne ikke fordele tilsynskvitteringen`))
            )
          )
        )
      })
    )

export const hentFraKollegaEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.HENT_FRA_KOLLEGA),
      withLatestFrom(state$),
      mergeMap(([{ begrunnelse, kollega, kvitteringIds }, state]) =>
        of(state).pipe(
          commonApi.post(
            `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/overtagelse`,
            state,
            {
              begrunnelse: begrunnelse,
              tilsynskvitteringIds: kvitteringIds,
            }
          ),
          mergeMap(() => {
            return concat(
              of(kvitteringActions.hentFraKollegaSuccess()),
              of(successToast(`Hentet tilsynskvitteringer fra ${kollega}`)),
              of(kvitteringActions.fetchKvitteringer())
            )
          }),
          catchError((error) =>
            concat(
              of(kvitteringActions.hentFraKollegaFailed(error.message)),
              of(dangerToast(`Kunne ikke hente tilsynskvitteringer`))
            )
          )
        )
      )
    )

export const updateGenerellInformasjonEpic =
  () => (action$: Observable<AnyAction>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.UPDATE_GENERELL_INFORMASJON),
      groupBy(({ kvittering }) => kvittering._links.self.href || kvittering.id),
      mergeMap((group$) =>
        group$.pipe(
          filter(() => window.navigator.onLine),
          debounce(() => interval(1000)),
          map(({ kvittering }) =>
            kvitteringActions.updateKvittering(kvittering)
          )
        )
      )
    )

export const fetchKvitteringerFraKollegaEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(kvitteringActionTypes.FETCH_KVITTERINGER_FRA_KOLLEGA),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      mergeMap(([{ userId }, state]) => {
        const pageSize = 100
        const queries = createQueryStringFromObject('?')({
          'page.size': pageSize,
          'page.number': 0,
        })

        return of(state).pipe(
          commonApi.get(
            `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/overtagelse/${userId}${queries}`,
            state
          ),
          map(
            (resp: any) => resp._embedded?.overtagbarTilsynskvitteringList ?? []
          ),
          mergeMap((kvitteringer) =>
            of(
              kvitteringActions.fetchKvitteringerFraKollegaSuccess(kvitteringer)
            )
          ),
          catchError((error) =>
            of(kvitteringActions.fetchKvitteringerFraKollegaFail(error.message))
          )
        )
      })
    )

export default (commonApi: ICommonApi) =>
  combineEpics(
    fetchKvitteringerEpic(commonApi),
    addKvitteringEpic(commonApi),
    fetchKvitteringByIdEpic(commonApi),
    updateKvitteringEpic(commonApi),
    deleteKvitteringEpic(commonApi),
    arkiverKvitteringEpic(commonApi),
    ferdigstillKvitteringEpic(commonApi),
    fetchSammenstillingEpic(commonApi),
    updateCurrentKvitteringEpic(commonApi),
    fetchKvitteringFromIdAndUpdateCurrentKvitteringEpic(commonApi),
    fetchAdresseEpic(commonApi),
    fetchTilsynstyperEpic(commonApi),
    setTypeTilsynEpic(),
    fetchVirksomhetOrganisasjonsFormEpic(commonApi),
    fordelTilKollegaEpic(commonApi),
    hentFraKollegaEpic(commonApi),
    updateGenerellInformasjonEpic(),
    fetchKvitteringerFraKollegaEpic(commonApi)
  )
