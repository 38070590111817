import React, { useCallback, useState } from 'react';
import { useTypedSelector } from '../../common/custom-hooks';
import { userSelectors } from '../../ducks/user/selectors';
import { getVisibleCards } from '../../common/get-visible-cards';
import './style.css';
import { useKvittering } from '../../features/kvitteringer';
import { useKvitteringId } from '../../common/kvittering-context';
import { TilsynsobjektMenu } from '../tilsynsobjekt-menu';
import { Column, LoadingDots } from '@mattilsynet/mt-ui';
import { Tilsynsobjektinfo } from '../../features/tilsynsobjektinfo/components';
export const TilsynsobjektinfoPage = () => {
  const authUser = useTypedSelector(userSelectors.getAuthUser);
  const accessToken = authUser?.accessToken;
  const visibleCards = getVisibleCards(accessToken);
  const kvitteringId = useKvitteringId();
  const [selectedTilsynsobjektId, setSelectedTilsynsobjektId] = useState<string>();
  const {
    data: tilsynsobjekter,
    isLoading
  } = useKvittering(kvitteringId, {
    select: kvittering => kvittering.tilsynsobjekter
  });
  const selectedTilsynsobjekt = selectedTilsynsobjektId === undefined ? tilsynsobjekter?.[0] : tilsynsobjekter?.find(to => to.id === selectedTilsynsobjektId);
  const onUpdateSelectedTilsynsobjekt = useCallback((tilsynsobjektId: string) => setSelectedTilsynsobjektId(tilsynsobjektId), []);
  if (isLoading) {
    return <LoadingDots />;
  }
  return <>
      <Column padding={[2, 2, 0, 2]} data-sentry-element="Column" data-sentry-source-file="index.tsx">
        <TilsynsobjektMenu selectedTilsynsobjektId={selectedTilsynsobjekt?.id ?? ''} tilsynsobjekter={tilsynsobjekter ?? []} onSelectTilsynsobjekt={onUpdateSelectedTilsynsobjekt} data-sentry-element="TilsynsobjektMenu" data-sentry-source-file="index.tsx" />
      </Column>
      <Tilsynsobjektinfo tilsynsobjektId={selectedTilsynsobjekt?.tilsynsobjektId ?? ''} visibleCards={visibleCards} data-sentry-element="Tilsynsobjektinfo" data-sentry-source-file="index.tsx" />
    </>;
};