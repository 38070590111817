import { reducerHelpers } from './store'
import epics from './epic'
import { SearchInputWrapper } from './view'

export type { ISearchStateDefault } from './store/types'

export const SearchDraft = <TStoreName extends string, Queries, Result = any>(
  storeName: TStoreName
) => {
  const { reducer, actionTypes, actions } = reducerHelpers<
    Queries,
    Result,
    TStoreName
  >(storeName)
  return {
    search: {
      actions,
      actionTypes,
      reducer,
      epics: epics(storeName, actions, actionTypes),
    },
    Search: {
      SearchInputWrapper,
    },
  }
}
