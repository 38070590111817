import React from 'react';
import { Column, Text } from '@mattilsynet/mt-ui';
import { IFunn } from '../../hooks/funn-hooks';
interface IFunnIndividListProps {
  funn: IFunn;
  isAktivitetSvinehold: boolean | undefined;
}
export const getGjelderFlereDyrFunnText = (gjelderflereDyr, isAktivitetSvinehold: boolean | undefined) => isAktivitetSvinehold ? gjelderflereDyr ? 'Funnet gjelder en gruppe med dyr' : 'Funnet gjelder ett dyr' : gjelderflereDyr ? 'Funnet gjelder en gruppe med individer' : 'Funnet gjelder ett individ';
export const FunnIndividList = ({
  funn,
  isAktivitetSvinehold
}: IFunnIndividListProps) => {
  if (funn.isAmFunn && funn.gjelderFlereDyr == null) {
    return <Text weight="light" size="normal">
        {`Informasjon om ${isAktivitetSvinehold ? 'antall dyr' : 'individer'} mangler`}
      </Text>;
  }
  if (funn.isAmFunn) {
    return <Column spacing={2}>
        <Text size="normal">
          {getGjelderFlereDyrFunnText(funn.gjelderFlereDyr, isAktivitetSvinehold)}
        </Text>
        {!!funn.individer?.length && <Column spacing={0.5}>
            {funn.individer!.map(individ => <p key={individ.individId}>{`Individnummer: ${individ.individId}`}</p>)}
          </Column>}
        {funn.gjelderFlereDyr === false && !isAktivitetSvinehold && (!funn.individer?.length ? <Text weight="light" size="normal">
              Individnummer mangler
            </Text> : funn.individer[0]?.individId.length < 12 && <Text weight="light" size="normal">
                Individnummer må inneholde 12 siffer
              </Text>)}
      </Column>;
  }
  return <Column spacing={1} data-sentry-element="Column" data-sentry-component="FunnIndividList" data-sentry-source-file="index.tsx">
      {funn.skrottnummer ? <Text size="normal">{`Skrottnummer: ${funn.skrottnummer}`}</Text> : <Text size="normal" weight="light">
          Skrottnummer mangler
        </Text>}
      {funn.individId ? <Text size="normal">{`Individnummer: ${funn.individId}`}</Text> : !isAktivitetSvinehold && <Text size="normal" weight="light">
            Individnummer mangler
          </Text>}
      {funn.individId && funn.individId.length < 12 && <Text size="normal" weight="light">
          Individnummer må inneholde 12 siffer
        </Text>}
    </Column>;
};