import {
  mergeMap,
  delay,
  retryWhen,
  takeUntil,
  switchMap,
  debounceTime,
  filter,
  catchError,
  mapTo,
  map,
  withLatestFrom,
} from 'rxjs/operators'

import { combineEpics, Epic, ofType } from 'redux-observable'
import { of, timer } from 'rxjs'
import { AnyAction } from 'redux'

import { actions, actionTypes } from '../store'

import { notificationApi } from './api'
import notificationDb from '../indexed-db'

import {
  removeOfflineDeletedNotifications,
  setOfflineNotificationsSeen,
  parseNotifications,
} from './helpers'

/**
 * Fetches notifications from api every `pollTime` ms
 * - Interval timer starts when redux action [[notification.actions.START_POLLING]] is fired with pollTime in ms.
 * - Interval timer stops when redux action [[notification.actions.STOP_POLLING]]
 *
 * @category epic
 * @param api Interface to communicate with api
 * @param db Interface to communicate with IndexedDB
 * @param scheduler Test scheduler to time in tests
 * @return [[notification.actions.ADD]]
 */
export const getNotificationEpic =
  (
    api: ReturnType<typeof notificationApi>,
    db = notificationDb,
    scheduler = undefined
  ): Epic<AnyAction, AnyAction> =>
  (action$, state$) =>
    action$.pipe(
      ofType(actionTypes.START_POLLING),
      switchMap(({ pollTime, applicationNames, eftanummer }) =>
        timer(0, pollTime, scheduler).pipe(
          takeUntil(action$.pipe(ofType(actionTypes.STOP_POLLING))),
          withLatestFrom(state$),
          mergeMap(([, state]) =>
            of(state).pipe(
              api.getNotifications(state, applicationNames, eftanummer)
            )
          ),
          retryWhen((error) => error.pipe(delay(pollTime))),
          map((resp) => parseNotifications(resp)),
          removeOfflineDeletedNotifications(db),
          setOfflineNotificationsSeen(db),
          map((notificationList: any) => actions.fetchOk(notificationList))
        )
      )
    )

/**
 * Deletes notification when redux action [[notification.actions.DELETE]]] with notification object is fired.
 *
 * If `api.deleteNotification` fails the *delete notification* event will be staged in indexDB and replayed on next [[notification.actions.SYNC]]. This is handled in [[syncNotificationDeletionEpic]].
 *
 * @category epic
 * @param api Interface to communicate with api
 * @param db Interface to communicate with IndexedDB
 * @return OK - [[notification.actions.DELETE_SUCCESS]]
 * @return FAIL - [[notification.actions.DELETE_FAIL]]
 */
export const deleteNotificationsEpic =
  (
    api: ReturnType<typeof notificationApi>,
    db = notificationDb
  ): Epic<AnyAction, AnyAction> =>
  (action$, state$) =>
    action$.pipe(
      ofType(actionTypes.DELETE),
      filter(({ notification }) => !notification.local),
      withLatestFrom(state$),
      mergeMap(([{ notification }, state]) =>
        of(notification).pipe(
          mergeMap(() => db.stageNotificationForDeletion(notification.id)),
          api.deleteNotification(notification.id, state),
          mergeMap(() => db.removeNotificationStagedAction(notification.id)),
          mapTo(actions.deleteSuccess(notification.id)),
          catchError((err) => {
            if (err.status === 404) {
              return db
                .removeNotificationStagedAction(notification.id)
                .pipe(mapTo(actions.deleteSuccess(notification.id)))
            }
            throw err
          }),
          catchError(() => of(actions.deleteFail(notification.id)))
        )
      )
    )

/**
 * Mark notification as read when redux action [[notification.actions.READ]] is fired.
 *
 * If `api.readNotification` fails the *read notification* event will be staged in indexDB and replayed on next [[notification.actions.SYNC]] is fired. This is handled in [[syncNotificationSeenEpic]].
 *
 * @category epic
 * @param api Interface to communicate with api
 * @param db Interface to communicate with IndexedDB
 * @return OK - [[notification.actions.READ_SUCCESS]]
 * @return FAIL - [[notification.actions.READ_FAIL]]
 */
export const readNotificationEpic =
  (
    api: ReturnType<typeof notificationApi>,
    db = notificationDb
  ): Epic<AnyAction, AnyAction> =>
  (action$, state$) =>
    action$.pipe(
      ofType(actionTypes.READ),
      filter(({ notification }) => !(notification.local || notification.seen)),
      withLatestFrom(state$),
      mergeMap(([{ notification }, state]) =>
        of(notification).pipe(
          mergeMap(() => db.stageNotificationForSeen(notification.id)),
          api.readNotifications(notification.id, state),
          mergeMap(() => db.removeNotificationStagedAction(notification.id)),
          mapTo(actions.readSuccess(notification.id)),
          catchError((err) => {
            if (err.status === 404) {
              return db
                .removeNotificationStagedAction(notification.id)
                .pipe(mapTo(actions.readSuccess(notification.id)))
            }
            throw err
          }),
          catchError(() => of(actions.readFail(notification.id)))
        )
      )
    )

/**
 *  Gets staged actions of type *SEEN* and tries to resend that user has seen a notification
 *
 * @category epic
 * @param db Interface to communicate with IndexedDB
 * @return Staged actions exist - [[notification.actions.READ]]
 * @return No staged actions - Nothing
 * @return Fail - [[notification.actions.READ_SYNC_FAIL]]
 */

export const syncNotificationSeenEpic =
  (db = notificationDb) =>
  (action$) =>
    action$.pipe(
      ofType(actionTypes.SYNC),
      debounceTime(3000),
      mergeMap(() =>
        db.getStagedActions('SEEN').pipe(
          mergeMap((notificationAction: any) => notificationAction),
          map((notificationAction: any) => notificationAction.id),
          map((id: string) => actions.read({ id: id, local: false })),
          catchError(() => of(actions.readSyncFail()))
        )
      )
    )

/**
 *  Gets staged actions of type *DELETE* and tries to resend deletion of remote image
 *
 * @category epic
 * @param db Interface to communicate with IndexedDB
 * @return Staged actions exist - [[notification.actions.DELETE]]
 * @return No staged actions - Nothing
 * @return Fail - [[notification.actions.DELETE_SYNC_FAIL]]
 */

export const syncNotificationDeletionEpic =
  (db = notificationDb) =>
  (action$) =>
    action$.pipe(
      ofType(actionTypes.SYNC),
      debounceTime(3000),
      mergeMap(() =>
        db.getStagedActions('DELETE').pipe(
          mergeMap((notificationAction: any) => notificationAction),
          map((notificationAction: any) => notificationAction.id),
          map((id: string) => actions.delete({ id: id, local: false })),
          catchError(() => of(actions.deleteSyncFail()))
        )
      )
    )

/**
 * Post notification when redux action [[notification.actions.POST]]] with notification object is fired.
 *
 *
 * @category epic
 * @param api Interface to communicate with api
 * @return OK - [[notification.actions.POST_SUCCESS]]
 * @return FAIL - [[notification.actions.POST_FAIL]]
 */
export const postNotificationsEpic =
  (api: ReturnType<typeof notificationApi>): Epic<AnyAction, any> =>
  (action$, state$) =>
    action$.pipe(
      ofType(actionTypes.POST),
      withLatestFrom(state$),
      mergeMap(([{ notification }, state]) => {
        const body = {
          ...notification,
          withMoreInfo: JSON.stringify(notification.withMoreInfo),
          link: JSON.stringify(notification.link),
        }
        return of(notification).pipe(
          api.postNotification(body, state),
          mapTo(actions.postSuccess(notification)),
          catchError((error) => of(actions.postFail(error.message)))
        )
      })
    )

/**
 * Set up epics for notifications
 * @param accessTokenPath Path in redux for valid API access token
 * @param rootUrl optional url - default: /api/
 * @param api optional (mostly for testing purposes)
 * @param db optional (mostly for testing purposes)
 */
export default (
  accessTokenPath: string[],
  rootUrl = '/api',
  api?: ReturnType<typeof notificationApi>,
  db?: typeof notificationDb
) =>
  combineEpics(
    getNotificationEpic(
      api || notificationApi(accessTokenPath, rootUrl),
      db || notificationDb
    ),
    deleteNotificationsEpic(
      api || notificationApi(accessTokenPath, rootUrl),
      db || notificationDb
    ),
    readNotificationEpic(
      api || notificationApi(accessTokenPath, rootUrl),
      db || notificationDb
    ),
    syncNotificationDeletionEpic(db || notificationDb),
    syncNotificationSeenEpic(db || notificationDb),
    postNotificationsEpic(api || notificationApi(accessTokenPath, rootUrl))
  )
