import { CommonReduxTypes } from '../../../../common/redux'
import { tidligereTilsynskvitteringerActions } from './store'

export enum TilsynskvitteringStatus {
  PAABEGYNT = 'PAABEGYNT',
  FORBEREDT = 'FORBEREDT',
  FERDIGSTILT = 'FERDIGSTILT',
  ARKIVERT = 'ARKIVERT',
}

export type ITidligereTilsynskvitteringerActions =
  CommonReduxTypes.IGetActionTypesFromObject<
    typeof tidligereTilsynskvitteringerActions
  >

export interface ITidligereTilsynskvittering {
  tilsynsdato: string
  ansattNavn: string
  noarksakAar: string
  noarksakSekvensnummer: string
  antallKontrollpunkter: string
  antallBilder: string
  tilsynskvitteringId: number
  status: TilsynskvitteringStatus
  unntattOffentlighet: boolean
}

export interface ITidligereTilsynskvitteringerResponse {
  tilsynsobjektId: string
  tilsynskvitteringer: ITidligereTilsynskvittering[]
}

export type ITidligereTilsynskvitteringerState =
  CommonReduxTypes.IStateDataList<ITidligereTilsynskvittering[]>
