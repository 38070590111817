import * as R from 'ramda'
import dayjs from 'dayjs'
import { ITilsynsobjekt } from './types'
import { sortByKey } from '../../../../common/sorting'
import { IRemoteKodeverk } from '../../../kodeverk/store/types'

export const findAktivitetForTilsynsobjekt = (
  tilsynsobjekt: ITilsynsobjektRemote.Tilsynsobjekt | undefined,
  aktivitetKodeverk: IRemoteKodeverk[] | undefined
): IRemoteKodeverk | undefined => {
  const aktiviteter = aktivitetKodeverk?.reduce(
    (acc: IRemoteKodeverk[], item: IRemoteKodeverk) => {
      return item.children ? [...acc, item, ...item.children] : [...acc, item]
    },
    []
  )
  return aktiviteter?.find(
    (kodeverk: IRemoteKodeverk) =>
      `${kodeverk.codeType}$${kodeverk.codeString}` ===
      tilsynsobjekt?.aktivitetsId
  )
}

export const getProdusentNummerFromProdusent = (
  produsent: ITilsynsobjekt | undefined
) =>
  R.pipe(
    R.prop('identiteter'),
    R.defaultTo([]),
    R.reject(({ gyldigTil }) => {
      const gyldigTilDate = dayjs(gyldigTil).endOf('day')
      const todayDate = dayjs()
      return gyldigTilDate.isBefore(todayDate)
    }),
    R.indexBy(R.path(['identitetstype', 'id'])),
    R.path(['IDENTITETSTYPE$PRODUSENTNUMMER', 'verdi']),
    R.defaultTo('')
  )(produsent)

export const getDyreholdIdFromProdusent = (
  produsent: ITilsynsobjekt | undefined
) =>
  R.pipe(
    R.prop('identiteter'),
    R.defaultTo([]),
    R.reject(({ gyldigTil }) => {
      const gyldigTilDate = dayjs(gyldigTil).endOf('day')
      const todayDate = dayjs()
      return gyldigTilDate.isBefore(todayDate)
    }),
    R.indexBy(R.path(['identitetstype', 'id'])),
    R.path(['IDENTITETSTYPE$DYREHOLDID', 'verdi']),
    R.defaultTo('')
  )(produsent)

export const getValidIdentitetListWithAktivitet = (
  produsent: ITilsynsobjekt | undefined
) => {
  if (!produsent) return []
  const identiteter = sortByKey('label')(
    (produsent.identiteter || [])
      .filter(({ gyldigTil }) => {
        const gyldigTilDate = dayjs(gyldigTil).endOf('day')
        const todayDate = dayjs()
        return gyldigTilDate.isAfter(todayDate)
      })
      .map((identitet) => ({
        value: identitet.verdi,
        label: identitet.identitetstype?.beskrivelse,
      }))
  ).reverse()

  const aktivitet = {
    label: 'Aktivitet',
    value: produsent.aktivitet?.beskrivelse,
  }

  return [aktivitet, ...identiteter]
}
