import { reducer, getByOrgNr, actionsTypes, actions } from './store'
import Brreg from './view/index'
import epics from './epic'

const brreg = {
  reducer,
  getByOrgNr,
  actionsTypes,
  actions,
  epics,
}

export { Brreg, brreg }
