import React from 'react';
import { TextLink, GridItem, Row, Grid, Column, RowItem, ShadowBox } from '@mattilsynet/mt-ui';
import { StatusIndicator } from '../../components/status-indicator';
import './style.css';
interface IAboutViewProps {
  apiStatus: {
    checked: boolean;
    ok: boolean;
  };
  goBack: () => void;
}
export const AboutView = ({
  apiStatus,
  goBack
}: IAboutViewProps) => <Grid id="about" data-sentry-element="Grid" data-sentry-component="AboutView" data-sentry-source-file="view.tsx">
    <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]} data-sentry-element="GridItem" data-sentry-source-file="view.tsx">
      <Row data-sentry-element="Row" data-sentry-source-file="view.tsx">
        <TextLink leftArrow onClick={goBack} data-sentry-element="TextLink" data-sentry-source-file="view.tsx">
          Tilbake
        </TextLink>
      </Row>
    </GridItem>
    <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]} data-sentry-element="GridItem" data-sentry-source-file="view.tsx">
      <ShadowBox data-sentry-element="ShadowBox" data-sentry-source-file="view.tsx">
        <Column spacing={1} padding={2.5} data-sentry-element="Column" data-sentry-source-file="view.tsx">
          <h1>Om applikasjonen</h1>
          <Column spacing={1} data-sentry-element="Column" data-sentry-source-file="view.tsx">
            <h2>Tilsynskvittering</h2>
            <Row data-sentry-element="Row" data-sentry-source-file="view.tsx">
              Webløsning som lar deg registrere deltakere, kontrollpunkter,
              observasjoner og veiledning på inspeksjoner. Du kan også ta bilder
              direkte i løsningen eller legge til bilder fra fotoløsningen.
              Registreringene resulterer i et dokument som fremviser det
              foreløpige faktagrunnlaget i saken på en strukturert måte for
              part. Løsningen legger til rette for automatisk partsinnsyn ved at
              tilsynskvitteringen, med ev. bilder, arkiveres korrekt og sendes
              ut via Svar-Ut-tjenesten. Utsendelsen gjøres umiddelbart etter
              endt inspeksjon, uavhengig av den etterfølgende
              inspeksjonsrapporten
            </Row>
          </Column>
          <Column spacing={1} data-sentry-element="Column" data-sentry-source-file="view.tsx">
            <h2>Brukerstøtte</h2>
            <div>
              <span className="space">
                Hvis du har behov for hjelp kan du opprette
              </span>
              <TextLink href="https://makuba.mattilsynet.no/" linkOutside data-sentry-element="TextLink" data-sentry-source-file="view.tsx">
                brukerstøttesak
              </TextLink>
              <Row data-sentry-element="Row" data-sentry-source-file="view.tsx">(Fungerer best på PC på Mattilsynets nettverk)</Row>
            </div>
            <Row data-sentry-element="Row" data-sentry-source-file="view.tsx">
              Du kan også kontakte IKT-brukerstøtte på telefon eller e-post:
            </Row>
            <Row data-sentry-element="Row" data-sentry-source-file="view.tsx">
              <RowItem className="space" data-sentry-element="RowItem" data-sentry-source-file="view.tsx">Telefon:</RowItem>
              <TextLink href="tel:22 77 88 99" data-sentry-element="TextLink" data-sentry-source-file="view.tsx">22 77 88 99</TextLink>
            </Row>
            <Row data-sentry-element="Row" data-sentry-source-file="view.tsx">
              <RowItem className="space" data-sentry-element="RowItem" data-sentry-source-file="view.tsx">E-post:</RowItem>
              <TextLink href="mailto:99@mattilsynet.no" data-sentry-element="TextLink" data-sentry-source-file="view.tsx">
                99@mattilsynet.no
              </TextLink>
            </Row>
          </Column>
          <Column spacing={1} data-sentry-element="Column" data-sentry-source-file="view.tsx">
            <h2>Status</h2>
            <Row data-sentry-element="Row" data-sentry-source-file="view.tsx">
              <RowItem className="space" data-sentry-element="RowItem" data-sentry-source-file="view.tsx">Server:</RowItem>
              <StatusIndicator checked={apiStatus.checked} ok={apiStatus.ok} data-sentry-element="StatusIndicator" data-sentry-source-file="view.tsx" />
            </Row>
          </Column>
          <Column spacing={1} data-sentry-element="Column" data-sentry-source-file="view.tsx">
            <h2>Versjon</h2>
            <Row data-sentry-element="Row" data-sentry-source-file="view.tsx">{process.env.APP_VERSION}</Row>
          </Column>
        </Column>
      </ShadowBox>
    </GridItem>
  </Grid>;