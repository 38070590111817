import {
  UseMutationResult,
  useMutation,
  MutationKey,
} from '@tanstack/react-query'
import { bildeKeys, getMetadataWithGeodata } from '../queries/helpers'
import { useDispatch } from 'react-redux'
import { dangerToast } from '../../../common/toast'
import { getValidBildeMetadataFn, setBildeUrl } from '../metadata-helpers'
import {
  BILDE_SIZE_VALUES,
  BildeSizes,
  BildeMutationVars,
  BildeObservasjonVars,
  BildeKvitteringUploadVars,
  BildeKvitteringVars,
  IImage,
} from '../types'
import { useEffect } from 'react'
import { bildeApi } from '../queries/bilde-api'
import { useTypedSelector } from '../../../common/custom-hooks'
import { bildeSelectors } from '../bilde-slice'
import { useKvittering } from '../../kvitteringer'
import {
  getAllBildeIdsFromKvittering,
  getAllGroupedBildeIdsFromKvittering,
  removeBildeMutationsIfExisting,
  toSortedBildeIds,
} from '../cache-helpers'
import { AxiosResponse } from 'axios'
import { useBildeMetadatasFromIds } from './metadata-query-hooks'
import { useLocalBildeFile } from './component-hooks'

export {
  useBilde,
  useAllBildeIds,
  useAllGroupedBildeIds,
  useKvitteringBildeIds,
  useUploadBildeToObservasjon,
  useUploadBildeToKvittering,
  useRemoveBildeFromObservasjon,
  useDeleteBilde,
  useAttachBildeToObservasjon,
  useAttachBildeToKvittering,
}

const useBilde = (
  kvitteringId: string,
  bildeId: string,
  preferredImageSize: BildeSizes = 'small',
  app: 'TILSYNSKVITTERING' | 'MAKKS' = 'TILSYNSKVITTERING'
): [url: string | undefined, size: BildeSizes | undefined] => {
  const { size, url } =
    useTypedSelector(bildeSelectors.selectBildeUrl(kvitteringId, bildeId)) ?? {}

  const localBilde = useLocalBildeFile(bildeId)

  const preferLargerBilde =
    !size || BILDE_SIZE_VALUES[size] < BILDE_SIZE_VALUES[preferredImageSize]

  useEffect(() => {
    if (preferLargerBilde && window.navigator.onLine) {
      const controller = new AbortController()
      const getBilde = async () => {
        const { data } = await bildeApi.getBilde(
          {
            kvitteringId,
            bildeId,
            preferredImageSize,
            app,
          },
          { signal: controller.signal }
        )

        setBildeUrl(kvitteringId, bildeId, data, preferredImageSize)
      }

      getBilde().then()

      return () => {
        controller.abort()
      }
    }
  }, [preferLargerBilde, preferredImageSize, bildeId, kvitteringId, app])

  useEffect(() => {
    if ((!url || !size) && bildeId && localBilde) {
      setBildeUrl(kvitteringId, bildeId, localBilde, 'local')
    }
  }, [bildeId, kvitteringId, localBilde, size, url])

  return [url, size]
}

const useAllBildeIds = (kvitteringId: string) => {
  return useKvittering(kvitteringId, {
    select: (kvittering) => getAllBildeIdsFromKvittering(kvittering),
  })
}

const useAllGroupedBildeIds = (kvitteringId: string) =>
  useKvittering(kvitteringId, {
    select: (kvittering) => getAllGroupedBildeIdsFromKvittering(kvittering),
  })

const useKvitteringBildeIds = (kvitteringId: string) => {
  const { data: kvitteringBildeIds } = useKvittering(kvitteringId, {
    select: (kvittering) => kvittering.bildeIds,
  })
  const { data: bildeMetadataList } = useBildeMetadatasFromIds(
    kvitteringId,
    kvitteringBildeIds ?? []
  )

  return toSortedBildeIds(kvitteringBildeIds ?? [], bildeMetadataList ?? [])
}

const useUploadBildeToObservasjon = (
  kvitteringId: string,
  observasjonId: number,
  username: string
) => {
  const dispatch = useDispatch()
  const mutation: UseMutationResult<void, unknown, BildeMutationVars> =
    useMutation({
      mutationKey: bildeKeys.uploadToObservasjon(kvitteringId, observasjonId),
    })
  const metadataWithGeodata = getMetadataWithGeodata(kvitteringId)

  return {
    ...mutation,
    mutate: ({ bildeId, bilde }: BildeObservasjonVars) => {
      const getValidBildeMetadata = getValidBildeMetadataFn(
        bildeId,
        kvitteringId,
        username
      )

      getValidBildeMetadata(bilde)
        .then(([bildeMetadata, bilde]) => {
          if (
            metadataWithGeodata &&
            !bildeMetadata.locationLatitude &&
            !bildeMetadata.locationLongitude
          ) {
            const {
              locationLatitude: lat,
              locationLongitude: lon,
              id,
              accuracy,
            } = metadataWithGeodata

            bildeMetadata.locationLatitude = lat
            bildeMetadata.locationLongitude = lon
            bildeMetadata.accuracy = accuracy ?? 0
            bildeMetadata.locationCopiedFromId = id
          }

          mutation.mutate({
            bildeId: bildeMetadata.id,
            bildeMetadata,
            bilde,
            kvitteringId,
            observasjonId,
          })
        })
        .catch((error) => {
          if (error instanceof Error) {
            dispatch(dangerToast(error.message))
          }
        })
    },
  }
}

const useUploadBildeToKvittering = (kvitteringId: string, username: string) => {
  const dispatch = useDispatch()
  const mutation: UseMutationResult<void, unknown, BildeKvitteringUploadVars> =
    useMutation({ mutationKey: bildeKeys.uploadToKvittering(kvitteringId) })
  const metadataWithGeodata = getMetadataWithGeodata(kvitteringId)

  return {
    ...mutation,
    mutate: ({ bildeId, bilde }: BildeKvitteringVars) => {
      const getValidBildeMetadata = getValidBildeMetadataFn(
        bildeId,
        kvitteringId,
        username
      )

      getValidBildeMetadata(bilde)
        .then(([bildeMetadata, bilde]) => {
          if (
            metadataWithGeodata &&
            !bildeMetadata.locationLatitude &&
            !bildeMetadata.locationLongitude
          ) {
            const {
              locationLatitude: lat,
              locationLongitude: lon,
              id,
              accuracy,
            } = metadataWithGeodata

            bildeMetadata.locationLatitude = lat
            bildeMetadata.locationLongitude = lon
            bildeMetadata.accuracy = accuracy ?? 0
            bildeMetadata.locationCopiedFromId = id
          }

          mutation.mutate({
            bildeId: bildeMetadata.id,
            bildeMetadata,
            bilde,
            kvitteringId,
          })
        })
        .catch((error) => {
          if (error instanceof Error) {
            dispatch(dangerToast(error.message))
          }
        })
    },
  }
}

const useBildeMutation = (mutationKey: MutationKey, kvitteringId: string) => {
  const mutation = useMutation<
    AxiosResponse<null>,
    string | Error,
    BildeMutationVars,
    { previousMetadatas: IImage[] }
  >({ mutationKey })

  return {
    ...mutation,
    mutate: (variables: { bildeId: string; observasjonId: number }) =>
      mutation.mutate({
        kvitteringId,
        ...variables,
      }),
  }
}

const useRemoveBildeFromObservasjon = (kvitteringId: string) => {
  const mutation = useMutation<
    AxiosResponse<null>,
    string | Error,
    BildeMutationVars,
    { previousMetadatas: IImage[] }
  >({ mutationKey: bildeKeys.removeFromObservasjon(kvitteringId) })

  return {
    ...mutation,
    mutate: (variables: { bildeId: string; observasjonId: number }) =>
      mutation.mutate({
        kvitteringId,
        ...variables,
      }),
  }
}

const useDeleteBilde = (kvitteringId: string) => {
  const mutation: UseMutationResult<
    AxiosResponse<null>,
    string | Error,
    BildeKvitteringUploadVars
  > = useMutation({ mutationKey: bildeKeys.deleteFromKvittering(kvitteringId) })

  return {
    ...mutation,
    mutate: ({ bildeId }: { bildeId: string }) => {
      if (removeBildeMutationsIfExisting(kvitteringId, bildeId)) {
        return
      }

      mutation.mutate({ kvitteringId, bildeId })
    },
  }
}

const useAttachBildeToObservasjon = (kvitteringId: string) =>
  useBildeMutation(bildeKeys.attachToObservasjon(kvitteringId), kvitteringId)

const useAttachBildeToKvittering = (kvitteringId: string) =>
  useMutation<
    AxiosResponse<null>,
    string | Error,
    BildeKvitteringUploadVars,
    { previousMetadatas: IImage[] }
  >({ mutationKey: bildeKeys.attachToKvittering(kvitteringId) })
