import React from 'react';
import { TextLink, GridItem, Row, Grid, Column, RowItem, ShadowBox } from '@mattilsynet/mt-ui';
import './style.css';
const AccessDeniedRoute = () => <Grid id="access-denied" data-sentry-element="Grid" data-sentry-component="AccessDeniedRoute" data-sentry-source-file="index.tsx">
    <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]} data-sentry-element="GridItem" data-sentry-source-file="index.tsx">
      <ShadowBox data-sentry-element="ShadowBox" data-sentry-source-file="index.tsx">
        <Column spacing={1} padding={3} data-sentry-element="Column" data-sentry-source-file="index.tsx">
          <Row data-sentry-element="Row" data-sentry-source-file="index.tsx">Du har ikke tilgang til Tilsynskvittering</Row>
          <span>
            Kontakt brukerstøtte og{' '}
            <TextLink href="/login" inline data-sentry-element="TextLink" data-sentry-source-file="index.tsx">
              prøv på nytt
            </TextLink>
            .
          </span>
          <Column spacing={1} data-sentry-element="Column" data-sentry-source-file="index.tsx">
            <h2>Brukerstøtte</h2>
            <div>
              <span className="space">
                Opprett{' '}
                <TextLink href="https://makuba.mattilsynet.no/" linkOutside inline data-sentry-element="TextLink" data-sentry-source-file="index.tsx">
                  brukerstøttesak
                </TextLink>
              </span>
            </div>
            <Row data-sentry-element="Row" data-sentry-source-file="index.tsx">
              Du kan også kontakte IKT-brukerstøtte på telefon eller e-post:
            </Row>
            <Row data-sentry-element="Row" data-sentry-source-file="index.tsx">
              <RowItem className="space" data-sentry-element="RowItem" data-sentry-source-file="index.tsx">Telefon:</RowItem>
              <TextLink href="tel:22 77 88 99" data-sentry-element="TextLink" data-sentry-source-file="index.tsx">22 77 88 99</TextLink>
            </Row>
            <Row data-sentry-element="Row" data-sentry-source-file="index.tsx">
              <RowItem className="space" data-sentry-element="RowItem" data-sentry-source-file="index.tsx">E-post:</RowItem>
              <TextLink href="mailto:99@mattilsynet.no" data-sentry-element="TextLink" data-sentry-source-file="index.tsx">
                99@mattilsynet.no
              </TextLink>
            </Row>
          </Column>
        </Column>
      </ShadowBox>
    </GridItem>
  </Grid>;
export default AccessDeniedRoute;