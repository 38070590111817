import { ISearchActions, ISearchStateDefault } from './types'
import { commonRedux } from '../../../common/redux'
import * as R from 'ramda'
import { IHandlers } from '../../../common/redux/types'

export const reducerHelpers = <Queries, Result, IStoreName extends string>(
  storeName: IStoreName
) => {
  const actionTypes = {
    SEARCH: `${storeName}/SEARCH` as const,
    FETCH_OK: `${storeName}/FETCH_OK` as const,
    FETCH_FAIL: `${storeName}/FETCH_FAIL` as const,
    RESET: `${storeName}/RESET` as const,
    SEARCH_VIRKSOMHET_ADDRESS:
      `${storeName}/SEARCH_VIRKSOMHET_ADDRESS` as const,
    FETCH_VIRKSOMHET_ADDRESS_OK:
      `${storeName}/FETCH_VIRKSOMHET_ADDRESS_OK` as const,
    FETCH_VIRKSOMHET_ADDRESS_FAIL:
      `${storeName}/FETCH_VIRKSOMHET_ADDRESS_FAIL` as const,
    SEARCH_PERSON_ADDRESS: `${storeName}/SEARCH_PERSON_ADDRESS` as const,
    FETCH_PERSON_ADDRESS_OK: `${storeName}/FETCH_PERSON_ADDRESS_OK` as const,
    FETCH_PERSON_ADDRESS_FAIL:
      `${storeName}/FETCH_PERSON_ADDRESS_FAIL` as const,
  }

  const actions = {
    search: (
      queries: Queries
    ): ISearchActions<Queries, Result, IStoreName> => ({
      type: actionTypes.SEARCH,
      queries,
    }),

    fetchOk: (data: Result): ISearchActions<Queries, Result, IStoreName> => ({
      type: actionTypes.FETCH_OK,
      data,
    }),

    fetchFail: (
      error: string
    ): ISearchActions<Queries, Result, IStoreName> => ({
      type: actionTypes.FETCH_FAIL,
      error,
    }),

    searchVirksomhetsAdresse: (
      queries: Queries
    ): ISearchActions<Queries, Result, IStoreName> => ({
      type: actionTypes.SEARCH_VIRKSOMHET_ADDRESS,
      queries,
    }),

    fetchVirksomhetAddressOk: (
      data: Result
    ): ISearchActions<Queries, Result, IStoreName> => ({
      type: actionTypes.FETCH_VIRKSOMHET_ADDRESS_OK,
      data,
    }),

    fetchVirksomhetAddressFail: (
      error: string
    ): ISearchActions<Queries, Result, IStoreName> => ({
      type: actionTypes.FETCH_VIRKSOMHET_ADDRESS_FAIL,
      error,
    }),

    searchPersonAdresse: (
      queries: Queries
    ): ISearchActions<Queries, Result, IStoreName> => ({
      type: actionTypes.SEARCH_PERSON_ADDRESS,
      queries,
    }),

    fetchPersonAddressOk: (
      data: Result
    ): ISearchActions<Queries, Result, IStoreName> => ({
      type: actionTypes.FETCH_PERSON_ADDRESS_OK,
      data,
    }),

    fetchPersonAddressFail: (
      error: string
    ): ISearchActions<Queries, Result, IStoreName> => ({
      type: actionTypes.FETCH_PERSON_ADDRESS_FAIL,
      error,
    }),

    reset: (): ISearchActions<Queries, Result, IStoreName> => ({
      type: actionTypes.RESET,
    }),
  }

  const initialState: ISearchStateDefault<Queries, Result> = {
    error: '',
    loading: false,
    loaded: false,
    result: undefined,
    queries: undefined,
    page: {
      number: 0,
      size: 0,
      totalElements: 0,
      totalPages: 0,
    },
  }

  const reducer = {
    [storeName]: commonRedux.createReducer(initialState, {
      [actionTypes.SEARCH]: (state, { queries }) =>
        R.mergeLeft(
          {
            loading: true,
            queries,
          },
          initialState
        ),

      [actionTypes.FETCH_OK]: (state, { data }) =>
        R.mergeLeft(
          {
            error: '',
            loading: false,
            loaded: true,
            page: data?.page || initialState.page,
            result: data?._embedded,
          },
          state
        ),

      [actionTypes.FETCH_FAIL]: (state, { error }) =>
        R.mergeLeft(
          {
            error: error,
            loading: false,
            loaded: false,
          },
          state
        ),
      [actionTypes.FETCH_VIRKSOMHET_ADDRESS_OK]: (state, { data }) =>
        R.mergeLeft(
          {
            error: '',
            result: {
              virksomhetList: state.result.virksomhetList.map((v) =>
                v.organisasjonsnummer === data.orgNr
                  ? {
                      ...v,
                      beliggenhetsadresse: data.beliggenhetsadresse,
                      postadresse: data.postadresse,
                      overordnetOrgNr: data.overordnetOrgNr,
                    }
                  : v
              ),
            },
          },
          state
        ),

      [actionTypes.FETCH_VIRKSOMHET_ADDRESS_FAIL]: (state, { error }) =>
        R.mergeLeft(
          {
            error: error,
          },
          state
        ),
      [actionTypes.FETCH_PERSON_ADDRESS_OK]: (state, { data }) =>
        R.mergeLeft(
          {
            error: '',
            result: {
              virksomhetList: state.result.virksomhetList.map((v) =>
                v.tilsynsobjekter && v.tilsynsobjekter[0].idstring === data.id
                  ? {
                      ...v,
                      adresse: data.adresse,
                    }
                  : v
              ),
            },
          },
          state
        ),

      [actionTypes.FETCH_PERSON_ADDRESS_FAIL]: (state, { error }) =>
        R.mergeLeft(
          {
            error: error,
          },
          state
        ),

      [actionTypes.RESET]: (): ISearchStateDefault<Queries, Result> =>
        initialState,
    } as unknown as IHandlers<
      ISearchStateDefault<Queries, Result>,
      ISearchActions<Queries, Result, IStoreName>
    >),
  } as Record<
    IStoreName,
    (
      state: ISearchStateDefault<Queries, Result>,
      action: ISearchActions<Queries, Result, IStoreName>
    ) => ISearchStateDefault<Queries, Result>
  >

  return { actions, actionTypes, reducer }
}
