import React from 'react';
import { useTypedSelector } from '../../common/custom-hooks';
import { useDispatch } from 'react-redux';
import { ModalWrapper } from '../modal-wrapper';
import { uiActions } from '../../ducks/ui/actions';
import { uiSelectors } from '../../ducks/ui/selectors';
import { ImageInfo } from '../../features/bilder';
import { ModalButtonsContainer } from '../modal-buttons-container';
import { ModalButton } from '../modal-button';
const ImageNavigationModal = ({
  title,
  editable = true
}: {
  title: string;
  editable?: boolean;
}) => {
  const {
    isOpen
  } = useTypedSelector(uiSelectors.getOpenImageNavigationModal);
  const dispatch = useDispatch();
  return <ModalWrapper pauseFocusTrap={true} isOpen={isOpen} onCancel={() => dispatch(uiActions.setImageNavigationModal(false, '', []))} fullscreenMobile paddingHorizontal={0} className={'image-navigation-modal'} title={title} data-sentry-element="ModalWrapper" data-sentry-component="ImageNavigationModal" data-sentry-source-file="index.tsx">
      <ImageInfo editable={editable} data-sentry-element="ImageInfo" data-sentry-source-file="index.tsx" />

      <ModalButtonsContainer data-sentry-element="ModalButtonsContainer" data-sentry-source-file="index.tsx">
        <ModalButton secondary onClick={() => dispatch(uiActions.setImageNavigationModal(false, '', []))} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">
          Lukk
        </ModalButton>
      </ModalButtonsContainer>
    </ModalWrapper>;
};
export default ImageNavigationModal;