import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { offlineInFerdigstillingFromHome, offlineInForberedelseProcess, useSingleToast } from '../../common/toast';
import { kvitteringActions } from '../../ducks/kvittering/actions';
import { HomeRouteView } from './view';
import { clearSak } from '../../ducks/sak/actions';
import { currentKvitteringInitialState } from '../../ducks/kvittering/initialState';
import { IKvitteringData, kvitteringStatusEnum } from '../../ducks/kvittering/types';
import { getFerdigstillingRoute } from '../ferdigstilling';
import { useTypedSelector } from '../../common/custom-hooks';
import { IStoreState } from '../../reducers/types';
import { useTKNavigate } from '../../common/navigation';
const HomeRoute: React.FC<{
  match?: any;
}> = () => {
  const dispatch = useDispatch();
  const navigate = useTKNavigate();
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline);
  const singleToast = useSingleToast();
  const onForberedNyttTilsyn = () => {
    if (isOffline) {
      return singleToast.showToast(offlineInForberedelseProcess());
    }
    dispatch(clearSak());
    dispatch(kvitteringActions.setCurrentKvittering(currentKvitteringInitialState));
    navigate('/forbered-new-tilsyn');
  };
  const onFullfoerTilsyn = (kvittering: IKvitteringData) => {
    if (isOffline) {
      return singleToast.showToast(offlineInForberedelseProcess());
    }
    dispatch(dispatch(kvitteringActions.setCurrentKvitteringId(kvittering.id!)));
    navigate('/forbered-new-tilsyn');
  };
  const onClickTilsyn = (kvittering: IKvitteringData) => {
    const shouldStartTilsyn = kvittering.tilsynsobjekter.length > 0;
    if (shouldStartTilsyn) {
      dispatch(clearSak());
      if (kvittering.status === kvitteringStatusEnum.ARKIVERT) {
        if (isOffline) {
          return singleToast.showToast(offlineInFerdigstillingFromHome());
        }
        dispatch(kvitteringActions.setCurrentKvittering(kvittering));
        navigate(getFerdigstillingRoute(kvittering.id!));
      } else {
        dispatch(kvitteringActions.setCurrentKvitteringId(kvittering.id!));
        dispatch(kvitteringActions.setCurrentKvittering(kvittering));
        navigate(`/start-tilsyn/${kvittering.id}`);
      }
    } else {
      onFullfoerTilsyn(kvittering);
    }
  };
  const onRedigerTilsyn = (kvittering: IKvitteringData) => {
    if (kvittering.status === 'PAABEGYNT') {
      onClickTilsyn(kvittering);
    }
    if (kvittering.status === 'FORBEREDT') {
      onFullfoerTilsyn(kvittering);
    }
  };
  const onDeleteKvittering = (kvittering: IKvitteringData) => {
    dispatch(kvitteringActions.deleteKvittering(kvittering));
  };
  useEffect(() => {
    dispatch(kvitteringActions.fetchKvitteringer());
    dispatch(kvitteringActions.fetchTilsynstyper());
  }, [dispatch]);
  return <HomeRouteView onForberedNyttTilsyn={onForberedNyttTilsyn} onClickTilsyn={onClickTilsyn} onDeleteKvittering={onDeleteKvittering} onRedigerTilsyn={onRedigerTilsyn} data-sentry-element="HomeRouteView" data-sentry-component="HomeRoute" data-sentry-source-file="index.tsx" />;
};
export default HomeRoute;