import React from 'react';
import { Column, Modal, Text } from '@mattilsynet/mt-ui';
import { ModalButtonsContainer } from '../../components/modal-buttons-container';
import { modalContentPadding, modalContentPaddingLarge } from '../../components/modal-buttons-container/constants';
import { ModalButton } from '../../components/modal-button';
import { useMediaQuery } from 'react-responsive';
interface IRemoveImageModalProps {
  /**
   * The Id of the entity to delete
   */
  entityId: string;
  observasjonId: number;
  onCancel: () => void;
  onRemove: (entityId: string, observasjonId: number) => void;
  onDelete: (entityId: string) => void;
  isOpen: boolean;
}
const RemoveImageModal = ({
  entityId,
  observasjonId,
  isOpen,
  onCancel,
  onRemove,
  onDelete
}: IRemoveImageModalProps) => {
  const isSmall = useMediaQuery({
    query: '(max-width: 767px)'
  });
  const contentPadding = isSmall ? modalContentPaddingLarge : modalContentPadding;
  return <Modal isOpen={isOpen} onCancel={onCancel} data-sentry-element="Modal" data-sentry-component="RemoveImageModal" data-sentry-source-file="index.tsx">
      <Column padding={contentPadding} spacing={5} align="center" data-sentry-element="Column" data-sentry-source-file="index.tsx">
        <h2>{`Er du sikker på at du vil fjerne bildet?`}</h2>
        <Column spacing={3} align="center" data-sentry-element="Column" data-sentry-source-file="index.tsx">
          <Text data-sentry-element="Text" data-sentry-source-file="index.tsx">Vil du fjerne bildet fra observasjonen eller slette det?</Text>
        </Column>
      </Column>
      <ModalButtonsContainer data-sentry-element="ModalButtonsContainer" data-sentry-source-file="index.tsx">
        <ModalButton secondary onClick={onCancel} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">
          Avbryt
        </ModalButton>
        <ModalButton secondary onClick={() => onDelete(entityId)} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">
          Slett bilde
        </ModalButton>
        <ModalButton onClick={() => onRemove(entityId, observasjonId)} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">
          Fjern bilde fra observasjon
        </ModalButton>
      </ModalButtonsContainer>
    </Modal>;
};
export default RemoveImageModal;