type SyncOrAsync<T> = T | Promise<T>
type OperatorFn<T, R> = (data: T) => SyncOrAsync<R>

/**
 * Pipe operator for both sync and async functions.
 * @param operations List of operations to pipe.
 * @returns Function A function that takes an input, pipes it through the operations and returns a promise.
 */
export const pipeAsync = <T, R>(...operations: OperatorFn<any, any>[]) =>
  (async (data: T): Promise<R> => {
    return operations.reduce(
      async (prevOp, currentOp) => {
        return currentOp(await prevOp)
      },
      Promise.resolve(data as SyncOrAsync<any>)
    )
  }) satisfies OperatorFn<T, R>

export const distinctBy = <T>(
  array: T[],
  key: (item: T) => string | number
) => {
  const seen = new Set()

  return array.filter((item) => {
    const k = key(item)

    return seen.has(k) ? false : seen.add(k)
  })
}

/**
 * Convert object to [FormData](https://developer.mozilla.org/en-US/docs/Web/API/FormData).
 * This makes it possible to send `multipart/form-data` that includes binary files (eg. images).
 *
 * @param data Object to be converted to formdata
 * @return FormData
 */
export const createFormData = (data) =>
  Object.entries(data).reduce((fData, [key, value]: [any, any]) => {
    fData.append(key, value)
    return fData
  }, new FormData())

/**
 * Strip functions from an object or array while preserving ArrayBuffers and Blobs.
 *
 * JSON.stringify does not handle these types.
 */
export const stripFunctions = (obj: unknown) => {
  if (typeof obj !== 'object' || obj === null) {
    // If the input is not an object or array, return it as is
    return obj
  }

  if (Array.isArray(obj)) {
    return obj
      .filter((item) => typeof item !== 'function')
      .map((item) => stripFunctions(item))
  }

  return Object.entries(obj).reduce((newObj, [key, value]) => {
    if (typeof value === 'function') {
      return newObj
    }

    if (typeof value !== 'object' || value === null) {
      newObj[key] = value
      return newObj
    }

    if (value instanceof ArrayBuffer || value instanceof Blob) {
      newObj[key] = value
      return newObj
    }

    newObj[key] = stripFunctions(value)
    return newObj
  }, {})
}

/**
 * Get item by id in a deep object or array.
 * @param obj object to search in
 * @param path path to the item list, e.g. ['tilsynsobjekter', 'kontrollpunkter', 'observasjoner']
 * @param id id of the item to find
 * @returns item or null
 */
export const getDeepItemById = <TResult = unknown>(
  obj: unknown,
  path: string[],
  id: string | number
): TResult | null => {
  if (!obj || typeof obj !== 'object') {
    return null
  }

  if (Array.isArray(obj)) {
    return obj.reduce(
      (result: any, i: unknown) => getDeepItemById(i, path, id) || result,
      null
    )
  }

  if (path.length === 0) {
    if ('id' in obj && obj.id !== id) {
      return null
    }

    return obj as TResult
  }

  const [first, ...restPath] = path

  return getDeepItemById(obj[first], restPath, id)
}

/**
 * Copy text to clipboard.
 * @param text
 */
export const copyToClipboard = (text: string) => {
  return navigator.clipboard.writeText(text)
}
