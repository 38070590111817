import { combineEpics, Epic } from 'redux-observable'
import {
  of,
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
  EMPTY,
  combineLatest,
  concat,
} from 'rxjs'
import dayjs from 'dayjs'
import * as R from 'ramda'
import { IProdusentMeldingsHistorikkActions } from '../store/types'
import {
  produsentMeldingsHistorikkActions,
  produsentMeldingsHistorikkActionTypes,
} from '../store'
import {
  convertStatusArrayToObject,
  getFunnRequests,
  getMeldingListByEftanummer,
  populateMeldingsHistorikkWithFunnResponse,
} from './helpers'
import { AnyAction } from 'redux'
import { ENDPOINT } from '../../../../../constants'
import { ICommonApi } from '../../../../../api'
import { sortByKey } from '../../../../../common/sorting'
import { IStoreState } from '../../../../../reducers/types'
import { dangerToast } from '../../../../../common/toast'
import { isOfType } from '../../../../../common/redux/helpers'
import { createQueryStringFromObject } from '../../../../../common/createQueryStringFromObject'

const getMeldingsHistorikkForVirksomhet =
  (
    commonApi: ICommonApi
  ): Epic<
    IProdusentMeldingsHistorikkActions,
    IProdusentMeldingsHistorikkActions,
    IStoreState
  > =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(produsentMeldingsHistorikkActionTypes.FETCH)),
      withLatestFrom(state$),
      switchMap(([{ tilsynsobjektId }, state]) => {
        return of(state).pipe(
          commonApi.get(
            `${
              ENDPOINT.TILSYNSOBJEKT
            }/v2/virksomheter${createQueryStringFromObject('?')({
              'filter.tilsynsobjekter.idstring': [tilsynsobjektId],
              expand: ['tilsynsobjekter'],
              includeInactive: true,
            })}`,
            state
          ),
          mergeMap((resp) => {
            const virksomhetList = resp?._embedded?.virksomhetList || []
            const virksomhet = virksomhetList[0] || {}
            const tilsynsobjekter = virksomhet.tilsynsobjekter || []

            return of(state).pipe(
              commonApi.get(
                `${
                  ENDPOINT.MELDING_TIL_LOKALT_MATTILSYN
                }/v2/mattilsynmelding/${createQueryStringFromObject('?')({
                  'filter.produsentTilsynsobjektIds': tilsynsobjekter.map(
                    (tilsynsobjekt) => tilsynsobjekt.idstring
                  ),
                  'page.size': 100,
                })}`,
                state
              ),
              mergeMap((resp) => {
                const populertMattilsynMeldingList =
                  resp?._embedded?.populertMattilsynMeldingList || []

                const meldingsHistorikk = populertMattilsynMeldingList.map(
                  (melding) => ({
                    ...melding,
                    sistRedigert: dayjs(melding.sistRedigert),
                    funnList: [],
                  })
                )
                return of(
                  produsentMeldingsHistorikkActions.fetchMeldingsHistorikkOk(
                    tilsynsobjektId,
                    sortByKey('sistRedigert', true)(meldingsHistorikk),
                    virksomhet
                  )
                )
              })
            )
          }),

          catchError((err) => {
            return of(
              produsentMeldingsHistorikkActions.fetchMeldingsHistorikkFail(
                err.message
              )
            )
          })
        )
      })
    )

const getMeldingsHistorikkFunn =
  (commonApi: ICommonApi): Epic<any, any, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType([produsentMeldingsHistorikkActionTypes.FETCH_OK])),
      withLatestFrom(state$),
      switchMap(([{ meldingsHistorikk: meldingsHistorikkUtenFunn }, state]) => {
        const meldingListByEftanummer = getMeldingListByEftanummer(
          meldingsHistorikkUtenFunn
        )

        if (Object.values(meldingListByEftanummer).length === 0) {
          return EMPTY
        }
        const requests = getFunnRequests(
          meldingListByEftanummer,
          commonApi,
          state
        )

        return combineLatest([...requests]).pipe(
          map((res: any) => {
            const meldingsHistorikk = populateMeldingsHistorikkWithFunnResponse(
              res,
              meldingsHistorikkUtenFunn
            )
            return produsentMeldingsHistorikkActions.fetchFunnOk(
              sortByKey('sistRedigert', true)(meldingsHistorikk)
            )
          }),
          catchError((err) => {
            return concat(
              of(produsentMeldingsHistorikkActions.fetchFunnFail(err.message)),
              of(dangerToast('Kunne ikke hente funn')),
              of(
                produsentMeldingsHistorikkActions.fetchTilsynskvitteringStatus(
                  sortByKey('sistRedigert', true)(meldingsHistorikkUtenFunn)
                )
              )
            )
          })
        )
      })
    )

const getMeldingsHistorikkTilsynskvitteringStatus =
  (commonApi: ICommonApi): Epic<AnyAction, AnyAction, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(
        isOfType([
          produsentMeldingsHistorikkActionTypes.FETCH_FUNN_OK,
          produsentMeldingsHistorikkActionTypes.FETCH_TILSYNSKVITTERING_STATUS,
        ])
      ),
      withLatestFrom(state$),
      switchMap(
        ([
          { meldingsHistorikk: meldingsHistorikkUtenTilsynUtfoert },
          state,
        ]) => {
          const vurderingSaksNummerList = R.pipe(
            R.map((melding) => melding?.vurdering?.tilknyttetSaksnummer),
            R.filter((saksnummer) => !!saksnummer),
            R.uniq
          )(meldingsHistorikkUtenTilsynUtfoert)

          if (
            meldingsHistorikkUtenTilsynUtfoert?.length === 0 ||
            !vurderingSaksNummerList.length
          ) {
            return EMPTY
          }

          return of(state).pipe(
            commonApi.get(
              `${
                ENDPOINT.TILSYNSKVITTERING
              }/v1/tilsynskvitteringstatuser${createQueryStringFromObject('?')({
                'filter.has.noarksak': vurderingSaksNummerList,
              })}`,
              state
            ),
            map((resp) => {
              const tilsynskvitteringStatuser =
                resp?._embedded?.tilsynskvitteringStatuser || []
              const meldingsHistorikk = meldingsHistorikkUtenTilsynUtfoert?.map(
                (melding) => {
                  const tilknyttetSaksnummer =
                    melding?.vurdering?.tilknyttetSaksnummer
                  const tilsynskvitteringStatus =
                    tilsynskvitteringStatuser.find(
                      (status) => status.noarksak === tilknyttetSaksnummer
                    )
                  return {
                    ...melding,
                    tilsynskvitteringStatus,
                  }
                }
              )
              return produsentMeldingsHistorikkActions.fetchTilsynskvitteringStatusOk(
                sortByKey('sistRedigert', true)(meldingsHistorikk)
              )
            })
          )
        }
      ),
      catchError((error) => {
        return concat(
          of(
            produsentMeldingsHistorikkActions.fetchTilsynskvitteringStatusFail(
              error
            )
          ),
          of(dangerToast('Kunne ikke hente tilsynskvitteringstatuser'))
        )
      })
    )

export const getAllowedStatuses =
  (
    commonApi: ICommonApi
  ): Epic<
    IProdusentMeldingsHistorikkActions,
    IProdusentMeldingsHistorikkActions,
    IStoreState
  > =>
  (action$, state$) =>
    action$.pipe(
      filter(
        isOfType(produsentMeldingsHistorikkActionTypes.FETCH_ALLOWED_STATUSES)
      ),
      withLatestFrom(state$),
      switchMap(([, state]) =>
        of(state).pipe(
          commonApi.get(
            `${ENDPOINT.MELDING_TIL_LOKALT_MATTILSYN}/v1/vurderinger/statuser`,
            state
          ),
          mergeMap((resp: any) => {
            const allowedStatuses = convertStatusArrayToObject(
              resp?._embedded?.statusList
            )
            return of(
              produsentMeldingsHistorikkActions.fetchAllowedStatusesOk(
                allowedStatuses
              )
            )
          }),
          catchError((err) =>
            of(
              produsentMeldingsHistorikkActions.fetchAllowedStatusesFail(
                err.message
              )
            )
          )
        )
      )
    )

export const getAllowedStatusBegrunnelser =
  (
    commonApi: ICommonApi
  ): Epic<
    IProdusentMeldingsHistorikkActions,
    IProdusentMeldingsHistorikkActions,
    IStoreState
  > =>
  (action$, state$) =>
    action$.pipe(
      filter(
        isOfType(
          produsentMeldingsHistorikkActionTypes.FETCH_ALLOWED_BEGRUNNELSER
        )
      ),
      withLatestFrom(state$),
      switchMap(([, state]) =>
        of(state).pipe(
          commonApi.get(
            `${ENDPOINT.MELDING_TIL_LOKALT_MATTILSYN}/v1/statusbegrunnelser`,
            state
          ),
          mergeMap((resp: any) => {
            return of(
              produsentMeldingsHistorikkActions.fetchAllowedBegrunnelserOk(
                resp?._embedded?.statusList
              )
            )
          }),
          catchError((err) =>
            of(
              produsentMeldingsHistorikkActions.fetchAllowedBegrunnelserFail(
                err.message
              )
            )
          )
        )
      )
    )

export default (commonApi: ICommonApi) =>
  combineEpics(
    getMeldingsHistorikkForVirksomhet(commonApi),
    getMeldingsHistorikkFunn(commonApi),
    getMeldingsHistorikkTilsynskvitteringStatus(commonApi),
    getAllowedStatuses(commonApi),
    getAllowedStatusBegrunnelser(commonApi)
  )
