import React, { ChangeEvent, useState } from 'react';
import { Column, Row, Text, TextArea } from '@mattilsynet/mt-ui';
import { useDebounceFn } from '../../common/custom-hooks';
import { LabelWithInfo } from '../label-with-info';
import { useKvitteringId } from '../../common/kvittering-context';
import { useKvittering, useUpdateKvittering } from '../../features/kvitteringer';
import { DEBOUNCE_MS } from '../../constants';
const GenerellInfomasjonCard = () => {
  const kvitteringId = useKvitteringId();
  const {
    data: kvittering
  } = useKvittering(kvitteringId);
  const {
    mutate: updateKvittering
  } = useUpdateKvittering();
  const debounce = useDebounceFn(DEBOUNCE_MS);
  const [generellInformasjon, setGenerellInformasjon] = useState(kvittering?.generellInformasjon);
  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setGenerellInformasjon(e.target.value);
    if (kvittering) {
      debounce(() => updateKvittering({
        kvittering: {
          ...kvittering,
          generellInformasjon: e.target.value
        }
      }));
    }
  };
  return <Row id="generell-informasjon" data-sentry-element="Row" data-sentry-component="GenerellInfomasjonCard" data-sentry-source-file="index.tsx">
      <Column fluid spacing={1} data-sentry-element="Column" data-sentry-source-file="index.tsx">
        <LabelWithInfo label="Faktabeskrivelse om tilsynsobjektet/ene" data-sentry-element="LabelWithInfo" data-sentry-source-file="index.tsx">
          <div>
            <Text data-sentry-element="Text" data-sentry-source-file="index.tsx">
              Feltet kan benyttes til å registrere nødvendige faktaopplysninger
              om tilsynsobjektet/ene som ikke passer å skrive i
              observasjonsfeltene. F. eks. ved endring i produksjon og endring i
              antall dyr.
            </Text>
            <br />
            <Text data-sentry-element="Text" data-sentry-source-file="index.tsx">OBS: Feltet skal ikke inneholde vurderinger.</Text>
            <Text data-sentry-element="Text" data-sentry-source-file="index.tsx">Skriv kort og konsist.</Text>
          </div>
        </LabelWithInfo>
        <TextArea onChange={onChange} placeholder="F. eks. antall dyr" value={generellInformasjon ?? ''} defaultRows={2} maxLength={500} infoText={`${generellInformasjon?.length ?? 0}/500`} data-sentry-element="TextArea" data-sentry-source-file="index.tsx" />
      </Column>
    </Row>;
};
export default GenerellInfomasjonCard;