import React, { useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { ThemeContext, Text } from '@mattilsynet/mt-ui';
import { IThemeColors } from '@mattilsynet/mt-ui/dist/assets/colors';

// TODO: Remove when this issue is fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2452
// eslint-disable-next-line
interface IStyledSliderDotItemProps {
  active: boolean;
  theme: IThemeColors;
  title?: string;
}
interface ISliderDotItemProps {
  active: boolean;
  goToSlide: () => void;
  title?: string;
  number: number;
}
const StyledSliderDotItem = styled.button<IStyledSliderDotItemProps>`
  &.SliderDotItem {
    width: 2rem;
    height: 2rem;
    font-variant-numeric: tabular-nums;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100%;
    font-size: 0.65em;
    color: ${({
  active,
  theme
}) => active ? theme.white : ''};
    background: ${({
  active
}) => active ? 'var(--navigator)' : 'transparent'};
  }
`;
export const SliderDotItem = ({
  active,
  goToSlide,
  title,
  number
}: ISliderDotItemProps) => {
  const theme = useContext(ThemeContext);
  const sliderDotItemClasses = classNames('SliderDotItem', {
    active
  });
  return <StyledSliderDotItem theme={theme} className={sliderDotItemClasses} active={active} onClick={goToSlide} title={title} aria-label={title} data-sentry-element="StyledSliderDotItem" data-sentry-component="SliderDotItem" data-sentry-source-file="index.tsx">
      <Text weight="semibold" data-sentry-element="Text" data-sentry-source-file="index.tsx">{number}</Text>
    </StyledSliderDotItem>;
};