import { Column, Row } from '@mattilsynet/mt-ui';
import React, { ReactElement, ReactNode, useCallback } from 'react';
import './style.css';
import { useMediaQuery } from 'react-responsive';
interface IModalButtonsContainer {
  children: ReactNode;
  small?: boolean;
}
export const ModalButtonsContainer = ({
  children,
  small
}: IModalButtonsContainer) => {
  const isSmall = useMediaQuery({
    query: '(max-width: 767px)'
  });
  const hasMultipleButtons = Array.isArray(children);
  const renderModalButtons = useCallback(() => {
    if (hasMultipleButtons) {
      return (isSmall ? [...children].reverse() : children).map((button: ReactElement, index: number) => {
        const keyedButton = {
          ...button,
          key: `modal-button${index}`
        };
        return keyedButton;
      });
    }
    return children;
  }, [hasMultipleButtons, isSmall, children]);
  const className = `modal-buttons-container${small ? ' small' : ''}`;
  return <Column className={className} justify="center" align="center" padding={[1, 0]} margin={[0, -3, 0, -3]} data-sentry-element="Column" data-sentry-component="ModalButtonsContainer" data-sentry-source-file="index.tsx">
      <Column fluid data-sentry-element="Column" data-sentry-source-file="index.tsx">
        <Row justify="space-evenly" smColumn data-sentry-element="Row" data-sentry-source-file="index.tsx">
          {renderModalButtons()}
        </Row>
      </Column>
    </Column>;
};