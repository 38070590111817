import { AnyAction } from 'redux'
import dayjs from 'dayjs'
import shortid from 'shortid'
import { commonRedux } from '../../../common/redux'
import { IToast, IStoreToast, IToastActions, IToastState } from './types'

export function createReducer(initialState: any, handlers: any) {
  return function reducer(state = initialState, action: AnyAction) {
    if (handlers.hasOwnProperty(action.type)) {
      // eslint-disable-line no-prototype-builtins
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}

export const actionTypes = {
  SHOW: '@toasts/SHOW' as const,
  HIDE: '@toasts/HIDE' as const,
}

export const actions = {
  showToast: (data: IToast) => ({
    type: actionTypes.SHOW,
    data: data,
  }),
  hideToast: (id: string) => ({
    type: actionTypes.HIDE,
    id: id,
  }),
}

const enrichToast = (toast: IToast): IStoreToast => ({
  ...toast,
  id: toast.id || shortid.generate(),
  createTime: dayjs().toDate(),
})

export const selectors = {
  getToasts: (state: any): IToastState =>
    state['@toasts'].sort((a, b) =>
      dayjs(b.createTime).isBefore(a.createTime) ? 1 : -1
    ),
  isToastDisplayed: (state: any): boolean => state['@toasts'].length > 0,
}

const customReducer = commonRedux.createReducer<IToastState, IToastActions>(
  [],
  {
    [actionTypes.SHOW]: (state, { data }: { data: IToast }) => [
      ...state,
      enrichToast(data),
    ],
    [actionTypes.HIDE]: (state, { id }) => {
      const newState = state.filter((toast) => toast.id !== id)
      return newState
    },
  }
)

export const reducer = {
  '@toasts': customReducer,
}
