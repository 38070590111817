import * as R from 'ramda'
import { IRemoteKodeverk } from '../store/types'

export interface IKodeverkTreeData {
  display?: string
  value: string
  children?: IKodeverkTreeData[]
}

export const kodeverkToTreeComponentObject = (
  data: IRemoteKodeverk[] = []
): IKodeverkTreeData[] =>
  R.pipe(
    R.map((child: IRemoteKodeverk) => ({
      children: child.children
        ? kodeverkToTreeComponentObject(child.children)
        : [],
      display: child?.displayNames?.no || '',
      value: child.codeString,
    }))
  )(data)
