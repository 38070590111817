import React from 'react';
import Fallback from './Fallback';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { CAPTURE_NAME, CaptureType } from '../../sentry';
interface ErrorBoundaryProps {
  children: React.ReactNode;
  captureType: CaptureType.ROOT | CaptureType.ROUTE;
}
export const ErrorBoundary = ({
  children,
  captureType
}: ErrorBoundaryProps) => {
  return <SentryErrorBoundary fallback={<Fallback />} beforeCapture={scope => {
    scope.setTag(CAPTURE_NAME, captureType);
    return scope;
  }} data-sentry-element="SentryErrorBoundary" data-sentry-component="ErrorBoundary" data-sentry-source-file="ErrorBoundary.tsx">
      {children}
    </SentryErrorBoundary>;
};