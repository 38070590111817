import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, LoadingDots, Header, Column, TextLink, Text } from '@mattilsynet/mt-ui';
import { useDispatch } from 'react-redux';
import { VirksomhetAdresseInfo } from '../virksomhet-adresse-info';
import { PrivatPersonAdresseInfo } from '../privat-person-adresse-info';
import { IKvitteringData } from '../../ducks/kvittering/types';
import { IVirksomhetItem } from '../../ducks/virksomhet-search/types';
import './style.css';
interface IAdresseAccordionProps {
  orgNr?: string;
  fetchAddress: any;
  item: IKvitteringData | IVirksomhetItem;
  hasError: boolean;
  isDefaultOpen?: boolean;
}
export const AdresseAccordion = ({
  orgNr,
  fetchAddress,
  item: {
    adresse,
    beliggenhetsadresse,
    postadresse,
    overordnetOrgNr
  },
  hasError,
  isDefaultOpen = false
}: IAdresseAccordionProps) => {
  const dispatch = useDispatch();
  const [showAdress, setShowAdress] = useState(isDefaultOpen);
  const onToggleAdresseDropdown = useCallback(() => {
    setShowAdress(!showAdress);
    const hasAddress = orgNr ? beliggenhetsadresse : adresse;
    if (!showAdress && !hasAddress) {
      dispatch(fetchAddress);
    }
  }, [adresse, beliggenhetsadresse, dispatch, fetchAddress, orgNr, showAdress]);
  useEffect(() => {
    if (isDefaultOpen) {
      dispatch(fetchAddress);
    }
  }, []);
  const renderOverordnetOrgNr = (orgNr: string | undefined) => {
    const hasOrgNr = orgNr !== undefined;
    return <Column spacing={1} data-sentry-element="Column" data-sentry-component="renderOverordnetOrgNr" data-sentry-source-file="index.tsx">
        <Header as="h3" size="normal" data-sentry-element="Header" data-sentry-source-file="index.tsx">
          Overordnet org nr
        </Header>
        {!hasOrgNr && <Text>Intet registrert overordnet org nr</Text>}
        {hasOrgNr && <>
            <Column>
              <Text textAlign="left">{orgNr}</Text>
            </Column>
            <Column>
              <TextLink href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${orgNr}`} linkOutside>
                Vis overordnet enhet
              </TextLink>
            </Column>
          </>}
        &nbsp;
      </Column>;
  };
  const renderAdresse = () => {
    const hasAdresse = !(beliggenhetsadresse && Object.keys(beliggenhetsadresse).length === 0 || adresse && Object.keys(adresse).length === 0);
    if (!hasAdresse) {
      return <Text>Ingen registrert adresse</Text>;
    }
    if (!orgNr) {
      return <PrivatPersonAdresseInfo tittel="Adresse" adresse={adresse} />;
    }
    return <div className="virksomhet-adresse-info" data-sentry-component="renderAdresse" data-sentry-source-file="index.tsx">
        <VirksomhetAdresseInfo adresse={beliggenhetsadresse} tittel="Beliggenhetsadresse" data-sentry-element="VirksomhetAdresseInfo" data-sentry-source-file="index.tsx" />
        <VirksomhetAdresseInfo adresse={postadresse} tittel="Postadresse" data-sentry-element="VirksomhetAdresseInfo" data-sentry-source-file="index.tsx" />
      </div>;
  };
  const renderAdresseError = () => {
    return <>
        <Text margin={[0, 0, 1, 0]} data-sentry-element="Text" data-sentry-source-file="index.tsx">
          Kunne ikke hente adresse og overordnet org nr
        </Text>
        <TextLink onClick={() => dispatch(fetchAddress)} data-sentry-element="TextLink" data-sentry-source-file="index.tsx">
          Last innhold på nytt
        </TextLink>
      </>;
  };
  let toggleText = 'Vis adresse';
  if (showAdress) {
    toggleText = 'Skjul adresse';
  }
  if (orgNr) {
    toggleText += ' og overordnet org nr';
  }
  const hasFetched = Boolean(beliggenhetsadresse || adresse);
  return <Accordion className="adresse-accordion" open={showAdress} paddingChildren={[0, 0, 0, 0]} header={<Header as="h3" size="heading3">
          {toggleText}
        </Header>} onClick={onToggleAdresseDropdown} data-sentry-element="Accordion" data-sentry-component="AdresseAccordion" data-sentry-source-file="index.tsx">
      <Column justify="flex-start" align="flex-start" data-sentry-element="Column" data-sentry-source-file="index.tsx">
        {hasError && renderAdresseError()}
        {!hasError && !hasFetched && <LoadingDots />}

        {!hasError && hasFetched && renderAdresse()}
      </Column>
      &nbsp;
      {orgNr && !hasError && hasFetched && renderOverordnetOrgNr(overordnetOrgNr)}
    </Accordion>;
};