import { Tilsynsobjektinfo } from './components'
import { epics } from './epic'
import { reducer } from './store'

const tilsynsobjektinfo = {
  reducer,
  epics,
}

export { Tilsynsobjektinfo, tilsynsobjektinfo }
