import React from 'react';
import { BottomNavigator } from '../bottom-navigator';
import { BottomPageNav } from '../bottom-page-nav';
import { AddImageIcon, NotesIcon } from '@mattilsynet/mt-ui';
import './style.css';
import { useShouldShowNavigator, useTypedSelector } from '../../common/custom-hooks';
import { IStoreState } from '../../reducers/types';
interface IKvitteringNavigatorProps {
  slides: JSX.Element[];
  currentSlide: number;
  currentPage: number;
  toNextSlide: () => void;
  toPreviousSlide: () => void;
  toSlide: (position: number) => void;
  toPage: (position: number) => void;
}
const KvitteringNavigator = ({
  slides,
  currentSlide,
  currentPage,
  toNextSlide,
  toPreviousSlide,
  toSlide,
  toPage
}: IKvitteringNavigatorProps) => {
  const isDekstop = useTypedSelector((state: IStoreState) => state.ui.isDesktop);
  const navigatorClass = useShouldShowNavigator(!isDekstop) ? 'kvittering-navigator' : 'kvittering-navigator kvittering-navigator-keyboard-opened';
  const pages = [{
    title: 'Kvittering',
    icon: <NotesIcon />
  }, {
    title: 'Bilder',
    icon: <AddImageIcon />
  }, {
    title: isDekstop ? 'Tilsynsobjektinfo' : 'Info',
    icon: <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
        </svg>
  }];
  return <div className={navigatorClass} data-sentry-component="KvitteringNavigator" data-sentry-source-file="index.tsx">
      {currentPage === 0 && <BottomNavigator slides={slides} currentSlide={currentSlide} goToPrevious={toPreviousSlide} goToNext={toNextSlide} goToSlide={toSlide} />}
      <BottomPageNav pages={pages} currentPage={currentPage} goToPage={toPage} data-sentry-element="BottomPageNav" data-sentry-source-file="index.tsx" />
    </div>;
};
export default KvitteringNavigator;