import React from 'react';
import * as R from 'ramda';
import { useMediaQuery } from 'react-responsive';
import { ErrorBox, InfoText, LoadingSpinner, SplitSections } from '@mattilsynet/mt-ui';
import { ITilsynsobjekt } from '../../ducks/tilsynsobjekt/types';
import { getValidIdentitetListWithAktivitet } from '../../ducks/tilsynsobjekt/helpers';
import './style.css';
const formatAddress = adresse => {
  if (!adresse) return '-';
  const gateadresseWithDefault = adresse.gateadresse ? `${adresse.gateadresse}, ` : '';
  const postnrWithDefault = adresse.postNr ? `${adresse.postNr} ` : '';
  const poststedWithDefault = adresse.poststed ? adresse.poststed : '';
  return `${gateadresseWithDefault}${postnrWithDefault}${poststedWithDefault}`;
};
const withAddress = ({
  adresse
}) => ({
  label: 'Besøksadresse',
  value: formatAddress(adresse) || '-'
});
const mapInfoList = (produsent, additionalInfoElements): {
  label: string;
  value: string;
}[] => R.pipe(getValidIdentitetListWithAktivitet, R.prepend(withAddress(produsent)), R.append(additionalInfoElements), R.flatten, R.filter(identitet => identitet?.value))(produsent);
export interface ITilsynsobjektCardProps {
  tilsynsobjekt: ITilsynsobjekt | undefined;
  fetchTilsynsobjekt: () => void;
  loadingStatus: {
    loading: boolean;
    error: string;
  } | undefined;
  additionalInfoElements?: ({
    label: string;
    value: string;
  } | undefined)[];
}
const TilsynsobjektCard = ({
  tilsynsobjekt,
  fetchTilsynsobjekt,
  loadingStatus,
  additionalInfoElements
}: ITilsynsobjektCardProps) => {
  const showInfoTextInline = useMediaQuery({
    query: '(min-width: 1024px)'
  });
  if (!loadingStatus || loadingStatus.loading) {
    return <LoadingSpinner title="Laster tilsynsobjekt..." />;
  } else if (loadingStatus.error || !tilsynsobjekt) {
    return <ErrorBox errorAction={fetchTilsynsobjekt} errorActionText="Last innhold på nytt" errorText="Kunne ikke hente tilsynsobjekt..." />;
  }
  const infoList = mapInfoList(tilsynsobjekt, additionalInfoElements);
  return <SplitSections className="tilsynsobjekt-card" data-sentry-element="SplitSections" data-sentry-component="TilsynsobjektCard" data-sentry-source-file="index.tsx">
      <div className="tilsynsobjekt-navn">
        <div className="virksomhets-name">{tilsynsobjekt.virksomhetsNavn}</div>
        <div className="name">{tilsynsobjekt.navn}</div>
      </div>
      <div className="info-list">
        {infoList.map(infoElem => <InfoText key={infoElem.label} title={infoElem.label} inline={showInfoTextInline}>
            {infoElem.value}
          </InfoText>)}
      </div>
    </SplitSections>;
};
export default TilsynsobjektCard;