import { useDispatch } from 'react-redux'
import {
  faktabeskrivelserTidligereTilsynActions,
  faktabeskrivelserTidligereTilsynSelectors,
} from '../../ducks/faktabeskrivelser-tidligere-tilsyn/store'
import { useCallback, useEffect } from 'react'
import { useTypedSelector } from '../../../../common/custom-hooks'

export { useFaktabeskrivelserTidligereTilsyn }

const useFaktabeskrivelserTidligereTilsyn = (tilsynsobjektId: string) => {
  const dispatch = useDispatch()

  const faktabeskrivelser = useTypedSelector(
    faktabeskrivelserTidligereTilsynSelectors.getFaktabeskrivelserTidligereTilsynByTilsynsobjekt(
      tilsynsobjektId
    )
  )

  const error = useTypedSelector(
    faktabeskrivelserTidligereTilsynSelectors.getErrorByTilsynsobjekt(
      tilsynsobjektId
    )
  )

  const fetchFaktabeskrivelserTidligereTilsyn = useCallback(() => {
    if (!faktabeskrivelser) {
      dispatch(
        faktabeskrivelserTidligereTilsynActions.fetchFaktabeskrivelserTidligereTilsyn(
          tilsynsobjektId
        )
      )
    }
  }, [faktabeskrivelser, dispatch, tilsynsobjektId])

  useEffect(() => {
    fetchFaktabeskrivelserTidligereTilsyn()
  }, [fetchFaktabeskrivelserTidligereTilsyn])

  return {
    faktabeskrivelser,
    error,
    fetchFaktabeskrivelserTidligereTilsyn,
  }
}
