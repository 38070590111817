import React, { useEffect, useState } from 'react';
import { AboutView } from './view';
import { useGoBack } from '../../common/navigation';
const About = () => {
  const goBack = useGoBack();
  const [apiStatus, setApiStatus] = useState({
    checked: false,
    ok: false
  });
  useEffect(() => {
    fetch(`${location.origin}/api/`).then(cb => {
      if (cb.ok) {
        setApiStatus({
          checked: true,
          ok: true
        });
      }
    }).catch(() => {
      setApiStatus({
        checked: true,
        ok: false
      });
    });
  }, []);
  return <AboutView apiStatus={apiStatus} goBack={goBack} data-sentry-element="AboutView" data-sentry-component="About" data-sentry-source-file="index.tsx" />;
};
export default About;