import * as Sentry from '@sentry/react'
import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

const EMAIL_REGEX = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g
const UUID_REGEX =
  /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/g
const ID_REGEX = /\b\d+\b/g

/** Sanitize URL by replacing sensitive information with placeholders. */
const sanitizeUrl = (url: string) =>
  url
    .replace(EMAIL_REGEX, '[email]')
    .replace(UUID_REGEX, '[uuid]')
    .replace(ID_REGEX, '[id]')

const ignorePatters: (string | RegExp)[] = [
  '/api/notification-api/notification',
  '/api/bruker-api/feature-toggles',
]
/** Check if URL should be ignored from Sentry breadcrumbs or spans. */
const ignoreUrl = (url: string) =>
  ignorePatters.some((pattern) => {
    if (typeof pattern === 'string') {
      return url.includes(pattern)
    }

    return pattern.test(url)
  })

Sentry.init({
  dsn: 'https://b570a3ec8d501e16d1b7dc950dd83a7f@o4508319312642048.ingest.de.sentry.io/4508358336643152',
  // @ts-ignore This is magically injected by nginx and index.html
  environment: globalThis.SENTRY_APP_ENV ?? 'localhost',
  tunnel: '/api/sentry-tunnel', // Tunnel to send Sentry events through tilsynskvittering-ui-backend to bypass add-blockers and the like
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation: useLocation,
      useNavigationType: useNavigationType,
      createRoutesFromChildren: createRoutesFromChildren,
      matchRoutes: matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/tilsynskvittering\.inspektor(-(test|utv))?\.mattilsynet\.io/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeBreadcrumb: (breadcrumb) => {
    if (
      ignoreUrl(breadcrumb.data?.url ?? '') ||
      ignoreUrl(breadcrumb.message ?? '')
    ) {
      // Ignore the breadcrumb
      return null
    }

    if (breadcrumb.type === 'http' && breadcrumb.data?.url) {
      breadcrumb.data.url = sanitizeUrl(breadcrumb.data.url)
    }

    return breadcrumb
  },
  beforeSendSpan: (span) => {
    if (ignoreUrl(span.description ?? '')) {
      // Ignore the span
      return null
    }

    span.description = sanitizeUrl(span.description ?? '')

    return span
  },
})

// Add global context to Sentry
Sentry.getGlobalScope().setContext('tilsynskvittering', {
  version: process.env.APP_VERSION,
})

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

/** Constant tag name for custom exception capture throughout the application. */
export const CAPTURE_NAME = 'tkCaptureType' as const
/** Capture type to signify what type of custom exception capture is being sent.
 * What/where in the application that sent the error.
 */
export enum CaptureType {
  ROOT = 'root', // Error in the UI
  ROUTE = 'route', // Error in the route UI
  EPIC = 'epic', // Error in an epic
  MUTATION = 'mutation', // Error in a Tanstack mutation
}

// tracing:
// Hvor mye tracing trenger vi å monitorere? xx%
// Hvilke sider trenger tracing? observasjoner-slide
