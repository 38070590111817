import ToastArea from './view/toast-area'
import FixedToastArea from './view/fixed-toast-area'

import * as toastFunctions from './store/index'

export type { IToast } from './store/types'
export const toast = toastFunctions

export const Toast = {
  ToastArea,
  FixedToastArea,
}
