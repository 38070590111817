import { of } from 'rxjs'
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators'
import { combineEpics, Epic } from 'redux-observable'
import { observasjonerActions, observasjonerActionsTypes } from '../store'
import { IObservasjonActions } from '../store/types'
import { ICommonApi } from '../../../../../api'
import { IStoreState } from '../../../../../reducers/types'
import { ENDPOINT } from '../../../../../constants'
import { commonRedux } from '../../../../../common/redux'

const { isOfType } = commonRedux

const formatObservasjonResp = (isAmfunn: boolean, resp: any) =>
  isAmfunn
    ? { observasjonList: resp._embedded?.amObservasjonList || [] }
    : { observasjonList: resp._embedded?.pmObservasjonList || [] }

export const fetchObservasjonListEpic =
  (
    commonApi: ICommonApi
  ): Epic<IObservasjonActions, IObservasjonActions, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(observasjonerActionsTypes.FETCH_ID)),
      withLatestFrom(state$),
      mergeMap(([{ isAmFunn, id }, state]) => {
        const URL = isAmFunn
          ? `${ENDPOINT.KJOETTKONTROLL_FUNN}/v2/am/funn/${id}/observasjoner?expand=bildeIds&size=1000`
          : `${ENDPOINT.KJOETTKONTROLL_FUNN}/v2/pm/funn/${id}/observasjoner?expand=bildeIds&size=1000`
        return of(state).pipe(
          commonApi.get(URL, state),
          map((resp) => formatObservasjonResp(isAmFunn, resp)),
          map((data) =>
            observasjonerActions.fetchIdOk({ ...data, funnId: id })
          ),
          catchError((err) =>
            of(observasjonerActions.fetchIdFail(id, err.message))
          )
        )
      })
    )

const observasjonerEpic = (commonApi: ICommonApi) =>
  combineEpics(fetchObservasjonListEpic(commonApi))

export default observasjonerEpic
