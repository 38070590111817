import store from '../reducers/store';
import { isArkivertStatus, isFerdigstiltStatus } from '../utils/kvittering';
import { syncSelectors } from '../ducks/kvittering/selectors';
import { kvitteringActions } from '../ducks/kvittering/actions';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTypedSelector } from '../common/custom-hooks';
import { userSelectors } from '../ducks/user/selectors';
import { uiSelectors } from '../ducks/ui/selectors';
import { useTKNavigate } from '../common/navigation';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { uiActions } from '../ducks/ui/actions';
import { IStoreState } from '../reducers/types';
import { Login } from '../components/login';
import { PageError } from '../components/page-error';
import { PageLoading } from '../components/page-loading';
import { useIsQueryClientReady } from '../utils/query-client-ready';
const sync = () => {
  const state = store.getState();
  const kvitteringer = state.kvittering.kvitteringer.filter(kvittering => !isArkivertStatus(kvittering.status) && !isFerdigstiltStatus(kvittering.status) && !kvittering.synced);
  kvitteringer.forEach(kvittering => {
    store.dispatch(kvitteringActions.updateKvittering(kvittering, true));
  });
};
export const Private = ({
  element
}: {
  element: ReactElement;
}) => {
  const isQueryClientReady = useIsQueryClientReady();
  const authUser = useTypedSelector(userSelectors.getAuthUser);
  const initializationStatus = useTypedSelector(uiSelectors.getInitializationStatus);
  const navigate = useTKNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const errorAction = () => dispatch(uiActions.initialize());
  const [synced, setSynced] = useState(false);
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline);
  const isKvitteringerSynced = useTypedSelector(syncSelectors.isAllKvitteringerSyncedOLD);
  const pathAndParams = window.location.href.split('?');
  const params = pathAndParams.length > 1 ? pathAndParams.pop() : '';
  const paramsWithoutSyncing = params?.split('&').filter(param => !param.includes('syncing')).join('&');
  const hasReloaded = params?.includes('syncing');
  useEffect(() => {
    if (synced && hasReloaded) {
      const pathWithoutParams = location.pathname.split('?')[0];
      const pathWithoutSyncingParam = `${pathWithoutParams}?${paramsWithoutSyncing ?? ''}`;
      navigate(pathWithoutSyncingParam, {
        replace: true
      });
    }
  }, [synced, hasReloaded, dispatch, paramsWithoutSyncing, location.pathname, navigate]);
  useEffect(() => {
    if (initializationStatus.loaded) {
      if (isKvitteringerSynced) {
        setSynced(true);
        dispatch(kvitteringActions.cleanSyncState());
      } else if (!isOffline) {
        setSynced(false);
        sync();
      }
    }
  }, [isOffline, initializationStatus, isKvitteringerSynced, dispatch]);
  if (!authUser?.username) {
    return <Login />;
  }
  if (initializationStatus.error) {
    return <PageError errorText="Kunne ikke laste inn Tilsynskvittering." errorAction={errorAction} errorActionText="Prøv å laste innhold på nytt" />;
  }
  if (!synced && window.navigator.onLine) {
    // const newPath = `${fullPathWithoutParams}?${paramsWithoutSyncing}${syncingParam}`
    if (!hasReloaded && !isKvitteringerSynced) {
      // window.location.href = newPath
    }
    return <PageLoading loadingText="Innhold i tilsynskvitteringen lastes opp. Dette vil kunne ta tid ved dårlig dekning eller ved opplasting av mange bilder." />;
  }
  if (!initializationStatus.loaded) {
    return <PageLoading loadingText="Laster tilsynskvittering..." />;
  }
  if (!isQueryClientReady) {
    return <PageLoading loadingText="Venter på tilsynskvittering..." />;
  }
  return element;
};