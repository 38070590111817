import React from 'react';
import ToastArea from '../toast-area';
import './style.css';
export interface IFixedToastAreaProps {
  id?: string;
}
const FixedToastArea = ({
  id
}: IFixedToastAreaProps) => <div className="common__FixedToastArea" id={id ?? 'common__FixedToastArea'} data-sentry-component="FixedToastArea" data-sentry-source-file="index.tsx">
    <ToastArea data-sentry-element="ToastArea" data-sentry-source-file="index.tsx" />
  </div>;
export default FixedToastArea;