import { IObservasjon, IObservasjonActions, IObservasjonState } from './types'
import { ROOT_STORE_NAME } from '../../../store'
import { IStoreState } from '../../../../../reducers/types'
import { commonRedux } from '../../../../../common/redux'

export const STORE_NAME = '@tilsyn/observasjoner'

const { fetchWithIndexHandlers, fetchActionTypes, fetchActions } =
  commonRedux.fetchWithIndexHelpers(STORE_NAME, 'funnId')

export const observasjonerActionsTypes = {
  ...fetchActionTypes,
  POST: `${STORE_NAME}/POST` as const,
  POST_FAIL: `${STORE_NAME}/POST_FAIL` as const,
  POST_OK: `${STORE_NAME}/POST_OK` as const,
  PUT: `${STORE_NAME}/PUT` as const,
  PUT_FAIL: `${STORE_NAME}/PUT_FAIL` as const,
  PUT_OK: `${STORE_NAME}/PUT_OK` as const,
  DELETE: `${STORE_NAME}/DELETE` as const,
  DELETE_FAIL: `${STORE_NAME}/DELETE_FAIL` as const,
  DELETE_OK: `${STORE_NAME}/DELETE_OK` as const,
  RESET: `${STORE_NAME}/RESET` as const,
  RESET_CURRENT_OBSERVSAJON_STATUS:
    `${STORE_NAME}/RESET_CURRENT_OBSERVSAJON_STATUS` as const,
  RESET_CURRENT_OBSERVSAJON_ID:
    `${STORE_NAME}/RESET_CURRENT_OBSERVSAJON_ID` as const,
}

export const observasjonerActions = {
  ...fetchActions,
  post: (observasjon: Partial<IObservasjon>, isAmFunn: boolean) => ({
    type: observasjonerActionsTypes.POST,
    observasjon,
    isAmFunn,
  }),
  postFail: (error: string) => ({
    type: observasjonerActionsTypes.POST_FAIL,
    error,
  }),
  postOk: (id: string) => ({
    type: observasjonerActionsTypes.POST_OK,
    id,
  }),
  put: (observasjon: IObservasjon, isAmFunn: boolean) => ({
    type: observasjonerActionsTypes.PUT,
    observasjon,
    isAmFunn,
  }),
  putFail: (id: string, error: string) => ({
    type: observasjonerActionsTypes.PUT_FAIL,
    error,
    id,
  }),
  putOk: (id: string) => ({
    type: observasjonerActionsTypes.PUT_OK,
    id,
  }),
  delete: (funnId: string, isAmFunn: boolean, observasjonId: string) => ({
    type: observasjonerActionsTypes.DELETE,
    observasjonId,
    funnId,
    isAmFunn,
  }),
  deleteFail: (error: string) => ({
    type: observasjonerActionsTypes.DELETE_FAIL,
    error,
  }),
  deleteOk: () => ({
    type: observasjonerActionsTypes.DELETE_OK,
  }),
  reset: () => ({
    type: observasjonerActionsTypes.RESET,
  }),
  resetCurrentObservasjonStatus: () => ({
    type: observasjonerActionsTypes.RESET_CURRENT_OBSERVSAJON_STATUS,
  }),
  resetCurrentObservasjonId: () => ({
    type: observasjonerActionsTypes.RESET_CURRENT_OBSERVSAJON_ID,
  }),
  fetchId: (id: string, isAmFunn: boolean, silent = false) => ({
    type: observasjonerActionsTypes.FETCH_ID,
    id,
    isAmFunn,
    silent,
  }),
}

export const initialState: IObservasjonState = {
  ...commonRedux.initialListState,
  currentObservasjonStatus: {
    error: '',
    saved: true,
    saving: false,
  },
}

// selectors
export const observasjonerSelectors = {
  getById: (id: string | undefined) => (state: IStoreState) =>
    id ? state[ROOT_STORE_NAME][STORE_NAME].data[id] : undefined,

  currentObservajonStatus: (state: IStoreState) =>
    state[ROOT_STORE_NAME][STORE_NAME].currentObservasjonStatus,
}

const setCurrentObservajonStatus =
  (error: string, saved: boolean, saving: boolean, observasjonId?: string) =>
  (state: IObservasjonState): IObservasjonState => ({
    ...state,
    currentObservasjonStatus: {
      error,
      saved,
      saving,
      observasjonId,
    },
  })

const customReducer = commonRedux.createReducer<
  IObservasjonState,
  IObservasjonActions
>(initialState, {
  ...fetchWithIndexHandlers,
  [observasjonerActionsTypes.POST]: (
    state: IObservasjonState
  ): IObservasjonState => setCurrentObservajonStatus('', false, true)(state),

  [observasjonerActionsTypes.POST_OK]: (
    state: IObservasjonState,
    { id }: ReturnType<typeof observasjonerActions.postOk>
  ): IObservasjonState =>
    setCurrentObservajonStatus('', true, false, id)(state),

  [observasjonerActionsTypes.POST_FAIL]: (
    state: IObservasjonState,
    { error }
  ): IObservasjonState =>
    setCurrentObservajonStatus(error, false, false)(state),

  [observasjonerActionsTypes.PUT]: (
    state: IObservasjonState,
    { observasjon }: ReturnType<typeof observasjonerActions.put>
  ): IObservasjonState =>
    setCurrentObservajonStatus(
      '',
      false,
      true,
      observasjon.observasjonId
    )(state),

  [observasjonerActionsTypes.PUT_OK]: (
    state: IObservasjonState,
    { id }: ReturnType<typeof observasjonerActions.putOk>
  ): IObservasjonState =>
    setCurrentObservajonStatus('', true, false, id)(state),

  [observasjonerActionsTypes.PUT_FAIL]: (
    state: IObservasjonState,
    { id, error }: ReturnType<typeof observasjonerActions.putFail>
  ): IObservasjonState =>
    setCurrentObservajonStatus(error, false, false, id)(state),

  [observasjonerActionsTypes.DELETE]: (
    state: IObservasjonState
  ): IObservasjonState => setCurrentObservajonStatus('', false, true)(state),

  [observasjonerActionsTypes.DELETE_OK]: (
    state: IObservasjonState
  ): IObservasjonState => setCurrentObservajonStatus('', true, false)(state),

  [observasjonerActionsTypes.DELETE_FAIL]: (
    state: IObservasjonState,
    { error }
  ): IObservasjonState =>
    setCurrentObservajonStatus(error, false, false)(state),

  [observasjonerActionsTypes.RESET]: (): IObservasjonState => initialState,

  [observasjonerActionsTypes.RESET_CURRENT_OBSERVSAJON_STATUS]: (
    state: IObservasjonState
  ): IObservasjonState => ({
    ...state,
    currentObservasjonStatus: initialState.currentObservasjonStatus,
  }),
  [observasjonerActionsTypes.RESET_CURRENT_OBSERVSAJON_ID]: (
    state: IObservasjonState
  ): IObservasjonState => ({
    ...state,
    currentObservasjonStatus: {
      ...state.currentObservasjonStatus,
      observasjonId: undefined,
    },
  }),
})

export const observasjonerReducer = {
  [STORE_NAME]: customReducer,
}
