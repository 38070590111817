import { Column, Row, TextLink } from '@mattilsynet/mt-ui';
import React, { useCallback, useEffect } from 'react';
import { IStoreState } from '../../reducers/types';
import { useTypedSelector } from '../../common/custom-hooks';
import './style.css';
import { useBilde, useBildeMetadata } from '../../features/bilder';
import { useKvitteringId } from '../../common/kvittering-context';
interface IFullImagePageProps {
  onTilbake: () => void;
}
const FullImagePage = ({
  onTilbake
}: IFullImagePageProps) => {
  const kvitteringId = useKvitteringId();
  const imageId = useTypedSelector((state: IStoreState) => state.ui.openImageNavigationModal.imageId);
  useEffect(() => {
    if (imageId === undefined) {
      onTilbake();
    }
  }, [imageId, onTilbake]);
  const [bildeMetadata] = useBildeMetadata(kvitteringId, imageId ?? '');
  const [url] = useBilde(kvitteringId, bildeMetadata?.id ?? '', 'original');
  const onBack = useCallback(() => {
    onTilbake();
  }, [onTilbake]);
  return <Column className="fullSizeImage" data-sentry-element="Column" data-sentry-component="FullImagePage" data-sentry-source-file="index.tsx">
      <Row padding={[0, 0, 2, 0]} data-sentry-element="Row" data-sentry-source-file="index.tsx">
        <TextLink onClick={onBack} leftArrow data-sentry-element="TextLink" data-sentry-source-file="index.tsx">
          Tilbake
        </TextLink>
      </Row>
      <Row data-sentry-element="Row" data-sentry-source-file="index.tsx">
        <img src={url} alt={bildeMetadata?.description} />
      </Row>
    </Column>;
};
export { FullImagePage };