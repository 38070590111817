import { AddCircle, Row, TextLink } from '@mattilsynet/mt-ui';
import React from 'react';
export interface IAddDeltaker {
  disabled?: boolean;
  onAdd: () => void;
}
const AddDeltaker = ({
  disabled,
  onAdd
}: IAddDeltaker) => {
  return <Row justify="center" data-sentry-element="Row" data-sentry-component="AddDeltaker" data-sentry-source-file="add-deltaker.tsx">
      <TextLink leftIcon={<AddCircle />} onClick={onAdd} disabled={disabled} data-sentry-element="TextLink" data-sentry-source-file="add-deltaker.tsx">
        Ny deltaker
      </TextLink>
    </Row>;
};
export default AddDeltaker;