import { Column, ContrastBox, Row } from '@mattilsynet/mt-ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDeepEffect, useMidlertidigTilsynsobjekt, useTypedSelector } from '../../../common/custom-hooks';
import { offlineInSakAndTilsynsobjekt, offlineInTypeTilsynSelect, useSingleToast } from '../../../common/toast';
import GenerellInfomasjonCard from '../../../components/generell-informasjon-card';
import { KontrollpunkterSection } from '../../../features/kontrollpunkter';
import { PageLoading } from '../../../components/page-loading';
import SakCardInfo from '../../../components/sak-card-info';
import SakSearch from '../../../components/sak-search';
import TilsynsobjekterCardInfo from '../../../components/tilsynsobjekter-card-info';
import TypeTilsyn from '../../../components/type-tilsyn';
import { useKvittering, useUpdateKvittering } from '../../../features/kvitteringer';
import { onKeyDown } from '../../../helpers';
import { IStoreState } from '../../../reducers/types';
import { useViewsContext } from '..';
import { useKvitteringId } from '../../../common/kvittering-context';
import { VirksomhetModal } from '../../../modals';
import { initialSearch } from '../../forbered-new-tilsyn/utils';
import { AktivitetModal } from '../../../modals/aktivitet-modal';
import { kodeverk } from '../../../features/kodeverk';
import { useBildeMetadataList } from '../../../features/bilder';
import { getSakData, getSaksnummer, useSak } from '../../../features/sak';
import { MutationStatus, useMutationStatus } from '../../../common/query';
import { kvitteringKeys } from '../../../features/kvitteringer/queries/helpers';
interface ISakOgTilsynsobjektSlideProps {
  blockContinueFromZero: boolean;
  sakNumberValue: string;
  setSakNumberValue: (value: string) => void;
}
export const SakOgTilsynsobjektSlide = ({
  blockContinueFromZero,
  sakNumberValue,
  setSakNumberValue
}: ISakOgTilsynsobjektSlideProps) => {
  const singleToast = useSingleToast();
  const {
    viewDispatch
  } = useViewsContext();
  const kvitteringId = useKvitteringId();
  const {
    data: kvittering,
    isLoading
  } = useKvittering(kvitteringId);
  const [saknummer, setSaknummer] = useState('');
  const kvitteringSaksnummer = kvittering?.noarksakSekvensnummer ? `${kvittering.noarksakAar}/${kvittering.noarksakSekvensnummer}` : '';
  const kvitteringRef = useRef(kvittering);
  useEffect(() => {
    kvitteringRef.current = kvittering;
  }, [kvittering]);
  const {
    data: sakData,
    isPending: isSakLoading,
    isError: isSakError,
    error: sakError
  } = useSak(getSaksnummer(saknummer, kvittering));
  const {
    mutate: updateKvittering
  } = useUpdateKvittering();

  // Prepopulate cache with initial data.
  // Using this since `initialData` is considered fresh data
  useBildeMetadataList(kvitteringId);
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline);
  const {
    sakNumber,
    title,
    sakFail,
    sakNotFound,
    sakClosed,
    sakHjemmel,
    sakTilgang,
    loading: sakLoading
  } = getSakData(sakData, isSakLoading, isSakError, sakError, saknummer);
  const [showSak, setShowSak] = useState(false);
  const isAKvitteringBeingCreated = useMutationStatus({
    mutationKey: kvitteringKeys.create()
  }).filter(([status]) => status !== MutationStatus.SUCCESS).length > 0;
  const openTidligereKontrollpunkter = useCallback(() => viewDispatch('tidligereKontrollpunkter'), [viewDispatch]);
  const onChange = e => {
    if (isOffline) {
      return singleToast.showToast(offlineInSakAndTilsynsobjekt());
    }
    setSakNumberValue(onKeyDown(e, sakNumberValue));
  };
  const onAddSak = useCallback(() => {
    if (sakNumberValue.length > 0) {
      setShowSak(true);
      if (isOffline) {
        return singleToast.showToast(offlineInSakAndTilsynsobjekt());
      }
      setSaknummer(sakNumberValue);
    }
  }, [sakNumberValue, singleToast, isOffline]);
  const onCancelSak = () => {
    setShowSak(false);
    if (isOffline) {
      return singleToast.showToast(offlineInSakAndTilsynsobjekt());
    }
    if (kvitteringRef.current) {
      updateKvittering({
        kvittering: {
          ...kvitteringRef.current,
          noarksakAar: '',
          noarksakSekvensnummer: ''
        }
      });
    }
    setSakNumberValue('');
    setSaknummer('');
  };
  const onChangeTypeTilsyn = typeValue => {
    if (isOffline) {
      return singleToast.showToast(offlineInTypeTilsynSelect());
    }
    if (kvittering) {
      updateKvittering({
        kvittering: {
          ...kvittering,
          typeTilsyn: typeValue
        }
      });
    }
  };
  const [isVirksomhetModalOpen, setIsVirksomhetModalOpen] = useState(false);
  const onCancelVirksomhetModal = () => {
    setIsVirksomhetModalOpen(false);
  };
  const onAddRemoveTilsynsobjekt = useCallback(() => {
    if (isOffline) {
      return singleToast.showToast(offlineInSakAndTilsynsobjekt());
    }
    setIsVirksomhetModalOpen(true);
  }, [isOffline, singleToast, setIsVirksomhetModalOpen]);
  const kodeverkAktiviteterBehandlesIMats = kodeverk.hooks.useKodeverk('AKTIVITET', {
    filter: 'H'
  });
  const {
    isAktivitetModalOpen,
    aktivitetOptions,
    selectedAktivitet,
    setSelectedAktivitet,
    onSelectAktivitet,
    onSearchAktivitet,
    midlertidigTilsynsObjektVirksomhet,
    setMidlertidigTilsynsObjektVirksomhet,
    onClickMidlertidigTilsynsobjekt,
    onCancelAktivitetModal,
    unselectedMidlertidigTilsynsobjekt,
    setUnselectedMidlertidigTilsynsobjekt
  } = useMidlertidigTilsynsobjekt(kodeverkAktiviteterBehandlesIMats, setIsVirksomhetModalOpen);
  useEffect(() => {
    if (kvittering?.id) {
      const midlertidigTilsynsobjekt = kvittering.tilsynsobjekter?.find(t => t.midlertidig);
      if (midlertidigTilsynsobjekt) {
        setSelectedAktivitet({
          label: midlertidigTilsynsobjekt.aktivitetsBeskrivelse,
          value: midlertidigTilsynsobjekt.aktivitetsId
        });
      }
    }
  }, [kvittering?.id, kvittering?.tilsynsobjekter, setSelectedAktivitet]);
  useDeepEffect(() => {
    const [noarksakAar, noarksakSekvensnummer] = sakData?.sakNumber ? sakData.sakNumber.split('/') : ['', ''];
    if (isSakError && kvitteringRef.current) {
      updateKvittering({
        kvittering: {
          ...kvitteringRef.current,
          noarksakAar: '',
          noarksakSekvensnummer: ''
        }
      });
    }
    if (sakData?.sakNumber && kvitteringRef.current && kvitteringRef.current.noarksakSekvensnummer !== noarksakSekvensnummer) {
      updateKvittering({
        kvittering: {
          ...kvitteringRef.current,
          noarksakAar,
          noarksakSekvensnummer
        }
      });
    }
    if (isSakError) {
      singleToast.showToast({
        text: 'Saken du har lagt til kan ikke brukes. Du må enten fjerne saken eller legge til en ny sak',
        timeoutSeconds: 5,
        dismissible: true,
        type: 'DANGER'
      });
    }
  }, [sakData, isSakError, singleToast, updateKvittering]);
  if (isLoading) {
    return <PageLoading loadingText="Laster inn tilsynskvittering..." />;
  }
  const hasKvitteringSaksnummer = Boolean(kvitteringSaksnummer);
  const hasError = (sakNumberValue ?? sakNumber).length > 0 && blockContinueFromZero && !hasKvitteringSaksnummer;
  const sakSearchErrorText = !hasError ? '' : 'Saken må legges til eller tekstfeltet må være tomt';
  return <Column spacing={3} fluid key={1} data-sentry-element="Column" data-sentry-component="SakOgTilsynsobjektSlide" data-sentry-source-file="sak-og-tilsynsobjekt-slide.tsx">
      <Row className="title" data-sentry-element="Row" data-sentry-source-file="sak-og-tilsynsobjekt-slide.tsx">
        <h1>Sak, tilsynstype, tilsynsobjekt og kontrollpunkter</h1>
      </Row>
      <Row data-sentry-element="Row" data-sentry-source-file="sak-og-tilsynsobjekt-slide.tsx">
        <Column spacing={2} fluid data-sentry-element="Column" data-sentry-source-file="sak-og-tilsynsobjekt-slide.tsx">
          <TypeTilsyn disabled={isAKvitteringBeingCreated} onChange={onChangeTypeTilsyn} currentType={kvittering?.typeTilsyn ?? ''} currentTypeTypeTilsynBeskrivelse={kvittering?.typeTilsynBeskrivelse} data-sentry-element="TypeTilsyn" data-sentry-source-file="sak-og-tilsynsobjekt-slide.tsx" />
          <ContrastBox noBorders backgroundColor="white" data-sentry-element="ContrastBox" data-sentry-source-file="sak-og-tilsynsobjekt-slide.tsx">
            <SakSearch onAddSak={onAddSak} onChange={onChange} disabled={isAKvitteringBeingCreated} sakNumberValue={sakNumberValue ?? sakNumber} errorText={sakSearchErrorText} data-sentry-element="SakSearch" data-sentry-source-file="sak-og-tilsynsobjekt-slide.tsx" />
            <br />
            <SakCardInfo sakNumber={sakNumber} title={title} onCancelSak={onCancelSak} sakFail={sakFail} sakNotFound={sakNotFound} sakClosed={sakClosed} sakHjemmel={sakHjemmel} sakTilgang={sakTilgang} isLoading={sakLoading} showSak={showSak} data-sentry-element="SakCardInfo" data-sentry-source-file="sak-og-tilsynsobjekt-slide.tsx" />
          </ContrastBox>

          <ContrastBox noBorders backgroundColor="white" data-sentry-element="ContrastBox" data-sentry-source-file="sak-og-tilsynsobjekt-slide.tsx">
            <GenerellInfomasjonCard data-sentry-element="GenerellInfomasjonCard" data-sentry-source-file="sak-og-tilsynsobjekt-slide.tsx" />
          </ContrastBox>

          <TilsynsobjekterCardInfo disabled={isAKvitteringBeingCreated} onAddRemoveTilsynsobjekt={onAddRemoveTilsynsobjekt} tilsynsobjekter={kvittering?.tilsynsobjekter ?? []} virksomhetsNavn={kvittering?.virksomhetsNavn} virksomhetsOrgNr={kvittering?.virksomhetsOrgNr} data-sentry-element="TilsynsobjekterCardInfo" data-sentry-source-file="sak-og-tilsynsobjekt-slide.tsx" />
          {kvittering && kvittering.tilsynsobjekter?.length > 0 && <KontrollpunkterSection isLoading={isLoading} tilsynsobjekter={kvittering.tilsynsobjekter} openTidligereKontrollpunkter={openTidligereKontrollpunkter} />}

          <VirksomhetModal isOpen={isVirksomhetModalOpen} onCancel={onCancelVirksomhetModal} initialSearch={initialSearch(kvittering?.virksomhetsNavn, title)} onClickMidlertidigTilsynsobjekt={onClickMidlertidigTilsynsobjekt} midlertidigTilsynsobjektAktivitet={selectedAktivitet} midlertidigTilsynsobjektVirksomhet={midlertidigTilsynsObjektVirksomhet} onResetMidlertidigTilsynsojekt={() => {
          setMidlertidigTilsynsObjektVirksomhet({
            navn: '',
            nummer: ''
          });
        }} onUnselectMidlertidigTilsynsobjekt={tilsynsobjekt => setUnselectedMidlertidigTilsynsobjekt(tilsynsobjekt)} unselectedMidlertidigTilsynsobjekt={unselectedMidlertidigTilsynsobjekt} data-sentry-element="VirksomhetModal" data-sentry-source-file="sak-og-tilsynsobjekt-slide.tsx" />
          {isAktivitetModalOpen && <AktivitetModal isOpen={isAktivitetModalOpen} onCancel={() => onCancelAktivitetModal()} aktivitetOptions={aktivitetOptions} selectedAktivitet={selectedAktivitet} onSelect={onSelectAktivitet} onSearch={onSearchAktivitet} onUnselectMidlertidigTilsynsobjekt={tilsynsobjekt => setUnselectedMidlertidigTilsynsobjekt(tilsynsobjekt)} unselectedMidlertidigTilsynsobjekt={unselectedMidlertidigTilsynsobjekt} />}
        </Column>
      </Row>
    </Column>;
};