import { IStateDataList } from './types'
import * as R from 'ramda'
import { AnyAction } from 'redux'

export const initialListState = {
  error: undefined,
  loading: false,
  data: {},
  index: [],
  loaded: false,
}

export const initialLoadState = (silent: boolean) => {
  return R.mergeLeft({
    error: undefined,
    loading: !silent,
  })
}

export const loadedSuccessState = {
  error: undefined,
  loading: false,
}

export const loadingSuccessListState = {
  error: undefined,
  loading: false,
  loaded: true,
}

// Helpers
export const addDataToList = <TData>(
  idString: string,
  newData: TData,
  state: IStateDataList<TData>
): IStateDataList<TData> => {
  const newId = R.pipe(R.prop(idString))(newData)

  const newIndexList = R.pipe(R.path(['index']), R.append(newId), R.uniq)(state)

  const newDataList = R.pipe(
    R.path(['data']),
    R.mergeLeft({
      [newId]: {
        ...loadedSuccessState,
        data: newData,
      },
    })
  )(state)

  return {
    ...state,
    data: newDataList,
    index: newIndexList,
    ...loadingSuccessListState,
  }
}

export const isOfType =
  <A extends AnyAction, T extends A['type']>(actionTypeOrTypes: T | T[]) =>
  (action: A): action is A extends { type: T } ? A : never => {
    const actionTypes = Array.isArray(actionTypeOrTypes)
      ? actionTypeOrTypes
      : [actionTypeOrTypes]

    return actionTypes.includes(action.type)
  }
