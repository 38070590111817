import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../common/custom-hooks';
import { IStoreState } from '../../reducers/types';
import './style.css';
import { SammenstillingView } from './view';
import { FullImagePage } from '../full-image';
import { uiActions } from '../../ducks/ui/actions';
import { isArkivertStatus, isFerdigstiltStatus } from '../../utils/kvittering';
import { useParams } from 'react-router';
import { useGoBack, useTKNavigate } from '../../common/navigation';
import { KvitteringIdProvider } from '../../common/kvittering-context';
import { useKvittering } from '../../features/kvitteringer';
export const getSammenstillingRoute = (kvitteringId: string) => `/sammenstilling/${kvitteringId}`;
const SammenstillingRoute = () => {
  const {
    id: kvitteringId
  } = useParams();
  const navigate = useTKNavigate();
  const goBack = useGoBack();
  const showFullSizeImage = useTypedSelector((state: IStoreState) => state.ui.openImageNavigationModal.isFullversjonOpen);
  const {
    data: kvittering
  } = useKvittering(kvitteringId);
  const dispatch = useDispatch();
  const onFullversjonTilbake = useCallback(() => dispatch(uiActions.setImageFullversjonOpen(false)), [dispatch]);
  const showSammenstilling = isArkivertStatus(kvittering?.status) || isFerdigstiltStatus(kvittering?.status);
  const goToStartTilsynSammenstillingRoute = () => navigate(`/start-tilsyn/${kvitteringId}?currentSlide=4`);
  if (showFullSizeImage) {
    return <FullImagePage onTilbake={onFullversjonTilbake} />;
  }
  return <KvitteringIdProvider value={kvitteringId!} data-sentry-element="KvitteringIdProvider" data-sentry-component="SammenstillingRoute" data-sentry-source-file="index.tsx">
      {kvittering && <SammenstillingView goBack={goBack} kvittering={kvittering} showSammenstilling={showSammenstilling} goToStartTilsynSammenstillingRoute={goToStartTilsynSammenstillingRoute} />}
    </KvitteringIdProvider>;
};
export default SammenstillingRoute;