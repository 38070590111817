import React, { useCallback } from 'react';
import { CopyToClipboardButton } from '../copy-to-clipboard';
import { InformationBox } from '../information-box';
import { copySaknummer, useSingleToast } from '../../common/toast';
interface IBilderUploadDangerCardProps {
  sakNumber: string;
}
export const BilderUploadDangerCard = ({
  sakNumber
}: IBilderUploadDangerCardProps) => {
  const singleToast = useSingleToast();
  const onCopySaknummer = useCallback(() => singleToast.showToast(copySaknummer()), [singleToast]);
  return <InformationBox variant="danger" header="Feil med bildedata" data-sentry-element="InformationBox" data-sentry-component="BilderUploadDangerCard" data-sentry-source-file="index.tsx">
      <p>
        {`Tilsynskvittering med saksnummer ${sakNumber}`}{' '}
        <CopyToClipboardButton onCopied={onCopySaknummer} value={sakNumber} data-sentry-element="CopyToClipboardButton" data-sentry-source-file="index.tsx" />{' '}
        har feil med bildedata under opplasting til arkiv. Se i Elements om du
        finner feil på saken. Det kan være lurt å se i den interne journalposten
        Tekniske billeddata. Opprett sak og gi beskjed om eventuelle feil du
        finner til{' '}
        <a href="https://mattilsynet.pureservice.com/#/new/1" target="_blank" rel="noreferrer" style={{
        color: 'var(--text-color)',
        textDecoration: 'underline'
      }}>
          brukerstøtte
        </a>
        .
      </p>
    </InformationBox>;
};