import { useDispatch } from 'react-redux'
import {
  tidligereTilsynskvitteringerActions,
  tidligereTilsynskvitteringerSelectors,
} from '../../ducks/tidligere-tilsynskvitteringer/store'
import { useCallback, useEffect } from 'react'
import { useTypedSelector } from '../../../../common/custom-hooks'

export { useTidligereTilsynskvitteringer }

const useTidligereTilsynskvitteringer = (tilsynsobjektId: string) => {
  const dispatch = useDispatch()

  const tilsynskvitteringer = useTypedSelector(
    tidligereTilsynskvitteringerSelectors.getTidligereTilsynskvitteringerByTilsynsobjekt(
      tilsynsobjektId
    )
  )

  const error = useTypedSelector(
    tidligereTilsynskvitteringerSelectors.getErrorByTilsynsobjekt(
      tilsynsobjektId
    )
  )

  const fetchTilsynskvitteringer = useCallback(() => {
    if (!tilsynskvitteringer) {
      dispatch(
        tidligereTilsynskvitteringerActions.fetchTidligereTilsynskvitteringer([
          tilsynsobjektId,
        ])
      )
    }
  }, [tilsynskvitteringer, dispatch, tilsynsobjektId])

  useEffect(fetchTilsynskvitteringer, [fetchTilsynskvitteringer])

  return {
    tilsynskvitteringer,
    error,
    fetchTilsynskvitteringer,
  }
}
