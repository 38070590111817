import { Observable } from 'rxjs'
import { INotification } from '../../store/types'
import { APPLIKASJONER } from '../../store/constants'
import { commonApi, getToken } from '../../../../api'
import { createQueryStringFromObject } from '../../../../common/createQueryStringFromObject'

const NOTIFICATION_API_URI = 'notification'

export const notificationApi = (accessTokenPath, rootUrl) => ({
  getNotifications:
    (state, applicationNames: APPLIKASJONER[], eftanummer: string[]) =>
    (
      obs$
    ): Observable<{ _embedded: { notificationList: INotification[] } }> => {
      const URL = `${rootUrl}/${NOTIFICATION_API_URI}${createQueryStringFromObject(
        '?'
      )({
        app: applicationNames,
        eftanummer: eftanummer,
      })}`
      return obs$.pipe(getToken(), commonApi.get(URL, state))
    },

  readNotifications: (id: string, state) => (obs$) =>
    obs$.pipe(
      getToken(),
      commonApi.post(
        `${rootUrl}/${NOTIFICATION_API_URI}/seen/${id}`,
        state,
        null
      )
    ),

  deleteNotification: (id: string, state) => (obs$) =>
    obs$.pipe(
      getToken(),
      commonApi.delete(`${rootUrl}/${NOTIFICATION_API_URI}/${id}`, state)
    ),

  postNotification: (notification: any, state) => (obs$) =>
    obs$.pipe(
      getToken(),
      commonApi.post(`${rootUrl}/${NOTIFICATION_API_URI}`, state, notification)
    ),
})
