import { DeleteModalLarge } from '../delete-modal-large';
import { Column, Text } from '@mattilsynet/mt-ui';
import React from 'react';
import { ITilsynsobjekt } from '../../features/tilsynsobjekter/types';
interface ITilsynsobjektDeleteModal {
  isDeleteModalOpen: boolean;
  onDelete: () => void;
  tilsynsobjekt: ITilsynsobjekt;
  onCancel: () => void;
}
export const TilsynsobjektDeleteModal = ({
  isDeleteModalOpen,
  onDelete,
  tilsynsobjekt,
  onCancel
}: ITilsynsobjektDeleteModal) => <DeleteModalLarge isOpen={isDeleteModalOpen} onCancel={onCancel} onDelete={onDelete} title={'Er du sikker på at du vil fjerne tilsynsobjektet?'} description={<Column spacing={2}>
        <Text>{`Du velger bort tilsynsobjektet med aktivitet ${tilsynsobjekt.aktivitetsBeskrivelse},
                som har tilknyttede kontrollpunkter. Kontrollpunktene vil forsvinne -
                ønsker du å fjerne tilsynsobjektet fra tilsynskvitteringen?`}</Text>
      </Column>} confirmText={'Ja, fjern tilsynsobjektet'} cancelText={'Avbryt'} data-sentry-element="DeleteModalLarge" data-sentry-component="TilsynsobjektDeleteModal" data-sentry-source-file="index.tsx" />;