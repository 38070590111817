import React from 'react';
import { ArrowIcon, Row, RowItem, Text } from '@mattilsynet/mt-ui';
const buttonStyle = {
  width: '100%',
  cursor: 'pointer'
};
export const UpdateNotification = () => {
  const onClickUpdate = () => window.location.reload();
  return <button style={buttonStyle} onClick={onClickUpdate} data-sentry-component="UpdateNotification" data-sentry-source-file="index.tsx">
      <Row backgroundColor="var(--mt-sommerdag)" align="center" justify="center" spacing={2} padding={2} data-sentry-element="Row" data-sentry-source-file="index.tsx">
        <RowItem data-sentry-element="RowItem" data-sentry-source-file="index.tsx">
          <Text data-sentry-element="Text" data-sentry-source-file="index.tsx">Trykk for å oppdatere til siste versjon</Text>
        </RowItem>
        <RowItem display="flex" data-sentry-element="RowItem" data-sentry-source-file="index.tsx">
          <ArrowIcon size="small" rotateDirection="DOWN" data-sentry-element="ArrowIcon" data-sentry-source-file="index.tsx" />
        </RowItem>
      </Row>
    </button>;
};