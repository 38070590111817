import React, { useCallback, useState } from 'react';
import { Button, ErrorBox, LoadingSpinner, Table, TableCell, TableRow, Text, TextLink } from '@mattilsynet/mt-ui';
import { useTidligereTilsynskvitteringer } from './hooks';
import dayjs from 'dayjs';
import './style.css';
import { Sammenstilling } from '@mattilsynet/tk-sammenstilling';
import { queryApi } from '../../../../api';
import { CopyToClipboardButton } from '../../../../components/copy-to-clipboard';
import { copySaknummer, sammenstillingUOToast, useSingleToast } from '../../../../common/toast';
const fetchApi = {
  get: async <T,>(url: string) => (await queryApi.get<T>(url)).data
};
interface ITidligereTilsynskvitteringerProps {
  tilsynsobjektId: string;
}
export const TidligereTilsynskvitteringer = ({
  tilsynsobjektId
}: ITidligereTilsynskvitteringerProps) => {
  const {
    tilsynskvitteringer,
    error,
    fetchTilsynskvitteringer
  } = useTidligereTilsynskvitteringer(tilsynsobjektId);
  const singleToast = useSingleToast();
  const [showSammenstilling, setShowSammenstilling] = useState(false);
  const [sammenstillingId, setSammenstillingId] = useState<number>(0);
  const onCopySaksnummer = useCallback(() => singleToast.showToast(copySaknummer()), [singleToast]);
  const headers = ['Tilsynsdato', 'Navn på innsender', 'Saksnummer', 'Kontrollpunkter', 'Bilder', ' '].map((element: string) => ({
    display: element
  }));
  if (error) {
    return <ErrorBox errorActionText="Last innhold på nytt" errorAction={fetchTilsynskvitteringer} errorText="Kunne ikke hente tidligere tilsynskvitteringer" />;
  }
  if (!tilsynskvitteringer) {
    return <LoadingSpinner delay={0} title="Henter tidligere tilsynskvitteringer" />;
  }
  if (tilsynskvitteringer.length === 0) {
    return <Text>Fant ingen tidligere tilsynskvitteringer</Text>;
  }
  if (showSammenstilling) {
    return <div>
        <TextLink onClick={() => {
        setShowSammenstilling(false);
        setSammenstillingId(0);
      }} leftArrow>
          Tilbake til listen
        </TextLink>

        <Sammenstilling kvitteringId={sammenstillingId + ''} fetchApi={fetchApi} />
      </div>;
  }
  return <Table header={headers} data-sentry-element="Table" data-sentry-component="TidligereTilsynskvitteringer" data-sentry-source-file="index.tsx">
      {(tilsynskvitteringer ?? []).map(({
      tilsynsdato,
      ansattNavn,
      noarksakAar,
      noarksakSekvensnummer,
      antallKontrollpunkter,
      antallBilder,
      tilsynskvitteringId,
      unntattOffentlighet
    }) => {
      const dato = dayjs(tilsynsdato).format('DD.MM.YYYY');
      const saksnummer = `${noarksakAar}/${noarksakSekvensnummer}`;
      const onClickSammenstilling = () => {
        if (unntattOffentlighet) {
          singleToast.showToast(sammenstillingUOToast());
          return;
        }
        setShowSammenstilling(true);
        setSammenstillingId(tilsynskvitteringId);
      };
      return <TableRow className="tidligere-tilsynskvittering-row" key={tilsynskvitteringId}>
              <TableCell>
                <Text textAlign="center">{dato}</Text>
              </TableCell>
              <TableCell>
                <Text textAlign="center">{ansattNavn}</Text>
              </TableCell>
              <TableCell>
                <Text textAlign="center">
                  {saksnummer}{' '}
                  <CopyToClipboardButton onCopied={onCopySaksnummer} value={saksnummer} />
                </Text>
              </TableCell>
              <TableCell>
                <Text textAlign="center">{antallKontrollpunkter}</Text>
              </TableCell>
              <TableCell>
                <Text textAlign="center">{antallBilder}</Text>
              </TableCell>
              <TableCell>
                <Button onClick={onClickSammenstilling} secondary>
                  Se sammenstilling
                </Button>
              </TableCell>
            </TableRow>;
    })}
    </Table>;
};