import React, { useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { WarningIcon, Row, RowItem, ThemeContext } from '@mattilsynet/mt-ui';
import { IThemeColors } from '@mattilsynet/mt-ui/dist/assets/colors';
interface IStyledTextWithValidationProps {
  bold?: boolean;
  theme: IThemeColors;
  hasError: boolean;
  withWhiteSpace?: boolean;
}
interface ITextWithValidationProps {
  value: string;
  hasError?: boolean;
  errorText: string;
  bold?: boolean;
  withWhiteSpace?: boolean;
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
}
const StyledTextWithValidation = styled.div<IStyledTextWithValidationProps>`
  &.TextWithValidation {
    color: ${({
  theme,
  hasError
}) => hasError ? theme.signalRed : theme.gray1};
    font-weight: ${props => props.bold ? 'bold' : 'normal'};
    overflow-wrap: anywhere;
  }

  &.TextWithValidation.withWhiteSpace {
    white-space: pre-wrap;
  }

  .warning-icon {
    padding: 0 0.5em 0 0;
    height: 1.2em;
  }
`;
export const TextWithValidation = ({
  errorText,
  value,
  hasError = false,
  bold,
  as = 'p',
  withWhiteSpace
}: ITextWithValidationProps) => {
  const theme = useContext(ThemeContext);
  const TextWithValidationClasses = classNames('TextWithValidation', {
    errorText,
    value,
    hasError,
    bold,
    withWhiteSpace
  });
  return <StyledTextWithValidation className={TextWithValidationClasses} hasError={hasError} bold={bold} theme={theme} data-sentry-element="StyledTextWithValidation" data-sentry-component="TextWithValidation" data-sentry-source-file="index.tsx">
      {hasError ? <Row>
          <RowItem>
            {errorText && <WarningIcon className="warning-icon" color={theme.signalRed} />}
          </RowItem>
          {/* @ts-ignore */}
          <Row as={as}>{errorText}</Row>
        </Row> :
    // @ts-ignore
    <Row as={as}>{value}</Row>}
    </StyledTextWithValidation>;
};