import React, { useContext } from 'react';
import { ErrorBox, Grid, GridItem, Row, ThemeContext } from '@mattilsynet/mt-ui';
interface IPageErrorProps {
  errorAction: () => void;
  errorActionText: string;
  errorText: string;
}
export const PageError = ({
  errorAction,
  errorActionText,
  errorText
}: IPageErrorProps) => {
  const theme = useContext(ThemeContext);
  return <Grid data-sentry-element="Grid" data-sentry-component="PageError" data-sentry-source-file="index.tsx">
      <GridItem backgroundColor={theme.white} data-sentry-element="GridItem" data-sentry-source-file="index.tsx">
        <Row minHeight="91vh" center data-sentry-element="Row" data-sentry-source-file="index.tsx">
          <ErrorBox errorText={errorText} errorAction={errorAction} errorActionText={errorActionText} data-sentry-element="ErrorBox" data-sentry-source-file="index.tsx" />
        </Row>
      </GridItem>
    </Grid>;
};