import * as R from 'ramda'
import { IRestriksjonerActions, IRestriksjonerState } from './types'
import { addLoadingStatusToList } from '../../proveanalysesvar/store/helpers'
import { ROOT_STORE_NAME } from '../../../store'
import { IStoreState } from '../../../../../reducers/types'
import { commonRedux } from '../../../../../common/redux'

export const STORE_NAME = '@tilsyn/restriksjoner'

export const restriksjonerActionsTypes = {
  FETCH_LIST_BY_IDS: `${STORE_NAME}/FETCH_LIST_BY_IDS` as const,
  FETCH_LIST_BY_IDS_OK: `${STORE_NAME}/FETCH_LIST_BY_IDS_OK` as const,
  FETCH_LIST_BY_IDS_FAIL: `${STORE_NAME}/FETCH_LIST_BY_IDS_FAIL` as const,
}

export const restriksjonerActions = {
  fetchListByIds: (ids: string[]) => ({
    type: restriksjonerActionsTypes.FETCH_LIST_BY_IDS,
    ids,
  }),

  fetchListByIdsOk: (data) => ({
    type: restriksjonerActionsTypes.FETCH_LIST_BY_IDS_OK,
    data,
  }),

  fetchListByIdsFail: (ids: string[], error: any) => ({
    type: restriksjonerActionsTypes.FETCH_LIST_BY_IDS_FAIL,
    ids,
    error,
  }),
}

// selectors
export const restriksjonerSelectors = {
  getById: (id: string) => (state: IStoreState) =>
    state[ROOT_STORE_NAME][STORE_NAME].data[id],
}

const customReducer = commonRedux.createReducer<
  IRestriksjonerState,
  IRestriksjonerActions
>(commonRedux.initialListState, {
  [restriksjonerActionsTypes.FETCH_LIST_BY_IDS]: (state, { ids }) =>
    R.reduce(
      (acc, id) => addLoadingStatusToList(id, true, undefined, acc),
      state,
      ids
    ),

  [restriksjonerActionsTypes.FETCH_LIST_BY_IDS_OK]: (state, { data }) =>
    R.reduce(
      (acc, newData) =>
        commonRedux.addDataToList('tilsynsobjektId', newData, acc),
      state,
      data
    ),

  [restriksjonerActionsTypes.FETCH_LIST_BY_IDS_FAIL]: (state, { ids, error }) =>
    R.reduce(
      (acc, id) => addLoadingStatusToList(id, false, error, acc),
      state,
      ids
    ),
})
export const restriksjonerReducer = {
  [STORE_NAME]: customReducer,
}
