import * as R from 'ramda'
import { initialState } from './initialState'
import { uiActions, uiActionTypes } from '../actions'

// Types
import { IUiState } from '../types'
import { IConfigInterface, IOidcConfig } from '../../../config.d'

// lenses
import { reducerLenses } from '../selectors'
import { createReducer } from '../../../common/redux/create-reducer'
import { IHandlers } from '../../../common/redux/types'

export const STORE_NAME = 'ui'

const uiReducer = {
  [STORE_NAME]: createReducer(initialState, {
    [uiActionTypes.ACCESS_DENIED]: (state: IUiState): IUiState =>
      R.set(
        reducerLenses.initializationStatus(),
        {
          loading: false,
          error: null,
          loaded: true,
        },
        state
      ),

    [uiActionTypes.CONFIG_LOADED]: (
      state: IUiState,
      { payload }: { payload: IConfigInterface }
    ): IUiState => R.mergeRight(state, { ...payload }),

    [uiActionTypes.SET_OIDC_CONFIG]: (
      state: IUiState,
      { oidcConfig }: { oidcConfig: IOidcConfig }
    ): IUiState => R.mergeRight(state, { oidcConfig }),

    [uiActionTypes.INITIALIZE]: (state: IUiState): IUiState =>
      R.set(
        reducerLenses.initializationStatus(),
        {
          loading: true,
          error: null,
          loaded: false,
        },
        state
      ),

    [uiActionTypes.INITIALIZE_SUCCESS]: (state: IUiState): IUiState =>
      R.set(
        reducerLenses.initializationStatus(),
        {
          loading: false,
          error: null,
          loaded: true,
        },
        state
      ),

    [uiActionTypes.INITIALIZE_FAILED]: (state: IUiState, { error }): IUiState =>
      R.set(
        reducerLenses.initializationStatus(),
        {
          loading: false,
          error: error,
          loaded: false,
        },
        state
      ),

    [uiActionTypes.ONLINE]: (state: IUiState): IUiState =>
      R.mergeLeft(
        {
          offline: false,
        },
        state
      ),

    [uiActionTypes.OFFLINE]: (state: IUiState): IUiState =>
      R.mergeLeft(
        {
          offline: true,
        },
        state
      ),

    [uiActionTypes.SET_IMAGE_NAVIGATION_MODAL]: (
      state: IUiState,
      {
        isOpen,
        imageId,
        bildeIds,
      }: ReturnType<typeof uiActions.setImageNavigationModal>
    ): IUiState => ({
      ...state,
      openImageNavigationModal: {
        isOpen,
        imageId,
        bildeIds,
      },
    }),
    [uiActionTypes.SET_IMAGE_NAVIGATION_IMAGE]: (
      state: IUiState,
      { imageId }
    ): IUiState => ({
      ...state,
      openImageNavigationModal: {
        ...state.openImageNavigationModal,
        imageId,
      },
    }),
    [uiActionTypes.SET_IMAGE_FULLVERSJON_OPEN]: (
      state: IUiState,
      { isOpen }
    ): IUiState => ({
      ...state,
      openImageNavigationModal: {
        ...state.openImageNavigationModal,
        isFullversjonOpen: isOpen,
      },
    }),

    [uiActionTypes.SET_USER_DEVICE]: (
      state: IUiState,
      { isDesktop }: { isDesktop: boolean }
    ): IUiState => R.mergeRight(state, { isDesktop }),

    [uiActionTypes.UPDATE_INSTALLED]: (state: IUiState): IUiState =>
      R.mergeRight(state, { hasUpdated: true }),
  } as unknown as IHandlers<
    IUiState,
    ReturnType<(typeof uiActions)[keyof typeof uiActions]>
  >),
}

export default uiReducer
