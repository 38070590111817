import { commonRedux } from '../../../../common/redux'
import { ITilsynsobjektActions, ITilsynsobjektState } from './types'

export const STORE_NAME = '@tilsyn/tilsynsobjekt'

const { fetchWithIndexHandlers, fetchActions, fetchActionTypes } =
  commonRedux.fetchWithIndexHelpers(STORE_NAME, 'idstring')

export const tilsynsobjektActionsTypes = {
  ...fetchActionTypes,

  FETCH_LIST_BY_IDS: `${STORE_NAME}/FETCH_LIST_BY_IDS` as const,
  FETCH_LIST_BY_IDS_OK: `${STORE_NAME}/FETCH_LIST_BY_IDS_OK` as const,
  FETCH_LIST_BY_IDS_FAIL: `${STORE_NAME}/FETCH_LIST_BY_IDS_FAIL` as const,
}

export const tilsynsobjektActions = {
  ...fetchActions,
  fetchListByIds: (ids: string[]) => ({
    type: tilsynsobjektActionsTypes.FETCH_LIST_BY_IDS,
    ids,
  }),

  fetchListByIdsOk: (data) => ({
    type: tilsynsobjektActionsTypes.FETCH_LIST_BY_IDS_OK,
    data,
  }),

  fetchListByIdsFail: (ids: string[], error: any) => ({
    type: tilsynsobjektActionsTypes.FETCH_LIST_BY_IDS_FAIL,
    ids,
    error,
  }),
}

const customReducer = commonRedux.createReducer<
  ITilsynsobjektState,
  ITilsynsobjektActions
>(commonRedux.initialListState, {
  ...fetchWithIndexHandlers,
})

export const tilsynsobjektReducer = {
  [STORE_NAME]: customReducer,
}
