import { Modal } from '@mattilsynet/mt-ui';
import React, { ComponentProps } from 'react';
interface IModalWrapperProps extends ComponentProps<typeof Modal> {}
export const ModalWrapper = ({
  onCancel,
  isOpen,
  focusGroupElementIds,
  children,
  ...rest
}: IModalWrapperProps) => {
  return <Modal isOpen={isOpen} onCancel={onCancel} focusGroupElementIds={focusGroupElementIds ?? ['common-toastarea']} {...rest} data-sentry-element="Modal" data-sentry-component="ModalWrapper" data-sentry-source-file="index.tsx">
      {children}
    </Modal>;
};