import { Column, Row, Text, RowItem, Button, PhotoLibrary } from '@mattilsynet/mt-ui';
import React, { useCallback } from 'react';
import { IKvitteringToGet } from '../../modals/hent-fra-kollega-modal';
interface IHentFraKollegaKvitteringDataListItemProps {
  orgNr: string;
  virksomhetsNavn: string;
  kvitteringId: string;
  theme: string;
  onSelect: (kvittering: IKvitteringToGet) => void;
  isSelected: boolean;
  harBilder: boolean;
}
export const HentFraKollegaKvitteringDataListItem = ({
  orgNr,
  virksomhetsNavn,
  kvitteringId,
  theme,
  onSelect,
  isSelected,
  harBilder
}: IHentFraKollegaKvitteringDataListItemProps) => {
  const textWeight = isSelected ? 'bold' : 'regular';
  const orgNrEllerPrivatperson = orgNr ? `Org.nr: ${orgNr}` : `Privatperson`;
  const onSelectKvittering = useCallback(() => onSelect({
    id: kvitteringId,
    name: virksomhetsNavn,
    harBilder: harBilder
  }), [kvitteringId, virksomhetsNavn, harBilder, onSelect]);
  return <Column as={'li'} data-sentry-element="Column" data-sentry-component="HentFraKollegaKvitteringDataListItem" data-sentry-source-file="index.tsx">
      <Row padding={[2, 2.5]} backgroundColor={theme} justify={'space-between'} align="center" data-sentry-element="Row" data-sentry-source-file="index.tsx">
        <RowItem alignSelf="flex-start" data-sentry-element="RowItem" data-sentry-source-file="index.tsx">
          <Column spacing={1} fluid data-sentry-element="Column" data-sentry-source-file="index.tsx">
            <Text weight={textWeight} data-sentry-element="Text" data-sentry-source-file="index.tsx">{virksomhetsNavn}</Text>
            <Text weight={textWeight} data-sentry-element="Text" data-sentry-source-file="index.tsx">{orgNrEllerPrivatperson}</Text>
          </Column>
        </RowItem>
        <RowItem data-sentry-element="RowItem" data-sentry-source-file="index.tsx">
          <Row data-sentry-element="Row" data-sentry-source-file="index.tsx">
            {harBilder && <RowItem>
                <PhotoLibrary size="large" />
              </RowItem>}
            <RowItem margin={[0, 0, 0, 2]} data-sentry-element="RowItem" data-sentry-source-file="index.tsx">
              <Button secondary={!isSelected} width="8em" small onClick={onSelectKvittering} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                {isSelected ? 'Valgt' : 'Velg'}
              </Button>
            </RowItem>
          </Row>
        </RowItem>
      </Row>
    </Column>;
};