import { IVirkomhetActions, IVirkomsomhetsState, IVirksomhet } from './types'
import { commonRedux } from '../../../common/redux'

const { fetchActionTypes, fetchWithIndexHandlers, fetchActions } =
  commonRedux.fetchWithIndexHelpers<IVirksomhet, '@brreg'>(
    '@brreg',
    'organisasjonsnummer'
  )

export const actionsTypes = fetchActionTypes
export const actions = fetchActions

export const reducer = {
  '@brreg': commonRedux.createReducer<IVirkomsomhetsState, IVirkomhetActions>(
    commonRedux.initialListState,
    fetchWithIndexHandlers
  ),
}

// Selectors
export const getByOrgNr =
  (id: string) =>
  (store): IVirkomsomhetsState['data'][typeof id] =>
    store?.['@brreg']?.data?.[id]
