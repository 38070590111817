import { Button, Column, Text, TextArea } from '@mattilsynet/mt-ui';
import dayjs from 'dayjs';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IMAGE_LOCATION_MAX_LENGTH } from '../../constants';
import { IImage } from '../../features/bilder/types';
import { userActions } from '../../ducks/user/actions';
import { useUpdateBildeLokasjon } from '../../features/bilder';
import { useKvitteringId } from '../../common/kvittering-context';
import { LabelWithInfo } from '../label-with-info';
interface IImageLocationEditor {
  bildeMetadata: IImage;
  onFocus?: () => void;
  onBlur?: () => void;
}
const ImageLocationEditor = ({
  bildeMetadata,
  onFocus,
  onBlur
}: IImageLocationEditor) => {
  const {
    id,
    locationLatitude: lat,
    locationLongitude: lon,
    locationCopiedFromId,
    locationDescription,
    accuracy
  } = bildeMetadata;
  const dispatch = useDispatch();
  const hasCoordinates = !!lat && !!lon;
  const isCopied = !!locationCopiedFromId;
  const hasOriginalCoordinates = !isCopied && hasCoordinates;
  let coordinatesText = `GPS [${lat?.toPrecision(8)}, ${lon?.toPrecision(8)}]`;
  if (accuracy) {
    const accuracyText = ` nøyaktighet ~ ${new Intl.NumberFormat('no-NO', {
      maximumFractionDigits: 0,
      unit: 'meter',
      style: 'unit'
    }).format(accuracy)}`;
    coordinatesText += accuracyText;
  }
  if (isCopied) {
    coordinatesText += ' (hentet fra annet bilde på tilsynet)';
  }
  const [sted, setSted] = useState(hasCoordinates ? coordinatesText : locationDescription);
  const [hasSted, setHasSted] = useState(!!sted);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const kvitteringId = useKvitteringId();
  const {
    mutate: updateLokasjon
  } = useUpdateBildeLokasjon(kvitteringId);
  const infoTextMaxChars = `${sted?.length ?? 0} av ${IMAGE_LOCATION_MAX_LENGTH} tegn`;
  const updateSted = useCallback((location: string) => {
    if (id) {
      setSted(location);
      updateLokasjon({
        bildeMetadata: {
          ...bildeMetadata,
          locationCopiedFromId: '',
          locationLatitude: 0,
          locationLongitude: 0,
          locationDescription: location,
          locationDescriptionUpdateTime: dayjs().toISOString()
        }
      });
    }
  }, [bildeMetadata, id, updateLokasjon]);
  const onChangeSted = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => updateSted(e.target.value), [updateSted]);
  const onToggleAddSted = () => {
    setSted('');
    updateSted('');
    setHasSted(hasSted => !hasSted);
  };
  const onToggleEdit = () => {
    setSted('');
    updateSted('');
    setIsReadOnly(isReadOnly => !isReadOnly);
  };
  const onBlurSted = useCallback(() => {
    dispatch(userActions.setLastLocation(sted ?? ''));
    onBlur?.();
  }, [dispatch, sted, onBlur]);
  return <Column padding={[1, 0, 1, 0]} data-sentry-element="Column" data-sentry-component="ImageLocationEditor" data-sentry-source-file="index.tsx">
      {hasOriginalCoordinates ? <>
          <Text as="label" weight="bold">
            Sted bildet er tatt
          </Text>
          <Text>{coordinatesText}</Text>
        </> : <>
          <LabelWithInfo label="Sted bildet er tatt" as="h6">
            <Column spacing={1} padding={1}>
              <Text>
                {`Det er kun nødvendig å vurdere og ${isCopied ? 'endre' : 'legge til'} sted når vi har
                avdekket alvorlige regelverksbrudd.`}
              </Text>
              <Text weight="semibold">
                Presis stedsangivelse er spesielt viktig i en ev. straffesak.
              </Text>
              <Text>
                Det du skriver blir lagret som interne metadata og er ikke
                synlig for mottaker.
              </Text>
            </Column>
          </LabelWithInfo>
          {hasSted && <div onFocus={onFocus} onBlur={onBlurSted} className="image-location-editor__input">
              <TextArea onChange={onChangeSted} value={sted ?? ''} infoText={infoTextMaxChars} placeholder="Beskrivelse av hvor bildet er tatt..." defaultRows={1} disabled={isCopied && !isReadOnly} autoGrow autoFocus />
            </div>}

          {!isCopied || isReadOnly ? <Button onClick={onToggleAddSted} secondary width="fit-content" small>
              {hasSted ? 'Fjern sted' : 'Legg til sted'}
            </Button> : <Button onClick={onToggleEdit} secondary width="fit-content" small>
              Endre sted
            </Button>}
        </>}
    </Column>;
};
export { ImageLocationEditor };