import {
  INotification,
  INotificationActions,
  INotificationState,
} from './types'
import { commonRedux } from '../../../common/redux'
import * as R from 'ramda'
import { APPLIKASJONER } from './constants'

export const actionTypes = {
  FETCH_OK: '@notification/FETCH_OK' as const,
  ADD: '@notification/ADD' as const,
  READ: '@notification/READ' as const,
  READ_SUCCESS: '@notification/READ _SUCCESS' as const,
  READ_FAIL: '@notification/READ _FAIL' as const,
  READ_SYNC_FAIL: '@notification/READ_SYNC_FAIL' as const,
  DELETE: '@notification/DELETE' as const,
  DELETE_SUCCESS: '@notification/DELETE_SUCCESS' as const,
  DELETE_FAIL: '@notification/DELETE_FAIL' as const,
  DELETE_SYNC_FAIL: '@notification/DELETE_SYNC_FAIL' as const,
  START_POLLING: '@notification/START_POLLING' as const,
  STOP_POLLING: '@notification/STOP_POLLING' as const,
  SYNC: '@notification/SYNC' as const,
  POST: '@notification/POST' as const,
  POST_SUCCESS: '@notification/POST_SUCCESS' as const,
  POST_FAIL: '@notification/POST_FAIL' as const,
}

export const actions = {
  fetchOk: (notificationList: Partial<INotification>[]) => ({
    type: actionTypes.FETCH_OK,
    notificationList,
  }),

  add: (notificationList: Partial<INotification>[]) => ({
    type: actionTypes.ADD,
    notificationList,
  }),

  read: (notification: Partial<INotification>) => ({
    type: actionTypes.READ,
    notification,
  }),

  readSyncFail: (id?: string) => ({
    type: actionTypes.READ_SYNC_FAIL,
    id,
  }),

  delete: (notification: Partial<INotification>) => ({
    type: actionTypes.DELETE,
    notification,
  }),

  deleteSuccess: (id: string) => ({
    type: actionTypes.DELETE_SUCCESS,
    id,
  }),

  deleteFail: (id: string) => ({
    type: actionTypes.DELETE_FAIL,
    id,
  }),

  deleteSyncFail: (id?: string) => ({
    type: actionTypes.DELETE_SYNC_FAIL,
    id,
  }),

  readFail: (id: string) => ({
    type: actionTypes.READ_FAIL,
    id,
  }),

  readSuccess: (id: string) => ({
    type: actionTypes.READ_SUCCESS,
    id,
  }),

  startPolling: (
    pollTime: number,
    applicationNames: APPLIKASJONER[],
    eftanummer?: string[]
  ) => ({
    type: actionTypes.START_POLLING,
    pollTime,
    applicationNames,
    eftanummer,
  }),

  stopPolling: () => ({
    type: actionTypes.STOP_POLLING,
  }),

  sync: () => ({
    type: actionTypes.SYNC,
  }),

  post: (notification: Partial<INotification>) => ({
    type: actionTypes.POST,
    notification,
  }),

  postSuccess: (notification: Partial<INotification>) => ({
    type: actionTypes.POST_SUCCESS,
    notification,
  }),

  postFail: (error: string) => ({
    type: actionTypes.POST_FAIL,
    error,
  }),
}

const initialState: INotificationState = []

const customReducer = commonRedux.createReducer<
  INotificationState,
  INotificationActions
>(initialState, {
  [actionTypes.FETCH_OK]: (state, { notificationList }): INotificationState =>
    R.uniqBy(R.prop('id'), [
      ...state.filter((notification) => notification.local),
      ...notificationList,
    ]),

  [actionTypes.ADD]: (state, { notificationList }): INotificationState =>
    R.uniqBy(R.prop('id'), [...notificationList, ...state]),

  [actionTypes.READ]: (state, { notification }): INotificationState => {
    const index = R.findIndex(R.propEq(notification.id, 'id'))(state)
    if (index === -1) return state
    return R.over(R.lensIndex(index), R.mergeLeft({ seen: new Date() }), state)
  },

  [actionTypes.DELETE]: (state, { notification }): INotificationState =>
    R.reject(R.propEq(notification.id, 'id'), state),

  [actionTypes.POST_SUCCESS]: (state, { notification }): INotificationState =>
    R.uniqBy(R.prop('id'), [notification, ...state]),
})

export const reducer = {
  '@notification': customReducer,
}

export const selectors = {
  getSorted: (store): INotification[] =>
    R.pipe(
      R.prop('@notification'),
      R.defaultTo([]),
      R.sortBy(
        (notification: INotification) =>
          notification.publishedTime || notification.created
      ),
      R.reverse
    )(store),

  getUnreadNumber: (store): number =>
    R.pipe(
      R.prop('@notification'),
      R.defaultTo([]),
      R.reject((notification: INotification) => !!notification.seen),
      R.length
    )(store),
}
