import React, { ReactNode, useCallback, useEffect, useState, useMemo } from 'react';
import { Column, ErrorBox, LoadingSpinner, Pagination, Row, SearchInput, SelectList, Text, ToggleSwitch } from '@mattilsynet/mt-ui';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useDebounce, usePaginatedList, useTypedSelector } from '../../common/custom-hooks';
import { ansatteActions, ansatteSelectors } from '../../ducks/ansatte';
import { ModalWrapper } from '../../components/modal-wrapper';
import { kvitteringActions } from '../../ducks/kvittering/actions';
import { IKvitteringData } from '../../ducks/kvittering/types';
import { dangerToast } from '../../common/toast';
import { IStoreState } from '../../reducers/types';
import './style.css';
import { ModalButtonsContainer } from '../../components/modal-buttons-container';
import { modalContentPaddingLarge } from '../../components/modal-buttons-container/constants';
import { ModalButton } from '../../components/modal-button';
const PAGE_SIZE = 50;
interface IDataListItem {
  value: string;
  label: ReactNode;
}
const StyledRow = styled(Row)`
  & {
    width: 100%;
  }
  & .Text.ansatt-navn {
    min-width: 250px;
  }
`;
export const FordelTilModal = ({
  onCancel,
  isOpen,
  kvittering
}: {
  onCancel: () => void;
  isOpen: boolean;
  kvittering: IKvitteringData;
}) => {
  const dispatch = useDispatch();
  const isTinyScreen = useMediaQuery({
    query: '(max-width: 767px)'
  });
  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchInput = useDebounce(searchInput, 200);
  const [isAlleKolleger, setIsAlleKolleger] = useState(false);
  const [selectedAnsattBrukernavn, setSelectedAnsattBrukernavn] = useState<string | undefined>(undefined);
  const isDesktop = useTypedSelector((state: IStoreState) => state.ui.isDesktop);
  const loadingStatus = useTypedSelector(ansatteSelectors.getLoadingStatus);
  const ansatte = useTypedSelector(ansatteSelectors.getAnsatte);
  const searchedAnsatte = ansatte.filter(ansatt => String(ansatt.navn).toLowerCase().includes(debouncedSearchInput.toLowerCase()));
  const [currentPage, totalPages, pageNumber, setPage] = usePaginatedList(searchedAnsatte, PAGE_SIZE);
  const dataList = useMemo<IDataListItem[]>(() => {
    const fordeltTil = selectedAnsattBrukernavn ?? '';
    return currentPage.map(ansatt => ({
      value: ansatt.brukernavn,
      label: <StyledRow justify="space-between" key={ansatt.brukernavn} smColumn>
          <Text className={classNames({
          'ansatt-navn': !isTinyScreen
        })} margin={isTinyScreen ? [0, 1, 1, 0] : [0, 1, 0, 0]} weight={fordeltTil === ansatt.brukernavn ? 'bold' : 'regular'}>
            {ansatt.navn}
          </Text>
          <Text weight={fordeltTil === ansatt.brukernavn ? 'bold' : 'regular'} margin={isTinyScreen ? [0, 1, 0, 0] : [0, 1, 0, 0]}>
            {ansatt.kontorNavn}
          </Text>
        </StyledRow>
    }));
  }, [selectedAnsattBrukernavn, currentPage, isTinyScreen]);
  const fetchData = useCallback(() => {
    dispatch(isAlleKolleger ? ansatteActions.fetchAllAnsatte() : ansatteActions.fetchAnsatte());
  }, [dispatch, isAlleKolleger]);
  const onChangeSearchInput = useCallback(event => setSearchInput(event.target.value), []);
  const onChangePage = useCallback((pageNumber: number) => setPage(pageNumber), [setPage]);
  const handleFordelMelding = useCallback(() => {
    const selectedAnsatt = searchedAnsatte.find(ansatt => ansatt.brukernavn === selectedAnsattBrukernavn);
    if (!selectedAnsatt) {
      return dispatch(dangerToast('Du må velge en kollega å fordele tilsynskvitteringen til'));
    }
    dispatch(kvitteringActions.fordelTilKollega(kvittering, selectedAnsatt));
    onCancel();
  }, [searchedAnsatte, selectedAnsattBrukernavn, dispatch, onCancel, kvittering]);
  const onToggleAlleKolleger = useCallback(() => setIsAlleKolleger(!isAlleKolleger), [isAlleKolleger]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const renderContent = () => {
    if (loadingStatus?.loading && !loadingStatus?.loaded) {
      return <LoadingSpinner title="" />;
    }
    if (loadingStatus?.error) {
      return <ErrorBox errorText="Kunne ikke hente ansatte" errorAction={fetchData} errorActionText="Prøv på nytt" />;
    }
    const paginationPadding = isDesktop ? [2, 0, 0, 0] : [2, 2, 0, 2];
    const contentPadding = isDesktop ? [1, 3] : modalContentPaddingLarge;
    return <Column padding={contentPadding} spacing={2} data-sentry-element="Column" data-sentry-component="renderContent" data-sentry-source-file="index.tsx">
        <Column padding={[1, 3]} spacing={2} data-sentry-element="Column" data-sentry-source-file="index.tsx">
          <Text data-sentry-element="Text" data-sentry-source-file="index.tsx">
            Når du fordeler tilsynskvitteringen til en kollega vil den havne i
            oversikten til den du fordeler til.
          </Text>
          <SearchInput onChange={onChangeSearchInput} placeholder="Søk etter kollega" value={searchInput} data-sentry-element="SearchInput" data-sentry-source-file="index.tsx" />
          <Column margin={[0, 0, 1, 0]} data-sentry-element="Column" data-sentry-source-file="index.tsx">
            <Row data-sentry-element="Row" data-sentry-source-file="index.tsx">
              <Column data-sentry-element="Column" data-sentry-source-file="index.tsx">
                <ToggleSwitch onClick={onToggleAlleKolleger} checked={isAlleKolleger} labelAfter={true} data-sentry-element="ToggleSwitch" data-sentry-source-file="index.tsx">
                  Vis kolleger utenfor din avdeling
                </ToggleSwitch>
              </Column>
            </Row>
          </Column>
        </Column>
        <SelectList dataList={dataList} onClick={setSelectedAnsattBrukernavn} selected={[String(selectedAnsattBrukernavn)]} data-sentry-element="SelectList" data-sentry-source-file="index.tsx" />

        {searchedAnsatte.length > PAGE_SIZE && <Column padding={paginationPadding}>
            <Pagination page={{
          number: pageNumber,
          totalPages: totalPages
        }} onPaginationClick={onChangePage} />
          </Column>}
      </Column>;
  };
  const renderTitle = () => {
    if (loadingStatus?.error) return 'En feil oppstod';
    if (loadingStatus?.loading) return 'Laster ansatte';
    return `Fordel tilsynskvittering til kollega`;
  };
  return <ModalWrapper title={renderTitle()} onCancel={onCancel} isOpen={isOpen} paddingHorizontal={0} className="fordelTilKollegaModal" fullscreenMobile data-sentry-element="ModalWrapper" data-sentry-component="FordelTilModal" data-sentry-source-file="index.tsx">
      {renderContent()}
      <ModalButtonsContainer data-sentry-element="ModalButtonsContainer" data-sentry-source-file="index.tsx">
        <ModalButton secondary onClick={onCancel} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">
          Lukk
        </ModalButton>
        <ModalButton onClick={handleFordelMelding} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">
          Fordel tilsynskvittering
        </ModalButton>
      </ModalButtonsContainer>
    </ModalWrapper>;
};