import { IAnsatteActions, IAnsatteState } from '../types'
import { commonRedux } from '../../../common/redux'
import { ansatteActionTypes } from '../index'

const initialState: IAnsatteState = {
  loading: false,
  loaded: false,
  error: null,
  ansatte: [],
  kontor: undefined,
}

export const ansatteReducer = commonRedux.createReducer<
  IAnsatteState,
  IAnsatteActions
>(initialState, {
  [ansatteActionTypes.FETCH_ANSATTE]: (state: IAnsatteState) => ({
    ...state,
    loading: true,
    error: null,
  }),

  [ansatteActionTypes.FETCH_ANSATTE_OK]: (
    state: IAnsatteState,
    {
      ansatte,
      kontor,
    }: {
      ansatte: IAnsatteState['ansatte']
      kontor: IAnsatteState['kontor']
    }
  ) => ({
    ...state,
    loading: false,
    loaded: true,
    ansatte,
    kontor,
  }),

  [ansatteActionTypes.FETCH_ANSATTE_FAIL]: (
    state: IAnsatteState,
    {
      error,
    }: {
      error: string
    }
  ) => ({ ...state, error, loading: false, loaded: true }),
})
