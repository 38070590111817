import React, { useEffect } from 'react';
import { Toast } from '@mattilsynet/mt-ui';
import { selectors, actions } from '../../store';
import { IToast, IStoreToast } from '../../store/types';
import { useSelector, useDispatch } from 'react-redux';
const setToastType = (toastType: IToast['type']): [boolean, boolean, boolean] => [toastType === 'SUCCESS', toastType === 'WARN', toastType === 'DANGER'];
const ActionToast = ({
  toast
}: {
  toast: IStoreToast;
}) => {
  const dispatch = useDispatch();
  const dismissToast = () => dispatch(actions.hideToast(toast.id));
  const actionFunc = () => {
    if (toast?.action) {
      dispatch(toast.action);
    }
  };
  useEffect(() => {
    if (toast.timeoutSeconds) {
      const timer = setTimeout(dismissToast, toast.timeoutSeconds * 1000);
      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [success, warning, danger] = setToastType(toast.type);
  return <Toast text={toast.text} dismissible={toast.dismissible} dismissFunc={dismissToast} warning={warning} danger={danger} success={success} actionFunc={toast.action && actionFunc} actionText={toast.actionText} data-sentry-element="Toast" data-sentry-component="ActionToast" data-sentry-source-file="index.tsx" />;
};
const ToastArea = () => {
  const toasts = useSelector(selectors.getToasts);
  return <div className="common__ToastArea" data-sentry-component="ToastArea" data-sentry-source-file="index.tsx">
      {toasts.map((toast: IStoreToast) => <ActionToast key={toast.id} toast={toast} />)}
    </div>;
};
export default ToastArea;