import {
  IProdusentMeldingsHistorikkActions,
  IProdusentMeldingsHistorikkState,
} from './types'
import { ROOT_STORE_NAME } from '../../../store'
import { IStoreState } from '../../../../../reducers/types'
import { commonRedux } from '../../../../../common/redux'

const STORE_NAME = '@tilsyn/meldingsHistorikk'

export const produsentMeldingsHistorikkActionTypes = {
  FETCH: `${STORE_NAME}/FETCH` as const,
  FETCH_OK: `${STORE_NAME}/FETCH_OK` as const,
  FETCH_FAIL: `${STORE_NAME}/FETCH_FAIL` as const,
  FETCH_FUNN_OK: `${STORE_NAME}/FETCH_FUNN_OK` as const,
  FETCH_FUNN_FAIL: `${STORE_NAME}/FETCH_FUNN_FAIL` as const,
  FETCH_TILSYNSKVITTERING_STATUS:
    `${STORE_NAME}/FETCH_TILSYNSKVITTERING_STATUS` as const,
  FETCH_TILSYNSKVITTERING_STATUS_OK:
    `${STORE_NAME}/FETCH_TILSYNSKVITTERING_STATUS_OK` as const,
  FETCH_TILSYNSKVITTERING_STATUS_FAIL:
    `${STORE_NAME}/FETCH_TILSYNSKVITTERING_STATUS_FAIL` as const,
  SET_OPEN_ROWS: `${STORE_NAME}/SET_OPEN_ROWS` as const,
  FETCH_ALLOWED_STATUSES: `${STORE_NAME}/FETCH_ALLOWED_STATUSES` as const,
  FETCH_ALLOWED_STATUSES_OK: `${STORE_NAME}/FETCH_ALLOWED_STATUSES_OK` as const,
  FETCH_ALLOWED_STATUSES_FAIL:
    `${STORE_NAME}/FETCH_ALLOWED_STATUSES_FAIL` as const,
  FETCH_ALLOWED_BEGRUNNELSER:
    `${STORE_NAME}/FETCH_ALLOWED_BEGRUNNELSER` as const,
  FETCH_ALLOWED_BEGRUNNELSER_OK:
    `${STORE_NAME}/FETCH_ALLOWED_BEGRUNNELSER_OK` as const,
  FETCH_ALLOWED_BEGRUNNELSER_FAIL:
    `${STORE_NAME}/FETCH_ALLOWED_BEGRUNNELSER_FAIL` as const,
}

export const produsentMeldingsHistorikkActions = {
  fetchMeldingsHistorikk: (tilsynsobjektId: string) => ({
    type: produsentMeldingsHistorikkActionTypes.FETCH,
    tilsynsobjektId,
  }),

  fetchMeldingsHistorikkOk: (
    tilsynsobjektId: string,
    meldingsHistorikk: IProdusentMeldingsHistorikkState['meldingsHistorikk'],
    virksomhet: IProdusentMeldingsHistorikkState['virksomhet']
  ) => ({
    type: produsentMeldingsHistorikkActionTypes.FETCH_OK,
    tilsynsobjektId,
    meldingsHistorikk,
    virksomhet,
  }),

  fetchMeldingsHistorikkFail: (err: string) => ({
    type: produsentMeldingsHistorikkActionTypes.FETCH_FAIL,
    err,
  }),

  fetchFunnOk: (
    meldingsHistorikk: IProdusentMeldingsHistorikkState['meldingsHistorikk']
  ) => ({
    type: produsentMeldingsHistorikkActionTypes.FETCH_FUNN_OK,
    meldingsHistorikk,
  }),

  fetchFunnFail: (err: string) => ({
    type: produsentMeldingsHistorikkActionTypes.FETCH_FUNN_FAIL,
    err,
  }),

  fetchTilsynskvitteringStatus: (
    meldingsHistorikk: IProdusentMeldingsHistorikkState['meldingsHistorikk']
  ) => ({
    type: produsentMeldingsHistorikkActionTypes.FETCH_TILSYNSKVITTERING_STATUS,
    meldingsHistorikk,
  }),
  fetchTilsynskvitteringStatusOk: (
    meldingsHistorikk: IProdusentMeldingsHistorikkState['meldingsHistorikk']
  ) => ({
    type: produsentMeldingsHistorikkActionTypes.FETCH_TILSYNSKVITTERING_STATUS_OK,
    meldingsHistorikk,
  }),

  fetchTilsynskvitteringStatusFail: (err: string) => ({
    type: produsentMeldingsHistorikkActionTypes.FETCH_TILSYNSKVITTERING_STATUS_FAIL,
    err,
  }),

  setOpenRows: (openRows: string[]) => ({
    type: produsentMeldingsHistorikkActionTypes.SET_OPEN_ROWS,
    openRows,
  }),

  fetchAllowedStatuses: () => ({
    type: produsentMeldingsHistorikkActionTypes.FETCH_ALLOWED_STATUSES,
  }),

  fetchAllowedStatusesOk: (
    allowedStatuses: IProdusentMeldingsHistorikkState['allowedStatuses']
  ) => ({
    type: produsentMeldingsHistorikkActionTypes.FETCH_ALLOWED_STATUSES_OK,
    allowedStatuses,
  }),

  fetchAllowedStatusesFail: (error: string) => ({
    type: produsentMeldingsHistorikkActionTypes.FETCH_ALLOWED_STATUSES_FAIL,
    error,
  }),

  fetchAllowedBegrunnelser: () => ({
    type: produsentMeldingsHistorikkActionTypes.FETCH_ALLOWED_BEGRUNNELSER,
  }),

  fetchAllowedBegrunnelserOk: (
    allowedStatusBegrunnelser: IProdusentMeldingsHistorikkState['allowedStatusBegrunnelser']
  ) => ({
    type: produsentMeldingsHistorikkActionTypes.FETCH_ALLOWED_BEGRUNNELSER_OK,
    allowedStatusBegrunnelser,
  }),

  fetchAllowedBegrunnelserFail: (error: string) => ({
    type: produsentMeldingsHistorikkActionTypes.FETCH_ALLOWED_BEGRUNNELSER_FAIL,
    error,
  }),
}

export const produsentMeldingsHistorikkSelectors = {
  getLoadingStatus: (
    state: IStoreState
  ): {
    loading: boolean
    loaded: boolean
    error: string | null
    funnLoaded: boolean
    tilsynskvitteringStatusLoaded: boolean
  } => ({
    loading: state[ROOT_STORE_NAME][STORE_NAME].loading,
    loaded: state[ROOT_STORE_NAME][STORE_NAME].loaded,
    error: state[ROOT_STORE_NAME][STORE_NAME].error,
    funnLoaded: state[ROOT_STORE_NAME][STORE_NAME].funnLoaded,
    tilsynskvitteringStatusLoaded:
      state[ROOT_STORE_NAME][STORE_NAME].tilsynskvitteringStatusLoaded,
  }),

  getMeldingsHistorikk: (
    state: IStoreState
  ): IProdusentMeldingsHistorikkState['meldingsHistorikk'] | undefined =>
    state[ROOT_STORE_NAME][STORE_NAME]?.meldingsHistorikk,

  getVirksomhet: (
    state: IStoreState
  ): IProdusentMeldingsHistorikkState['virksomhet'] | undefined =>
    state[ROOT_STORE_NAME][STORE_NAME]?.virksomhet,

  getOpenRows: (state: IStoreState): string[] =>
    state[ROOT_STORE_NAME][STORE_NAME].openRows,

  getAllowedStatuses: (
    state: IStoreState
  ): IProdusentMeldingsHistorikkState['allowedStatuses'] =>
    state[ROOT_STORE_NAME][STORE_NAME]?.allowedStatuses,

  getAllowedStatusBegrunnelser: (
    state: IStoreState
  ): IProdusentMeldingsHistorikkState['allowedStatusBegrunnelser'] =>
    state[ROOT_STORE_NAME][STORE_NAME]?.allowedStatusBegrunnelser,
}

const initialState: IProdusentMeldingsHistorikkState = {
  loading: false,
  loaded: false,
  funnLoaded: false,
  tilsynskvitteringStatusLoaded: false,
  error: null,
  meldingsHistorikk: undefined,
  openRows: [],
  virksomhet: undefined,
  allowedStatuses: undefined,
  allowedStatusBegrunnelser: undefined,
}

const customReducer = commonRedux.createReducer<
  IProdusentMeldingsHistorikkState,
  IProdusentMeldingsHistorikkActions
>(initialState, {
  [produsentMeldingsHistorikkActionTypes.FETCH]: (
    state: IProdusentMeldingsHistorikkState
  ) => ({
    ...state,
    loading: true,
  }),

  [produsentMeldingsHistorikkActionTypes.FETCH_OK]: (
    state: IProdusentMeldingsHistorikkState,
    {
      meldingsHistorikk,
      virksomhet,
    }: {
      meldingsHistorikk: IProdusentMeldingsHistorikkState['meldingsHistorikk']
      virksomhet: IProdusentMeldingsHistorikkState['virksomhet']
    }
  ) => ({
    ...initialState,
    loaded: true,
    funnLoaded: false,
    tilsynskvitteringStatusLoaded: false,
    meldingsHistorikk,
    openRows: state.openRows,
    virksomhet,
    allowedStatuses: state.allowedStatuses,
    allowedStatusBegrunnelser: state.allowedStatusBegrunnelser,
  }),

  [produsentMeldingsHistorikkActionTypes.FETCH_FAIL]: (
    state: IProdusentMeldingsHistorikkState,
    { err }: { err: string }
  ) => ({
    ...state,
    loading: false,
    error: err,
  }),

  [produsentMeldingsHistorikkActionTypes.FETCH_FUNN_OK]: (
    state: IProdusentMeldingsHistorikkState,
    {
      meldingsHistorikk,
    }: {
      meldingsHistorikk: IProdusentMeldingsHistorikkState['meldingsHistorikk']
    }
  ) => ({
    ...state,
    funnLoaded: true,
    meldingsHistorikk: state.meldingsHistorikk?.map((melding) => {
      const updatedMelding = meldingsHistorikk?.find(
        (mld) => mld.meldingId === melding.meldingId
      )
      if (updatedMelding) {
        return { ...melding, ...updatedMelding }
      }
      return melding
    }),
  }),

  [produsentMeldingsHistorikkActionTypes.FETCH_TILSYNSKVITTERING_STATUS_OK]: (
    state: IProdusentMeldingsHistorikkState,
    {
      meldingsHistorikk,
    }: {
      meldingsHistorikk: IProdusentMeldingsHistorikkState['meldingsHistorikk']
    }
  ) => ({
    ...state,
    tilsynskvitteringStatusLoaded: true,
    meldingsHistorikk: state.meldingsHistorikk?.map((melding) => {
      const updatedMelding = meldingsHistorikk?.find(
        (mld) => mld.meldingId === melding.meldingId
      )
      if (updatedMelding) {
        return { ...melding, ...updatedMelding }
      }
      return melding
    }),
  }),

  [produsentMeldingsHistorikkActionTypes.SET_OPEN_ROWS]: (
    state: IProdusentMeldingsHistorikkState,
    { openRows }: { openRows: string[] }
  ) => ({
    ...state,
    openRows: openRows,
  }),

  [produsentMeldingsHistorikkActionTypes.FETCH_ALLOWED_STATUSES_OK]: (
    state: IProdusentMeldingsHistorikkState,
    {
      allowedStatuses,
    }: { allowedStatuses: IProdusentMeldingsHistorikkState['allowedStatuses'] }
  ) => ({
    ...state,
    allowedStatuses,
  }),

  [produsentMeldingsHistorikkActionTypes.FETCH_ALLOWED_BEGRUNNELSER_OK]: (
    state: IProdusentMeldingsHistorikkState,
    {
      allowedStatusBegrunnelser,
    }: {
      allowedStatusBegrunnelser: IProdusentMeldingsHistorikkState['allowedStatusBegrunnelser']
    }
  ) => ({
    ...state,
    allowedStatusBegrunnelser,
  }),
})

export const produsentMeldingsHistorikkReducer = {
  [STORE_NAME]: customReducer,
}
