import React, { ReactNode } from 'react';
import { Row, RowItem, IconButton, ArrowIcon, Grid, GridItem } from '@mattilsynet/mt-ui';
import { SliderDots } from '../slider-dots';
import './style.css';
interface IBottomNavigatorProps {
  slides: ReactNode[];
  currentSlide: number;
  goToPrevious: () => void;
  goToNext: () => void;
  goToSlide: (slide: number) => void;
}
export const BottomNavigator = ({
  slides,
  currentSlide,
  goToPrevious,
  goToNext,
  goToSlide
}: IBottomNavigatorProps) => {
  const showPreviousButton = currentSlide !== 0;
  const showNextButton = currentSlide !== slides.length - 1;
  let nextSlideText;
  let previousSlideText;
  const getIconClass = (hide?: boolean) => `focus${hide ? ' hide' : ''}`;
  if (showPreviousButton) {
    previousSlideText = slides[currentSlide - 1]?.['key'] || '';
  }
  if (showNextButton) {
    nextSlideText = slides[currentSlide + 1]?.['key'] || '';
  }
  return <div className="bottom-navigator" data-sentry-component="BottomNavigator" data-sentry-source-file="index.tsx">
      <Grid className="navigator-grid" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]} data-sentry-element="GridItem" data-sentry-source-file="index.tsx">
          <Row justify="center" spacing={2} align="center" data-sentry-element="Row" data-sentry-source-file="index.tsx">
            <RowItem justify="center" data-sentry-element="RowItem" data-sentry-source-file="index.tsx">
              <IconButton onClick={goToPrevious} icon={<ArrowIcon />} className={getIconClass(!showPreviousButton)} ariaLabel={previousSlideText} iconBefore data-sentry-element="IconButton" data-sentry-source-file="index.tsx" />
            </RowItem>
            <RowItem className="dots" data-sentry-element="RowItem" data-sentry-source-file="index.tsx">
              <SliderDots slides={slides} currentSlide={currentSlide} goToSlide={goToSlide} data-sentry-element="SliderDots" data-sentry-source-file="index.tsx" />
            </RowItem>
            <RowItem justify="center" data-sentry-element="RowItem" data-sentry-source-file="index.tsx">
              <IconButton onClick={goToNext} icon={<ArrowIcon rotateDirection="RIGHT" />} className={getIconClass(!showNextButton)} ariaLabel={nextSlideText} data-sentry-element="IconButton" data-sentry-source-file="index.tsx" />
            </RowItem>
          </Row>
        </GridItem>
      </Grid>
    </div>;
};