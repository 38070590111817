import { ENDPOINT } from '../../../constants'
import { queryApi } from '../../../api'
import { BildeKvitteringUploadVars, BildeMutationVars, IImage } from '../types'
import { createFormData } from '../../../common/helpers'
import { createQueryStringFromObject } from '../../../common/createQueryStringFromObject'
import { IQueryApiResponse, Prettify } from '../../../common/types'

type GetBildeArgs = Prettify<{
  kvitteringId: string
  bildeId: string
  preferredImageSize: string
  app: 'TILSYNSKVITTERING' | 'MAKKS'
}>

type GetMetadatasArgs = Prettify<{
  kvitteringId: string
}>

type UploadArgs = Prettify<{
  bildeMetadata: IImage
  bilde: File
}>

type UpdateMetadataArgs = Prettify<{
  kvitteringId: string
  bildeId: string
  data: Partial<IImage>
}>

interface GetBildeOptions {
  signal?: AbortSignal
}

export const bildeApi = {
  getBilde: async (
    { kvitteringId, bildeId, preferredImageSize, app }: GetBildeArgs,
    options?: GetBildeOptions
  ) => {
    const queries = createQueryStringFromObject('?')({
      'filter.app': app,
      'filter.type': app === 'TILSYNSKVITTERING' ? kvitteringId : undefined,
    })
    const url = `${ENDPOINT.BILDE}/kategorier/bilder/${bildeId}/${preferredImageSize}${queries}`

    if (!url) {
      throw new Error('url is missing')
    }

    return queryApi.get<File>(url, {
      responseType: 'blob',
      signal: options?.signal,
    })
  },
  getMetadatas: async ({ kvitteringId }: GetMetadatasArgs) => {
    const queries = createQueryStringFromObject('?')({
      'filter.app': 'TILSYNSKVITTERING',
      'filter.type': kvitteringId,
      includeDeleted: false,
      'page.size': 999,
    })

    return queryApi.get<
      IQueryApiResponse<{ imageMetaDataKategorisertList: IImage[] }>
    >(`${ENDPOINT.BILDE}/kategorier/bilder/kategorisert${queries}`)
  },
  upload: async ({ bildeMetadata, bilde }: UploadArgs) => {
    return queryApi.put(
      `${ENDPOINT.BILDE}/bilder/${bildeMetadata.id}/original`,
      createFormData({ ...bildeMetadata, image: bilde }),
      { isFormData: true }
    )
  },
  attachToObservasjon: async ({
    observasjonId,
    bildeId,
  }: BildeMutationVars) => {
    return queryApi.post(
      `${ENDPOINT.TILSYNSKVITTERING}/bilder/observasjoner/${observasjonId}/bilder/${bildeId}`,
      undefined
    )
  },
  attachToKvittering: async ({
    bildeId,
    kvitteringId,
  }: BildeKvitteringUploadVars) => {
    return queryApi.post(
      `${ENDPOINT.TILSYNSKVITTERING}/bilder/tilsynskvitteringer/${kvitteringId}/bilder/${bildeId}`,
      undefined
    )
  },
  updateMetadata: async ({
    kvitteringId,
    bildeId,
    data,
  }: UpdateMetadataArgs) => {
    const queries = createQueryStringFromObject('?')({
      'filter.app': 'TILSYNSKVITTERING',
      'filter.type': kvitteringId,
    })

    return queryApi.patch(
      `${ENDPOINT.BILDE}/kategorier/bilder/${bildeId}${queries}`,
      data
    )
  },
  removeFromObservasjon: async ({
    observasjonId,
    bildeId,
  }: BildeMutationVars) => {
    return queryApi.delete(
      `${ENDPOINT.TILSYNSKVITTERING}/bilder/observasjoner/${observasjonId}/${bildeId}`
    )
  },
  delete: async ({ bildeId }: { bildeId: string }) => {
    const queries = createQueryStringFromObject('?')({
      forceDelete: true,
    })

    return queryApi.delete(
      `${ENDPOINT.TILSYNSKVITTERING}/bilder/${bildeId}${queries}`
    )
  },
}
