import { Observable, of } from 'rxjs'
import { AnyAction } from 'redux'
import { combineEpics, ofType } from 'redux-observable'
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import { AjaxError } from 'rxjs/ajax'
import { actions, actionTypes } from '../store'
import { fetchKodeVerkApi } from './api'

export const fetchKodeverkEpic =
  (api: ReturnType<typeof fetchKodeVerkApi>) =>
  (action$, state$): Observable<AnyAction> =>
    action$.pipe(
      ofType(actionTypes.FETCH),
      withLatestFrom(state$),
      mergeMap(
        ([{ kodeverkName, queries }, state]: [
          ReturnType<typeof actions.fetch>,
          any,
        ]) => {
          return of(state).pipe(
            api(kodeverkName, queries, state),
            map((data: any) =>
              actions.fetchOk(kodeverkName, data.children || [])
            ),
            catchError((err: AjaxError) =>
              of(actions.fetchFail(kodeverkName, err.message))
            )
          )
        }
      )
    )

const kodeverkEpic = (accessTokenPatch: string[], rootUrl: string, api?: any) =>
  combineEpics(
    fetchKodeverkEpic(api || fetchKodeVerkApi(accessTokenPatch, rootUrl))
  )

export default kodeverkEpic
