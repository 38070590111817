import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import NotificationArea from './notification-area';
import { NotificationBell } from '@mattilsynet/mt-ui';
import { selectors } from '../store';
import { ILink } from '../store/types';
export interface INotificationsProps {
  customActions?: (action: ILink['link']['action']) => (() => void) | undefined;
  visible?: boolean;
}
export const Notifications = ({
  visible = true,
  customActions
}: INotificationsProps) => {
  const [isOpen, setNotificationsToggled] = useState(false);
  const notificationRef = useRef<HTMLDivElement>(null);
  const unreadNotifications = useSelector(selectors.getUnreadNumber);
  const onToggleMenu = () => {
    setNotificationsToggled(!isOpen);
  };
  const onCloseMenuOnBlur = event => {
    if (notificationRef.current && (!event.relatedTarget || !notificationRef.current.contains(event.relatedTarget))) {
      return setNotificationsToggled(false);
    }
  };
  if (!visible) return null;
  return <div ref={notificationRef} onBlur={onCloseMenuOnBlur} data-sentry-component="Notifications" data-sentry-source-file="index.tsx">
      <NotificationBell onClick={onToggleMenu} unreadNotifications={unreadNotifications} data-sentry-element="NotificationBell" data-sentry-source-file="index.tsx" />
      {isOpen && <NotificationArea customActions={customActions} />}
    </div>;
};