import React from 'react';
import { InformationBox } from '../../components/information-box';
import { useAvdelingstilhorighet } from './tilhorighet-hooks';
import { useKvitteringId } from '../../common/kvittering-context';
import { LoadingDots } from '@mattilsynet/mt-ui';
import { useMutationStatus } from '../../common/query';
import { tilsynsobjektKeys } from '../tilsynsobjekter/queries/helpers';
export const Avdelingstilhorighet = () => {
  const kvitteringId = useKvitteringId();
  const tilsynsobjekterBeingAdded = useMutationStatus({
    mutationKey: tilsynsobjektKeys.add(),
    predicate: mutation => mutation.state.status !== 'success'
  });
  const {
    data,
    isLoading
  } = useAvdelingstilhorighet(kvitteringId, tilsynsobjekterBeingAdded.length === 0);
  if (isLoading) {
    return <LoadingDots />;
  }

  // TILHORER
  // TILHORER_IKKE
  // MIDLERTIDIG_TILSYNSOBJEKT
  if (data === undefined || data !== 'TILHORER_IKKE') {
    return null;
  }
  return <InformationBox small variant="warning" data-sentry-element="InformationBox" data-sentry-component="Avdelingstilhorighet" data-sentry-source-file="index.tsx">
      Tilsynsobjektet har adresse utenfor din avdeling, sjekk at du har valgt
      rett!
    </InformationBox>;
};