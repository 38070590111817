import localForage from 'localforage'
import {
  PersistedClient,
  Persister,
  PersistQueryClientProviderProps,
} from '@tanstack/react-query-persist-client'
import { DehydratedState } from '@tanstack/react-query'
import { isBildeUploadKey } from '../features/bilder/queries/helpers'
import { shouldHandleError } from '../common/query'
import { stripFunctions } from '../common/helpers'

/** Get element type of array. Ex. ArrElement<strint[]> => string */
type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
  ? ElementType
  : never

const CACHE_KEY = 'cache'

const cacheDatabase = localForage.createInstance({
  name: 'query-cache',
  storeName: 'tilsynskvittering',
  version: 1.0,
})

const getMutationsOutput = (mutations: DehydratedState['mutations']) => {
  const outputLines: string[] = []

  mutations.forEach((mutation) => {
    const variables = (mutation.state.variables ?? {}) as any
    const localOutput: string[] = []

    if (variables.kontrollpunkt && variables.kontrollpunkt.beskrivelse) {
      const { id, beskrivelse } = variables.kontrollpunkt
      if (id) localOutput.push(`id: ${id}`)
      if (beskrivelse) localOutput.push(`kontrollpunkt: ${beskrivelse}`)
    }

    if (variables.deltaker && variables.deltaker.navn) {
      const { id, navn } = variables.deltaker
      const { entity } = mutation.mutationKey?.[0] ?? ({} as any)
      if (entity) localOutput.push(`${entity}`)
      if (id) localOutput.push(`id: ${id}`)
      if (navn) localOutput.push(`deltaker: ${navn}`)
    }

    if (variables.observasjon && variables.observasjon.tekst) {
      const { id, tekst } = variables.observasjon
      if (id !== undefined) localOutput.push(`id: ${id}`)
      if (tekst) localOutput.push(`observasjon: ${tekst}`)
    }

    if (variables.veiledning && variables.veiledning.tekst) {
      const { id, tekst } = variables.veiledning
      if (id) localOutput.push(`id: ${id}`)
      if (tekst) localOutput.push(`veiledning: ${tekst}`)
    }

    if (localOutput.length === 0) {
      return
    }

    // Separate entries with a blank line
    outputLines.push(...localOutput)
    outputLines.push('-–-–-')
  })

  return outputLines.join('\n')
}

const getBilderOutput = (mutations: DehydratedState['mutations']) => {
  const outputLines: string[] = []
  const bilder: any[] = []

  mutations.forEach((mutation) => {
    const variables = (mutation.state.variables ?? {}) as any
    const localOutput: string[] = []

    if (variables.bilde) {
      const { id } = variables.bildeMetadata
      bilder.push([id, variables.bilde] as any)
    }

    if (variables.bildeMetadata) {
      const { id, description } = variables.bildeMetadata
      if (id) localOutput.push(`id: ${id}`)
      if (description) localOutput.push(`bildetittel: ${description}`)
      localOutput.push(JSON.stringify(variables.bildeMetadata, undefined, 4))
    }

    if (localOutput.length === 0) {
      return
    }

    // Separate entries with a blank line
    outputLines.push(...localOutput)
    outputLines.push('-–-–-')
  })

  return [bilder, outputLines.join('\n')] as const
}

export const downloadMutations = async () => {
  const cacheState = await persister.restoreClient()

  if (!cacheState || cacheState.clientState.mutations.length === 0) {
    return null
  }

  const output = getMutationsOutput(cacheState.clientState.mutations)
  const [bilder, metadataOutput] = getBilderOutput(
    cacheState.clientState.mutations
  )

  return {
    output,
    bilder,
    metadataOutput,
    cacheState: cacheState.clientState.mutations,
  }
}

/**
 * Transforms Error obj to normal obj
 * so that it can be serialized.
 */
const objectifyError = (error: unknown) => {
  if (!error) {
    return error
  }

  return JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)))
}

/**
 * Parses a mutation to be serialized including Errors
 * and sets necessary statuses.
 */
const parseMutation = (
  mutation: ArrElement<DehydratedState['mutations']>
): ArrElement<DehydratedState['mutations']> => {
  const { error, failureReason, status, isPaused } = mutation.state
  const newStatus = {
    // Needs these two to be run with queryClient.resumePausedMutations
    status: status === 'error' ? 'pending' : status,
    isPaused: status === 'error' ? true : isPaused,
  }
  const isBildeUpload =
    !!mutation.mutationKey && isBildeUploadKey(mutation.mutationKey)
  if (status === 'pending' && isBildeUpload) {
    newStatus.isPaused = true
  }

  return {
    ...mutation,
    state: {
      ...mutation.state,
      error: objectifyError(error),
      failureReason: objectifyError(failureReason),
      ...newStatus,
    },
  }
}

const parsePersistedClient = (client: PersistedClient): PersistedClient =>
  stripFunctions({
    ...client,
    clientState: {
      ...client.clientState,
      mutations: client.clientState.mutations.map(parseMutation),
    },
  })

const createIDBPersister = (idbValidKey = CACHE_KEY) => {
  return {
    persistClient: async (client: PersistedClient) => {
      const parsedClient = parsePersistedClient(client)

      await cacheDatabase.setItem(idbValidKey, parsedClient)
    },
    restoreClient: async () => {
      return await cacheDatabase.getItem(idbValidKey)
    },
    removeClient: async () => {
      await cacheDatabase.removeItem(idbValidKey)
    },
  } as Persister
}

const persister = createIDBPersister()

export const persistOptions: PersistQueryClientProviderProps['persistOptions'] =
  {
    persister: persister,
    dehydrateOptions: {
      shouldDehydrateMutation: (mutation) => {
        const isValidError =
          mutation.state.status === 'error' &&
          shouldHandleError(mutation.state.error)

        const isBildeUploading =
          mutation.state.status === 'pending' &&
          isBildeUploadKey(mutation.options.mutationKey ?? [])

        return mutation.state.isPaused || isValidError || isBildeUploading
      },
    },
  }

export const exportedForTesting = {
  objectifyError,
  parseMutation,
  parsePersistedClient,
}
