import React from 'react';
import { Grid, GridItem, Column, Row, Button } from '@mattilsynet/mt-ui';
import { useTKNavigate } from '../../common/navigation';
const NoRoute = () => {
  const navigate = useTKNavigate();
  const goToHome = () => navigate('/');
  return <Grid id="no-route-content" data-sentry-element="Grid" data-sentry-component="NoRoute" data-sentry-source-file="index.tsx">
      <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]} data-sentry-element="GridItem" data-sentry-source-file="index.tsx">
        <Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <GridItem data-sentry-element="GridItem" data-sentry-source-file="index.tsx">
            <Column justify="center" align="center" margin={[7.5, 0]} spacing={1} data-sentry-element="Column" data-sentry-source-file="index.tsx">
              <Row className="text" data-sentry-element="Row" data-sentry-source-file="index.tsx">Kunne ikke finne siden</Row>
              <Button onClick={goToHome} data-sentry-element="Button" data-sentry-source-file="index.tsx">Gå til hovedsiden</Button>
            </Column>
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>;
};
export default NoRoute;