import React, { useCallback, useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider, colors } from '@mattilsynet/mt-ui';
import { v1 as uuidV1 } from 'uuid';
import { themePicker } from './common/theme';
import store, { persistor } from './reducers/store';
import { useTypedSelector } from './common/custom-hooks';
import '@mattilsynet/mt-ui/dist/assets/global.css';
import './app.css';
import './print.css';
import { config$, oidcConfig$ } from './config';
import { uiActions } from './ducks/ui/actions';
import { notifications } from './features/notifications';
import dayjs from 'dayjs';
import locale from './common/nb-locale-dayjs';
import { PersistGate } from 'redux-persist/integration/react';
import { isUserDeviceDesktop } from './common/userDevice';
import { authReadySubject } from './auth/helpers';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { queryClient } from './api/query-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LocalToggleProvider } from './common/unleashToggles';
import { checkNetwork } from './utils/network';
import { router } from './routes/router';
import { IsQueryClientReadyProvider } from './utils/query-client-ready';
import { persistOptions } from './api/query-client-persister';
import { QueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
dayjs.locale(locale);
config$.subscribe(config => store.dispatch(uiActions.configLoaded(config)));
oidcConfig$.subscribe(oidcConfig => {
  store.dispatch(uiActions.setOidcConfig(oidcConfig));
});
store.dispatch(uiActions.setUserDevice(isUserDeviceDesktop(navigator.platform, navigator.userAgent)));
const updateNotificationUUID = uuidV1();
export const updateInstalled = () => {
  store.dispatch({
    type: 'UPDATE_INSTALLED'
  });
  store.dispatch(notifications.actions.add([{
    id: updateNotificationUUID,
    title: 'Applikasjon oppdatert',
    body: 'Oppdatering vil bli tatt i bruk neste gang du åpner applikasjonen',
    created: new Date().toString(),
    status: 'info',
    local: true,
    link: {
      onClick: () => window.location.reload(),
      text: 'Last inn applikasjonen på nytt'
    }
  }]));
};

/**
 * HACK: Fixes bug in Tanstack React Query (https://github.com/TanStack/query/issues/6825)
 * REMOVEME: When issue is fixed
 *
 * When the network is unstable the sync can freeze only solved by a refresh.
 * This function forces the sync to continue.
 * @param queryClient
 */
const continueFirstMutation = (queryClient: QueryClient) => {
  const pendingMutations = queryClient.getMutationCache().findAll({
    status: 'pending'
  });
  const first = pendingMutations[0];
  first?.continue();
};
const App = () => {
  const [isQueryClientReady, setIsQueryClientReady] = useState(false);
  const env = useTypedSelector(state => state.ui.environment);
  const theme = themePicker(env);
  colors.makeCustomProperties(theme, document.documentElement);
  useEffect(() => {
    checkNetwork();
  }, []);
  const onSuccess = useCallback(() => {
    if (!window.navigator.onLine) {
      setIsQueryClientReady(true);
    }
    authReadySubject.subscribe(isReady => {
      const authReadyInit = async () => {
        if (isReady && window.navigator.onLine) {
          // REMOVEME: See `continueFirstMutation` func
          setTimeout(() => continueFirstMutation(queryClient), 1000);
          await queryClient.resumePausedMutations();
          await queryClient.invalidateQueries();
          setIsQueryClientReady(true);
        }
      };
      void authReadyInit();
    });
  }, []);
  return <LocalToggleProvider data-sentry-element="LocalToggleProvider" data-sentry-component="App" data-sentry-source-file="app.tsx">
      <PersistGate loading={null} persistor={persistor} data-sentry-element="PersistGate" data-sentry-source-file="app.tsx">
        <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions} onSuccess={onSuccess} data-sentry-element="PersistQueryClientProvider" data-sentry-source-file="app.tsx">
          <IsQueryClientReadyProvider value={isQueryClientReady} data-sentry-element="IsQueryClientReadyProvider" data-sentry-source-file="app.tsx">
            <ThemeProvider value={theme} data-sentry-element="ThemeProvider" data-sentry-source-file="app.tsx">
              <RouterProvider router={router} data-sentry-element="RouterProvider" data-sentry-source-file="app.tsx" />
            </ThemeProvider>
          </IsQueryClientReadyProvider>

          <ReactQueryDevtools position="top" buttonPosition="top-left" data-sentry-element="ReactQueryDevtools" data-sentry-source-file="app.tsx" />
        </PersistQueryClientProvider>
      </PersistGate>
    </LocalToggleProvider>;
};
export default Sentry.withProfiler(App);