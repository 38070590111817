import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Column, ErrorBox, LoadingSpinner, ShadowBox, Table, Text, TextLink, TableRow, TableCell } from '@mattilsynet/mt-ui';
import dayjs from 'dayjs';
import * as R from 'ramda';
import { IMelding } from '../../ducks/produsent-meldings-historikk/store/types';
import Observasjon = IKjoettkontrollFunnRemote.Observasjon;
import { produsentMeldingsHistorikkActions, produsentMeldingsHistorikkSelectors } from '../../ducks/produsent-meldings-historikk/store';
import { findAktivitetForTilsynsobjekt } from '../../ducks/tilsynsobjekt/helpers';
import { FunnCardItem } from '../funn-card-item';
import { useTypedSelector } from '../../../../common/custom-hooks';
import { getSortableTableHeader, sortByPath } from '../../../../common/sorting';
import { kodeverk } from '../../../kodeverk';
export enum MeldingStatus {
  NY = 'NY',
  UNDER_VURDERING = 'UNDER_VURDERING',
  VURDERT = 'VURDERT',
  DUBLETT = 'DUBLETT',
  INSPEKSJON = 'INSPEKSJON',
  SAKSBEHANDLING = 'SAKSBEHANDLING',
  UTKAST = 'UTKAST',
}
export const ProdusentMeldingsHistorikk = ({
  tilsynsobjektId
}: {
  tilsynsobjektId: string;
}) => {
  const isTinyScreen = useMediaQuery({
    query: '(max-width: 767px)'
  });
  const dispatch = useDispatch();
  const {
    loading,
    error,
    loaded,
    funnLoaded,
    tilsynskvitteringStatusLoaded
  } = useTypedSelector(produsentMeldingsHistorikkSelectors.getLoadingStatus);
  const meldingsHistorikk = useTypedSelector(produsentMeldingsHistorikkSelectors.getMeldingsHistorikk);
  const virksomhet = useTypedSelector(produsentMeldingsHistorikkSelectors.getVirksomhet);
  const openRows = useTypedSelector(produsentMeldingsHistorikkSelectors.getOpenRows);
  const allowedStatuses = useTypedSelector(produsentMeldingsHistorikkSelectors.getAllowedStatuses);
  const allowedStatusBegrunnelser = useTypedSelector(produsentMeldingsHistorikkSelectors.getAllowedStatusBegrunnelser);
  const {
    data: aktivitetKodeverk
  } = kodeverk.hooks.useKodeverk('AKTIVITET', {
    rootCode: 'DYR_MAT'
  });
  const [sortColumn, setSortColumn] = useState('sistRedigertAv');
  const [sortArrow, setSortArrow] = useState<'DOWN' | 'UP'>('UP');
  const [listSorted, setListSorted] = useState<IMelding[] | undefined>(meldingsHistorikk);
  useEffect(() => {
    if (meldingsHistorikk && meldingsHistorikk.length === 0) {
      setListSorted([]);
    }
    if (virksomhet && aktivitetKodeverk?.length && meldingsHistorikk?.length) {
      const updatedListSorted = R.pipe(R.map(melding => {
        const tilsynsobjekt = virksomhet?.tilsynsobjekter?.find(tilsynsobjekt => tilsynsobjekt.idstring === melding.produsentTilsynsobjektId);
        const aktivitet = findAktivitetForTilsynsobjekt(tilsynsobjekt, aktivitetKodeverk);
        return {
          ...melding,
          aktivitet: aktivitet?.displayNames?.no
        };
      }), sortByPath(sortColumn.split('.'), sortArrow !== 'UP'))(meldingsHistorikk);
      setListSorted(updatedListSorted);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virksomhet, meldingsHistorikk?.length, funnLoaded, tilsynskvitteringStatusLoaded, aktivitetKodeverk?.length, sortColumn, sortArrow]);
  const fetchData = useCallback(() => {
    dispatch(produsentMeldingsHistorikkActions.fetchMeldingsHistorikk(tilsynsobjektId));
  }, [dispatch, tilsynsobjektId]);
  const fetchAllowedStatuses = useCallback(() => {
    dispatch(produsentMeldingsHistorikkActions.fetchAllowedStatuses());
  }, [dispatch]);
  const fetchAllowedStatusBegrunnelser = useCallback(() => {
    dispatch(produsentMeldingsHistorikkActions.fetchAllowedBegrunnelser());
  }, [dispatch]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    fetchAllowedStatuses();
    fetchAllowedStatusBegrunnelser();
  }, [fetchAllowedStatuses, fetchAllowedStatusBegrunnelser]);
  const toggleOpenRow = meldingId => {
    const updatedOpenRows = openRows.includes(meldingId) ? openRows.filter(id => id !== meldingId) : [...openRows, meldingId];
    dispatch(produsentMeldingsHistorikkActions.setOpenRows(updatedOpenRows));
  };
  if (error) {
    return <ErrorBox errorText="Kunne ikke laste inn meldingshistorikk." errorAction={fetchData} errorActionText="Prøv å laste innhold på nytt" />;
  }
  if (loading && !loaded || !meldingsHistorikk || !listSorted || loading && !meldingsHistorikk.length || loading && !meldingsHistorikk?.some(m => m.produsentTilsynsobjektId === tilsynsobjektId) || !allowedStatuses || !allowedStatusBegrunnelser) {
    return <LoadingSpinner title="Laster meldingshistorikk" />;
  }
  if (loaded && meldingsHistorikk?.length === 0) {
    return <Text margin={[0, 2, 2, 2]}>Virksomheten har ingen meldinger</Text>;
  }
  const tableHeader = getSortableTableHeader([{
    display: 'Mottatt',
    sortable: true,
    sortKey: 'sistRedigertAv'
  }, {
    display: 'Aktivitet',
    sortable: true,
    sortKey: 'aktivitet'
  }, {
    display: 'Kategori'
  }, {
    display: 'Diagnose'
  }, {
    display: 'Status',
    sortable: true,
    sortKey: 'vurdering.status'
  }, {
    display: 'Saksnummer for oppfølging'
  }, {
    display: ''
  }], sortColumn, sortArrow, setSortColumn, setSortArrow);
  return <div data-sentry-component="ProdusentMeldingsHistorikk" data-sentry-source-file="index.tsx">
      <Table header={tableHeader} data-sentry-element="Table" data-sentry-source-file="index.tsx">
        {listSorted?.map((melding: IMelding) => {
        const showMore = openRows.some(id => id === melding.meldingId);
        const observasjoner: Observasjon[] | undefined = melding.funnList?.reduce((acc: Observasjon[], funn) => funn && funn.observasjoner ? [...acc, ...funn.observasjoner] : [...acc], []);
        const diagnoser = R.pipe(R.map(observasjon => observasjon.diagnose?.beskrivelse), R.uniq)(observasjoner);
        return <React.Fragment key={melding.meldingId}>
              <TableRow key={melding.meldingId} header={tableHeader.map(item => String(item.display))}>
                <TableCell>
                  {dayjs(melding.sistRedigert).format('D. MMM YYYY')}
                </TableCell>
                <TableCell>{melding.aktivitet}</TableCell>

                <TableCell>
                  <Column spacing={1}>
                    {melding.kategorier.map(kategori => <Column key={kategori.kode} spacing={1}>
                        <Text>{kategori.beskrivelse}</Text>
                      </Column>)}
                  </Column>
                </TableCell>
                <TableCell>
                  <Column spacing={1}>
                    {diagnoser?.map(diagnose => <Column key={diagnose}>
                        <Text>{diagnose}</Text>
                      </Column>)}
                  </Column>
                </TableCell>
                <TableCell>
                  <Column spacing={2}>
                    <Text>
                      {allowedStatuses?.[melding?.vurdering?.status || '']}
                    </Text>
                    {MeldingStatus[melding?.vurdering?.status || ''] !== MeldingStatus.NY && <Column>
                        <Text weight="light">
                          Status satt:{' '}
                          {dayjs(melding?.vurdering?.sistRedigert).format('DD.MM.YY')}
                        </Text>
                        <Text weight="light">
                          Behandlet av: {melding?.vurdering?.sistRedigertAv}
                        </Text>
                      </Column>}

                    {melding?.vurdering?.statusBegrunnelse && <div>
                        <Text as="span" weight="light" margin={[0, 0.5, 0, 0]}>
                          Begrunnelse:
                        </Text>
                        <Text as="span" weight="light">
                          {allowedStatusBegrunnelser?.find(begrunnelse => begrunnelse.status === melding?.vurdering?.statusBegrunnelse)?.beskrivelse || melding?.vurdering?.statusBegrunnelse}
                        </Text>
                      </div>}

                    {melding?.tilsynskvitteringStatus && <Text weight="light">
                        Tilsynskvittering:{' '}
                        {melding?.tilsynskvitteringStatus?.status}
                      </Text>}
                  </Column>
                </TableCell>
                <TableCell>
                  {melding?.vurdering?.tilknyttetSaksnummer ? <Text>{melding?.vurdering?.tilknyttetSaksnummer}</Text> : <Text weight="light">Sak er ikke lagt til</Text>}
                </TableCell>
                <TableCell>
                  <TextLink onClick={() => toggleOpenRow(melding.meldingId)} small>
                    {showMore ? 'Skjul melding' : 'Vis melding'}
                  </TextLink>
                </TableCell>
              </TableRow>
              {showMore && <TableRow>
                  <TableCell colSpan={8}>
                    <Column padding={isTinyScreen ? 0 : 1}>
                      <ShadowBox>
                        <Column padding={isTinyScreen ? 1 : 3} spacing={3}>
                          <Column spacing={2}>
                            <Text size="heading3" weight="bold">
                              Avsender
                            </Text>
                            <Text>{melding.avsenderNavn}</Text>
                          </Column>
                          <Column spacing={2}>
                            <Text size="heading3" weight="bold">
                              Slakteri
                            </Text>
                            <Text>
                              {melding.slakteriNavn} - Efta-nummer:{' '}
                              {melding.eftanummer}
                            </Text>
                          </Column>
                          <Column spacing={2}>
                            <Text size="heading3" weight="bold">
                              Tilsynsobjekt meldingen gjelder
                            </Text>
                            <Text>{melding.aktivitet}</Text>
                          </Column>

                          <Column spacing={2}>
                            <Text size="heading3" weight="bold">
                              Begrunnelse for bekymring
                            </Text>
                            <Text>{melding.begrunnelse}</Text>
                          </Column>

                          <Column spacing={2}>
                            <Text size="heading3" weight="bold">
                              Relevante funn
                            </Text>
                            {melding.funnList?.map?.(funn => <FunnCardItem key={funn.funnId} funn={funn} />)}
                          </Column>
                        </Column>
                      </ShadowBox>
                    </Column>
                  </TableCell>
                </TableRow>}
            </React.Fragment>;
      })}
      </Table>
    </div>;
};