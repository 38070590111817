import React from 'react';
import { Column, Row, Button, ContrastBox, LoadingDots } from '@mattilsynet/mt-ui';
import './style.css';
import { ITilsynsobjekt } from '../../features/tilsynsobjekter/types';
import { Avdelingstilhorighet } from '../../features/avdelingstilhorighet';
interface ITilsynsobjekterCardInfoProps {
  onAddRemoveTilsynsobjekt: () => void;
  tilsynsobjekter: ITilsynsobjekt[];
  virksomhetsNavn?: string;
  virksomhetsOrgNr?: string;
  disabled: boolean;
}
const TilsynsobjekterCardInfo = ({
  onAddRemoveTilsynsobjekt,
  tilsynsobjekter,
  virksomhetsNavn,
  virksomhetsOrgNr,
  disabled
}: ITilsynsobjekterCardInfoProps) => {
  const areSelectedTilsynsobjekter = tilsynsobjekter && tilsynsobjekter.length > 0;
  const renderTilsynsobjekter = () => {
    if (!areSelectedTilsynsobjekter) return;
    const aktivitetsBeskrivelse = tilsynsobjekt => tilsynsobjekt.aktivitetsBeskrivelse ? `(${tilsynsobjekt.aktivitetsBeskrivelse})` : '';
    const orgOrPerson = virksomhetsOrgNr ? `Org nr underenhet: ${virksomhetsOrgNr}` : `Privatperson`;
    const navn = tilsynsobjekter.some(t => t.midlertidig) ? tilsynsobjekter[0].virksomhetsNavn : virksomhetsNavn;
    return <Column spacing={1} data-sentry-element="Column" data-sentry-component="renderTilsynsobjekter" data-sentry-source-file="index.tsx">
        <Row className="info-header" data-sentry-element="Row" data-sentry-source-file="index.tsx">{navn}</Row>
        <Row data-sentry-element="Row" data-sentry-source-file="index.tsx">{orgOrPerson}</Row>
        {tilsynsobjekter.map((tilsynsobjekt: ITilsynsobjekt) => <Row key={tilsynsobjekt.idstring + tilsynsobjekt.tilsynsobjektId + tilsynsobjekt.id}>
            {`${tilsynsobjekt.navn} ${aktivitetsBeskrivelse(tilsynsobjekt)}`}
          </Row>)}
      </Column>;
  };
  const tilsynsobjektButtonContent = () => {
    if (disabled) {
      return 'Kvittering blir opprettet';
    }
    if (areSelectedTilsynsobjekter) {
      return 'Legg til/fjern tilsynsobjekt';
    }
    return 'Legg til tilsynsobjekt';
  };
  return <div id="tilsynsobjekter-card-info" data-sentry-component="TilsynsobjekterCardInfo" data-sentry-source-file="index.tsx">
      <ContrastBox noBorders backgroundColor="white" data-sentry-element="ContrastBox" data-sentry-source-file="index.tsx">
        <Column fluid spacing={2.5} data-sentry-element="Column" data-sentry-source-file="index.tsx">
          <Row className="subtitle" data-sentry-element="Row" data-sentry-source-file="index.tsx">
            <h3>Tilsynsobjekt</h3>
          </Row>
          {renderTilsynsobjekter()}

          {tilsynsobjekter.length > 0 && <Avdelingstilhorighet />}

          <Row justify="center" className="button-add" data-sentry-element="Row" data-sentry-source-file="index.tsx">
            <Button iconAfter={disabled} disabled={disabled} icon={disabled ? <LoadingDots /> : undefined} fill secondary onClick={onAddRemoveTilsynsobjekt} data-sentry-element="Button" data-sentry-source-file="index.tsx">
              {tilsynsobjektButtonContent()}
            </Button>
          </Row>
        </Column>
      </ContrastBox>
    </div>;
};
export default TilsynsobjekterCardInfo;