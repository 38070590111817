import React, { useCallback, useState } from 'react';
import { AttachImagesToObservationModal } from './add-images-to-observasjon-modal';
import { IMultiActionButton } from '../types';
import { BildeGroupedItem } from '../../bilder/types';
import { useKvitteringId } from '../../../common/kvittering-context';
import { useAttachBildeToObservasjon } from '../../bilder/hooks/bilde-query-hooks';
import { useCanRunMultiAction } from '../hooks';
import { AttachIcon } from '../../../assets/attach-icon';
export const AttachSelectedImages = ({
  selectedImages,
  clearSelectedImages
}: IMultiActionButton) => {
  const [isAttachImagesToObservasjonModalOpen, setIsAttachImagesToObservasjonModalOpen] = useState(false);
  const kvitteringId = useKvitteringId();
  const {
    mutate: attachBilde
  } = useAttachBildeToObservasjon(kvitteringId);
  const onAttachImagesToObservasjon = useCallback((observasjonId: number) => {
    selectedImages.forEach((image: BildeGroupedItem) => {
      attachBilde({
        bildeId: image.id,
        observasjonId
      });
    });
    setIsAttachImagesToObservasjonModalOpen(false);
    clearSelectedImages();
  }, [selectedImages, attachBilde, setIsAttachImagesToObservasjonModalOpen, clearSelectedImages]);
  const onOpenAddImagesToObervationModal = useCanRunMultiAction(selectedImages, () => setIsAttachImagesToObservasjonModalOpen(true));
  const onCloseAddImagesToObservationModal = () => setIsAttachImagesToObservasjonModalOpen(false);
  return <>
      <button className="image-action-menu__button" onClick={onOpenAddImagesToObervationModal}>
        <span className="tooltip-text">Knytt til observasjon</span>

        <AttachIcon size="large" data-sentry-element="AttachIcon" data-sentry-source-file="attach-selected-images.tsx" />
      </button>
      <AttachImagesToObservationModal isOpen={isAttachImagesToObservasjonModalOpen} onCancel={onCloseAddImagesToObservationModal} onAttach={onAttachImagesToObservasjon} selectedImages={selectedImages} data-sentry-element="AttachImagesToObservationModal" data-sentry-source-file="attach-selected-images.tsx" />
    </>;
};