import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { unleashTogglesActions, unleashTogglesSelectors } from './store';
import { IToggleNames } from './types';
export const useUnleashTogglesHooks = (toggleName: IToggleNames, queries?: Record<string, string | string[] | undefined>, readyToFetch = true) => {
  const [hasFetched, setHasFetched] = useState(false);
  const toggle = useSelector(unleashTogglesSelectors.getToggle(toggleName));
  const dispatch = useDispatch();
  const fetchToggle = useCallback(() => {
    dispatch(unleashTogglesActions.fetch(toggleName, queries));
    setHasFetched(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, toggleName, JSON.stringify(queries)]);
  const loadingStatus = {
    loading: toggle?.loading,
    error: toggle?.error,
    loaded: toggle?.loaded
  };
  const enabled = !!toggle?.enabled;
  const shouldFetch = toggleName && readyToFetch && !toggle?.loading && (!toggle || !hasFetched && !!toggle?.error);
  useEffect(() => {
    if (shouldFetch) {
      fetchToggle();
    }
  }, [shouldFetch, fetchToggle]);
  return {
    enabled,
    loadingStatus,
    fetchToggle
  };
};
export const useUnleashTogglesWithEftaNummer = (toggleName: IToggleNames, eftanummer: string | undefined) => {
  return useUnleashTogglesHooks(toggleName, {
    eftanummer
  }, !!eftanummer);
};