import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ansatteActions, ansatteSelectors } from '../../ducks/ansatte';
import { useDebounce, usePaginatedList, useTypedSelector } from '../../common/custom-hooks';
import { AnsattDataListItem } from '../ansatt-data-list-item';
import { useDispatch } from 'react-redux';
import { ErrorBox, LoadingSpinner, SearchInput, SelectList, ToggleSwitch, Column, Row, Pagination } from '@mattilsynet/mt-ui';
import { IKollega } from '../../modals/hent-fra-kollega-modal';
import { IStoreState } from '../../reducers/types';
const PAGE_SIZE = 50;
interface IDataListItem {
  value: string;
  label: JSX.Element;
}
interface IHentFraKollegaKollegerViewProps {
  onSelect: (kollega: IKollega) => void;
  selectedKollega: IKollega | undefined;
}
export const HentFraKollegaKollegerView = ({
  onSelect,
  selectedKollega
}: IHentFraKollegaKollegerViewProps) => {
  const [searchInput, setSearchInput] = useState('');
  const [isAlleKolleger, setIsAlleKolleger] = useState(false);
  const debouncedSearchInput = useDebounce(searchInput, 200);
  const dispatch = useDispatch();
  const isDesktop = useTypedSelector((state: IStoreState) => state.ui.isDesktop);
  const loadingStatus = useTypedSelector(ansatteSelectors.getLoadingStatus);
  const ansatte = useTypedSelector(ansatteSelectors.getAnsatte);
  const searchedAnsatte = ansatte.filter(ansatt => String(ansatt.navn).toLowerCase().includes(debouncedSearchInput.toLowerCase()));
  const [currentPage, totalPages, pageNumber, setPage] = usePaginatedList(searchedAnsatte, PAGE_SIZE);
  const dataList = useMemo<IDataListItem[]>(() => {
    return currentPage.map(ansatt => {
      return {
        value: ansatt.brukernavn,
        label: <AnsattDataListItem brukernavn={ansatt.brukernavn} navn={ansatt.navn} kontorNavn={ansatt.kontorNavn ?? ''} selected={selectedKollega?.username === ansatt.brukernavn} />
      };
    });
  }, [selectedKollega, currentPage]);
  const fetchData = useCallback(() => {
    dispatch(isAlleKolleger ? ansatteActions.fetchAllAnsatte() : ansatteActions.fetchAnsatte());
  }, [dispatch, isAlleKolleger]);
  const onChangeSearchInput = useCallback(event => setSearchInput(event.target.value), []);
  const onToggleAlleKolleger = useCallback(() => setIsAlleKolleger(!isAlleKolleger), [isAlleKolleger]);
  const onSelectKollega = useCallback((username: string) => {
    const name = searchedAnsatte.find(ansatt => ansatt.brukernavn === username)?.navn;
    onSelect({
      name: name,
      username: username
    });
  }, [searchedAnsatte, onSelect]);
  const onChangePage = useCallback((pageNumber: number) => setPage(pageNumber), [setPage]);
  useEffect(() => fetchData(), [fetchData]);
  if (loadingStatus?.loading && !loadingStatus?.loaded) {
    return <LoadingSpinner title="" />;
  }
  if (loadingStatus?.error) {
    return <ErrorBox errorText="Kunne ikke hente kolleger" errorAction={fetchData} errorActionText="Prøv på nytt" />;
  }
  const paginationPadding = isDesktop ? [2, 0, 0, 0] : [2, 2, 0, 2];
  return <div data-sentry-component="HentFraKollegaKollegerView" data-sentry-source-file="index.tsx">
      <SearchInput onChange={onChangeSearchInput} placeholder="Søk etter kollega" value={searchInput} className="kollegerSearch" data-sentry-element="SearchInput" data-sentry-source-file="index.tsx" />
      <Column margin={[0, 0, 1, 0]} data-sentry-element="Column" data-sentry-source-file="index.tsx">
        <Row data-sentry-element="Row" data-sentry-source-file="index.tsx">
          <Column data-sentry-element="Column" data-sentry-source-file="index.tsx">
            <ToggleSwitch onClick={onToggleAlleKolleger} checked={isAlleKolleger} labelAfter={true} data-sentry-element="ToggleSwitch" data-sentry-source-file="index.tsx">
              Vis kolleger utenfor din avdeling
            </ToggleSwitch>
          </Column>
        </Row>
      </Column>
      <SelectList dataList={dataList} onClick={onSelectKollega} selected={[String(selectedKollega?.username)]} data-sentry-element="SelectList" data-sentry-source-file="index.tsx" />
      {searchedAnsatte.length > PAGE_SIZE && <Column padding={paginationPadding}>
          <Pagination page={{
        number: pageNumber,
        totalPages: totalPages
      }} onPaginationClick={onChangePage} />
        </Column>}
    </div>;
};