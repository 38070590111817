import { of } from 'rxjs'
import { combineEpics, Epic } from 'redux-observable'
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators'
import { funnHistorikkActions, funnHistorikkActionTypes } from '../store'
import { IProdusentFunnHistorikkActions } from '../store/types'
import { IStoreState } from '../../../../../reducers/types'
import { ICommonApi } from '../../../../../api'
import { createQueryStringFromObject } from '../../../../../common/createQueryStringFromObject'
import { ENDPOINT } from '../../../../../constants'
import { commonRedux } from '../../../../../common/redux'

const { isOfType } = commonRedux

const fetchProdusentFunnHistorikkEpic =
  (
    commonApi: ICommonApi
  ): Epic<
    IProdusentFunnHistorikkActions,
    IProdusentFunnHistorikkActions,
    IStoreState
  > =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(funnHistorikkActionTypes.FETCH_ID)),
      withLatestFrom(state$),
      switchMap(
        ([{ id }, state]: [
          ReturnType<typeof funnHistorikkActions.fetchId>,
          IStoreState,
        ]) => {
          const queries = createQueryStringFromObject('?')({
            'filter.status': 'AVSLUTTET',
            'page.size': 1000,
          })
          const URL = `${ENDPOINT.KJOETTKONTROLL_FUNN}/v1/historikk/funn/${id}${queries}`
          return of(state).pipe(
            commonApi.get(URL, state),
            map((data) => {
              const historiskFunnList = (
                data?._embedded?.historiskFunnList || []
              ).filter((funn) => funn.status === 'AVSLUTTET')

              return funnHistorikkActions.fetchIdOk({
                historiskFunnList,
                tilsynsobjektId: id,
              })
            }),
            catchError((err) =>
              of(funnHistorikkActions.fetchIdFail(id, err.message))
            )
          )
        }
      )
    )

const produsentFunnHistorikkEpics = (commonApi) =>
  combineEpics(fetchProdusentFunnHistorikkEpic(commonApi))

export default produsentFunnHistorikkEpics
