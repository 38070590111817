import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ErrorBox, Grid, GridItem, Row } from '@mattilsynet/mt-ui';
import { IStoreState } from '../../reducers/types';
import { useDebounce, useTypedSelector } from '../../common/custom-hooks';
import './style.css';
import { swipeToEnum, useSlider } from '../../components/slider';
import { SakOgTilsynsobjektSlide, DeltakereSlide, VeiledningSlide, ObservasjonerSlide, SammenstillingSlide } from './slides';
import { useDispatch } from 'react-redux';
import { kvitteringActions } from '../../ducks/kvittering/actions';
import { LoadingModal } from '../../modals';
import { ArkiveringSlide } from './slides/arkivering-slide';
import { checkValidation } from './utils';
import { dangerToast, useSingleToast, offlineInArkiveringSlide, sakITekstfelt } from '../../common/toast';
import { IKvitteringData, kvitteringStatusEnum } from '../../ducks/kvittering/types';
import { toast } from '../../features/toast';
import KvitteringNavigator from '../../components/kvittering-navigator';
import { GalleryPage } from '../../components/gallery-page';
import { FullImagePage } from '../full-image';
import { useKvitteringId } from '../../common/kvittering-context';
import { TidligereKontrollpunkter } from '../../features/tidligere-kontrollpunkter';
import { useViewsContext } from '.';
import { isArkivertStatus, isFerdigstiltStatus } from '../../utils/kvittering';
import { KvitteringPage } from '../../components/kvittering-page';
import { TilsynsobjektinfoPage } from '../../components/tilsynsobjektinfo-page';
import { useTKNavigate, useReplaceQuery } from '../../common/navigation';
import { kvitteringQueryHelpers } from '../../features/kvitteringer/queries/helpers';
import { useKvittering, useUpdateKvittering } from '../../features/kvitteringer';
const INVALID = 'INVALID';
const CHECKING = 'CHECKING';
const ERROR = 'ERROR';
const OK = 'OK';
const TilsynskvitteringPages = {
  TILSYNSKVITTERING: 0,
  GALLERI: 1,
  TILSYNSOBJEKTINFO: 2
};
export const StartTilsynView = () => {
  const dispatch = useDispatch();
  const navigate = useTKNavigate();
  const replaceQuery = useReplaceQuery();
  const singleToast = useSingleToast();
  const kvitteringId = useKvitteringId();
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline);
  const {
    data: simpleKvittering,
    isLoading: isKvitteringLoading,
    isError: isKvitteringError
  } = useKvittering(kvitteringId, {
    select: kvittering => ({
      id: kvittering.id,
      status: kvittering.status,
      noarksakAar: kvittering.noarksakAar,
      noarksakSekvensnummer: kvittering.noarksakSekvensnummer
    })
  });
  const {
    mutate: updateKvittering
  } = useUpdateKvittering();
  const [sakNumberValue, setSakNumberValue] = useState(() => simpleKvittering?.noarksakAar ? `${simpleKvittering.noarksakAar}/${simpleKvittering.noarksakSekvensnummer}` : '');
  const initialCurrentSlide = Number(new URLSearchParams(location.search).get('currentSlide'));

  // Fix later
  const loading = false;
  // const loading = useTypedSelector(
  //   (state: IStoreState) => state.kvittering.selectedKvittering.loading
  // )

  const [blockContinueFromZero, setBlockContinueFromZero] = useState(false);
  const [formStatus, setFormStatus] = useState(INVALID);
  const {
    view,
    viewDispatch
  } = useViewsContext();
  const onBack = useCallback(() => viewDispatch('default'), [viewDispatch]);
  const [isValidationDirty, setIsValidationDirty] = useState(false);
  const slides = useMemo(() => [<SakOgTilsynsobjektSlide key={'Sak'} blockContinueFromZero={blockContinueFromZero} sakNumberValue={sakNumberValue} setSakNumberValue={setSakNumberValue} />, <DeltakereSlide key={'Deltakere'} />, <ObservasjonerSlide key={'Observasjoner'} />, <VeiledningSlide key={'Veiledninger'} />, <SammenstillingSlide key="Sammenstilling" shouldValidate={isValidationDirty} />, <ArkiveringSlide key="Arkivering" />], [blockContinueFromZero, isValidationDirty, sakNumberValue, setSakNumberValue]);
  const [currentSlide, goToNext, goToPrevious, goToSlide, swipeTo, onSwipe] = useSlider(slides.length, initialCurrentSlide > 0 && initialCurrentSlide < 5 ? initialCurrentSlide : 0);
  const [currentPage,,, goToPage] = useSlider(2, 0);
  const onGoToSlide = useCallback((slide: number) => {
    if (currentSlide === 0) {
      setBlockContinueFromZero(true);
      if (sakNumberValue && !(simpleKvittering?.noarksakSekvensnummer && simpleKvittering?.noarksakAar)) {
        return dispatch(toast.actions.showToast(sakITekstfelt()));
      }
    }
    if (slide < 5) {
      goToSlide(slide);
      return;
    }
    if (isOffline) {
      return singleToast.showToast(offlineInArkiveringSlide());
    }
    setFormStatus(CHECKING);
    // Trenger vi denne? Kan vel fjernes etter arkiveringsiden er migrert til tanstack
    dispatch(kvitteringActions.fetchSammenstilling());
  }, [dispatch, goToSlide, currentSlide, sakNumberValue, simpleKvittering?.noarksakAar, simpleKvittering?.noarksakSekvensnummer, isOffline, singleToast]);
  const showLoadingModal = useDebounce(formStatus === CHECKING, 200) && formStatus === CHECKING;
  const onGoToNext = useCallback(() => {
    if (currentSlide === 0) {
      setBlockContinueFromZero(true);
      if (sakNumberValue && !(simpleKvittering?.noarksakSekvensnummer && simpleKvittering?.noarksakAar)) {
        return dispatch(toast.actions.showToast(sakITekstfelt()));
      }
    }
    if (currentSlide !== 4) {
      goToNext();
      return;
    }
    if (isOffline) {
      return singleToast.showToast(offlineInArkiveringSlide());
    }
    setFormStatus(CHECKING);
    dispatch(kvitteringActions.fetchSammenstilling());
  }, [dispatch, goToNext, currentSlide, sakNumberValue, simpleKvittering?.noarksakSekvensnummer, simpleKvittering?.noarksakAar, isOffline, singleToast]);
  const [hasFetchedKvittering, setHasFetchedKvittering] = useState(false);
  const renderKvitteringPage = useCallback(() => {
    switch (currentPage) {
      case TilsynskvitteringPages.GALLERI:
        return <GridItem xl={[2, -2]} lg={[2, -2]} md={[1, -1]} sm={[1, -1]}>
            <GalleryPage />
          </GridItem>;
      case TilsynskvitteringPages.TILSYNSOBJEKTINFO:
        return <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
            <TilsynsobjektinfoPage />
          </GridItem>;
      case TilsynskvitteringPages.TILSYNSKVITTERING:
      default:
        return <GridItem xl={[2, -2]} lg={[2, -2]} md={[1, -1]} sm={[1, -1]}>
            <KvitteringPage slides={slides} currentSlide={currentSlide} isLoading={isKvitteringLoading} />
          </GridItem>;
    }
  }, [currentPage, slides, currentSlide, isKvitteringLoading]);
  useEffect(() => {
    if (swipeTo === swipeToEnum.RIGHT) {
      goToPrevious();
    }
    if (swipeTo === swipeToEnum.LEFT) {
      onGoToNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSwipe]);
  useEffect(() => {
    window.scrollTo(0, 0);
    // Update url with currentSlide to stay there on refresh without rerender
    replaceQuery(`currentSlide=${currentSlide}`);
  }, [currentSlide, replaceQuery]);
  useEffect(() => window.scrollTo(0, 0), [currentPage]);
  useEffect(() => {
    if (formStatus === CHECKING && !loading) {
      const onSuccess = () => {
        setFormStatus(OK);
        goToSlide(5);
      };
      const onFail = (errorText: string) => {
        dispatch(dangerToast(errorText));
        setFormStatus(ERROR);
      };
      setIsValidationDirty(true);
      const kvittering = kvitteringQueryHelpers.getKvitteringCache(kvitteringId) as IKvitteringData;
      if (kvittering) {
        checkValidation(kvittering, onSuccess, onFail);
        return;
      }
    }
  }, [loading, formStatus, dispatch, goToSlide, kvitteringId]);
  useEffect(() => {
    if (sakNumberValue.length < 1) {
      setBlockContinueFromZero(false);
    }
  }, [sakNumberValue]);
  useEffect(() => {
    setHasFetchedKvittering(true);
    if (initialCurrentSlide > 0 && initialCurrentSlide < 5) {
      goToSlide(initialCurrentSlide);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (simpleKvittering?.status === kvitteringStatusEnum.FORBEREDT) {
      const cacheKvittering = kvitteringQueryHelpers.getKvitteringCache(kvitteringId) as IKvitteringData;
      updateKvittering({
        kvittering: {
          ...cacheKvittering,
          status: kvitteringStatusEnum.PAABEGYNT
        }
      });
    }
  }, [simpleKvittering?.status, kvitteringId, updateKvittering]);
  if (!simpleKvittering?.id && isKvitteringError) {
    return <Row minHeight="calc(100vh - 300px)" center>
        <ErrorBox errorText="Kunne ikke hente innhold.." errorActionText="Prøv igjen.." errorAction={() => dispatch(kvitteringActions.setCurrentKvitteringFromId(kvitteringId, ['tilsynsobjekter']))} />
      </Row>;
  }
  if (hasFetchedKvittering && currentSlide !== 5 && (isFerdigstiltStatus(simpleKvittering?.status) || isArkivertStatus(simpleKvittering?.status))) {
    const errorText = isArkivertStatus(simpleKvittering?.status) ? 'Denne tilsynskvitteringen er allerede arkivert' : 'Denne tilsynskvitteringen er allerede ferdigstilt';
    return <Row minHeight="calc(100vh - 300px)" center>
        <ErrorBox errorText={errorText} errorActionText="Gå tilbake til forsiden" errorAction={() => navigate('/')} />
      </Row>;
  }
  if (view === 'fullversjonImage') {
    return <FullImagePage onTilbake={onBack} />;
  }
  if (view === 'tidligereKontrollpunkter' && simpleKvittering?.id) {
    return <TidligereKontrollpunkter kvitteringId={simpleKvittering.id} onCancel={onBack} />;
  }
  return <Grid id="start-tilsyn" data-sentry-element="Grid" data-sentry-component="StartTilsynView" data-sentry-source-file="view.tsx">
      <LoadingModal isOpen={showLoadingModal} onCancel={() => setFormStatus(ERROR)} data-sentry-element="LoadingModal" data-sentry-source-file="view.tsx" />

      {renderKvitteringPage()}

      {kvitteringId && simpleKvittering?.id && <KvitteringNavigator slides={slides} currentSlide={currentSlide} currentPage={currentPage} toPreviousSlide={goToPrevious} toNextSlide={onGoToNext} toSlide={onGoToSlide} toPage={goToPage} />}
    </Grid>;
};