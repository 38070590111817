import React from 'react';
import { Column, Row, Button, Text, Header } from '@mattilsynet/mt-ui';
import { ModalWrapper } from '../../components/modal-wrapper';
interface IInfoModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title?: string;
  info?: string;
  confirmText?: string;
  cancelText?: string;
}
interface IInfoModalWithoutConfirmationProps {
  isOpen: boolean;
  onCancel: () => void;
  title?: string;
  info?: string;
  cancelText?: string;
}
// Trengs disse?
export const InfoModal = ({
  isOpen,
  onCancel,
  onConfirm,
  title = '',
  info = '',
  confirmText = 'Opprett en ny sak',
  cancelText = 'Gå tilbake'
}: IInfoModalProps) => {
  return <ModalWrapper fullscreenMobile isOpen={isOpen} onCancel={onCancel} data-sentry-element="ModalWrapper" data-sentry-component="InfoModal" data-sentry-source-file="index.tsx">
      <div id="info-modal">
        <Column spacing={5} margin={[2, 0]} minHeight="unset" data-sentry-element="Column" data-sentry-source-file="index.tsx">
          {info && <Row justify="center" padding={[0, 4]}>
              <Header as="h1" size="heading2">
                {title}
              </Header>
            </Row>}
          <Row justify="center" padding={[0, 4]} data-sentry-element="Row" data-sentry-source-file="index.tsx">
            <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">{info}</Text>
          </Row>
          <Column spacing={3} data-sentry-element="Column" data-sentry-source-file="index.tsx">
            <Row justify="center" data-sentry-element="Row" data-sentry-source-file="index.tsx">
              <Button secondary width="22em" onClick={onCancel} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                {cancelText}
              </Button>
            </Row>
            <Row justify="center" data-sentry-element="Row" data-sentry-source-file="index.tsx">
              <Button secondary width="22em" onClick={onConfirm} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                {confirmText}
              </Button>
            </Row>
          </Column>
        </Column>
      </div>
    </ModalWrapper>;
};
export const InfoModalWithoutConfirmation = ({
  isOpen,
  onCancel,
  title = '',
  info = '',
  cancelText = 'Gå tilbake'
}: IInfoModalWithoutConfirmationProps) => {
  return <ModalWrapper fullscreenMobile isOpen={isOpen} onCancel={onCancel} data-sentry-element="ModalWrapper" data-sentry-component="InfoModalWithoutConfirmation" data-sentry-source-file="index.tsx">
      <div id="info-modal">
        <Column spacing={5} margin={[2, 0]} minHeight="unset" data-sentry-element="Column" data-sentry-source-file="index.tsx">
          {info && <Row justify="center" padding={[0, 4]}>
              <Header as="h1" size="heading2">
                {title}
              </Header>
            </Row>}
          <Row justify="center" padding={[0, 4]} data-sentry-element="Row" data-sentry-source-file="index.tsx">
            <Text size="normal" data-sentry-element="Text" data-sentry-source-file="index.tsx">{info}</Text>
          </Row>
          <Column spacing={3} data-sentry-element="Column" data-sentry-source-file="index.tsx">
            <Row justify="center" data-sentry-element="Row" data-sentry-source-file="index.tsx">
              <Button secondary width="22em" onClick={onCancel} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                {cancelText}
              </Button>
            </Row>
          </Column>
        </Column>
      </div>
    </ModalWrapper>;
};