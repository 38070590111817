import { useQuery, queryOptions } from '@tanstack/react-query'
import { avdelingstilhorighetKeys } from './queries/helpers'
import { avdelingstilhorighetApi } from './tilhorighet-api'

export { useAvdelingstilhorighet }

const avdelingstilhorighetOptions = (kvitteringId: string, enabled: boolean) =>
  queryOptions({
    queryKey: avdelingstilhorighetKeys.kvitteringId(kvitteringId),
    queryFn: async () => {
      const { data } = await avdelingstilhorighetApi.get(kvitteringId)
      return data
    },
    enabled: !!kvitteringId && enabled,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
  })

const useAvdelingstilhorighet = (kvitteringId: string, enabled = true) =>
  useQuery(avdelingstilhorighetOptions(kvitteringId, enabled))
