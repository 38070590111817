import React, { Dispatch, ReactNode, createContext, useContext, useReducer, useMemo, useState, useEffect } from 'react';
import { unleashToggles } from '../features/unleash-toggles';
import { IToggleNames } from '../features/unleash-toggles/types';
import { queryApi } from '../api';
import { useIsQueryClientReady } from '../utils/query-client-ready';

/** Use only with ActiveTogglesView */
export const togglesValues = ['tilsynskvitteringOnlyTesters', 'tkArkivering'] as const;
const createToggles = <T extends typeof togglesValues, TResult = { [K in T[number]]: K }>(values: T): TResult => values.reduce((res, toggle) => ({
  ...res,
  [toggle]: toggle
}), {} as TResult);
export const TOGGLES = createToggles(togglesValues);
export const useUnleashToggles = (toggleName: keyof typeof TOGGLES) => {
  const {
    enabled: isOnlyTestersEnabled
  } = unleashToggles.useUnleashTogglesHooks(TOGGLES.tilsynskvitteringOnlyTesters);
  const unleashToggle = unleashToggles.useUnleashTogglesHooks(toggleName);
  const localToggle = useLocalToggle(toggleName);
  if (isOnlyTestersEnabled && localToggle) {
    return localToggle;
  }
  return unleashToggle;
};
export const fetchToggle = (toggleName: IToggleNames) => unleashToggles.actions.fetch(toggleName);

/** TODO: Fix up unleash */
export const useSafeUnleashWithoutAnnoyingBugs = (toggleName: keyof typeof TOGGLES) => {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const isReady = useIsQueryClientReady();
  useEffect(() => {
    if (!isReady) {
      return;
    }
    const fetchData = async () => {
      try {
        const response = await queryApi.get<boolean>(`/api/bruker-api/feature-toggles/${toggleName}`);
        setIsEnabled(response.data);
        setIsLoading(false);
      } catch (err) {
        setError((err as Error).message);
        setIsLoading(false);
      }
    };
    void fetchData();
  }, [isReady, toggleName]);
  return {
    isEnabled,
    isLoading,
    error
  };
};

//
///
/// LOCAL TOGGLE OVERRIDE
type LocalToggleState = Partial<Record<keyof typeof TOGGLES, boolean>>;
type ActionTypes = keyof typeof TOGGLES | 'reset';
interface Actions {
  type: ActionTypes;
  payload?: boolean;
}
type ReducerFn = (state: LocalToggleState, action: Actions) => LocalToggleState;
type ActionMapper = Record<ActionTypes, ReducerFn>;
const getLocalToggles = () => {
  const localState = localStorage.getItem('__local-toggles__');
  if (!localState) {
    return null;
  }
  return JSON.parse(localState) as LocalToggleState;
};
const setLocalToggles = (localToggleState: LocalToggleState) => localStorage.setItem('__local-toggles__', JSON.stringify(localToggleState));
const initialState = (getLocal = true) => {
  if (!getLocal) {
    return {};
  }
  return getLocalToggles() ?? {};
};
const actionMapper = togglesValues.reduce((mapper, type) => {
  const reducerFn: ReducerFn = (state, {
    type,
    payload
  }) => {
    const updatedState = {
      ...state,
      [type]: payload
    };
    setLocalToggles(updatedState);
    return updatedState;
  };
  return {
    ...mapper,
    [type]: reducerFn
  };
},
// initial value
{
  reset: () => {
    const resetState = initialState(false);
    setLocalToggles(resetState);
    return resetState;
  }
} as unknown as ActionMapper);
const reducer: ReducerFn = (state, action) => {
  return actionMapper[action.type](state, action);
};
const LocalToggleContext = createContext({} as {
  state: LocalToggleState;
  dispatch: Dispatch<Actions>;
});
export const LocalToggleProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState());
  const value = useMemo(() => ({
    state,
    dispatch
  }), [state, dispatch]);
  return <LocalToggleContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="LocalToggleProvider" data-sentry-source-file="unleashToggles.tsx">
      {children}
    </LocalToggleContext.Provider>;
};
export const useLocalToggles = () => useContext(LocalToggleContext);
export const useLocalToggle = (toggle: keyof typeof TOGGLES) => {
  const {
    state
  } = useLocalToggles();
  const localToggle = state[toggle];
  if (localToggle === undefined) {
    return undefined;
  }
  return {
    enabled: localToggle,
    loadingStatus: {
      loading: false,
      loaded: true,
      error: undefined
    },
    fetchToggle: () => {
      /**/
    }
  };
};
export const useLocalToggleDispatch = () => {
  const {
    dispatch
  } = useLocalToggles();
  return dispatch;
};
/// LOCAL TOGGLE OVERRIDE
///
//