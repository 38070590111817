import { of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { INotification } from '../../store/types'

const withNotificationActions = (db, action) => (stream$) =>
  stream$.pipe(
    mergeMap((notificationList) =>
      of(notificationList).pipe(
        mergeMap(() => db.getStagedActions(action)),
        map((notificationAction: any) =>
          notificationAction.map((notificationAction) => notificationAction.id)
        ),
        map((actionNotifications) => ({
          actionNotifications,
          notificationList,
        }))
      )
    )
  )

export const removeOfflineDeletedNotifications = (db) => (stream$) =>
  stream$.pipe(
    withNotificationActions(db, 'DELETE'),
    map(({ notificationList, actionNotifications }: any) =>
      notificationList.filter(
        (notification) => !actionNotifications.includes(notification.id)
      )
    )
  )

export const setOfflineNotificationsSeen = (db) => (stream$) =>
  stream$.pipe(
    withNotificationActions(db, 'SEEN'),
    map(({ notificationList, actionNotifications }: any) =>
      notificationList.map((notification) => {
        if (actionNotifications.includes(notification.id)) {
          return {
            ...notification,
            seen: new Date(),
          }
        } else {
          return notification
        }
      })
    )
  )

export const parseNotifications = (resp): INotification[] => {
  return (resp?._embedded?.notificationList || []).map((notification) => ({
    ...notification,
    withMoreInfo:
      notification.withMoreInfo && JSON.parse(notification.withMoreInfo),
    link: notification.link && JSON.parse(notification.link),
  }))
}
