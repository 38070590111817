import { CrossDeleteIcon, Row, RowItem, Text } from '@mattilsynet/mt-ui';
import React from 'react';
import { BildeGroupedItem } from '../../bilder/types';
import { DeleteSelectedImages } from './delete-selected-images';
import { RemoveSelectedImages } from './remove-selected-images';
import { AttachSelectedImages } from './attach-selected-images';
import '../style.css';
interface IImageActionsMenu {
  selectedImages: BildeGroupedItem[];
  onClose: () => void;
  clearSelectedImages: () => void;
}
export const ImageActionsMenu = ({
  selectedImages,
  onClose,
  clearSelectedImages
}: IImageActionsMenu) => {
  const selectedImagesCount = selectedImages.length > 1 ? `${selectedImages.length} bilder valgt` : '1 bilde valgt';
  const selectedImagesText = selectedImages.length === 0 ? 'Ingen bilder valgt' : selectedImagesCount;
  return <Row className="image-action-menu" padding={2} justify="space-between" data-sentry-element="Row" data-sentry-component="ImageActionsMenu" data-sentry-source-file="image-actions-menu.tsx">
      <RowItem alignSelf="center" data-sentry-element="RowItem" data-sentry-source-file="image-actions-menu.tsx">
        <Row spacing={2} data-sentry-element="Row" data-sentry-source-file="image-actions-menu.tsx">
          <button onClick={onClose} className="image-action-menu__button">
            <CrossDeleteIcon size="large" data-sentry-element="CrossDeleteIcon" data-sentry-source-file="image-actions-menu.tsx" />
          </button>
          <Text data-sentry-element="Text" data-sentry-source-file="image-actions-menu.tsx">{selectedImagesText}</Text>
        </Row>
      </RowItem>
      <RowItem data-sentry-element="RowItem" data-sentry-source-file="image-actions-menu.tsx">
        <Row spacing={2} data-sentry-element="Row" data-sentry-source-file="image-actions-menu.tsx">
          <RowItem data-sentry-element="RowItem" data-sentry-source-file="image-actions-menu.tsx">
            <DeleteSelectedImages selectedImages={selectedImages} clearSelectedImages={clearSelectedImages} data-sentry-element="DeleteSelectedImages" data-sentry-source-file="image-actions-menu.tsx" />
          </RowItem>
          <RowItem data-sentry-element="RowItem" data-sentry-source-file="image-actions-menu.tsx">
            <AttachSelectedImages selectedImages={selectedImages} clearSelectedImages={clearSelectedImages} data-sentry-element="AttachSelectedImages" data-sentry-source-file="image-actions-menu.tsx" />
          </RowItem>
          <RowItem data-sentry-element="RowItem" data-sentry-source-file="image-actions-menu.tsx">
            <RemoveSelectedImages selectedImages={selectedImages} clearSelectedImages={clearSelectedImages} data-sentry-element="RemoveSelectedImages" data-sentry-source-file="image-actions-menu.tsx" />
          </RowItem>
        </Row>
      </RowItem>
    </Row>;
};