import { of } from 'rxjs'
import { combineEpics, Epic } from 'redux-observable'
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators'
import { unleashTogglesActions, unleashTogglesActionTypes } from './store'
import { IUnleashTogglesActions } from './types'
import { commonRedux } from '../../common/redux'
import { createQueryStringFromObject } from '../../common/createQueryStringFromObject'
import { ICommonApi } from '../../api'

const { isOfType } = commonRedux

const BASE_URL = '/api/bruker-api/feature-toggles'

export const fetchUnleashToggleEpic =
  (
    commonApi: ICommonApi
  ): Epic<IUnleashTogglesActions, IUnleashTogglesActions, any> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(unleashTogglesActionTypes.FETCH)),
      withLatestFrom(state$),
      mergeMap(([{ toggleName, queries }, state]) => {
        const URL = `${BASE_URL}/${toggleName}${createQueryStringFromObject(
          '?'
        )(queries)}`
        return of(state).pipe(
          commonApi.get(URL, state),
          map((enabled) => unleashTogglesActions.fetchOk(toggleName, enabled)),
          catchError((err) =>
            of(unleashTogglesActions.fetchFail(toggleName, err.message))
          )
        )
      })
    )

const unleashTogglesEpics = (commonApi: ICommonApi) =>
  combineEpics(fetchUnleashToggleEpic(commonApi))

export default unleashTogglesEpics
