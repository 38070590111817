import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { LoadingDots, Row, DatePicker, CheckboxItem, Text, CalendarIcon, RowItem } from '@mattilsynet/mt-ui';
import dayjs from 'dayjs';
import { IKvitteringData, IKvitteringSavingStatus, SAVING } from '../../ducks/kvittering/types';
import { kvitteringActions } from '../../ducks/kvittering/actions';
import { useDispatch } from 'react-redux';
import './style.css';
interface ITilsynsdatoPickerProps {
  currentKvittering: IKvitteringData;
  currentKvitteringSavingStatus: IKvitteringSavingStatus;
}
const CalendarInput = forwardRef(
//  eslint-disable-next-line @typescript-eslint/no-unused-vars
({
  onClick,
  isLoading,
  value
}: any, ref) => <Row className="date-display__pickers--input" onClick={onClick} spacing={2} align="center">
      <RowItem>
        {isLoading ? <LoadingDots /> : <Text>{dayjs(value).format('DD. MMM YYYY')}</Text>}
      </RowItem>
      <RowItem display="flex">
        <CalendarIcon size="medium" />
      </RowItem>
    </Row>);
CalendarInput.displayName = 'CalendarInput';
export const TilsynsdatoPicker = ({
  currentKvittering,
  currentKvitteringSavingStatus
}: ITilsynsdatoPickerProps) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<string>(currentKvittering.tilsynsdato || dayjs().toISOString());
  const [endDate, setEndDate] = useState<string | undefined>(currentKvittering.tilsynsdatoSlutt ?? undefined);
  const [isTilsynOverFlereDager, setIsTilsynOverFlereDager] = useState<boolean>(!!(startDate && endDate));
  const isLoading = currentKvitteringSavingStatus === SAVING && !currentKvittering.tilsynsdato;
  const updateTilsynsdato = useCallback((date: string, endDate: string | undefined) => {
    if (date) {
      dispatch(kvitteringActions.updateCurrentKvittering({
        tilsynsdato: dayjs(date).format('YYYY-MM-DD'),
        tilsynsdatoSlutt: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined
      }));
    }
  }, [dispatch]);
  const onChangeTilsynsdatoStart = (date: string) => {
    if (date) {
      setStartDate(date);
      updateTilsynsdato(date, endDate);
    }
    // if start date is after or equal to end date, set end date to start date + 1 day
    if (dayjs(date).isAfter(dayjs(endDate)) || dayjs(date).isSame(dayjs(endDate))) {
      const tomorrow = dayjs(date).add(1, 'day').toISOString();
      setEndDate(tomorrow);
      updateTilsynsdato(date, tomorrow);
    }
  };
  const onChangeTilsynsdatoEnd = (date: string) => {
    if (date) {
      setEndDate(date);
      updateTilsynsdato(startDate, date);
    }
  };
  const onChangeIsTilsynOverFlereDager = (checked: boolean) => {
    const today = dayjs().toISOString();
    setIsTilsynOverFlereDager(checked);

    // Reset start and end date if user unchecks
    if (!checked) {
      setEndDate('');
      setStartDate(today);
      updateTilsynsdato(today, '');
    }

    // Set start date to yesterday and end date today if user checks
    if (checked) {
      const yesterday = dayjs().subtract(1, 'day').toISOString();
      setStartDate(yesterday);
      setEndDate(today);
      updateTilsynsdato(yesterday, today);
    }
  };

  // Sets tilsynsdato to today's date if not set
  useEffect(() => {
    if (!currentKvittering.tilsynsdato) {
      updateTilsynsdato(dayjs().toISOString(), undefined);
    }
  }, [currentKvittering.tilsynsdato, updateTilsynsdato]);
  return <div data-sentry-component="TilsynsdatoPicker" data-sentry-source-file="index.tsx">
      <Row align="center" className="date-display" data-sentry-element="Row" data-sentry-source-file="index.tsx">
        <Row className="date-display__title" data-sentry-element="Row" data-sentry-source-file="index.tsx">
          <Text weight="bold" data-sentry-element="Text" data-sentry-source-file="index.tsx">Dato for tilsyn</Text>
        </Row>
        <Row align="center" className="date-display__pickers" data-sentry-element="Row" data-sentry-source-file="index.tsx">
          {isTilsynOverFlereDager && <Text size="normal" margin={[0, 1, 0, 0]}>
              fra
            </Text>}
          <Row align="center" padding={[0.25, 1, 0.25, 1]} className="date-display__pickers--row" data-sentry-element="Row" data-sentry-source-file="index.tsx">
            <DatePicker ariaLabel="date" date={startDate} setDate={onChangeTilsynsdatoStart} minDate={isTilsynOverFlereDager ? dayjs().subtract(1, 'year').toISOString() : dayjs().subtract(1, 'week').toISOString()} maxDate={isTilsynOverFlereDager ? dayjs().subtract(1, 'day').toISOString() : dayjs().toISOString()} customInput={<CalendarInput isLoading={isLoading} />} data-sentry-element="DatePicker" data-sentry-source-file="index.tsx" />
          </Row>

          {isTilsynOverFlereDager && <>
              <Text size="normal" margin={[0, 1, 0, 1]}>
                til
              </Text>
              <Row align="center" className="date-display__pickers--row">
                <Row align="center" padding={[0.25, 1, 0.25, 1]}>
                  <DatePicker ariaLabel="date" date={endDate ?? undefined} setDate={onChangeTilsynsdatoEnd} minDate={dayjs(startDate).add(1, 'day').toISOString()} maxDate={dayjs().toISOString()} customInput={<CalendarInput isLoading={isLoading} />} />
                </Row>
              </Row>
            </>}
        </Row>
      </Row>
      <div className="date-display__has-end-date">
        <CheckboxItem onClick={() => onChangeIsTilsynOverFlereDager(!isTilsynOverFlereDager)} selected={isTilsynOverFlereDager} data-sentry-element="CheckboxItem" data-sentry-source-file="index.tsx">
          Tilsyn over flere dager
        </CheckboxItem>
      </div>
    </div>;
};