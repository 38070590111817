import React from 'react';
import { Column, Header, Text } from '@mattilsynet/mt-ui';
import { IPrivatPersonAdresse } from '../../ducks/kvittering/types';
interface IPrivatPersonAdresseInfo {
  adresse?: IPrivatPersonAdresse;
  tittel: string;
}
export const PrivatPersonAdresseInfo = ({
  adresse,
  tittel
}: IPrivatPersonAdresseInfo) => {
  if (!adresse) return null;
  return <Column justify={'flex-start'} align={'flex-start'} spacing={1} data-sentry-element="Column" data-sentry-component="PrivatPersonAdresseInfo" data-sentry-source-file="index.tsx">
      <Header as="h3" size="normal" data-sentry-element="Header" data-sentry-source-file="index.tsx">
        {tittel}
      </Header>
      <Column data-sentry-element="Column" data-sentry-source-file="index.tsx">
        <Text textAlign="left" data-sentry-element="Text" data-sentry-source-file="index.tsx">{adresse.gateadresse}</Text>
      </Column>
      <Column data-sentry-element="Column" data-sentry-source-file="index.tsx">
        <Text data-sentry-element="Text" data-sentry-source-file="index.tsx">{`${adresse.postNr || ''} ${adresse.poststed || ''}`}</Text>
      </Column>
    </Column>;
};