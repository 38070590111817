import React from 'react';
import { useMutationState } from '@tanstack/react-query';
import { Text } from '@mattilsynet/mt-ui';
import './style.css';
import { SyncIcon } from '../../assets/sync-icon';
import { useTypedSelector } from '../../common/custom-hooks';
import { IStoreState } from '../../reducers/types';
import { isErrorMutationState } from '../../common/query';
interface SynkStatusProps {
  kvitteringId: string;
}
export const SynkStatus = ({
  kvitteringId
}: SynkStatusProps) => {
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline);
  const mutations = useKvitteringMutations(kvitteringId);
  const notSettled = mutations.filter(state => state.status !== 'success' && !isErrorMutationState(state));
  const percent = 1 - notSettled.length / (mutations.length || 1);
  const displayPercent = new Intl.NumberFormat('nb-NO', {
    style: 'percent'
  }).format(percent);
  if (isOffline || percent === 1) {
    return null;
  }
  return <div className="synk-status" data-sentry-component="SynkStatus" data-sentry-source-file="index.tsx">
      <SyncIcon className="synk-status__icon" data-sentry-element="SyncIcon" data-sentry-source-file="index.tsx" />
      <Text data-sentry-element="Text" data-sentry-source-file="index.tsx">{displayPercent} av kvitteringen er lagret i sky</Text>
    </div>;
};
const useKvitteringMutations = (kvitteringId: string) => useMutationState({
  filters: {
    predicate: mutation => {
      const key = mutation.options.mutationKey?.[0] as Record<string, unknown>;
      if (!key) {
        return false;
      }
      return key.kvitteringId === kvitteringId;
    }
  }
});