import {
  IProdusentFunnHistorikkActions,
  IProdusentFunnHistorikkData,
  IProdusentFunnHistorikkState,
} from './types'
import { ROOT_STORE_NAME } from '../../../store'
import { IStoreState } from '../../../../../reducers/types'
import { commonRedux } from '../../../../../common/redux'

export const STORE_NAME = '@tilsyn/funnHistorikk'

const { fetchWithIndexHandlers, fetchActions, fetchActionTypes } =
  commonRedux.fetchWithIndexHelpers<
    IProdusentFunnHistorikkData,
    typeof STORE_NAME
  >(STORE_NAME, 'tilsynsobjektId')

export const funnHistorikkActionTypes = fetchActionTypes

export const funnHistorikkActions = fetchActions

export const funnHistorikkSelectors = {
  getById: (id: string) => (state: IStoreState) =>
    state[ROOT_STORE_NAME][STORE_NAME]?.data[id] ?? {
      loading: true,
      error: '',
    },
}

const customReducer = commonRedux.createReducer<
  IProdusentFunnHistorikkState,
  IProdusentFunnHistorikkActions
>(commonRedux.initialListState, fetchWithIndexHandlers)

export const funnHistorikkReducer = {
  [STORE_NAME]: customReducer,
}
