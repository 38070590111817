import { combineEpics } from 'redux-observable'
import { ICommonApi } from '../../api'
import { brreg } from '../brreg'
import { ENDPOINT } from '../../constants'
import { kodeverk } from '../kodeverk'
import observasjonerEpic from './ducks/observasjoner/epic'
import { orgenhetEpics } from './ducks/orgenhet'
import kassasjonerEpics from './ducks/kassasjoner/epic'
import produsentFunnHistorikkEpics from './ducks/funn-historikk/epic'
import produsentMeldingsHistorikkEpic from './ducks/produsent-meldings-historikk/epic'
import proveanalysesvarEpic from './ducks/proveanalysesvar/epic'
import restriksjonerEpic from './ducks/restriksjoner/epic'
import tilsynsobjektEpic from './ducks/tilsynsobjekt/epic'
import tidligereTlsynskvitteringEpic from './ducks/tidligere-tilsynskvitteringer/epic'
import faktabeskrivelserTidligereTilsynEpic from './ducks/faktabeskrivelser-tidligere-tilsyn/epic'

// To be used with tk-sammenstilling
export let _commonApi: ICommonApi | null = null

export const epics = (commonApi: ICommonApi, authTokenPath) => {
  _commonApi = commonApi

  return combineEpics(
    brreg.epics(commonApi as any, ENDPOINT.VIRKSOMHET) as any,
    kodeverk.epic(authTokenPath, ENDPOINT.KODEVERK),
    observasjonerEpic(commonApi) as any,
    orgenhetEpics(commonApi) as any,
    kassasjonerEpics(commonApi) as any,
    produsentFunnHistorikkEpics(commonApi) as any,
    produsentMeldingsHistorikkEpic(commonApi) as any,
    proveanalysesvarEpic(commonApi) as any,
    restriksjonerEpic(commonApi) as any,
    tilsynsobjektEpic(commonApi) as any,
    tidligereTlsynskvitteringEpic(commonApi) as any,
    faktabeskrivelserTidligereTilsynEpic(commonApi) as any
  )
}
