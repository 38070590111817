import { SearchDraft } from '../../features/virksomhet-search'
import { IVirksomhetsSearchQueries, IVirksomhetsSearchResults } from './types'

const STORE_NAME = 'virksomhetSearch'

const { search, Search } = SearchDraft<
  'virksomhetSearch',
  IVirksomhetsSearchQueries,
  IVirksomhetsSearchResults
>(STORE_NAME)

export const virksomhetSearch = search
export const VirksomhetSearchInputWrapper = Search.SearchInputWrapper
