import React, { memo, useCallback } from 'react';
import { IImage, IImageSizes } from '../../features/bilder/types';
import './styles.css';
import { isImageValid } from '../../validators/text-input-validators';
import { useBilde, BildeStatus, BildeThumbnail, useUploadStatus, BildeUploadStatus, useBildeMetadata } from '../../features/bilder';
import { useKvitteringId } from '../../common/kvittering-context';
import { RoundIndicator } from '@mattilsynet/mt-ui';
const ImageThumbnailWrapper = memo(function ImageThumbnailWrapper({
  imageId,
  indicator,
  onClick,
  onRemove,
  preferredImageSize = 'small',
  selected,
  shouldValidate
}: {
  imageId: string;
  indicator?: JSX.Element;
  onClick?: (imageId: string) => void;
  onRemove?: (imageId: string) => void;
  preferredImageSize?: IImageSizes;
  selected?: boolean;
  shouldValidate?: boolean;
}) {
  const kvitteringId = useKvitteringId();
  const [data] = useBildeMetadata(kvitteringId, imageId);
  const bildeMetadata = data ?? {
    id: imageId
  } as IImage;
  const [url] = useBilde(kvitteringId, bildeMetadata?.id ?? '', preferredImageSize);
  const uploadStatus = useUploadStatus(imageId);
  const imageStatusMap: Record<BildeUploadStatus, BildeStatus> = {
    [BildeUploadStatus.WAITING]: 'NOT_UPLOADED',
    [BildeUploadStatus.UPLOADING]: 'UPLOADING',
    [BildeUploadStatus.UPLOADED]: 'SUCCESS',
    [BildeUploadStatus.ERROR]: 'ERROR'
  };
  let isValid: boolean | undefined = undefined;
  if (shouldValidate && bildeMetadata) {
    isValid = isImageValid(bildeMetadata);
  }
  let imageStatus: BildeStatus = 'UNDEFINED';
  if (isValid === false && [BildeUploadStatus.UPLOADED, undefined].includes(uploadStatus)) {
    imageStatus = 'INVALID';
  } else if (uploadStatus) {
    imageStatus = imageStatusMap[uploadStatus];
  }
  const onThumbnailClick = useCallback(() => {
    if (bildeMetadata?.id) {
      onClick?.(bildeMetadata.id);
    }
  }, [bildeMetadata?.id, onClick]);
  const onThumbnailRemove = useCallback(() => {
    if (bildeMetadata?.id) {
      onRemove?.(bildeMetadata.id);
    }
  }, [bildeMetadata?.id, onRemove]);
  return <BildeThumbnail alt={bildeMetadata?.description ?? ''} url={url ?? ''} status={imageStatus} onClick={onThumbnailClick} onRemove={onRemove ? onThumbnailRemove : undefined} tag={indicator !== undefined ? <RoundIndicator>O</RoundIndicator> : undefined} isSelected={selected} />;
});
export { ImageThumbnailWrapper };