import React, { ReactNode, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SwipeableHandlers, useSwipeable } from 'react-swipeable';
import { Column, Row, ExpandArrow, TextLink } from '@mattilsynet/mt-ui';
import { ImageThumbnailWrapper } from '../../../../components/image-thumbnail-wrapper';
import { uiActions } from '../../../../ducks/ui/actions';
import { useMediaQuery } from 'react-responsive';
import './style.css';
interface ImageNavigationProps {
  currentImageId: string;
  bildeIds: string[];
  children: (handlers: {
    handlers: SwipeableHandlers;
    onFocusInput: () => void;
    onBlurInput: () => void;
  }) => ReactNode;
}
const ImageNavigation = ({
  currentImageId,
  bildeIds,
  children
}: ImageNavigationProps) => {
  const dispatch = useDispatch();
  const smallDevice = useMediaQuery({
    maxWidth: '767px'
  });
  const [isTyping, setIsTyping] = useState(false);
  const indexOfCurrent = bildeIds.indexOf(currentImageId);
  const isFirst = indexOfCurrent === 0;
  const isLast = indexOfCurrent === bildeIds.length - 1;
  const setCurrentImageId = useCallback((id: string) => dispatch(uiActions.setImageNavigationImage(id)), [dispatch]);
  const navigateToImage = useCallback((direction?: 'LEFT' | 'RIGHT') => {
    if (bildeIds) {
      if (direction === 'LEFT' && indexOfCurrent !== 0) {
        setCurrentImageId(bildeIds[indexOfCurrent - 1]);
      } else if (direction === 'RIGHT' && indexOfCurrent < bildeIds.length - 1) {
        setCurrentImageId(bildeIds[indexOfCurrent + 1]);
      }
    }
  }, [bildeIds, indexOfCurrent, setCurrentImageId]);
  const onKeyUp = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (isTyping) return;
    if (e.keyCode === 39) {
      navigateToImage('RIGHT');
    }
    if (e.keyCode === 37) {
      navigateToImage('LEFT');
    }
  }, [isTyping, navigateToImage]);
  const handlers = useSwipeable({
    onSwipedLeft: () => navigateToImage('RIGHT'),
    onSwipedRight: () => navigateToImage('LEFT')
  });
  const onFocusInput = useCallback(() => setIsTyping(true), []);
  const onBlurInput = useCallback(() => setIsTyping(false), []);
  return <div className={'image-navigation'} tabIndex={0} onKeyUp={e => onKeyUp(e)} data-sentry-component="ImageNavigation" data-sentry-source-file="index.tsx">
      <Column padding={[2, 3, 7, 3]} className={'image-modal-container'} data-sentry-element="Column" data-sentry-source-file="index.tsx">
        {smallDevice && <Row justify={'space-between'} padding={[0, 0, 2, 0]}>
            <TextLink leftArrow onClick={() => navigateToImage('LEFT')} disabled={isFirst}>
              Forrige
            </TextLink>
            <TextLink rightArrow onClick={() => navigateToImage('RIGHT')} disabled={isLast}>
              Neste
            </TextLink>
          </Row>}

        <Row className={'image-modal'} data-sentry-element="Row" data-sentry-source-file="index.tsx">
          {!smallDevice && <Column onClick={() => navigateToImage('LEFT')} align={'center'} justify={'center'} margin={[0, 1]}>
              <ExpandArrow rotateDirection={'LEFT'} color={isFirst ? 'gray' : 'black'} />
            </Column>}

          {children({
          handlers,
          onFocusInput,
          onBlurInput
        })}

          {!smallDevice && <Column onClick={() => navigateToImage('RIGHT')} align={'center'} justify={'center'} margin={[0, 1]}>
              <ExpandArrow rotateDirection={'RIGHT'} color={isLast ? 'gray' : 'black'} />
            </Column>}
        </Row>

        <Row justify="center" className="thumbnail-list" padding={smallDevice ? [2, 0] : [2, 7]} data-sentry-element="Row" data-sentry-source-file="index.tsx">
          {bildeIds.map(id => <ImageThumbnailWrapper key={id} imageId={id} preferredImageSize="small" onClick={setCurrentImageId} selected={currentImageId === id} shouldValidate />)}
        </Row>
      </Column>
    </div>;
};
export { ImageNavigation };