import { BildeKvitteringUploadOptions, IImage } from '../types'
import { kvitteringQueryHelpers } from '../../kvitteringer/queries/helpers'
import { removeBildeUrl, setBildeUrl, toValidCoord } from '../metadata-helpers'
import { queryClient } from '../../../api/query-client'
import { bildeKeys, metadataQueryHelpers, withPersistedBilde } from './helpers'
import { bildeApi } from './bilde-api'
import { offlineLexicon } from '../../offline-lexicon'
import { Draft } from 'immer'
import { AxiosError } from 'axios'
import { removeBildeIdFromKvittering } from '../cache-helpers'
import { shouldIgnoreError } from '../../../common/query'
import store from '../../../reducers/store'
import { dangerToast } from '../../../common/toast'

/**
 * Optimistically updates bildeIds in the cache.
 *
 * @returns Previous bildeIds (Context that
 * is passed to onError).
 */
const onMutate: (
  updateBilder: (bildeIds: string[], bildeId: string) => string[],
  updateMetadata?: (
    draftMetadataList: Draft<IImage[]>,
    bildeMetadata: IImage
  ) => void
) => BildeKvitteringUploadOptions['onMutate'] =
  (updateBilder, updateMetadata) =>
  async ({ kvitteringId, bilde, bildeId, bildeMetadata }) => {
    await kvitteringQueryHelpers.cancelKvitteringQuery(kvitteringId)

    const kvittering = kvitteringQueryHelpers.getKvitteringCache(kvitteringId)
    const previousBilder = kvittering?.bildeIds ?? []
    const previousMetadataList =
      metadataQueryHelpers.getMetadataList(kvitteringId) ?? []

    kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (draft) => {
      if (bilde === undefined) {
        removeBildeIdFromKvittering(draft, bildeId)
        return
      }

      draft.bildeIds = updateBilder(draft.bildeIds, bildeId)
    })

    if (bildeMetadata !== undefined && updateMetadata !== undefined) {
      metadataQueryHelpers.updateMetadataList(kvitteringId, (draft) =>
        updateMetadata(draft ?? [], {
          ...bildeMetadata,
          localData: { isOffline: true },
        })
      )
    }

    // Only on upload
    if (bilde !== undefined) {
      // Creates local image url to use in img tag
      setBildeUrl(kvitteringId, bildeId, bilde, 'local')
    }

    return { previousBilder, previousMetadataList }
  }

/** Displays error toast and reverts cache to previous bildeIds. */
const onError: BildeKvitteringUploadOptions['onError'] = (
  error,
  { kvitteringId, bildeId, bilde },
  context
) => {
  if (shouldIgnoreError(error)) {
    return
  }

  store.dispatch(
    dangerToast('Det har skjedd en feil med bildene. Kunne ikke lagre.')
  )

  kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (draft) => {
    draft.bildeIds = (context?.previousBilder as string[]) ?? draft.bildeIds
  })

  metadataQueryHelpers.updateMetadataList(
    kvitteringId,
    (draft) => (context?.previousMetadataList as IImage[]) ?? draft
  )

  // Only on upload
  if (bilde !== undefined) {
    removeBildeUrl(kvitteringId, bildeId)
  }
}

// Leaving it here for now, in case we need it later
/** Refetches kvittering and bildeMetadatas either on success or error. */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onSettled: BildeKvitteringUploadOptions['onSettled'] = (
  _,
  __,
  { kvitteringId }
) =>
  Promise.all([
    kvitteringQueryHelpers.invalidateKvittering(kvitteringId),
    queryClient.invalidateQueries({
      queryKey: bildeKeys.metadataList(kvitteringId),
    }),
  ])

// Upload to kvittering
queryClient.setMutationDefaults(bildeKeys.uploadToKvitteringBase(), {
  mutationFn: async (variables) => {
    try {
      const metadata = variables.bildeMetadata!

      await bildeApi.upload({
        bildeMetadata: {
          ...metadata,
          locationLatitude: toValidCoord(metadata.locationLatitude),
          locationLongitude: toValidCoord(metadata.locationLongitude),
        },
        bilde: variables.bilde!,
      })
    } catch (error) {
      // 409: image is already uploaded
      if (error instanceof AxiosError && error.response?.status !== 409) {
        throw error
      }
    }

    return bildeApi.attachToKvittering(variables)
  },
  onMutate: onMutate(
    (bildeIds, bildeId) => [...bildeIds, bildeId],
    (metadataList, bildeMetadata) => [...metadataList, bildeMetadata]
  ),
  onSuccess: (_, { kvitteringId, bildeId, bildeMetadata }) => {
    // Used to indicate that the image is uploaded
    offlineLexicon.set(
      offlineLexicon.types.bilde,
      bildeId,
      kvitteringId,
      bildeMetadata!
    )
  },
  onError: onError,
} satisfies BildeKvitteringUploadOptions)

// Delete bilde from kvittering
queryClient.setMutationDefaults(bildeKeys.deleteFromKvitteringBase(), {
  mutationFn: withPersistedBilde(async (variables) => {
    return bildeApi.delete(variables)
  }),
  onMutate: onMutate(
    (bildeIds, bildeId) => bildeIds.filter((bilde) => bilde !== bildeId),
    (metadataList, bildeMetadata) =>
      metadataList.filter((metadata) => metadata.id !== bildeMetadata.id)
  ),
  onError: onError,
} satisfies BildeKvitteringUploadOptions)
