import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
  AnyAction,
} from 'redux'
import {
  persistStore,
  persistReducer,
  createMigrate,
  createTransform,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { authSubject } from '../auth/keycloak'
import { userActions } from '../ducks/user/actions'
import { createEpicMiddleware } from 'redux-observable'
import { IStoreState } from './types'
import epics from '../epics'
import { ansatteReducer } from '../ducks/ansatte/reducer'
import uiReducer from '../ducks/ui/reducer'
import userReducer from '../ducks/user/reducer'
import kvitteringReducer from '../ducks/kvittering/reducer'
import { userSettingsReducer } from '../ducks/user-settings/store'
import sakReducer from '../ducks/sak/reducer'
import { virksomhetSearch } from '../ducks/virksomhet-search'
import { hentKvitteringBegrunnelserReducer } from '../ducks/hent-kvittering-begrunnelse/reducer'
import { kodeverk } from '../features/kodeverk'
import { notifications } from '../features/notifications'
import arkiveringStatusReducer from '../ducks/arkivering-status/reducer'
import { IKvitteringState } from '../ducks/kvittering/types'
import { bildeReducer } from '../features/bilder/bilde-slice'
import { tidligereKontrollpunkterReducer } from '../features/tidligere-kontrollpunkter/tidligere-kontrollpunkter-state'
import { toast } from '../features/toast'
import { unleashToggles } from '../features/unleash-toggles'
import { tilsynsobjektinfo } from '../features/tilsynsobjektinfo'

const epicMiddleware = createEpicMiddleware<
  AnyAction,
  AnyAction,
  IStoreState,
  void
>()

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancers = composeEnhancers(applyMiddleware(epicMiddleware))

const reducers = {
  ansatte: ansatteReducer,
  arkiveringStatus: arkiveringStatusReducer,
  kvittering: kvitteringReducer,
  sak: sakReducer,
  hentKvitteringBegrunnelser: hentKvitteringBegrunnelserReducer,
  bilder: bildeReducer,
  tidligereKontrollpunkter: tidligereKontrollpunkterReducer,
  ...tilsynsobjektinfo.reducer,
  ...toast.reducer,
  ...uiReducer,
  ...userReducer,
  ...virksomhetSearch.reducer,
  ...userSettingsReducer,
  ...kodeverk.reducer,
  ...notifications.reducer,
  ...unleashToggles.reducer,
}

const rootReducer = combineReducers(reducers)

const imageObjectTransform = createTransform<
  IKvitteringState,
  IKvitteringState
>(
  (inboundState) => {
    return inboundState
  },
  (outboundState) => {
    return { ...outboundState, currentKvitteringId: '' }
  },
  { whitelist: ['kvittering'] }
)

const migrations = {
  0: (state) => {
    return {
      ...state,
    }
  },
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['kvittering'],
  version: 0,
  migrate: createMigrate(migrations, { debug: false }),
  transforms: [imageObjectTransform],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store: Store<IStoreState> = createStore(persistedReducer, enhancers)
epicMiddleware.run(epics)

// Sync token with redux-store
authSubject.subscribe((keycloak) => {
  keycloak.addListener('authSuccess', ({ accessToken }) => {
    store.dispatch(userActions.updateToken(accessToken))
  })

  keycloak.addListener('refreshSuccess', ({ accessToken }) => {
    store.dispatch(userActions.updateToken(accessToken))
  })

  keycloak.addListener('refreshError', () => {
    store.dispatch(userActions.userExpired())
  })

  keycloak.addListener('logout', () => {
    store.dispatch(userActions.userSignedOut())
  })

  keycloak.addListener('tokenExpired', () => {
    keycloak.updateToken()
  })
})

export const persistor = persistStore(store)

export default store
