import React, { useCallback, useContext, useState } from 'react';
import { Button, Column, Grid, GridItem, LoadingSpinner, RadioButtonItem, Row, Text, ThemeContext } from '@mattilsynet/mt-ui';
import './style.css';
import { useTidligereKontrollpunkter } from '../hooks/query-hooks';
import { ITilsynsobjekt } from '../../tilsynsobjekter/types';
import { ITidligereKontrollpunktDto } from '../types';
import { useTypedSelector } from '../../../common/custom-hooks';
import { tidligereKontrollpunkterActions, tidligereKontrollpunkterSelectors } from '../tidligere-kontrollpunkter-state';
import { useDispatch } from 'react-redux';
import { compareSorteringsrekkefoelge, kontrollpunktInitialState } from '../../kontrollpunkter/helpers';
import { useAddKontrollpunkt } from '../../kontrollpunkter/hooks/query-hooks';
import { useTilsynsobjekter } from '../../tilsynsobjekter';
interface ITidligereKontrollpunkterProps {
  kvitteringId: string;
  onCancel: () => void;
}
export const TidligereKontrollpunkter = ({
  kvitteringId,
  onCancel
}: ITidligereKontrollpunkterProps) => {
  const dispatch = useDispatch();
  const {
    data: tilsynsobjekter
  } = useTilsynsobjekter(kvitteringId);
  const currentTilsynsobjektId = useTypedSelector(tidligereKontrollpunkterSelectors.selectTilsynsobjektId);
  const currentTilsynsobjekt = tilsynsobjekter!.find(tilsynsobjekt => tilsynsobjekt.id === currentTilsynsobjektId);
  const {
    data: tidligereKontrollpunkter,
    isLoading
  } = useTidligereKontrollpunkter([currentTilsynsobjekt].filter(Boolean) as ITilsynsobjekt[]);
  const theme = useContext(ThemeContext);
  const {
    mutate: addKontrollpunkt
  } = useAddKontrollpunkt(kvitteringId);
  const [selectedTKpTilsynsobjektId, setSelectedTKpTilsynsobjektId] = useState<number | null>(null);
  const onCancelTidligereKontrollpunkter = useCallback(() => {
    dispatch(tidligereKontrollpunkterActions.reset());
    onCancel();
  }, [dispatch, onCancel]);
  const onSelect = useCallback((tidligereKontrollpunkt: ITidligereKontrollpunktDto) => {
    setSelectedTKpTilsynsobjektId(tidligereKontrollpunkt.tilsynsobjektId);
  }, [setSelectedTKpTilsynsobjektId]);
  const onHentKontrollpunkter = useCallback(() => {
    const selectedTidligereTilsyn = tidligereKontrollpunkter?.find(tKp => tKp.tilsynsobjektId === selectedTKpTilsynsobjektId);
    selectedTidligereTilsyn?.kontrollpunkter?.slice().sort(compareSorteringsrekkefoelge).forEach(kontrollpunkt => {
      addKontrollpunkt({
        kontrollpunkt: {
          ...kontrollpunktInitialState(Number(currentTilsynsobjektId)),
          beskrivelse: kontrollpunkt.beskrivelse,
          sorteringsrekkefoelge: kontrollpunkt.sorteringsrekkefoelge
        }
      });
    });
    onCancelTidligereKontrollpunkter();
  }, [tidligereKontrollpunkter, onCancelTidligereKontrollpunkter, selectedTKpTilsynsobjektId, addKontrollpunkt, currentTilsynsobjektId]);
  const filteredTidligereKontrollpunkter = tidligereKontrollpunkter?.filter(tkp => tkp.kontrollpunkter.length > 0 && tkp.tilsynskvitteringId.toString() !== kvitteringId) ?? [];
  return <Grid className="tidligere-kontrollpunkter" data-sentry-element="Grid" data-sentry-component="TidligereKontrollpunkter" data-sentry-source-file="tidligere-kontrollpunkter.tsx">
      <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]} data-sentry-element="GridItem" data-sentry-source-file="tidligere-kontrollpunkter.tsx">
        <Grid data-sentry-element="Grid" data-sentry-source-file="tidligere-kontrollpunkter.tsx">
          <GridItem xl={[2, -2]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]} data-sentry-element="GridItem" data-sentry-source-file="tidligere-kontrollpunkter.tsx">
            <h1>
              Hent kontrollpunkter fra en tidligere tilsynskvittering på{' '}
              {currentTilsynsobjekt?.aktivitetsBeskrivelse ?? ''}
            </h1>
            <Column margin={[2, 0]} data-sentry-element="Column" data-sentry-source-file="tidligere-kontrollpunkter.tsx">
              {isLoading && <LoadingSpinner small title="Henter tidligere kontrollpunkter..." />}

              {!isLoading && filteredTidligereKontrollpunkter.length === 0 && <Text>Ingen tidligere kontrollpunkter funnet</Text>}

              {!isLoading && filteredTidligereKontrollpunkter.map((tKp, index) => {
              const backgroundColor = index % 2 ? theme.white : theme.gray7;
              return <TidligereKontrollpunkt key={tKp.tilsynsobjektId} tidligereKontrollpunkt={tKp} backgroundColor={backgroundColor} isSelected={selectedTKpTilsynsobjektId === tKp.tilsynsobjektId} onSelect={onSelect} />;
            })}

              <Row className="tidligere-kontrollpunkt-knapper" margin={[2, 0, 0, 0]} justify="space-between" spacing={1} data-sentry-element="Row" data-sentry-source-file="tidligere-kontrollpunkter.tsx">
                <Button width="18.75em" onClick={onCancelTidligereKontrollpunkter} secondary data-sentry-element="Button" data-sentry-source-file="tidligere-kontrollpunkter.tsx">
                  Tilbake
                </Button>
                <Button width="18.75em" disabled={!selectedTKpTilsynsobjektId} onClick={onHentKontrollpunkter} data-sentry-element="Button" data-sentry-source-file="tidligere-kontrollpunkter.tsx">
                  Hent
                </Button>
              </Row>
            </Column>
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>;
};
const TidligereKontrollpunkt = ({
  tidligereKontrollpunkt,
  backgroundColor,
  isSelected,
  onSelect
}: {
  tidligereKontrollpunkt: ITidligereKontrollpunktDto;
  backgroundColor: string;
  isSelected: boolean;
  onSelect: (tidligereKontrollpunkt: ITidligereKontrollpunktDto) => void;
}) => {
  const {
    virksomhetsnavn,
    kontrollpunkter,
    tilsynsdato,
    tilsynskvitteringId
  } = tidligereKontrollpunkt;
  const onClick = useCallback(() => {
    if (!isSelected) {
      onSelect(tidligereKontrollpunkt);
    }
  }, [onSelect, isSelected, tidligereKontrollpunkt]);
  return <Row padding={2} backgroundColor={backgroundColor} margin={[0, 0, 2, 0]} align="flex-start" className="tidligere-kontrollpunkt" onClick={onClick} data-sentry-element="Row" data-sentry-component="TidligereKontrollpunkt" data-sentry-source-file="tidligere-kontrollpunkter.tsx">
      <RadioButtonItem backgroundColor={backgroundColor} id={`${tilsynskvitteringId}/${tilsynsdato}`} onClick={() => false} selected={isSelected} data-sentry-element="RadioButtonItem" data-sentry-source-file="tidligere-kontrollpunkter.tsx">
        &nbsp;
      </RadioButtonItem>

      <div>
        <Text weight="bold" margin={[0, 0, 1, 0]} data-sentry-element="Text" data-sentry-source-file="tidligere-kontrollpunkter.tsx">
          {virksomhetsnavn}
        </Text>

        {kontrollpunkter.map((k, idx) => <Text className="kontrollpunkt-tekst" margin={[0, 0, 1, 1]} key={k.kontrollpunktId}>
            {idx + 1}. {k.beskrivelse}
          </Text>)}
      </div>
    </Row>;
};