import React from 'react';
import { Grid, GridItem } from '@mattilsynet/mt-ui';
import { useMediaQuery } from 'react-responsive';
import { Slider } from '../slider';
interface IKvitteringPage {
  slides: React.JSX.Element[];
  currentSlide: number;
  isLoading: boolean;
}
export const KvitteringPage = ({
  slides,
  currentSlide,
  isLoading
}: IKvitteringPage) => {
  const smallDevice = useMediaQuery({
    maxWidth: '767px'
  });
  return <Grid id="kvittering-page" data-sentry-element="Grid" data-sentry-component="KvitteringPage" data-sentry-source-file="index.tsx">
      <GridItem xl={[2, -2]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]} padding={[0.5, smallDevice ? 1 : 3]} data-sentry-element="GridItem" data-sentry-source-file="index.tsx">
        <Slider slides={slides} newSlide={currentSlide} loadingSlide={isLoading} data-sentry-element="Slider" data-sentry-source-file="index.tsx" />
      </GridItem>
    </Grid>;
};