import { IAmFunn, IAmFunnActions, IAmFunnPatch, IAmFunnState } from './types'
import * as R from 'ramda'
import dayjs from 'dayjs'
import { ROOT_STORE_NAME } from '../../../store'
import { IStoreState } from '../../../../../reducers/types'
import { commonRedux } from '../../../../../common/redux'

export const STORE_NAME = '@tilsyn/amFunn'

const { fetchActions, fetchActionTypes, fetchWithIndexHandlers } =
  commonRedux.fetchWithIndexHelpers<IAmFunn, typeof STORE_NAME>(
    STORE_NAME,
    'funnId'
  )

export const amFunnActionsTypes = {
  ...fetchActionTypes,
  POST: `${STORE_NAME}/POST` as const,
  POST_FAIL: `${STORE_NAME}/POST_FAIL` as const,
  POST_OK: `${STORE_NAME}/POST_OK` as const,
  PATCH_CURRENT_FUNN: `${STORE_NAME}/PATCH_CURRENT_FUNN` as const,
  PATCH_CURRENT_FUNN_FAIL: `${STORE_NAME}/PATCH_CURRENT_FUNN_FAIL` as const,
  PATCH_CURRENT_FUNN_OK: `${STORE_NAME}/PATCH_CURRENT_FUNN_OK` as const,
  DELETE: `${STORE_NAME}/DELETE` as const,
  DELETE_IF_EMPTY: `${STORE_NAME}/DELETE_IF_EMPTY` as const,
  DELETE_FAIL: `${STORE_NAME}/DELETE_FAIL` as const,
  DELETE_OK: `${STORE_NAME}/DELETE_OK` as const,
  RESET: `${STORE_NAME}/RESET` as const,
  RESET_CURRENT_FUNN_STATUS: `${STORE_NAME}/RESET_CURRENT_FUNN_STATUS` as const,
  SET_SLAKTEDATO_FROM: `${STORE_NAME}/SET_SLAKTEDATO_FROM` as const,
}

export const amFunnActions = {
  ...fetchActions,
  post: (
    funn: Partial<IAmFunnPatch>,
    shouldPushToFunnRoute?: boolean,
    initialObservasjonImageIds?: string[]
  ) => ({
    type: amFunnActionsTypes.POST,
    funn,
    initialObservasjonImageIds,
    shouldPushToFunnRoute,
  }),
  postFail: (error: string) => ({
    type: amFunnActionsTypes.POST_FAIL,
    error,
  }),
  postOk: (id: string) => ({
    type: amFunnActionsTypes.POST_OK,
    id,
  }),
  patchCurrentFunn: (
    funnPatch: Partial<IAmFunnPatch>,
    shouldFetchNew = true
  ) => ({
    type: amFunnActionsTypes.PATCH_CURRENT_FUNN,
    funnPatch,
    shouldFetchNew,
  }),
  patchCurrentFunnFail: (error: string) => ({
    type: amFunnActionsTypes.PATCH_CURRENT_FUNN_FAIL,
    error,
  }),
  patchCurrentFunnOk: () => ({
    type: amFunnActionsTypes.PATCH_CURRENT_FUNN_OK,
  }),
  delete: (funnId: string, silent = false) => ({
    type: amFunnActionsTypes.DELETE,
    funnId,
    silent,
  }),
  deleteIfEmpty: (funnId: string, silent?: true) => ({
    type: amFunnActionsTypes.DELETE_IF_EMPTY,
    funnId,
    silent,
  }),
  deleteFail: (error: string) => ({
    type: amFunnActionsTypes.DELETE_FAIL,
    error,
  }),
  deleteOk: (funnId: string) => ({
    type: amFunnActionsTypes.DELETE_OK,
    funnId,
  }),
  resetCurrentFunnStatus: (id?: string) => ({
    type: amFunnActionsTypes.RESET_CURRENT_FUNN_STATUS,
    id,
  }),
  reset: () => ({
    type: amFunnActionsTypes.RESET,
  }),
  fetchList: (slakteDatoFrom?: string, silent = false) => ({
    type: amFunnActionsTypes.FETCH_LIST,
    slakteDatoFrom,
    silent,
  }),
  setSlakteDatoFrom: (slakteDatoFrom: string) => ({
    type: amFunnActionsTypes.SET_SLAKTEDATO_FROM,
    slakteDatoFrom,
  }),
}

// selectors
export const amFunnSelectors = {
  getLoadingStatus: (
    state: IStoreState
  ): { loading: boolean; loaded: boolean; error: string } =>
    R.pick(['loading', 'loaded', 'error'], state[ROOT_STORE_NAME][STORE_NAME]),

  getById: (id: string) => (state: IStoreState) =>
    state[ROOT_STORE_NAME][STORE_NAME].data[id],

  currentFunnStatus: (state: IStoreState): IAmFunnState['currentFunnStatus'] =>
    state[ROOT_STORE_NAME][STORE_NAME].currentFunnStatus,

  getFunnList: (state: IStoreState): IAmFunn[] =>
    R.pipe(
      R.defaultTo({}),
      R.pluck('data'),
      R.values
    )(state[ROOT_STORE_NAME][STORE_NAME].data),

  getSlakteDatoFrom: (state: IStoreState): string =>
    state[ROOT_STORE_NAME][STORE_NAME].slakteDatoFrom,
}

export const initialState: IAmFunnState = {
  ...commonRedux.initialListState,
  currentFunnStatus: {
    error: '',
    saved: true,
    saving: false,
    creating: false,
  },
  slakteDatoFrom: dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
}

const setCurrentFunnStatus =
  (
    error: string,
    saved: boolean,
    saving: boolean,
    creating?: boolean,
    id?: string
  ) =>
  (state: IAmFunnState): IAmFunnState => ({
    ...state,
    currentFunnStatus: {
      error: error,
      saved: saved,
      saving: saving,
      creating: creating ?? state.currentFunnStatus.creating,
      id: id ?? state.currentFunnStatus.id,
    },
  })

const customReducer = commonRedux.createReducer<IAmFunnState, IAmFunnActions>(
  initialState,
  {
    ...fetchWithIndexHandlers,
    [amFunnActionsTypes.DELETE]: (
      state: IAmFunnState,
      { funnId }: ReturnType<typeof amFunnActions.delete>
    ): IAmFunnState => ({
      ...state,
      data: R.omit([funnId])(state.data),
      index: state.index.filter((i) => i !== funnId),
    }),

    [amFunnActionsTypes.RESET_CURRENT_FUNN_STATUS]: (
      state: IAmFunnState,
      { id }: ReturnType<typeof amFunnActions.resetCurrentFunnStatus>
    ): IAmFunnState =>
      setCurrentFunnStatus('', true, false, false, id || '')(state),

    [amFunnActionsTypes.RESET]: (): IAmFunnState => initialState,

    [amFunnActionsTypes.POST]: (state: IAmFunnState): IAmFunnState =>
      setCurrentFunnStatus('', false, true, true)(state),

    [amFunnActionsTypes.POST_OK]: (
      state: IAmFunnState,
      { id }: ReturnType<typeof amFunnActions.postOk>
    ): IAmFunnState => setCurrentFunnStatus('', true, false, false, id)(state),

    [amFunnActionsTypes.POST_FAIL]: (
      state: IAmFunnState,
      { error }: ReturnType<typeof amFunnActions.postFail>
    ): IAmFunnState =>
      setCurrentFunnStatus(error, false, false, false, undefined)(state),

    [amFunnActionsTypes.PATCH_CURRENT_FUNN]: (
      state: IAmFunnState
    ): IAmFunnState => setCurrentFunnStatus('', false, true)(state),

    [amFunnActionsTypes.PATCH_CURRENT_FUNN_OK]: (
      state: IAmFunnState
    ): IAmFunnState => setCurrentFunnStatus('', true, false)(state),

    [amFunnActionsTypes.PATCH_CURRENT_FUNN_FAIL]: (
      state: IAmFunnState,
      { error }: ReturnType<typeof amFunnActions.patchCurrentFunnFail>
    ): IAmFunnState => setCurrentFunnStatus(error, false, false)(state),

    [amFunnActionsTypes.SET_SLAKTEDATO_FROM]: (
      state: IAmFunnState,
      { slakteDatoFrom }: ReturnType<typeof amFunnActions.setSlakteDatoFrom>
    ): IAmFunnState => ({
      ...state,
      slakteDatoFrom,
    }),
  }
)

export const amFunnReducer = {
  [STORE_NAME]: customReducer,
}
