import { Action } from 'redux'
import { IHandlers } from './types'

export const createReducer =
  <TState, TActions extends Action>(
    initialState: TState,
    handlers: IHandlers<TState, TActions>
  ) =>
  (state = initialState, action: TActions): TState =>
    // eslint-disable-next-line
    handlers.hasOwnProperty(action.type)
      ? handlers[action.type](state, action)
      : state
