import React, { ChangeEvent, useEffect, useState } from 'react';
import { Row, RowItem, TextArea } from '@mattilsynet/mt-ui';
import { ModalWrapper } from '../../components/modal-wrapper';
import './style.css';
import { ModalButtonsContainer } from '../../components/modal-buttons-container';
import { ModalButton } from '../../components/modal-button';
import { modalContentPadding } from '../../components/modal-buttons-container/constants';
interface IBegrunnelseModalProps {
  isOpen: boolean;
  onCancel: () => void;
  begrunnelse?: string;
  onUpdate: (string) => void;
  hasContent: boolean;
}
export const BegrunnelseModal = ({
  isOpen,
  onCancel,
  begrunnelse,
  onUpdate,
  hasContent
}: IBegrunnelseModalProps) => {
  const [isInputDirty, setIsInputDirty] = useState(() => false);
  const [hasContentConstant] = useState(hasContent);
  const onUpdateBegrunnelse = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onUpdate(event.currentTarget.value);
  };
  const onSave = () => {
    if (Boolean(begrunnelse)) {
      onCancel();
    } else {
      setIsInputDirty(true);
      onCancel();
    }
  };
  const addOrEditText = () => hasContentConstant ? 'Endre utdypning' : 'Legg til utdypning';
  useEffect(() => {
    Boolean(begrunnelse) && setIsInputDirty(true);
  }, [begrunnelse]);
  return <>
      <ModalWrapper fullscreenMobile isOpen={isOpen} onCancel={onSave} title={addOrEditText()} data-sentry-element="ModalWrapper" data-sentry-source-file="index.tsx">
        <Row padding={modalContentPadding} className="begrunnelse-item-text-area" data-sentry-element="Row" data-sentry-source-file="index.tsx">
          <RowItem flex={1} data-sentry-element="RowItem" data-sentry-source-file="index.tsx">
            <TextArea onChange={onUpdateBegrunnelse} placeholder={'Utdypning av begrunnelse'} value={begrunnelse || ''} label={'Utdypning'} danger={!Boolean(begrunnelse) && isInputDirty} errorText={!Boolean(begrunnelse) && isInputDirty ? 'Utdypning mangler' : ''} data-sentry-element="TextArea" data-sentry-source-file="index.tsx" />
          </RowItem>
        </Row>
        <ModalButtonsContainer data-sentry-element="ModalButtonsContainer" data-sentry-source-file="index.tsx">
          <ModalButton secondary onClick={onSave} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">
            {addOrEditText()}
          </ModalButton>
        </ModalButtonsContainer>
      </ModalWrapper>
    </>;
};