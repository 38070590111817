import { Observable } from 'rxjs'
import { IKoderverkNames, IKodeverkQueries } from '../store/types'
import { commonApi, getToken } from '../../../api'
import { createQueryStringFromObject } from '../../../common/createQueryStringFromObject'

export const fetchKodeVerkApi =
  (accessTokenPath: string[], rootUrl: string) =>
  (
    kodeverkName: IKoderverkNames,
    queries: IKodeverkQueries | undefined,
    state
  ) =>
  (obs$): Observable<any> =>
    obs$.pipe(
      getToken(),
      commonApi.get(
        `${rootUrl}/code/${kodeverkName}${createQueryStringFromObject('?')(
          queries
        )}`,
        state
      )
    )
