import { commonRedux } from '../../common/redux'
import {
  IToggle,
  IToggleNames,
  IUnleashTogglesActions,
  IUnleashTogglesState,
} from './types'

export const STORE_NAME = '@unleashToggles'

export const unleashTogglesActionTypes = {
  FETCH: `${STORE_NAME}/FETCH` as const,
  FETCH_OK: `${STORE_NAME}/FETCH_OK` as const,
  FETCH_FAIL: `${STORE_NAME}/FETCH_FAIL` as const,
  RESET: `${STORE_NAME}/RESET` as const,
}

export const unleashTogglesActions = {
  fetch: (
    toggleName: IToggleNames,
    queries: Record<string, string | string[] | undefined> = {}
  ) => ({
    type: unleashTogglesActionTypes.FETCH,
    toggleName,
    queries,
  }),

  fetchOk: (toggleName: IToggleNames, enabled: boolean) => ({
    type: unleashTogglesActionTypes.FETCH_OK,
    enabled,
    toggleName,
  }),

  fetchFail: (toggleName: IToggleNames, error: string) => ({
    type: unleashTogglesActionTypes.FETCH_FAIL,
    error,
    toggleName,
  }),
  reset: () => ({
    type: unleashTogglesActionTypes.RESET,
  }),
}

export const initialState = {} as IUnleashTogglesState

const customReducer = commonRedux.createReducer<
  IUnleashTogglesState,
  IUnleashTogglesActions
>(initialState, {
  [unleashTogglesActionTypes.FETCH]: (
    state: IUnleashTogglesState,
    { toggleName }: ReturnType<typeof unleashTogglesActions.fetch>
  ): IUnleashTogglesState => ({
    ...state,
    [toggleName]: {
      loading: true,
      error: '',
      loaded: false,
    },
  }),

  [unleashTogglesActionTypes.FETCH_OK]: (
    state: IUnleashTogglesState,
    { toggleName, enabled }: ReturnType<typeof unleashTogglesActions.fetchOk>
  ): IUnleashTogglesState => ({
    ...state,
    [toggleName]: {
      loading: false,
      error: '',
      loaded: true,
      enabled: enabled,
    },
  }),

  [unleashTogglesActionTypes.FETCH_FAIL]: (
    state: IUnleashTogglesState,
    { toggleName, error }: ReturnType<typeof unleashTogglesActions.fetchFail>
  ): IUnleashTogglesState => ({
    ...state,
    [toggleName]: {
      loading: false,
      error: error,
      loaded: false,
      enabled: state[toggleName]?.enabled,
    },
  }),
  [unleashTogglesActionTypes.RESET]: () => initialState,
})

export const unleashTogglesReducer = {
  [STORE_NAME]: customReducer,
}

export const unleashTogglesSelectors = {
  getToggle:
    (toggleName: IToggleNames) =>
    (state): IToggle | undefined =>
      state[STORE_NAME][toggleName],
}
