import React, { useState } from 'react';
import { Column, Row, Text } from '@mattilsynet/mt-ui';
import { KontrollpunktItem } from '../../components/kontrollpunkt-item';
import { ModalWrapper } from '../../components/modal-wrapper';
import { DeleteModalLarge } from '../delete-modal-large';
import { useKvitteringId } from '../../common/kvittering-context';
import { useRemoveKontrollpunkt, useUpdateKontrollpunktBeskrivelse } from '../../features/kontrollpunkter';
import { ModalButtonsContainer } from '../../components/modal-buttons-container';
import { ModalButton } from '../../components/modal-button';
import { modalContentPadding } from '../../components/modal-buttons-container/constants';
import { IKontrollpunkt } from '../../features/kontrollpunkter/types';
interface IKontrollpunktEditModalProps {
  isOpen: boolean;
  onCancel: () => void;
  kontrollpunkt: IKontrollpunkt;
}
const KontrollpunktEditModal = ({
  isOpen,
  onCancel,
  kontrollpunkt
}: IKontrollpunktEditModalProps) => {
  const kvitteringId = useKvitteringId();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const {
    mutate: updateBeskrivelse
  } = useUpdateKontrollpunktBeskrivelse(kvitteringId);
  const {
    mutate: removeKontrollpunkt
  } = useRemoveKontrollpunkt(kvitteringId);
  const onUpdateKontrollpunkt = (kontrollpunkt: IKontrollpunkt) => {
    updateBeskrivelse({
      kontrollpunkt
    });
  };
  const onRemoveKontrollpunkt = () => {
    removeKontrollpunkt({
      kontrollpunkt
    });
    setIsDeleteModalOpen(false);
    onCancel();
  };
  const isValid = () => Boolean(kontrollpunkt.beskrivelse);
  const hasKontrollpunktImages = () => kontrollpunkt.observasjoner?.flatMap(observasjon => observasjon.bildeIds).length > 0;
  return <>
      <ModalWrapper fullscreenMobile isOpen={isOpen} onCancel={onCancel} title="Rediger kontrollpunkt" data-sentry-element="ModalWrapper" data-sentry-source-file="index.tsx">
        <Column padding={modalContentPadding} data-sentry-element="Column" data-sentry-source-file="index.tsx">
          <KontrollpunktItem onRemoveKontrollpunkt={() => setIsDeleteModalOpen(true)} onUpdateKontrollpunkt={onUpdateKontrollpunkt} kontrollpunkt={kontrollpunkt} placeholder="Kontrollpunkt" danger={!isValid()} data-sentry-element="KontrollpunktItem" data-sentry-source-file="index.tsx" />
        </Column>
        <ModalButtonsContainer data-sentry-element="ModalButtonsContainer" data-sentry-source-file="index.tsx">
          <ModalButton secondary onClick={onCancel} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">
            Lukk
          </ModalButton>
        </ModalButtonsContainer>
      </ModalWrapper>
      <DeleteModalLarge isOpen={isDeleteModalOpen} onCancel={() => {
      setIsDeleteModalOpen(false);
    }} onDelete={onRemoveKontrollpunkt} title={'Er du sikker på at du vil slette kontrollpunktet?'} description={<Column spacing={2}>
            <Row>
              <Text>Du er i ferd med å slette et kontrollpunkt</Text>
            </Row>
            <Row>
              <Text>
                Hvis kontrollpunktet du sletter har tilknyttede observasjoner
                vil disse også slettes.
              </Text>
            </Row>
            <Row>
              <Text>
                {hasKontrollpunktImages() && `Om det er bilder knyttet til kontrollpunktet, vil disse fortsatt ligge på tilsynskvitteringen`}
              </Text>
            </Row>
          </Column>} confirmText={'Ja, jeg vil slette kontrollpunktet'} cancelText={'Avbryt'} data-sentry-element="DeleteModalLarge" data-sentry-source-file="index.tsx" />
    </>;
};
export { KontrollpunktEditModal };