import { actions, actionTypes, reducer, selectors } from './store';
import epic from './epic';
import { Notifications } from './view';
const notifications = {
  actions,
  actionTypes,
  reducer,
  selectors,
  epic
};
export { notifications, Notifications };