import React, { useState, useEffect, ReactChild } from 'react';
import { TabBar } from '@mattilsynet/mt-ui';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
interface IOption {
  value: string;
  display: ReactChild;
}
interface ITabContainerProps {
  options: IOption[];
  onChange: (value: string) => void;
  currentValue?: string;
  defaultValue?: string;
  size?: 'tiny' | 'small' | 'normal' | 'large' | 'huge';
}
const TabBarWrapper = styled.div<{
  inactive: boolean;
}>`
  & .TabLink {
    cursor: ${({
  inactive
}) => inactive ? 'text' : 'pointer'};
  }
`;
export const TabContainer = ({
  options,
  onChange,
  currentValue = '',
  defaultValue,
  size = 'normal'
}: ITabContainerProps) => {
  const [selectedValue, setSelectedValue] = useState(() => defaultValue ?? options[0].value);
  const smallDevice = useMediaQuery({
    maxWidth: '767px'
  });
  useEffect(() => {
    const option = options.find((option: IOption) => option.value === selectedValue);
    option && onChange(option! && option.value);
  }, [selectedValue]);
  useEffect(() => {
    setSelectedValue(currentValue!);
  }, [currentValue]);
  return <TabBarWrapper inactive={options.length === 1} data-sentry-element="TabBarWrapper" data-sentry-component="TabContainer" data-sentry-source-file="index.tsx">
      <TabBar options={options} selectedValue={selectedValue} onClick={val => options.length > 1 && setSelectedValue(val)} size={size} forceShowAsDropdown={smallDevice ? options.length === 1 ? false : undefined : options.length > 3} data-sentry-element="TabBar" data-sentry-source-file="index.tsx" />
    </TabBarWrapper>;
};