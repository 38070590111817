import { useKvittering } from '../../kvitteringer'
import { MutationKey, useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { ITilsynsobjekt, ITilsynsobjektMutateVars } from '../types'
import { tilsynsobjektKeys } from '../queries/helpers'

export { useTilsynsobjekter, useRemoveTilsynsobjekt, useAddTilsynsobjekt }

const onlySimpleKontrollpunkter = (tilsynsobjekt: ITilsynsobjekt) =>
  ({
    ...tilsynsobjekt,
    kontrollpunkter: tilsynsobjekt.kontrollpunkter.map((kp) => ({
      id: kp.id,
      sorteringsrekkefoelge: kp.sorteringsrekkefoelge,
    })),
  }) as ITilsynsobjekt

/**
 * Get tilsynsobjekter for a given kvittering (w/o kontrollpunkter).
 *
 * Excludes kontrollpunkter to improve performance.
 *
 * @param kvitteringId
 */
const useTilsynsobjekter = (kvitteringId: string) =>
  useKvittering(kvitteringId, {
    select: (kvittering) => {
      return kvittering.tilsynsobjekter?.map(onlySimpleKontrollpunkter) ?? []
    },
  })

const useTilsynsobjektMutation = (mutationKey: MutationKey) => {
  return useMutation<
    AxiosResponse<null>,
    string | Error,
    ITilsynsobjektMutateVars,
    { previousTilsynsobjekter: ITilsynsobjekt[] }
  >({ mutationKey })
}

const useAddTilsynsobjekt = () =>
  useTilsynsobjektMutation(tilsynsobjektKeys.add())

const useRemoveTilsynsobjekt = (kvitteringId: string) =>
  useTilsynsobjektMutation(tilsynsobjektKeys.remove(kvitteringId))
