import * as R from 'ramda'
import { initialState } from './initialState'
import { userActions, userActionsTypes } from '../actions'
import { reducerLenses } from '../selectors'
import { IUserState } from '../types'
import { createReducer } from '../../../common/redux/create-reducer'
import { IHandlers } from '../../../common/redux/types'

export const STORE_NAME = 'user'

const userReducer = {
  [STORE_NAME]: createReducer(initialState, {
    [userActionsTypes.FETCH_USER_AVDELING_OK]: (
      state: IUserState,
      { data }: any
    ) => R.set(reducerLenses.avdeling(), R.omit(['children'], data), state),
    [userActionsTypes.FETCH_USER_REGION_OK]: (
      state: IUserState,
      { data }: any
    ) => R.set(reducerLenses.region(), R.omit(['children'], data), state),
    [userActionsTypes.FETCH_USER_ORGENHET_OK]: (
      state: IUserState,
      { data }: any
    ) => R.set(reducerLenses.me(), R.omit(['children'], data), state),

    [userActionsTypes.SET_USER]: (
      state: IUserState,
      {
        username,
        name,
        title,
      }: { username: string; name: string; title?: string }
    ) => ({
      ...state,
      authUser: {
        ...(state.authUser || {}),
        username,
        name,
        title,
      },
    }),

    [userActionsTypes.SET_LAST_LOCATION]: (
      state: IUserState,
      { lastLocation }
    ): IUserState => ({
      ...state,
      lastLocation,
    }),

    [userActionsTypes.UPDATE_TOKEN]: (
      state: IUserState,
      { data }: { data: string }
    ) => ({
      ...state,
      authUser: {
        ...(state.authUser || {}),
        accessToken: data,
      },
    }),
  } as unknown as IHandlers<
    IUserState,
    ReturnType<(typeof userActions)[keyof typeof userActions]>
  >),
}

export default userReducer
