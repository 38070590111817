import { combineEpics } from 'redux-observable'
import { catchError } from 'rxjs/operators'

import ansatteEpics from '../ducks/ansatte/epic'
import uiEpic from '../ducks/ui/epic'
import userEpic from '../ducks/user/epic'
import kvitteringEpic from '../ducks/kvittering/epic'
import sakEpic from '../ducks/sak/epic'
import userSettingsEpic from '../ducks/user-settings/epic'
import { commonApi } from '../api'
import { virksomhetSearch } from '../ducks/virksomhet-search'
import fetchArkiveringStatusEpic from '../ducks/arkivering-status/epic'
import veiledningEpic from '../ducks/kvittering/veiledning/epic'
import hentKvitteringBegrunnelserEpics from '../ducks/hent-kvittering-begrunnelse/epic'
import { notifications } from '../features/notifications'
import { kodeverk } from '../features/kodeverk'
import { unleashToggles } from '../features/unleash-toggles'
import { tilsynsobjektinfo } from '../features/tilsynsobjektinfo'
import { captureException } from '@sentry/react'
import { CAPTURE_NAME, CaptureType } from '../sentry'
const authTokenPath = ['user', 'authUser', 'accessToken']

const rootEpic = (action$, store$, dependencies) =>
  combineEpics(
    hentKvitteringBegrunnelserEpics(commonApi) as any,
    ansatteEpics(commonApi) as any,
    uiEpic(commonApi),
    userEpic(commonApi),
    kvitteringEpic(commonApi),
    veiledningEpic(commonApi) as any,
    sakEpic(commonApi),
    tilsynsobjektinfo.epics(commonApi, authTokenPath) as any,
    virksomhetSearch.epics(
      authTokenPath,
      '/api/tilsynsobjekt-api/v2/virksomheter'
    ),
    fetchArkiveringStatusEpic(commonApi),
    userSettingsEpic(commonApi),
    kodeverk.epic(authTokenPath, '/api/kodeverk-api/kodeverk') as any,
    notifications.epic(authTokenPath, '/api/notification-api'),
    unleashToggles.epics(commonApi as any) as any
  )(action$, store$, dependencies).pipe(
    catchError((err, source) => {
      // eslint-disable-next-line no-console
      console.error('catched', err)
      captureException(err, { tags: { [CAPTURE_NAME]: CaptureType.EPIC } })
      return source
    })
  )

export default rootEpic
