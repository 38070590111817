import React from 'react';
import { Row, Column, RadioButtonList, ContrastBox, Button } from '@mattilsynet/mt-ui';
import { TextWithValidation } from '../../components/text-with-validation';
import { isUuid } from '../../common/string-utils';
interface IRadioButtonCustomSammenstillingProps {
  label: string;
  options: {
    value: string;
    label: string;
  }[];
  onChange?: (value: string) => void;
  value?: boolean;
  hasError?: boolean;
  optionsBegrunnelse: {
    value: string;
    label?: string;
  }[];
  onChangeBegrunnelse?: (value: string) => void;
  customBegrunnelseUniqueId: string;
  isRadioDirty?: boolean;
  valueBegrunnelse: string;
  isValidBegrunnelse?: boolean;
  onCustomBegrunnelseRadioOpen?: () => void;
  shouldValidate?: boolean;
  disabled?: boolean;
}
export const RadioButtonCustomSammenstilling = ({
  label,
  options,
  onChange,
  value,
  hasError,
  optionsBegrunnelse,
  onChangeBegrunnelse,
  customBegrunnelseUniqueId,
  isRadioDirty,
  valueBegrunnelse,
  isValidBegrunnelse,
  onCustomBegrunnelseRadioOpen,
  shouldValidate = false,
  disabled = false
}: IRadioButtonCustomSammenstillingProps) => {
  const isValue = (value: boolean | undefined) => value !== undefined ? value ? '1' : '0' : undefined;
  const getRadioListValue = (begrunnelse, options, isRadioDirty, uniqueId) => {
    if (options.some(option => option.value === begrunnelse) && !isUuid(begrunnelse)) {
      return begrunnelse;
    }
    if (isRadioDirty) {
      return uniqueId;
    }
    if (begrunnelse === null || begrunnelse === undefined) {
      return null;
    }
    return uniqueId;
  };
  const isBegrunnelseValid = (value: string, id: string) => {
    if (value === id) return false;
    if (value === '') return false;
    return true;
  };
  return <Column spacing={1} data-sentry-element="Column" data-sentry-component="RadioButtonCustomSammenstilling" data-sentry-source-file="radio-button-custom-sammenstilling.tsx">
      <RadioButtonList label={label} options={options} onChange={onChange ? onChange : () => null} value={isValue(value)} noBackgroundColor hasError={hasError} disabled={disabled} data-sentry-element="RadioButtonList" data-sentry-source-file="radio-button-custom-sammenstilling.tsx" />
      <TextWithValidation errorText={'Utfylling mangler'} value={''} hasError={hasError} withWhiteSpace as="h4" bold data-sentry-element="TextWithValidation" data-sentry-source-file="radio-button-custom-sammenstilling.tsx" />
      {value === false && <Column spacing={1}>
          <RadioButtonList label="Velg begrunnelse" options={optionsBegrunnelse} onChange={onChangeBegrunnelse ? onChangeBegrunnelse : () => null} value={getRadioListValue(valueBegrunnelse, optionsBegrunnelse, isRadioDirty, customBegrunnelseUniqueId)} noBackgroundColor hasError={shouldValidate && !getRadioListValue(valueBegrunnelse, optionsBegrunnelse, isRadioDirty, customBegrunnelseUniqueId)} disabled={disabled} />
          <TextWithValidation errorText={'Utfylling mangler'} value={''} hasError={shouldValidate && !getRadioListValue(valueBegrunnelse, optionsBegrunnelse, isRadioDirty, customBegrunnelseUniqueId)} withWhiteSpace as="h4" bold />
        </Column>}
      {!value && getRadioListValue(valueBegrunnelse, optionsBegrunnelse, isRadioDirty, customBegrunnelseUniqueId) === customBegrunnelseUniqueId && <ContrastBox noBorders backgroundColor="white">
            <Column fluid spacing={2}>
              <Row className="subtitle">
                <h4>Utdypning</h4>
              </Row>
              <TextWithValidation errorText={valueBegrunnelse === customBegrunnelseUniqueId ? '' : 'Utdypning mangler'} value={valueBegrunnelse} hasError={!isValidBegrunnelse} withWhiteSpace as="h4" bold />
              {!disabled && <Row justify="center" className="button-add">
                  <Button fill secondary onClick={onCustomBegrunnelseRadioOpen}>
                    {isBegrunnelseValid(valueBegrunnelse, customBegrunnelseUniqueId) ? 'Endre utdypning' : 'Legg til utdypning'}
                  </Button>
                </Row>}
            </Column>
          </ContrastBox>}
      {value === false && <Row className="begrunnelse-info">
          Begrunnelsen vil ikke vises i tilsynskvitteringen.
        </Row>}
    </Column>;
};