import {
  userSettingActionTypes,
  userSettingsActions,
} from '../../user-settings/store'
import { uiActions, uiActionTypes } from '../actions'
import { combineEpics, ofType, StateObservable } from 'redux-observable'
import {
  catchError,
  filter,
  mapTo,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators'
import { concat, Observable, of } from 'rxjs'
import { AnyAction } from 'redux'
import { IStoreState } from '../../../reducers/types'
import { ICommonApi } from '../../../api'
import { fetchUserSettings } from '../../user-settings/epic'
import { commonRedux } from '../../../common/redux'
import { userActions, userActionsTypes } from '../../user/actions'
const { isOfType } = commonRedux

export const initialLoadingWhenUserFoundEpic = (
  action$: Observable<AnyAction>
): Observable<AnyAction> =>
  action$.pipe(ofType(userActionsTypes.SET_USER), mapTo(uiActions.initialize()))

export const initialLoadingEpic =
  () =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      filter(isOfType([uiActionTypes.INITIALIZE])),
      withLatestFrom(state$),
      mergeMap(() =>
        concat(
          of(userActions.fetchUserOrgenhet()),
          of(userSettingsActions.fetchInnstillinger()),
          of(uiActions.initializeSuccess())
        ).pipe(catchError((err) => of(uiActions.initializeFailed(err.message))))
      )
    )

export const fetchInnstillingerEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(userSettingActionTypes.FETCH_INNSTILLINGER),
      withLatestFrom(state$),
      mergeMap(([, state]) => concat(fetchUserSettings(commonApi)(state)))
    )

export default (commonApi: ICommonApi) =>
  combineEpics(
    initialLoadingEpic(),
    fetchInnstillingerEpic(commonApi),
    initialLoadingWhenUserFoundEpic
  )
