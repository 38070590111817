import { CopyIcon } from '@mattilsynet/mt-ui';
import React, { useCallback } from 'react';
import './style.css';
import { copyToClipboard } from '../../common/helpers';
interface ICopyToClipboardButtonProps {
  onCopied: () => void;
  value: string;
  size?: 'small' | 'medium' | 'large';
}
export const CopyToClipboardButton = ({
  value,
  size = 'small',
  onCopied
}: ICopyToClipboardButtonProps) => {
  const onCopy = useCallback(() => {
    copyToClipboard(value);
    onCopied();
  }, [onCopied, value]);
  return <span onClick={onCopy} className="copy-button" data-sentry-component="CopyToClipboardButton" data-sentry-source-file="index.tsx">
      <CopyIcon size={size} color="var(--mt-ui-signalRed)" data-sentry-element="CopyIcon" data-sentry-source-file="index.tsx" />
    </span>;
};