import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import { combineEpics, ofType } from 'redux-observable'
import { actionsTypes, actions } from '../store'

export interface ICommonApi {
  get(url: string, state: any): (obs$: Observable<any>) => Observable<any>
  delete(url: string, state: any): (obs$: Observable<any>) => Observable<any>
  post(url: string, state: any): (obs$: Observable<any>) => Observable<any>
  put(url: string, state: any): (obs$: Observable<any>) => Observable<any>
  default(
    url: string,
    method: string,
    state: any
  ): (obs$: Observable<any>) => Observable<any>
}

const fetchVirksomhetFromRemote =
  (commonApi: ICommonApi, rootUrl: string) => (action$, state$) =>
    action$.pipe(
      ofType(actionsTypes.FETCH_ID),
      withLatestFrom(state$),
      mergeMap(([{ id }, state]) =>
        of(id).pipe(
          commonApi.get(`${rootUrl}/virksomheter/orgnummer/${id}`, state),
          map((data) => actions.fetchIdOk(data)),
          catchError((err) => of(actions.fetchIdFail(id, err.message)))
        )
      )
    )

export default (commonApi: ICommonApi, rootUrl = '/api') =>
  combineEpics(fetchVirksomhetFromRemote(commonApi, rootUrl))
