import { IGetActionTypesFromObject, IHandlers, IStateDataList } from './types'
import {
  addDataToList,
  initialLoadState,
  loadingSuccessListState,
} from './helpers'
import * as R from 'ramda'

export const fetchWithIndexHelpers = <
  TData extends Record<string, any>,
  TStoreName extends string,
>(
  storeName: TStoreName,
  reducerId: string
) => {
  if (!reducerId) {
    throw new Error('Missing id on fetchWithIndexReducer')
  }

  const fetchActionTypes = {
    FETCH_ID: `${storeName}/FETCH_ID` as const,
    FETCH_LIST: `${storeName}/FETCH_LIST` as const,

    FETCH_ID_OK: `${storeName}/FETCH_OK_ID` as const,
    FETCH_LIST_OK: `${storeName}/FETCH_OK_LOAD` as const,
    FETCH_LIST_ADD_OK: `${storeName}/FETCH_OK_ADD_LOAD` as const,

    FETCH_LIST_FAIL: `${storeName}/FETCH_LIST_FAIL` as const,
    FETCH_ID_FAIL: `${storeName}/FETCH_ID_FAIL` as const,
  }

  const fetchActions = {
    // Fetch
    fetchId: (id: string, silent = false) => ({
      type: fetchActionTypes.FETCH_ID,
      silent,
      id,
    }),

    fetchList: (silent = false) => ({
      type: fetchActionTypes.FETCH_LIST,
      silent,
    }),

    fetchIdOk: (data: TData) => ({
      type: fetchActionTypes.FETCH_ID_OK,
      data,
    }),

    fetchListOk: (data: TData[]) => ({
      type: fetchActionTypes.FETCH_LIST_OK,
      data,
    }),

    fetchListAddOk: (data: TData[]) => ({
      type: fetchActionTypes.FETCH_LIST_ADD_OK,
      data,
    }),

    // Fetch Fail
    fetchIdFail: (id: string, error: any, silent = false) => ({
      type: fetchActionTypes.FETCH_ID_FAIL,
      id,
      error,
      silent,
    }),

    fetchListFail: (error: any, silent = false) => ({
      type: fetchActionTypes.FETCH_LIST_FAIL,
      silent,
      error,
    }),
  }

  const fetchWithIndexHandlers = {
    [fetchActionTypes.FETCH_ID]: (state, { id, silent }) =>
      R.pipe(
        R.over(R.lensPath(['data', id]), initialLoadState(silent)),
        R.set(R.lensPath(['index']), R.append(id)(state.index))
      )(state),

    [fetchActionTypes.FETCH_LIST]: (state, { silent }) =>
      initialLoadState(silent)(state),

    [fetchActionTypes.FETCH_LIST_OK]: (state, { data }) =>
      R.pipe(
        R.mergeLeft({ data: {}, index: [] }),
        R.reduce(
          (acc: IStateDataList, newData: any) =>
            addDataToList(reducerId, newData, acc),
          R.__,
          data
        ),
        R.mergeLeft(loadingSuccessListState)
      )(state),

    [fetchActionTypes.FETCH_LIST_ADD_OK]: (state, { data }) =>
      R.pipe(
        R.reduce(
          (acc: IStateDataList, newData: any) =>
            addDataToList(reducerId, newData, acc),
          R.__,
          data
        ),
        R.mergeLeft(loadingSuccessListState)
      )(state),

    [fetchActionTypes.FETCH_ID_OK]: (state, { data }) =>
      addDataToList(reducerId, data, state),

    [fetchActionTypes.FETCH_ID_FAIL]: (state, { id, error, silent }) =>
      R.over(
        R.lensPath(['data', id]),
        R.mergeLeft({
          error: silent ? undefined : error,
          loading: false,
        }),
        state
      ),

    [fetchActionTypes.FETCH_LIST_FAIL]: (state, { error, silent }) =>
      R.mergeLeft(
        {
          error: silent ? undefined : error,
          loading: false,
        },
        state
      ),
  } as unknown as IHandlers<any, IGetActionTypesFromObject<typeof fetchActions>>

  return { fetchActionTypes, fetchActions, fetchWithIndexHandlers }
}
