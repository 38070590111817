import { ISearchStateDefault } from '../../features/virksomhet-search'
import { IPrivatPersonAdresse, IVirksomhetAdresse } from '../kvittering/types'

export const STORE_NAME = 'tilsynsobjektSearch'

export const ADRESSE_ERROR = 'virksomhetSearch/ADRESSE_ERROR'

export interface IVirksomhetsSearchQueries {
  search?: string
  size?: number
  expand?: string
  page?: number
  sort?: string
  includeEmptyVirksomhet?: boolean
  includeSearchInTilsynsobjekt?: boolean
}

export interface IVirksomhetsSearchResults {
  virksomhetList?: IVirksomhetItem[]
}

export type IVirksomhetsSearchState = ISearchStateDefault<
  IVirksomhetsSearchQueries,
  IVirksomhetsSearchResults
>

export interface IVirksomhetTilsynsobjekt {
  aktivitet: IAktivitetItem
  idstring: string
  navn: string
  virksomhetsnavn: string
  selected: boolean
  identiteter: IIdentitetItem[]
  midlertidig?: false
  id?: string
  virksomhetsNummer?: number
}

export interface IAktivitetItem {
  id: string
  beskrivelse?: string
}

export interface IIdentitetItem {
  identitetstype: IIdentitetItemType
  verdi: string
}

export interface IIdentitetItemType {
  id: string
  beskrivelse?: string
}

export interface IVirksomhetItem {
  navn: string
  organisasjonsnummer: string
  tilsynsobjekter: IVirksomhetTilsynsobjekt[]
  beliggenhetsadresse?: IVirksomhetAdresse
  postadresse?: IVirksomhetAdresse
  adresse?: IPrivatPersonAdresse
  overordnetOrgNr?: string
}
