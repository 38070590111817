import { Checkmark, Row, Text, ThemeContext } from '@mattilsynet/mt-ui';
import React, { useCallback, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CopyToClipboardButton } from '../copy-to-clipboard';
import { copySaknummer, useSingleToast } from '../../common/toast';
import { ModalButtonsContainer } from '../modal-buttons-container';
import { ModalButton } from '../modal-button';
import { useTKNavigate } from '../../common/navigation';
import { useDispatch } from 'react-redux';
import { kvitteringActions } from '../../ducks/kvittering/actions';
interface IFerdigstillingOkContentProps {
  saksnummer?: string;
}
const FerdigstillingOkContent = ({
  saksnummer
}: IFerdigstillingOkContentProps) => {
  const navigate = useTKNavigate();
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const singleToast = useSingleToast();
  const onCopySaknummer = useCallback(() => singleToast.showToast(copySaknummer()), [singleToast]);
  const onFerdig = () => {
    dispatch(kvitteringActions.clearCurrentKvitteringId());
    navigate('/');
  };
  const marginHorizontal = useMediaQuery({
    query: '(min-width: 767px)'
  }) ? 14 : 5;
  return <>
      <Row margin={[0, marginHorizontal]} align="center" data-sentry-element="Row" data-sentry-source-file="index.tsx">
        <span style={{
        display: 'table',
        margin: 'auto'
      }}>
          <Checkmark color={theme.signalGreen} className="archive-checkmark" data-sentry-element="Checkmark" data-sentry-source-file="index.tsx" />
        </span>
      </Row>
      <Row margin={[0, marginHorizontal]} data-sentry-element="Row" data-sentry-source-file="index.tsx">
        <Text size="heading3" weight="bold" data-sentry-element="Text" data-sentry-source-file="index.tsx">
          Tilsynskvitteringen er ferdigstilt og sendt til mottaker.
        </Text>
      </Row>
      <Row margin={[0, marginHorizontal]} data-sentry-element="Row" data-sentry-source-file="index.tsx">
        {saksnummer !== undefined && <Text size="normal">
            Saksnummer: {saksnummer}
            &nbsp;
            <CopyToClipboardButton onCopied={onCopySaknummer} value={saksnummer} />
          </Text>}
      </Row>
      <ModalButtonsContainer data-sentry-element="ModalButtonsContainer" data-sentry-source-file="index.tsx">
        <ModalButton onClick={onFerdig} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">Ferdig</ModalButton>
      </ModalButtonsContainer>
    </>;
};
export default FerdigstillingOkContent;