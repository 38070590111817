import React, { useState } from 'react';
import { Column, InfoBox, InfoText, LoadingSpinner, Row, Text } from '@mattilsynet/mt-ui';
import './style.css';
import { parseSakTitleToHTML } from '../../common/parse-string';
import { kodeverk } from '../../features/kodeverk';
import { DeleteModalLarge } from '../../modals/delete-modal-large';
interface ISakCardProps {
  sakNumber: string;
  title: string;
  sakFail: boolean;
  sakNotFound: boolean;
  sakClosed: boolean;
  sakTilgang: string;
  sakHjemmel: string;
  onCancelSak: () => void;
  isLoading: boolean;
  showSak: boolean;
}
const SakCardInfo = ({
  sakNumber,
  title,
  onCancelSak,
  sakFail,
  sakNotFound,
  sakClosed,
  isLoading,
  sakTilgang,
  sakHjemmel,
  showSak
}: ISakCardProps) => {
  const [isFjernSakModalOpen, setIsFjernSakModalOpen] = useState(false);
  const kodeverkResponse = kodeverk.hooks.useKodeverk('TILGANGSHJEMMEL');
  const hjemmelDesc = kodeverkResponse.data?.map(d => {
    if (d.displayNames) {
      const no = d.displayNames.no;
      if (no === sakHjemmel) {
        return d.displayNames ? d.displayNames.descr_ifelt : '';
      }
    }
  });
  const onOpenFjernSakModal = () => {
    setIsFjernSakModalOpen(true);
  };
  const onCancelFjernSakModal = () => {
    setIsFjernSakModalOpen(false);
  };
  const onFjernSak = () => {
    setIsFjernSakModalOpen(false);
    onCancelSak();
  };
  const renderSak = () => {
    if (!sakNumber && !showSak) return;
    if (isLoading) {
      return <Row center>
          <LoadingSpinner title={'Laster sak...'} small />
        </Row>;
    }
    if (sakFail) return <InfoBox danger title="En feil oppstod under henting av sak" buttonText="Fjern" onClick={() => onOpenFjernSakModal()}>
          <InfoText title="Saksnummer" withBoldTitle>
            <p>{sakNumber}</p>
          </InfoText>
        </InfoBox>;
    if (sakNotFound) {
      return <InfoBox warning title="Sak finnes ikke i arkivet" buttonText="Fjern" onClick={() => onOpenFjernSakModal()}>
          <InfoText title="Saksnummer" withBoldTitle>
            <p>{sakNumber}</p>
          </InfoText>
        </InfoBox>;
    }
    if (sakClosed) {
      return <InfoBox warning title="Saken er avsluttet i arkivet" buttonText="Fjern" onClick={() => onOpenFjernSakModal()}>
          <InfoText title="Saksnummer" withBoldTitle>
            <p>{sakNumber}</p>
          </InfoText>
          <InfoText title="Sakstittel" withBoldTitle>
            <div dangerouslySetInnerHTML={{
            __html: parseSakTitleToHTML(title)
          }}></div>
          </InfoText>
          <InfoText title="" withBoldTitle>
            <p>
              Du må gjenåpne saken i arkivet om tilsynskvitteringen skal knyttes
              til den.
            </p>
          </InfoText>
        </InfoBox>;
    }
    if (sakTilgang === 'TILGANGSKODE$UO') {
      return <InfoBox success title="Saken er lagt til" buttonText="Fjern" onClick={() => onOpenFjernSakModal()}>
          <InfoText title="Saksnummer" withBoldTitle>
            <p>{sakNumber}</p>
          </InfoText>
          <InfoText title="Sakstittel" withBoldTitle>
            <div dangerouslySetInnerHTML={{
            __html: parseSakTitleToHTML(title)
          }}></div>
          </InfoText>
          <InfoText title="Saken er unntatt offentlighet" withBoldTitle>
            <p>{sakHjemmel}</p>
            <Text size="medium" weight="lighter">
              {hjemmelDesc}
            </Text>
          </InfoText>
        </InfoBox>;
    }
    return <InfoBox success title="Saken er lagt til" buttonText="Fjern" onClick={() => onOpenFjernSakModal()} data-sentry-element="InfoBox" data-sentry-component="renderSak" data-sentry-source-file="index.tsx">
        <InfoText title="Saksnummer" withBoldTitle data-sentry-element="InfoText" data-sentry-source-file="index.tsx">
          <p>{sakNumber}</p>
        </InfoText>
        <InfoText title="Sakstittel" withBoldTitle data-sentry-element="InfoText" data-sentry-source-file="index.tsx">
          <div dangerouslySetInnerHTML={{
          __html: parseSakTitleToHTML(title)
        }}></div>
        </InfoText>
      </InfoBox>;
  };
  return <div id="sak-card" data-sentry-component="SakCardInfo" data-sentry-source-file="index.tsx">
      {renderSak()}
      <DeleteModalLarge isOpen={isFjernSakModalOpen} onCancel={onCancelFjernSakModal} onDelete={() => onFjernSak()} title={'Fjern tilknyttet sak'} description={<Column spacing={2}>
            <Row>
              <Text>
                Er du sikker på at du vil fjerne koblingen til denne saken?
              </Text>
            </Row>
            <Row className={'description-info'}>
              Om du sender inn en tilsynskvittering uten saksnummer vil det bli
              opprettet <strong className={'strong'}>en ny sak</strong> i
              arkivet.
            </Row>
          </Column>} confirmText={'Ja, fjern saken'} cancelText={'Nei, behold saken'} data-sentry-element="DeleteModalLarge" data-sentry-source-file="index.tsx" />
    </div>;
};
export default SakCardInfo;