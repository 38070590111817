import store from '../reducers/store'
import { uiActions } from '../ducks/ui/actions'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { useTypedSelector } from '../common/custom-hooks'
import { IStoreState } from '../reducers/types'
import { toast } from '../features/toast'
import {
  offlineGeneral,
  offlineInRegisteringProcess,
  online,
} from '../common/toast'

export { checkNetwork, useNetworkConnectionToast }

const checkNetwork = () => {
  window.addEventListener('offline', () =>
    store.dispatch(uiActions.isOffline())
  )
  window.addEventListener('online', () => {
    store.dispatch(uiActions.isOnline())
  })
  if (window.navigator.onLine) {
    store.dispatch(uiActions.isOnline())
  } else {
    store.dispatch(uiActions.isOffline())
  }
}

const useNetworkConnectionToast = () => {
  const dispatch = useDispatch()
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const isInitialLoadRef = useRef(isInitialLoad)
  const { pathname } = useLocation()
  const pathnameRef = useRef(pathname)
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline)

  useEffect(() => {
    if (isInitialLoadRef.current !== isInitialLoad) {
      isInitialLoadRef.current = isInitialLoad
    }

    if (pathnameRef.current !== pathname) {
      pathnameRef.current = pathname
    }
  }, [pathname, isInitialLoad])

  useEffect(() => {
    if (!isOffline && !isInitialLoadRef.current) {
      dispatch(toast.actions.showToast(online()))
    }

    if (isOffline) {
      if (!pathnameRef.current.includes('start-tilsyn')) {
        dispatch(toast.actions.showToast(offlineGeneral()))
        return
      }
      dispatch(toast.actions.showToast(offlineInRegisteringProcess()))
    }

    setIsInitialLoad(false)
  }, [dispatch, isOffline])
}
