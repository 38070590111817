import React, { memo } from 'react';
import { ImageThumbnailWrapper } from '../../image-thumbnail-wrapper';
import { useImageDeletionModal } from '../hooks';
import { useScrollToElement } from '../../../common/custom-hooks';
interface IKvitteringImage {
  id: string;
  kvitteringId: string;
  isSelected?: boolean;
  onClick?: (id: string) => void;
  shouldScrollIntoView?: boolean;
}
export const KvitteringImage = memo(function KvitteringImage({
  id,
  kvitteringId,
  shouldScrollIntoView,
  isSelected,
  onClick
}: IKvitteringImage) {
  const {
    openDeleteConfirmation,
    ImageDeleteDialog
  } = useImageDeletionModal(kvitteringId);
  const elementRef = useScrollToElement(shouldScrollIntoView ?? false);
  return <div ref={elementRef}>
      <ImageThumbnailWrapper selected={isSelected} onClick={onClick} key={id} imageId={id} onRemove={openDeleteConfirmation} shouldValidate />

      <ImageDeleteDialog />
    </div>;
});