import { Observable, of } from 'rxjs'
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import { AnyAction } from 'redux'
import { combineEpics, ofType, StateObservable } from 'redux-observable'
import { searchApi } from './api'
import { createQueryStringFromObject } from '../../../common/createQueryStringFromObject'

const searchUrl = (rootUrl: string, queries: any) =>
  `${rootUrl}${createQueryStringFromObject('?')(queries)}`

const ADRESSE_ERROR = 'virksomhetSearch/ADRESSE_ERROR'

export const searchEpic =
  (actions, actionTypes) =>
  (api, rootUrl) =>
  (
    action$: Observable<AnyAction>,
    state$: StateObservable<any>
  ): Observable<AnyAction> =>
    action$.pipe(
      ofType(actionTypes.SEARCH),
      withLatestFrom(state$),
      mergeMap(([{ queries }, state]) => {
        return of(state).pipe(
          api.search(searchUrl(rootUrl, queries), state),
          map((resp) => actions.fetchOk(resp)),
          catchError((err) => of(actions.fetchFail(err.message)))
        )
      })
    )

export const searchVirksomhetsAdresseEpic =
  (actions, actionTypes) =>
  (api) =>
  (
    action$: Observable<AnyAction>,
    state$: StateObservable<any>
  ): Observable<AnyAction> =>
    action$.pipe(
      ofType(actionTypes.SEARCH_VIRKSOMHET_ADDRESS),
      withLatestFrom(state$),
      mergeMap(([{ queries }, state]) => {
        const url = `/api/virksomhet-api/virksomheter/orgnummer?filter.orgnummer=${queries.search}`
        return of(state).pipe(
          api.search(url, state),
          map((resp: any) =>
            resp._embedded && resp._embedded.virksomheter
              ? resp._embedded.virksomheter[0]
              : []
          ),
          map((virksomhet) => {
            return actions.fetchVirksomhetAddressOk({
              orgNr: virksomhet.organisasjonsnummer,
              beliggenhetsadresse: virksomhet.beliggenhetsadresse || {},
              postadresse: virksomhet.postadresse || {},
              overordnetOrgNr: virksomhet.overordnetInfo?.organisasjonsnummer,
            })
          }),
          catchError(() =>
            of(actions.fetchVirksomhetAddressFail(ADRESSE_ERROR))
          )
        )
      })
    )

export const searchPersonAdresseEpic =
  (actions, actionTypes) =>
  (api) =>
  (
    action$: Observable<AnyAction>,
    state$: StateObservable<any>
  ): Observable<AnyAction> =>
    action$.pipe(
      ofType(actionTypes.SEARCH_PERSON_ADDRESS),
      withLatestFrom(state$),
      mergeMap(([{ queries }, state]) => {
        const url = `/api/tilsynsobjekt-api/tilsynsobjekter/${queries.search}/virksomhet`
        return of(state).pipe(
          api.search(url, state),
          map((resp: any) => {
            return actions.fetchPersonAddressOk({
              id: queries.search,
              adresse: resp.adresse || {},
            })
          }),
          catchError(() => of(actions.fetchPersonAddressFail(ADRESSE_ERROR)))
        )
      })
    )

export default (storeName: string, actions, actionTypes) =>
  (
    authTokenPath: string[],
    rootUrl: string,
    api?: ReturnType<typeof searchApi>
  ) =>
    combineEpics(
      searchEpic(actions, actionTypes)(api || searchApi(), rootUrl),
      searchVirksomhetsAdresseEpic(actions, actionTypes)(api || searchApi()),
      searchPersonAdresseEpic(actions, actionTypes)(api || searchApi())
    )
