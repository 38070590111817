import React from 'react';
import { LoadingSpinner, Grid, GridItem, Row } from '@mattilsynet/mt-ui';
interface IPageLoadingProps {
  loadingText: string;
}
export const PageLoading = ({
  loadingText
}: IPageLoadingProps) => {
  return <Grid data-sentry-element="Grid" data-sentry-component="PageLoading" data-sentry-source-file="index.tsx">
      <GridItem data-sentry-element="GridItem" data-sentry-source-file="index.tsx">
        <Row minHeight="71vh" center data-sentry-element="Row" data-sentry-source-file="index.tsx">
          <LoadingSpinner title={loadingText} data-sentry-element="LoadingSpinner" data-sentry-source-file="index.tsx" />
        </Row>
      </GridItem>
    </Grid>;
};