import React, { useCallback, useState } from 'react';
import { Grid, GridItem, Row, TextLink, Column, Button, RowItem, ContrastBox } from '@mattilsynet/mt-ui';
import { useSelector } from 'react-redux';
import { IStoreState } from '../../reducers/types';
import './style.css';
import { VirksomhetModal } from '../../modals';
import SakCardInfo from '../../components/sak-card-info';
import TilsynsobjekterCardInfo from '../../components/tilsynsobjekter-card-info';
import SakSearch from '../../components/sak-search';
import { KontrollpunkterSection } from '../../features/kontrollpunkter';
import { Status } from '../../components/status';
import { initialSearch } from './utils';
import { useMidlertidigTilsynsobjekt, useTypedSelector } from '../../common/custom-hooks';
import { offlineInSakAndTilsynsobjekt, offlineInTypeTilsynSelect, sakNotAdded, tilsynsobjektMissing, typeTilsynBeskrivelseMissing, typeTilsynMissing, useSingleToast } from '../../common/toast';
import TypeTilsyn from '../../components/type-tilsyn';
import { AktivitetModal } from '../../modals/aktivitet-modal';
import { useCreateKvitteringMutation, useKvittering, useUpdateKvittering } from '../../features/kvitteringer';
import { KvitteringIdProvider } from '../../common/kvittering-context';
import { useViewsContext } from '.';
import { TidligereKontrollpunkter } from '../../features/tidligere-kontrollpunkter';
import { ISakState } from '../../features/sak/types';
import { MutationStatus, useMutationStatus } from '../../common/query';
import { kvitteringKeys } from '../../features/kvitteringer/queries/helpers';
import { kvitteringSelectors } from '../../ducks/kvittering/selectors';
interface IForberedNewTilsynViewProps {
  sakNumberValue: string;
  sakData: ISakState;
  onChange: (e: any) => void;
  onStartTilsyn: (id: string) => void;
  onGoBack: () => void;
  onAddSak: () => void;
  onCancelSak: () => void;
  showSak: boolean;
  kodeverkAktiviteterBehandlesIMats: any;
}
export const ForberedNewTilsynView = ({
  sakNumberValue,
  sakData,
  onChange,
  onStartTilsyn,
  onGoBack,
  onAddSak,
  onCancelSak,
  showSak,
  kodeverkAktiviteterBehandlesIMats
}: IForberedNewTilsynViewProps) => {
  const singleToast = useSingleToast();
  const {
    view,
    viewDispatch
  } = useViewsContext();
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline);
  const {
    sakNumber,
    title,
    sakFail,
    sakNotFound,
    sakClosed,
    sakTilgang,
    sakHjemmel,
    loading: isLoadingSak
  } = sakData;
  const [isVirksomhetModalOpen, setIsVirksomhetModalOpen] = useState(false);
  const [isStartTilsynClicked, setIsStartTilsynClicked] = useState(false);
  const currentKvitteringId = useSelector(kvitteringSelectors.getCurrentKvitteringId);
  const {
    data: kvittering,
    isLoading: isKvitteringLoading,
    isFetching: isKvitteringFetching,
    isError: isKvitteringError,
    isSuccess: isKvitteringSuccess
  } = useKvittering(currentKvitteringId, {
    enabled: currentKvitteringId !== ''
  });
  const {
    mutateAsync: createKvittering
  } = useCreateKvitteringMutation();
  const {
    mutate: updateKvittering
  } = useUpdateKvittering();
  const isAKvitteringBeingCreated = useMutationStatus({
    mutationKey: kvitteringKeys.create()
  }).filter(([status]) => status !== MutationStatus.SUCCESS).length > 0;
  const {
    isAktivitetModalOpen,
    aktivitetOptions,
    selectedAktivitet,
    onSelectAktivitet,
    onSearchAktivitet,
    midlertidigTilsynsObjektVirksomhet,
    setMidlertidigTilsynsObjektVirksomhet,
    onClickMidlertidigTilsynsobjekt,
    onCancelAktivitetModal,
    unselectedMidlertidigTilsynsobjekt,
    setUnselectedMidlertidigTilsynsobjekt
  } = useMidlertidigTilsynsobjekt(kodeverkAktiviteterBehandlesIMats, setIsVirksomhetModalOpen);
  const openTidligereKontrollpunkter = useCallback(() => viewDispatch('tidligereKontrollpunkter'), [viewDispatch]);
  const closeTidligereKontrollpunkter = useCallback(() => viewDispatch('default'), [viewDispatch]);
  const onCancelVirksomhetModal = () => {
    setIsVirksomhetModalOpen(false);
  };
  const onClickTilsyn = () => {
    setIsStartTilsynClicked(true);
    if ((kvittering?.typeTilsyn && kvittering?.typeTilsyn !== 'ANNET' || kvittering?.typeTilsyn === 'ANNET' && kvittering?.typeTilsynBeskrivelse) && kvittering?.tilsynsobjekter && kvittering?.tilsynsobjekter.length > 0 && kvittering?.id && (kvittering?.noarksakSekvensnummer && kvittering?.noarksakAar || !sakNumberValue)) {
      return onStartTilsyn(kvittering?.id);
    }
    if (sakNumberValue.length > 0 && !(kvittering?.noarksakSekvensnummer && kvittering?.noarksakAar)) {
      return singleToast.showToast(sakNotAdded());
    }
    if (!kvittering?.typeTilsyn) {
      return singleToast.showToast(typeTilsynMissing());
    }
    if (kvittering?.typeTilsyn === 'ANNET' && (!kvittering?.typeTilsynBeskrivelse || kvittering?.typeTilsynBeskrivelse.trim().length === 0)) {
      return singleToast.showToast(typeTilsynBeskrivelseMissing());
    }
    return singleToast.showToast(tilsynsobjektMissing());
  };
  const onAddRemoveTilsynsobjekt = () => {
    if (isOffline) {
      return singleToast.showToast(offlineInSakAndTilsynsobjekt());
    }
    setIsVirksomhetModalOpen(true);
  };
  const onChangeTypeTilsyn = async typeValue => {
    if (isOffline) {
      return singleToast.showToast(offlineInTypeTilsynSelect());
    }
    if (!currentKvitteringId) {
      await createKvittering({
        typeTilsyn: typeValue
      });
    }
    if (currentKvitteringId && kvittering) {
      updateKvittering({
        kvittering: {
          ...kvittering,
          typeTilsyn: typeValue
        }
      });
    }
  };
  if (view === 'tidligereKontrollpunkter') {
    return <TidligereKontrollpunkter kvitteringId={kvittering?.id ?? ''} onCancel={closeTidligereKontrollpunkter} />;
  }
  return <KvitteringIdProvider value={kvittering?.id ?? ''} data-sentry-element="KvitteringIdProvider" data-sentry-component="ForberedNewTilsynView" data-sentry-source-file="view.tsx">
      <Grid data-sentry-element="Grid" data-sentry-source-file="view.tsx">
        <VirksomhetModal isOpen={isVirksomhetModalOpen} onCancel={onCancelVirksomhetModal} initialSearch={initialSearch(kvittering?.virksomhetsNavn, title)} onClickMidlertidigTilsynsobjekt={onClickMidlertidigTilsynsobjekt} midlertidigTilsynsobjektAktivitet={selectedAktivitet} midlertidigTilsynsobjektVirksomhet={midlertidigTilsynsObjektVirksomhet} onResetMidlertidigTilsynsojekt={() => {
        setMidlertidigTilsynsObjektVirksomhet({
          navn: '',
          nummer: ''
        });
      }} onUnselectMidlertidigTilsynsobjekt={tilsynsobjekt => setUnselectedMidlertidigTilsynsobjekt(tilsynsobjekt)} unselectedMidlertidigTilsynsobjekt={unselectedMidlertidigTilsynsobjekt} data-sentry-element="VirksomhetModal" data-sentry-source-file="view.tsx" />
        {isAktivitetModalOpen && <AktivitetModal isOpen={isAktivitetModalOpen} onCancel={() => onCancelAktivitetModal()} aktivitetOptions={aktivitetOptions} selectedAktivitet={selectedAktivitet} onSelect={onSelectAktivitet} onSearch={onSearchAktivitet} onUnselectMidlertidigTilsynsobjekt={tilsynsobjekt => setUnselectedMidlertidigTilsynsobjekt(tilsynsobjekt)} unselectedMidlertidigTilsynsobjekt={unselectedMidlertidigTilsynsobjekt} />}

        <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]} data-sentry-element="GridItem" data-sentry-source-file="view.tsx">
          <Row data-sentry-element="Row" data-sentry-source-file="view.tsx">
            <TextLink leftArrow onClick={onGoBack} data-sentry-element="TextLink" data-sentry-source-file="view.tsx">
              Tilbake
            </TextLink>
          </Row>
        </GridItem>
        <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]} data-sentry-element="GridItem" data-sentry-source-file="view.tsx">
          <Grid id="forbered-new-tilsyn" data-sentry-element="Grid" data-sentry-source-file="view.tsx">
            <GridItem xl={[2, -2]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]} data-sentry-element="GridItem" data-sentry-source-file="view.tsx">
              <Column spacing={3} fluid data-sentry-element="Column" data-sentry-source-file="view.tsx">
                <Row className="forbered-title" data-sentry-element="Row" data-sentry-source-file="view.tsx">
                  <h2>Forbered nytt tilsyn</h2>
                </Row>
                <Row data-sentry-element="Row" data-sentry-source-file="view.tsx">
                  <Column spacing={2} fluid data-sentry-element="Column" data-sentry-source-file="view.tsx">
                    <TypeTilsyn disabled={isAKvitteringBeingCreated} onChange={onChangeTypeTilsyn} currentType={kvittering?.typeTilsyn ?? ''} currentTypeTypeTilsynBeskrivelse={kvittering?.typeTilsynBeskrivelse} data-sentry-element="TypeTilsyn" data-sentry-source-file="view.tsx" />
                    <ContrastBox noBorders backgroundColor="white" data-sentry-element="ContrastBox" data-sentry-source-file="view.tsx">
                      <Row margin={[0, 0, 1, 0]} className="forbered-subtitle" data-sentry-element="Row" data-sentry-source-file="view.tsx">
                        <h4>Legg til sak</h4>
                      </Row>
                      <SakSearch onAddSak={onAddSak} onChange={onChange} disabled={isAKvitteringBeingCreated} sakNumberValue={sakNumberValue} errorText={sakNumberValue.length > 0 && isStartTilsynClicked && !(kvittering?.noarksakSekvensnummer && kvittering?.noarksakAar) ? 'Saken må legges til eller tekstfeltet må være tomt' : ''} data-sentry-element="SakSearch" data-sentry-source-file="view.tsx" />
                      <br />
                      <SakCardInfo sakNumber={sakNumber} title={title} onCancelSak={onCancelSak} sakFail={sakFail} sakNotFound={sakNotFound} sakClosed={sakClosed} isLoading={isLoadingSak} sakTilgang={sakTilgang} sakHjemmel={sakHjemmel} showSak={showSak} data-sentry-element="SakCardInfo" data-sentry-source-file="view.tsx" />
                    </ContrastBox>

                    <TilsynsobjekterCardInfo disabled={isAKvitteringBeingCreated} onAddRemoveTilsynsobjekt={onAddRemoveTilsynsobjekt} tilsynsobjekter={kvittering?.tilsynsobjekter ?? []} virksomhetsNavn={kvittering?.virksomhetsNavn} virksomhetsOrgNr={kvittering?.virksomhetsOrgNr} data-sentry-element="TilsynsobjekterCardInfo" data-sentry-source-file="view.tsx" />
                    {kvittering && kvittering.tilsynsobjekter?.length > 0 && <KontrollpunkterSection isLoading={isKvitteringLoading} tilsynsobjekter={kvittering.tilsynsobjekter} openTidligereKontrollpunkter={openTidligereKontrollpunkter} />}
                    <Row justify="space-between" align="center" data-sentry-element="Row" data-sentry-source-file="view.tsx">
                      <RowItem data-sentry-element="RowItem" data-sentry-source-file="view.tsx">
                        <TextLink leftArrow onClick={onGoBack} data-sentry-element="TextLink" data-sentry-source-file="view.tsx">
                          Tilbake
                        </TextLink>
                      </RowItem>
                      <RowItem data-sentry-element="RowItem" data-sentry-source-file="view.tsx">
                        <Button secondary arrow onClick={onClickTilsyn} data-sentry-element="Button" data-sentry-source-file="view.tsx">
                          Start tilsyn
                        </Button>
                      </RowItem>
                    </Row>
                  </Column>
                </Row>
              </Column>
            </GridItem>
          </Grid>
        </GridItem>
        <Status success={isKvitteringSuccess} error={isKvitteringError} isLoading={isKvitteringLoading || isKvitteringFetching} data-sentry-element="Status" data-sentry-source-file="view.tsx" />
      </Grid>
    </KvitteringIdProvider>;
};