import React, { useCallback, useState } from 'react';
import { DeleteModal } from '../../../modals';
import { IMultiActionButton } from '../types';
import { useCanRunMultiAction } from '../hooks';
import { BildeGroupedItem } from '../../bilder/types';
import { useRemoveBildeFromObservasjon } from '../../bilder/hooks/bilde-query-hooks';
import { useKvitteringId } from '../../../common/kvittering-context';
import { DetachIcon } from '../../../assets/detach-icon';
export const RemoveSelectedImages = ({
  selectedImages,
  clearSelectedImages
}: IMultiActionButton) => {
  const [isRemoveFromObservationModalOpen, setIsRemoveFromObservationModalOpen] = useState(false);
  const kvitteringId = useKvitteringId();
  const {
    mutate: removeBilde
  } = useRemoveBildeFromObservasjon(kvitteringId);
  const onOpenRemoveFromObservationModal = useCanRunMultiAction(selectedImages, () => setIsRemoveFromObservationModalOpen(true));
  const onCloseRemoveFromObservationModal = () => setIsRemoveFromObservationModalOpen(false);
  const onRemoveFromObservasjon = useCallback(() => {
    selectedImages.forEach(({
      id,
      observasjonId
    }: BildeGroupedItem) => {
      if (observasjonId) {
        removeBilde({
          bildeId: id,
          observasjonId
        });
      }
    });
    setIsRemoveFromObservationModalOpen(false);
    clearSelectedImages();
  }, [selectedImages, removeBilde, setIsRemoveFromObservationModalOpen, clearSelectedImages]);
  const removeFromObervationModalTitle = `Fjern ${selectedImages.length === 1 ? 'det valgte bildet' : `valgte ${selectedImages.length} bilder`} fra observasjon`;
  return <>
      <button className="image-action-menu__button" onClick={onOpenRemoveFromObservationModal}>
        <span className="tooltip-text">Fjern fra observasjon</span>

        <DetachIcon size="large" data-sentry-element="DetachIcon" data-sentry-source-file="remove-selected-images.tsx" />
      </button>
      <DeleteModal isOpen={isRemoveFromObservationModalOpen} title={removeFromObervationModalTitle} onCancel={onCloseRemoveFromObservationModal} onDelete={onRemoveFromObservasjon} explanation={'Bildene blir fjernet fra observasjonene de er koblet til og blir liggende som generelle bilder på tilsynskvitteringen.'} confirmText="Fjern" data-sentry-element="DeleteModal" data-sentry-source-file="remove-selected-images.tsx" />
    </>;
};