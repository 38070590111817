import { Column, RoundIndicator, Row, Text, WarningIcon } from '@mattilsynet/mt-ui';
import React, { ReactNode, useEffect, useRef } from 'react';
import './style.css';
export interface IInformationBoxHeader {
  children: ReactNode;
}
interface IInformationBoxProps {
  variant?: 'info' | 'warning' | 'danger';
  header?: string;
  children: ReactNode;
  small?: boolean;
}
const InformationBox = ({
  variant = 'info',
  children,
  header,
  small
}: IInformationBoxProps) => {
  const indicatorRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const child = indicatorRef.current?.childNodes[0] as HTMLDivElement;
    if (indicatorRef.current?.className !== undefined) {
      indicatorRef.current.className += ` ${child.className}`;
    }
  }, [indicatorRef]);
  const variantCssMapper = {
    info: 'info-box-container--info',
    warning: 'info-box-container--warning',
    danger: 'info-box-container--danger'
  };
  const variantJsxMapper = {
    info: <RoundIndicator>?</RoundIndicator>,
    warning: <WarningIcon size="large" />,
    danger: <RoundIndicator>!</RoundIndicator>
  };
  const variantJsx = (variant: string) => <div className={`info-box-container__indicator--${variant} ${small ? 'info-box-container__indicator--small' : ''} `} data-sentry-component="variantJsx" data-sentry-source-file="index.tsx">
      {variantJsxMapper[variant]}
    </div>;
  return <Column className={`info-box-container ${variantCssMapper[variant]}`} align="baseline" padding={small ? 0.5 : 1.5} style={{
    flexDirection: header ? 'column' : 'row'
  }} data-sentry-element="Column" data-sentry-component="InformationBox" data-sentry-source-file="index.tsx">
      <Row align="center" className="info-box-container__header" data-sentry-element="Row" data-sentry-source-file="index.tsx">
        {variantJsx(variant)}
        {header && <Header>{header}</Header>}
      </Row>
      <Row padding={0.5} className="info-box-container__content" data-sentry-element="Row" data-sentry-source-file="index.tsx">
        <Text as="div" size={small ? 'medium' : 'normal'} data-sentry-element="Text" data-sentry-source-file="index.tsx">
          {children}
        </Text>
      </Row>
    </Column>;
};
const Header = ({
  children
}: IInformationBoxHeader) => {
  return <Row padding={[0, 0, 0, 1]} data-sentry-element="Row" data-sentry-component="Header" data-sentry-source-file="index.tsx">
      <Text weight="bold" data-sentry-element="Text" data-sentry-source-file="index.tsx">{children}</Text>
    </Row>;
};
InformationBox.Header = Header;
export { InformationBox };