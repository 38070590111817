import {
  IKodeverkData,
  IKoderverkNames,
  IKodeverkState,
  IKodeverkActions,
  IRemoteKodeverk,
  IKodeverkQueries,
} from './types'
import { commonRedux } from '../../../common/redux'

export const actionTypes = {
  FETCH: '@kodeverk/FETCH' as const,
  FETCH_FAIL: '@kodeverk/FETCH_FAIL' as const,
  FETCH_OK: '@kodeverk/FETCH_OK' as const,
}

export const actions = {
  fetch: (kodeverkName: IKoderverkNames, queries?: IKodeverkQueries) => ({
    type: actionTypes.FETCH,
    kodeverkName,
    queries,
  }),

  fetchOk: (kodeverkName: IKoderverkNames, payload: IRemoteKodeverk[]) => ({
    type: actionTypes.FETCH_OK,
    kodeverkName,
    payload,
  }),

  fetchFail: (kodeverkName: IKoderverkNames, error) => ({
    type: actionTypes.FETCH_FAIL,
    kodeverkName,
    error,
  }),
}

export const initialState = {}

export const reducer = {
  '@kodeverk': commonRedux.createReducer<IKodeverkState, IKodeverkActions>(
    initialState,
    {
      [actionTypes.FETCH]: (
        state: IKodeverkState,
        { kodeverkName, queries }
      ): IKodeverkState => {
        return {
          ...state,
          [kodeverkName]: {
            ...state[kodeverkName],
            loading: true,
            error: '',
            queries,
          },
        }
      },
      [actionTypes.FETCH_FAIL]: (
        state: IKodeverkState,
        { kodeverkName, error }
      ): IKodeverkState => {
        return {
          ...state,
          [kodeverkName]: {
            ...state[kodeverkName],
            loading: false,
            error: error,
          },
        }
      },
      [actionTypes.FETCH_OK]: (
        state: IKodeverkState,
        { kodeverkName, payload }
      ): IKodeverkState => {
        return {
          ...state,
          [kodeverkName]: {
            ...state[kodeverkName],
            loading: false,
            error: '',
            data: payload,
            loaded: true,
          },
        }
      },
    }
  ),
}

export const selectors = {
  getKodeverk:
    (kodeverkName: IKoderverkNames) =>
    (state: any): IKodeverkData | undefined =>
      state?.['@kodeverk']?.[kodeverkName],
}
