import React from 'react';
import { Column, LoadingDots, Row } from '@mattilsynet/mt-ui';
import { ModalWrapper } from '../../components/modal-wrapper';
interface ILoadingModalProps {
  isOpen: boolean;
  onCancel: () => void;
  text?: string;
}
export const LoadingModal = ({
  isOpen,
  onCancel
}: ILoadingModalProps) => {
  return <>
      <ModalWrapper isOpen={isOpen} onCancel={onCancel} data-sentry-element="ModalWrapper" data-sentry-source-file="index.tsx">
        <Column height="10vh" align="center" fluid spacing={1} data-sentry-element="Column" data-sentry-source-file="index.tsx">
          <Row center data-sentry-element="Row" data-sentry-source-file="index.tsx">
            <p>Sjekker status på utfylling</p>
          </Row>
          <Row center data-sentry-element="Row" data-sentry-source-file="index.tsx">
            <LoadingDots data-sentry-element="LoadingDots" data-sentry-source-file="index.tsx" />
          </Row>
        </Column>
      </ModalWrapper>
    </>;
};