import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorBox, LoadingSpinner, Column, Row, TextLink } from '@mattilsynet/mt-ui';
import { ImageDescriptionWrapper } from '../image-description-wrapper';
import './style.css';
import LazyLoad from 'react-lazyload';
import { PlaceholderBox } from '../image-placeholder-box';
import { ModalWrapper } from '../modal-wrapper';
import { ModalButtonsContainer } from '../modal-buttons-container';
import { ModalButton } from '../modal-button';
import { modalContentPadding } from '../modal-buttons-container/constants';
import { useKvitteringId } from '../../common/kvittering-context';
import { useBildeMetadataList, useKvitteringBildeIds } from '../../features/bilder';
interface IGalleryModalContentProps {
  onSelect: (imageId: string) => void;
  selectedImages: string[];
}
const GalleryModalContent = ({
  onSelect,
  selectedImages
}: IGalleryModalContentProps) => {
  const kvitteringId = useKvitteringId();
  const bildeIds = useKvitteringBildeIds(kvitteringId);
  const {
    isLoading
  } = useBildeMetadataList(kvitteringId);
  const [showFullSizeImageUrl, setShowFullSizeImageUrl] = useState('');
  const [scrollPosition, setScrollPosition] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const modalBody = contentRef.current?.parentNode?.parentElement;
  const onShowFullsize = useCallback((url: string) => {
    if (modalBody) {
      setScrollPosition(modalBody.scrollTop);
    }
    setShowFullSizeImageUrl(url);
  }, [modalBody, setShowFullSizeImageUrl, setScrollPosition]);
  useEffect(() => {
    if (modalBody?.scrollTop === 0 && scrollPosition !== 0) {
      modalBody?.scrollTo({
        top: scrollPosition,
        behavior: 'instant'
      });
    }
  }, [scrollPosition, modalBody, modalBody?.scrollTop]);
  if (isLoading) {
    return <LoadingSpinner title={'Henter bilder..'} />;
  }
  if (!bildeIds.length) {
    return <ErrorBox errorText="Ingen bilder i galleriet" />;
  }
  return <Column ref={contentRef} className="gallery-modal-content" spacing={2.5} data-sentry-element="Column" data-sentry-component="GalleryModalContent" data-sentry-source-file="index.tsx">
      {showFullSizeImageUrl && <>
          <Row>
            <TextLink onClick={() => setShowFullSizeImageUrl('')} leftArrow>
              Tilbake
            </TextLink>
          </Row>
          <img className="image-view-display" src={showFullSizeImageUrl} />
        </>}

      {!showFullSizeImageUrl && <div className="image-list">
          {bildeIds.map(id => <LazyLoad key={id} placeholder={<PlaceholderBox height="500px" withShadow />} scrollContainer={'.gallery-modal .modal-body'}>
              <ImageDescriptionWrapper imageId={id} selected={selectedImages.includes(id)} onSelect={onSelect} onShowFullsize={onShowFullsize} />
            </LazyLoad>)}
        </div>}
    </Column>;
};
interface IGalleryModalProps {
  isOpen: boolean;
  onClose: (imageIds: string[]) => void;
  initialSelected?: string[];
  title: string;
}
export const GalleryModal = ({
  isOpen,
  onClose,
  initialSelected,
  title
}: IGalleryModalProps) => {
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const onSelect = useCallback((bildeId: string) => {
    selectedImages.includes(bildeId) ? setSelectedImages(selectedImages.filter(id => id !== bildeId)) : setSelectedImages([...selectedImages, bildeId]);
  }, [selectedImages]);
  useEffect(() => {
    if (isOpen) {
      setSelectedImages(initialSelected ?? []);
    }
  }, [isOpen, initialSelected]);
  const onCloseModal = useCallback(() => onClose([]), [onClose]);
  const onSaveImages = useCallback(() => onClose(selectedImages), [onClose, selectedImages]);
  return <ModalWrapper pauseFocusTrap={true} className="gallery-modal" isOpen={isOpen} onCancel={onCloseModal} title={title} fullscreenMobile alignTop data-sentry-element="ModalWrapper" data-sentry-component="GalleryModal" data-sentry-source-file="index.tsx">
      <Column padding={modalContentPadding} data-sentry-element="Column" data-sentry-source-file="index.tsx">
        <GalleryModalContent onSelect={onSelect} selectedImages={selectedImages} data-sentry-element="GalleryModalContent" data-sentry-source-file="index.tsx" />
      </Column>
      <ModalButtonsContainer data-sentry-element="ModalButtonsContainer" data-sentry-source-file="index.tsx">
        <ModalButton secondary onClick={onCloseModal} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">
          Lukk
        </ModalButton>
        <ModalButton disabled={selectedImages.length === 0} onClick={onSaveImages} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">
          Bruk bilde(r)
        </ModalButton>
      </ModalButtonsContainer>
    </ModalWrapper>;
};