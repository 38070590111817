import { useCallback } from 'react'
import {
  useKvittering,
  useOptimisticDebounceKvitteringMutate,
} from '../../kvitteringer'
import { MutationKey, useMutation } from '@tanstack/react-query'
import { kontrollpunktKeys } from '../queries/helpers'
import {
  IKontrollpunkt,
  IKontrollpunktMutateVars,
  IKontrollpunktVars,
} from '../types'
import { DEBOUNCE_MS } from '../../../constants'
import { getKontrollpunktById, isKontrollpunkterEqual } from '../helpers'
import { ITilsynsobjekt } from '../../tilsynsobjekter/types'
import { AxiosResponse } from 'axios'

export {
  useKontrollpunkter,
  useKontrollpunkt,
  useAddKontrollpunkt,
  useUpdateKontrollpunktBeskrivelse,
  useUpdateSorteringsrekkefoelge,
  useRemoveKontrollpunkt,
}

const onlySimpleObservasjoner = (kontrollpunkt: IKontrollpunkt) =>
  ({
    ...kontrollpunkt,
    observasjoner: kontrollpunkt.observasjoner.map((obs) => ({
      id: obs.id,
      tekst: obs.tekst,
    })),
  }) as IKontrollpunkt

const useKontrollpunkter = (kvitteringId: string, tilsynsobjektId: number) =>
  useKvittering<IKontrollpunkt[]>(kvitteringId, {
    select: (kvittering) => {
      const tilsynsobjekt = kvittering.tilsynsobjekter?.find(
        (to) => parseInt(to.id ?? '') === tilsynsobjektId
      )

      return tilsynsobjekt?.kontrollpunkter.map(onlySimpleObservasjoner) ?? []
    },
  })

const useKontrollpunkt = (kvitteringId: string, kontrollpunktId: string) =>
  useKvittering<IKontrollpunkt | undefined>(kvitteringId, {
    select: (kvittering) => {
      const kontrollpunkt = getKontrollpunktById(kvittering, kontrollpunktId)

      if (!kontrollpunkt) {
        return undefined
      }

      return onlySimpleObservasjoner(kontrollpunkt)
    },
  })

const useKontrollpunktMutation = (
  mutationKey: MutationKey,
  kvitteringId: string
) => {
  const mutation = useMutation<
    AxiosResponse<null>,
    string | Error,
    IKontrollpunktMutateVars,
    { previousKontrollpunkter: IKontrollpunkt[] }
  >({ mutationKey })

  return {
    ...mutation,
    mutate: (variables: IKontrollpunktVars) =>
      mutation.mutate({ kvitteringId, ...variables }),
  }
}

const useAddKontrollpunkt = (kvitteringId: string) =>
  useKontrollpunktMutation(kontrollpunktKeys.add(kvitteringId), kvitteringId)

const useUpdateKontrollpunktBeskrivelse = (kvitteringId: string) => {
  const updateBeskrivelse = useKontrollpunktMutation(
    kontrollpunktKeys.updateBeskrivelse(kvitteringId),
    kvitteringId
  )

  const optimisticDebounceUpdate = useOptimisticDebounceKvitteringMutate(
    updateBeskrivelse.mutate,
    kvitteringId,
    (kvittering, { kontrollpunkt }) => {
      if (!kvittering) return kvittering

      return {
        ...kvittering,
        tilsynsobjekter: kvittering.tilsynsobjekter.map((tilsynsobjekt) => {
          if (
            String(tilsynsobjekt.id) !== String(kontrollpunkt.tilsynsobjektId)
          ) {
            return tilsynsobjekt
          }

          return {
            ...tilsynsobjekt,
            kontrollpunkter: tilsynsobjekt.kontrollpunkter.map((kp) => {
              if (!isKontrollpunkterEqual(kp, kontrollpunkt)) {
                return kp
              }

              return {
                ...kp,
                beskrivelse: kontrollpunkt.beskrivelse,
              }
            }),
          } satisfies ITilsynsobjekt
        }),
      }
    }
  )

  const updateBeskrivelseFn = useCallback(
    (variables: IKontrollpunktVars) => {
      const { id } = variables.kontrollpunkt
      const updateKey = JSON.stringify(
        kontrollpunktKeys.updateBeskrivelse(kvitteringId)[0]
      )
      const key = `${updateKey}_${id}`

      optimisticDebounceUpdate(variables, {
        debounceMs: DEBOUNCE_MS,
        key,
      })
    },
    [kvitteringId, optimisticDebounceUpdate]
  )

  return {
    ...updateBeskrivelse,
    mutate: updateBeskrivelseFn,
  }
}

const useUpdateSorteringsrekkefoelge = (kvitteringId: string) =>
  useKontrollpunktMutation(
    kontrollpunktKeys.updateSorteringsrekkefoelge(kvitteringId),
    kvitteringId
  )

const useRemoveKontrollpunkt = (kvitteringId: string) =>
  useKontrollpunktMutation(kontrollpunktKeys.remove(kvitteringId), kvitteringId)
