import React from 'react';
import { Column, Row, RowItem, InputField, Button, LoadingDots } from '@mattilsynet/mt-ui';
import './style.css';
interface ISakSearchProps {
  onAddSak: () => void;
  onChange: (e: any) => void;
  sakNumberValue: string;
  errorText: string;
  disabled: boolean;
}
const SakSearch = ({
  onAddSak,
  onChange,
  sakNumberValue,
  errorText,
  disabled
}: ISakSearchProps) => {
  //TODO: This will eventually be removed when we apply the latest UI for Saksnummer
  const onSubmitSearchSak = e => {
    if (e.key === 'Enter' && sakNumberValue.length > 0) {
      e.currentTarget.blur();
      onAddSak();
    }
  };
  return <Row id="sak-search" data-sentry-element="Row" data-sentry-component="SakSearch" data-sentry-source-file="index.tsx">
      <Column fluid spacing={1} data-sentry-element="Column" data-sentry-source-file="index.tsx">
        <Row align="center" data-sentry-element="Row" data-sentry-source-file="index.tsx">
          <RowItem data-sentry-element="RowItem" data-sentry-source-file="index.tsx">
            <InputField className="inputField" value={sakNumberValue} label="Saksnummer" onChange={onChange} pattern="[0-9]*" placeholder="Feks. 2018/342343" inputMode="decimal" status={Boolean(errorText) ? 'danger' : undefined} errorText={errorText} onKeyDown={onSubmitSearchSak} data-sentry-element="InputField" data-sentry-source-file="index.tsx" />
          </RowItem>
          <RowItem alignSelf="flex-end" className="legg-til-sak-button" data-sentry-element="RowItem" data-sentry-source-file="index.tsx">
            <Button secondary onClick={onAddSak} disabled={disabled} iconAfter={disabled} icon={disabled ? <LoadingDots /> : undefined} data-sentry-element="Button" data-sentry-source-file="index.tsx">
              {disabled ? `Kvittering blir opprettet` : 'Legg til sak'}
            </Button>
          </RowItem>
        </Row>
      </Column>
    </Row>;
};
export default SakSearch;