import React from 'react';
import { Column, Header, Text } from '@mattilsynet/mt-ui';
import { IVirksomhetAdresse } from '../../ducks/kvittering/types';
interface IVirksomhetAdresseInfo {
  adresse?: IVirksomhetAdresse;
  tittel: string;
}
export const VirksomhetAdresseInfo = ({
  adresse,
  tittel
}: IVirksomhetAdresseInfo) => {
  if (!adresse || Object.keys(adresse).length === 0) return null;
  return <Column justify={'flex-start'} align={'flex-start'} spacing={1} data-sentry-element="Column" data-sentry-component="VirksomhetAdresseInfo" data-sentry-source-file="index.tsx">
      <Header as="h3" size="normal" data-sentry-element="Header" data-sentry-source-file="index.tsx">
        {tittel}
      </Header>
      <Column data-sentry-element="Column" data-sentry-source-file="index.tsx">
        {adresse.adresse.length > 0 && adresse.adresse.map((adresse, index) => <Text key={index} textAlign="left">
              {adresse}
            </Text>)}
      </Column>
      <Column data-sentry-element="Column" data-sentry-source-file="index.tsx">
        <Text data-sentry-element="Text" data-sentry-source-file="index.tsx">{`${adresse?.postnummer || ''} ${adresse?.poststed || ''}`}</Text>
      </Column>
    </Column>;
};