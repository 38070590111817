import * as R from 'ramda'
import {
  ITidligereTilsynskvittering,
  ITidligereTilsynskvitteringerActions,
  ITidligereTilsynskvitteringerResponse,
  ITidligereTilsynskvitteringerState,
} from '../types'
import { ROOT_STORE_NAME } from '../../../store'
import { addLoadingStatusToList } from '../../proveanalysesvar/store/helpers'
import { IStoreState } from '../../../../../reducers/types'
import { commonRedux } from '../../../../../common/redux'

export {
  tidligereTilsynskvitteringerActions,
  tidligereTilsynskvitteringerActionTypes,
  tidligereTilsynskvitteringerReducer,
  tidligereTilsynskvitteringerSelectors,
}

const STORE_NAME = '@tilsyn/tidligereTilsynskvitteringer'
const reducerId = 'tilsynsobjektId'

const tidligereTilsynskvitteringerActionTypes = {
  FETCH_TIDLIGERE_TILSYNSKVITTERINGER:
    `${STORE_NAME}/FETCH_TIDLIGERE_TILSYNSKVITTERINGER` as const,
  FETCH_TIDLIGERE_TILSYNSKVITTERINGER_OK:
    `${STORE_NAME}/FETCH_TIDLIGERE_TILSYNSKVITTERINGER_OK` as const,
  FETCH_TIDLIGERE_TILSYNSKVITTERINGER_FAIL:
    `${STORE_NAME}/FETCH_TIDLIGERE_TILSYNSKVITTERINGER_FAIL` as const,
}

const tidligereTilsynskvitteringerSelectors = {
  getTidligereTilsynskvitteringerByTilsynsobjekt:
    (tilsynsobjektId: string) =>
    (state: IStoreState): ITidligereTilsynskvittering[] =>
      state[ROOT_STORE_NAME][STORE_NAME].data[tilsynsobjektId]?.data
        ?.tilsynskvitteringer,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getErrorByTilsynsobjekt: (tilsynsobjektId: string) => (state: IStoreState) =>
    state[ROOT_STORE_NAME][STORE_NAME].error,
}

const tidligereTilsynskvitteringerActions = {
  fetchTidligereTilsynskvitteringer: (tilsynsobjektIds: string[]) => ({
    type: tidligereTilsynskvitteringerActionTypes.FETCH_TIDLIGERE_TILSYNSKVITTERINGER,
    tilsynsobjektIds,
  }),

  fetchTidligereTilsynskvitteringerOk: (
    tidligereTilsynskvitteringer: ITidligereTilsynskvitteringerResponse
  ) => ({
    type: tidligereTilsynskvitteringerActionTypes.FETCH_TIDLIGERE_TILSYNSKVITTERINGER_OK,
    tidligereTilsynskvitteringer,
  }),

  fetchTidligereTilsynskvitteringerFail: (error: string) => ({
    type: tidligereTilsynskvitteringerActionTypes.FETCH_TIDLIGERE_TILSYNSKVITTERINGER_FAIL,
    error,
  }),
}

const customReducer = commonRedux.createReducer<
  ITidligereTilsynskvitteringerState,
  ITidligereTilsynskvitteringerActions
>(commonRedux.initialListState, {
  [tidligereTilsynskvitteringerActionTypes.FETCH_TIDLIGERE_TILSYNSKVITTERINGER]:
    (state, { tilsynsobjektIds }) =>
      R.reduce(
        (acc, id) => addLoadingStatusToList(id, true, undefined, acc),
        state,
        tilsynsobjektIds
      ),

  [tidligereTilsynskvitteringerActionTypes.FETCH_TIDLIGERE_TILSYNSKVITTERINGER_OK]:
    (state, { tidligereTilsynskvitteringer }) =>
      R.reduce(
        (acc, newData) => commonRedux.addDataToList(reducerId, newData, acc),
        state,
        [tidligereTilsynskvitteringer]
      ),

  [tidligereTilsynskvitteringerActionTypes.FETCH_TIDLIGERE_TILSYNSKVITTERINGER_FAIL]:
    (state, { error }) => ({
      ...state,
      error,
      loading: false,
      loaded: true,
    }),
})

const tidligereTilsynskvitteringerReducer = {
  [STORE_NAME]: customReducer,
}
