import { ROOT_STORE_NAME } from '../../store'
import {
  IFaktabeskrivelserTidligereTilsynActions,
  IFaktabeskrivelserTidligereTilsynResponse,
  IFaktabeskrivelserTidligereTilsynState,
  IFaktabeskrivelseTidligereTilsyn,
} from './types'
import * as R from 'ramda'
import { IStoreState } from '../../../../reducers/types'
import { addLoadingStatusToList } from '../../helpers'
import { commonRedux } from '../../../../common/redux'

export {
  faktabeskrivelserTidligereTilsynActions,
  faktabeskrivelserTidligereTilsynActionTypes,
  faktabeskrivelserTidligereTilsynReducer,
  faktabeskrivelserTidligereTilsynSelectors,
}

const STORE_NAME = '@tilsyn/faktbeskrivelserTidligereTilsyn'
const reducerId = 'tilsynsobjektId'

const faktabeskrivelserTidligereTilsynActionTypes = {
  FETCH_FAKTABESKRIVELSER_TIDLIGERE_TILSYN:
    `${STORE_NAME}/FETCH_FAKTABESKRIVELSER_TIDLIGERE_TILSYN` as const,
  FETCH_FAKTABESKRIVELSER_TIDLIGERE_TILSYN_OK:
    `${STORE_NAME}/FETCH_FAKTABESKRIVELSER_TIDLIGERE_TILSYN_OK` as const,
  FETCH_FAKTABESKRIVELSER_TIDLIGERE_TILSYN_FAIL:
    `${STORE_NAME}/FETCH_FAKTABESKRIVELSER_TIDLIGERE_TILSYN_FAIL` as const,
}

const faktabeskrivelserTidligereTilsynSelectors = {
  getFaktabeskrivelserTidligereTilsynByTilsynsobjekt:
    (tilsynsobjektId: string) =>
    (state: IStoreState): IFaktabeskrivelseTidligereTilsyn[] =>
      state[ROOT_STORE_NAME][STORE_NAME].data[tilsynsobjektId]?.data
        ?.faktabeskrivelserTidligereTilsyn,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getErrorByTilsynsobjekt: (tilsynsobjektId: string) => (state: IStoreState) =>
    state[ROOT_STORE_NAME][STORE_NAME].error,
}

const faktabeskrivelserTidligereTilsynActions = {
  fetchFaktabeskrivelserTidligereTilsyn: (tilsynsobjektId: string) => ({
    type: faktabeskrivelserTidligereTilsynActionTypes.FETCH_FAKTABESKRIVELSER_TIDLIGERE_TILSYN,
    tilsynsobjektId,
  }),
  fetchTidligereTilsynskvitteringerOk: (
    faktabeskrivelserTidligereTilsyn: IFaktabeskrivelserTidligereTilsynResponse
  ) => ({
    type: faktabeskrivelserTidligereTilsynActionTypes.FETCH_FAKTABESKRIVELSER_TIDLIGERE_TILSYN_OK,
    faktabeskrivelserTidligereTilsyn,
  }),
  fetchTidligereTilsynskvitteringerFail: (error: string) => ({
    type: faktabeskrivelserTidligereTilsynActionTypes.FETCH_FAKTABESKRIVELSER_TIDLIGERE_TILSYN_FAIL,
    error,
  }),
}

const customReducer = commonRedux.createReducer<
  IFaktabeskrivelserTidligereTilsynState,
  IFaktabeskrivelserTidligereTilsynActions
>(commonRedux.initialListState, {
  [faktabeskrivelserTidligereTilsynActionTypes.FETCH_FAKTABESKRIVELSER_TIDLIGERE_TILSYN]:
    (state, { tilsynsobjektId }) =>
      R.reduce(
        (acc, id) => addLoadingStatusToList(id, true, undefined, acc),
        state,
        [tilsynsobjektId]
      ),

  [faktabeskrivelserTidligereTilsynActionTypes.FETCH_FAKTABESKRIVELSER_TIDLIGERE_TILSYN_OK]:
    (state, { faktabeskrivelserTidligereTilsyn }) =>
      R.reduce(
        (acc, faktabeskrivelser) =>
          commonRedux.addDataToList(reducerId, faktabeskrivelser, acc),
        state,
        [faktabeskrivelserTidligereTilsyn]
      ),

  [faktabeskrivelserTidligereTilsynActionTypes.FETCH_FAKTABESKRIVELSER_TIDLIGERE_TILSYN_FAIL]:
    (state, { error }) => ({
      ...state,
      error,
      loading: false,
      loaded: true,
    }),
})

const faktabeskrivelserTidligereTilsynReducer = {
  [STORE_NAME]: customReducer,
}
