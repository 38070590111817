import { ROOT_STORE_NAME } from '../../../store'
import { IKassasjonerActions, IKassasjonerState } from './types'
import { IStoreState } from '../../../../../reducers/types'
import { commonRedux } from '../../../../../common/redux'

export const STORE_NAME = '@tilsyn/kassasjoner'

export const kassasjonerActionTypes = {
  FETCH_LIST: `${STORE_NAME}/FETCH_LIST` as const,
  FETCH_LIST_OK: `${STORE_NAME}/FETCH_LIST_OK` as const,
  FETCH_LIST_FAILED: `${STORE_NAME}/FETCH_LIST_FAILED` as const,
}

export const kassasjonerActions = {
  fetchList: (
    funnIds?: (string | undefined)[],
    kassasjonIds?: (number | undefined)[],
    forceFetch?: boolean
  ) => ({
    type: kassasjonerActionTypes.FETCH_LIST,
    funnIds,
    kassasjonIds,
    forceFetch,
  }),
  fetchListOk: (kassasjoner: IKassasjonerState['kassasjoner']) => ({
    type: kassasjonerActionTypes.FETCH_LIST_OK,
    kassasjoner,
  }),
  fetchListFailed: (error: string) => ({
    type: kassasjonerActionTypes.FETCH_LIST_FAILED,
    error,
  }),
}

export const initialState: IKassasjonerState = {
  loading: false,
  loaded: false,
  kassasjoner: [],
}

const customReducer = commonRedux.createReducer<
  IKassasjonerState,
  IKassasjonerActions
>(initialState, {
  [kassasjonerActionTypes.FETCH_LIST]: (
    state: IKassasjonerState
  ): IKassasjonerState => ({
    ...state,
    loading: true,
  }),
  [kassasjonerActionTypes.FETCH_LIST_OK]: (
    state: IKassasjonerState,
    { kassasjoner }: { kassasjoner: IKassasjonerState['kassasjoner'] }
  ): IKassasjonerState => ({
    ...state,
    kassasjoner,
    loading: false,
    loaded: true,
  }),
  [kassasjonerActionTypes.FETCH_LIST_FAILED]: (
    state: IKassasjonerState,
    { error }: { error: string }
  ): IKassasjonerState => ({
    ...state,
    error,
    loading: false,
    loaded: true,
  }),
})

export const kassasjonerReducer = {
  [STORE_NAME]: customReducer,
}

export const kassasjonerSelectors = {
  getLoadingStatus: (
    state: IStoreState
  ): {
    loading: boolean
    loaded: boolean
    error: IKassasjonerState['error']
  } => {
    const { loading, loaded, error } = state[ROOT_STORE_NAME][STORE_NAME]
    return {
      loading,
      loaded,
      error,
    }
  },

  getKassasjoner: (state: IStoreState): IKassasjonerState['kassasjoner'] =>
    state[ROOT_STORE_NAME][STORE_NAME].kassasjoner,
}
