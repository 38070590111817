import { Column, Text } from '@mattilsynet/mt-ui';
import React, { useCallback } from 'react';
import { ModalButton } from '../../components/modal-button';
import { ModalButtonsContainer } from '../../components/modal-buttons-container';
import { modalContentPaddingLarge } from '../../components/modal-buttons-container/constants';
import { ModalWrapper } from '../../components/modal-wrapper';
interface IDeleteDialogModal {
  isOpen: boolean;
  entityId: string;
  entity: string;
  onCancel: () => void;
  onDelete: (imageId: string) => void;
}
export const DeleteDialogModal = ({
  isOpen,
  entityId,
  entity,
  onCancel,
  onDelete
}: IDeleteDialogModal) => {
  const onDeleteImage = useCallback(() => onDelete(entityId), [entityId, onDelete]);
  const modalText = `Er du sikker på at du vil slette ${entity}?`;
  return <ModalWrapper isOpen={isOpen} onCancel={onCancel} data-sentry-element="ModalWrapper" data-sentry-component="DeleteDialogModal" data-sentry-source-file="index.tsx">
      <Column padding={modalContentPaddingLarge} data-sentry-element="Column" data-sentry-source-file="index.tsx">
        <Text textAlign="center" weight="bold" data-sentry-element="Text" data-sentry-source-file="index.tsx">
          {modalText}
        </Text>
      </Column>
      <ModalButtonsContainer data-sentry-element="ModalButtonsContainer" data-sentry-source-file="index.tsx">
        <ModalButton secondary onClick={onCancel} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">
          Avbryt
        </ModalButton>
        <ModalButton onClick={onDeleteImage} data-sentry-element="ModalButton" data-sentry-source-file="index.tsx">Ja, slett bildet</ModalButton>
      </ModalButtonsContainer>
    </ModalWrapper>;
};