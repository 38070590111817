import { queryClient } from '../../../api/query-client'
import { IDeltaker, IDeltakerVars, IMutationContext } from '../types'
import {
  andreDeltakereKeys,
  getKvitteringId,
  IDeltakerKeys,
  mattilsynetDeltakerKeys,
  virksomhetDeltakerKeys,
} from './helpers'
import { deltakerApi } from './deltaker-api'
import store from '../../../reducers/store'
import { dangerToast } from '../../../common/toast'
import { shouldIgnoreError } from '../../../common/query'
import { ENDPOINT } from '../../../constants'
import { onlineManager } from '@tanstack/react-query'

const setDeltakerDefaults = (keys: IDeltakerKeys) => {
  const onError = (
    error: string | Error,
    { url }: IDeltakerVars,
    context: IMutationContext | undefined
  ) => {
    if (shouldIgnoreError(error)) {
      return
    }

    store.dispatch(
      dangerToast('Det har skjedd en feil på deltakersiden. Kunne ikke lagre.')
    )

    const kvitteringId = getKvitteringId(url)
    queryClient.setQueryData(
      keys.url(kvitteringId, url),
      context?.previousDeltakere
    )
  }

  const onSettled = (_data: any, _error: any, { url }: IDeltakerVars) => {
    const kvitteringId = getKvitteringId(url)
    return queryClient.invalidateQueries({
      queryKey: keys.url(kvitteringId, url),
    })
  }

  const defaultMutationOptions = () => ({
    onError,
    onSettled,
  })

  const onMutate =
    (
      updateList: (deltakere: IDeltaker[], deltaker: IDeltaker) => IDeltaker[]
    ) =>
    async ({ url, deltaker }: IDeltakerVars): Promise<IMutationContext> => {
      const kvitteringId = getKvitteringId(url)

      await queryClient.cancelQueries({ queryKey: keys.all })
      const previousDeltakere =
        queryClient.getQueryData<IDeltaker[]>(keys.url(kvitteringId, url)) ?? []

      queryClient.setQueryData<IDeltaker[]>(
        keys.url(kvitteringId, url),
        (old) => updateList(old ?? [], deltaker)
      )

      return { previousDeltakere }
    }

  const onMutateAdd = onMutate(
    (deltakere: IDeltaker[], deltaker: IDeltaker) => {
      if (onlineManager.isOnline()) {
        return deltakere
      }

      return [...deltakere, deltaker]
    }
  )

  const onSuccessAdd = (res, { url, deltaker }: IDeltakerVars) => {
    const kvitteringId = getKvitteringId(url)
    const deltakerId = res.headers.location.match(/(\d+)$/)?.[1]
    const deltakerType = keys.all[0].entity.replace('deltakere', 'deltagere')

    const newDeltaker = {
      ...deltaker,
      _links: {
        self: {
          href: `${ENDPOINT.TILSYNSKVITTERING}/${deltakerType}/${deltakerId}`,
        },
      },
    }

    queryClient.setQueryData(
      keys.uuid(kvitteringId, deltaker.id as string),
      () => newDeltaker
    )
  }

  const onMutateUpdate = onMutate(
    (deltakere: IDeltaker[], deltaker: IDeltaker) => {
      for (const oldDeltaker of deltakere) {
        if (oldDeltaker.id === deltaker.id) {
          oldDeltaker.navn = deltaker.navn
          break
        }
      }

      return deltakere
    }
  )

  const onMutateRemove = onMutate(
    (deltakere: IDeltaker[], deltaker: IDeltaker) =>
      deltakere.filter((oldDeltaker) => deltaker.id !== oldDeltaker.id)
  )

  const onSuccessRemove = (_, { url, deltaker }: IDeltakerVars) => {
    const kvitteringId = getKvitteringId(url)
    queryClient.removeQueries({
      queryKey: keys.uuid(kvitteringId, deltaker.id as string),
      exact: true,
    })
  }

  const getMutationUrl = ({ url, deltaker }: IDeltakerVars) => {
    const kvitteringId = getKvitteringId(url)
    const deltakerType = keys.all[0].entity.replace('deltakere', 'deltagere')

    let mutationUrl: string | undefined =
      `${ENDPOINT.TILSYNSKVITTERING}/${deltakerType}/${deltaker.id}`
    if (typeof deltaker.id !== 'number') {
      const localDeltaker = queryClient.getQueryData<IDeltaker>(
        keys.uuid(kvitteringId, deltaker.id)
      )

      mutationUrl = localDeltaker?._links?.self.href
    }

    if (!mutationUrl) {
      throw new Error(`URL is undefined on deltaker ${deltaker.id}`)
    }

    return mutationUrl
  }

  const putWithMutationUrl = ({ url, deltaker }) => {
    let putUrl = ''
    try {
      putUrl = getMutationUrl({ url, deltaker })
    } catch (error) {
      return Promise.resolve()
    }

    return deltakerApi.put({ url: putUrl, deltaker })
  }

  const deleteWithMutationUrl = ({ url, deltaker }) => {
    let putUrl = ''
    try {
      putUrl = getMutationUrl({ url, deltaker })
    } catch (error) {
      return Promise.resolve()
    }

    return deltakerApi.delete({ url: putUrl, deltaker })
  }

  queryClient.setMutationDefaults(keys.add(), {
    ...defaultMutationOptions(),
    mutationFn: deltakerApi.post,
    onMutate: onMutateAdd,
    onSuccess: onSuccessAdd,
  })

  queryClient.setMutationDefaults(keys.update(), {
    ...defaultMutationOptions(),
    mutationFn: putWithMutationUrl,
    onMutate: onMutateUpdate,
  })

  queryClient.setMutationDefaults(keys.remove(), {
    ...defaultMutationOptions(),
    mutationFn: deleteWithMutationUrl,
    onMutate: onMutateRemove,
    onSuccess: onSuccessRemove,
  })
}

setDeltakerDefaults(virksomhetDeltakerKeys)
setDeltakerDefaults(mattilsynetDeltakerKeys)
setDeltakerDefaults(andreDeltakereKeys)
