import { ICommonApi } from '../../../api'
import { concat, EMPTY, interval, merge, Observable, of } from 'rxjs'
import { AnyAction } from 'redux'
import { combineEpics, ofType, StateObservable } from 'redux-observable'
import { IStoreState } from '../../../reducers/types'
import {
  catchError,
  debounce,
  filter,
  groupBy,
  map,
  mergeMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators'
import { IVeiledning } from './types'
import { getIdFromSelfLink, isNumeric } from '../utils'
import { dangerToast, successToast } from '../../../common/toast'
import { veiledningInitialState } from './initialState'
import { veiledningActions, veiledningActionTypes } from './actions'
import { ENDPOINT } from '../../../constants'

export const updateVeiledningerEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(veiledningActionTypes.UPDATE_VEILEDNINGER),
      withLatestFrom(state$),
      mergeMap(([{ tilsynsobjekt, blockToast }, state]) => {
        const url = `${ENDPOINT.TILSYNSKVITTERING}/tilsynsobjekter/${tilsynsobjekt.id}/veiledninger`

        const veiledninger: IVeiledning[] = (tilsynsobjekt.veiledninger || [])
          .filter(
            (veiledning: IVeiledning) =>
              veiledning.tekst && veiledning.tekst.length >= 1
          )
          .map((veiledning: IVeiledning) => {
            veiledning.id = isNumeric(veiledning.id)
              ? veiledning.id
              : veiledning._links?.self.href
                ? getIdFromSelfLink(veiledning._links?.self.href).toString()
                : undefined
            return veiledning
          })
        return of(state).pipe(
          commonApi.put(url, state, [...veiledninger]),
          map((payload) =>
            veiledningActions.updateVeiledningerSuccess(payload)
          ),
          catchError((err) => {
            if (blockToast) {
              return merge(
                of(veiledningActions.updateVeiledningerFailed(err.message))
              )
            } else {
              return merge(
                of(veiledningActions.updateVeiledningerFailed(err.message)),
                of(dangerToast())
              )
            }
          })
        )
      })
    )

export const fetchVeiledningerByTilsynsobjektIdEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(veiledningActionTypes.FETCH_VEILEDNINGER_BY_TILSYNSOBJEKT),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      mergeMap(([{ tilsynsobjekt }, state]) => {
        const url = `${ENDPOINT.TILSYNSKVITTERING}/tilsynsobjekter/${tilsynsobjekt.id}/veiledninger`
        return of(state).pipe(
          commonApi.get(url, state),
          map((resp: any) =>
            resp._embedded ? resp._embedded.veiledninger : []
          ),
          mergeMap((veiledninger) => {
            const shouldCreateNew = veiledninger.length === 0
            return concat(
              of(
                veiledningActions.fetchVeiledningerByTilsynsobjektSuccess(
                  veiledninger
                )
              ),
              shouldCreateNew
                ? of(
                    veiledningActions.createLocalVeiledning(
                      veiledningInitialState
                    )
                  )
                : EMPTY
            )
          }),

          catchError((err) => {
            return concat(
              of(
                veiledningActions.fetchVeiledningerByTilsynsobjektFailed(
                  err.message
                )
              ),
              of(dangerToast())
            )
          })
        )
      })
    )

export const updateVeiledningEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(veiledningActionTypes.UPDATE_VEILEDNING),
      groupBy(({ veiledning }) => veiledning.id || veiledning._links.self.href),
      mergeMap((group$) =>
        group$.pipe(
          withLatestFrom(state$),
          filter(() => window.navigator.onLine),
          debounce(([{ veiledning }]) =>
            !!veiledning._links.self.href ? interval(2000) : interval(0)
          ),
          takeUntil(
            action$.pipe(
              ofType(veiledningActionTypes.DELETE_VEILEDNING),
              filter(
                ({ veiledning }) => veiledning._links.self.href === group$.key
              )
            )
          ),
          mergeMap(([{ veiledning }, state]) => {
            let url = veiledning._links.self.href
            if (!url) {
              if (veiledning.isCreating) {
                return of(veiledningActions.updateLocalVeiledning(veiledning))
              }
              return of(veiledningActions.createVeiledning(veiledning))
            }

            const veiledningsId = veiledning._links.self.href.split('/').pop()
            url = `${ENDPOINT.TILSYNSKVITTERING}/veiledninger/${veiledningsId}`

            return of(state).pipe(
              commonApi.put(url, state, {
                ...veiledning,
                id: null,
              }),
              map((payload) =>
                veiledningActions.updateVeiledningSuccess(payload)
              ),
              catchError((err) => {
                return concat(
                  of(veiledningActions.updateVeiledningFailed(err.message)),
                  of(dangerToast())
                )
              })
            )
          })
        )
      )
    )

export const deleteVeiledningEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(veiledningActionTypes.DELETE_VEILEDNING),
      withLatestFrom(state$),
      mergeMap(([{ veiledning }, state]) => {
        if (window.navigator.onLine) {
          const veiledningsId = veiledning._links.self.href.split('/').pop()
          const URL = `${ENDPOINT.TILSYNSKVITTERING}/veiledninger/${veiledningsId}`

          return of(state).pipe(
            commonApi.delete(URL, state),

            mergeMap(() => {
              return of(state).pipe(
                mergeMap(() => {
                  return concat(
                    !!veiledning.tekst
                      ? of(successToast('Veiledning ble slettet'))
                      : EMPTY,
                    of(veiledningActions.deleteVeiledningSuccess(veiledning))
                  )
                })
              )
            }),
            catchError((err) => {
              return concat(
                of(veiledningActions.deleteVeiledningFailed(err.message)),
                of(dangerToast())
              )
            })
          )
        } else {
          return of(state).pipe(
            mergeMap(() => {
              return of(state).pipe(
                mergeMap(() => {
                  return concat(
                    !!veiledning.tekst
                      ? of(successToast('Veiledning ble slettet'))
                      : EMPTY,
                    of(veiledningActions.deleteVeiledningSuccess(veiledning))
                  )
                })
              )
            }),
            catchError((err) => {
              return concat(
                of(veiledningActions.deleteVeiledningFailed(err.message)),
                of(dangerToast())
              )
            })
          )
        }
      })
    )

export const createVeiledningEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(veiledningActionTypes.CREATE_VEILEDNING),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      mergeMap(([{ veiledning }, state]) => {
        const url = `${ENDPOINT.TILSYNSKVITTERING}/tilsynsobjekter/${state.kvittering.selectedTilsynsobjekt.data.id}/veiledninger`

        return of(state).pipe(
          commonApi.post(url, state, {
            tekst: veiledning.tekst,
          }),
          map((resp: any) => {
            const location = resp.xhr.getResponseHeader('location')

            const newVeiledning = {
              ...veiledning,

              _links: {
                self: {
                  href: location,
                },
              },
            }
            return newVeiledning
          }),

          mergeMap((veiledning: IVeiledning) => {
            const latestTekst =
              state$.value.kvittering.selectedTilsynsobjekt.data?.veiledninger?.find(
                (vei) => vei.id === veiledning.id
              )?.tekst
            const shouldUpdateVeiledning = latestTekst !== veiledning.tekst
            return concat(
              of(veiledningActions.createVeiledningSuccess(veiledning)),
              shouldUpdateVeiledning
                ? of(
                    veiledningActions.updateVeiledning({
                      ...veiledning,
                      tekst: latestTekst || veiledning.tekst,
                    })
                  )
                : EMPTY
            )
          }),
          catchError((err) => {
            return merge(
              of(
                veiledningActions.createVeiledningFailed(
                  veiledning,
                  err.message
                )
              ),
              of(dangerToast())
            )
          })
        )
      })
    )

export default (commonApi: ICommonApi) =>
  combineEpics(
    fetchVeiledningerByTilsynsobjektIdEpic(commonApi),
    updateVeiledningEpic(commonApi) as any,
    deleteVeiledningEpic(commonApi) as any,
    createVeiledningEpic(commonApi) as any,
    updateVeiledningerEpic(commonApi) as any
  )
