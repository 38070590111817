import dayjs from 'dayjs'
import * as R from 'ramda'
import PopulertMattilsynMelding = IMeldingTilLokaltMattilsynRemote.PopulertMattilsynMelding
import { IMelding, IRemoteStatus, IStatusObject } from '../store/types'
import { Observable, of } from 'rxjs'
import { ICommonApi } from '../../../../../api'
import { IStoreState } from '../../../../../reducers/types'
import { ENDPOINT } from '../../../../../constants'
import { IAmFunn } from '../../am-funn/store/types'
import { IPmFunn } from '../../pm-funn/store/types'
import { createQueryStringFromObject } from '../../../../../common/createQueryStringFromObject'

export const convertStatusArrayToObject = (
  statusArray?: IRemoteStatus[]
): IStatusObject => {
  return (statusArray || []).reduce(
    (acc: Partial<IStatusObject>, status: IRemoteStatus) => {
      acc[status.status] = status.beskrivelse
      return acc
    },
    {}
  ) as IStatusObject
}

export const getMeldingListByEftanummer = (
  populertMattilsynMeldingList:
    | PopulertMattilsynMelding[]
    | IMelding[]
    | undefined
) =>
  (populertMattilsynMeldingList || []).reduce((acc, melding) => {
    if (melding.amFunnIds?.length === 0 && melding.pmFunnIds?.length === 0) {
      return acc
    }
    if (!acc[melding.eftanummer]) {
      acc[melding.eftanummer] = []
    }
    acc[melding.eftanummer].push(melding)
    return acc
  }, {})

export const getFunnRequests = (
  meldingListByEftanummer,
  commonApi: ICommonApi,
  state: IStoreState
) => {
  const requests: Observable<any>[] = []

  Object.entries(meldingListByEftanummer).forEach(
    ([eftanummer, meldingList]) => {
      const amFunnIds = R.pipe(
        R.reduce(
          (acc, { amFunnIds }) => (amFunnIds ? [...acc, ...amFunnIds] : acc),
          []
        ),
        R.uniq
      )(meldingList)
      const pmFunnIds = R.pipe(
        R.reduce(
          (acc, { pmFunnIds }) => (pmFunnIds ? [...acc, ...pmFunnIds] : acc),
          []
        ),
        R.uniq
      )(meldingList)

      if (amFunnIds.length > 0) {
        requests.push(
          commonApi.get(
            `${
              ENDPOINT.KJOETTKONTROLL_FUNN
            }/v2/am/funn/${createQueryStringFromObject('?')({
              slakteDatoFrom: dayjs()
                .subtract(365, 'days')
                .format('YYYY-MM-DD'),
              eftanummer,
              funnIds: amFunnIds.join(','),
              expand: ['individer', 'observasjoner', 'bildeIds'],
              size: 100,
            })}`,
            state
          )(of(state))
        )
      }
      if (pmFunnIds.length > 0) {
        requests.push(
          commonApi.get(
            `${
              ENDPOINT.KJOETTKONTROLL_FUNN
            }/v2/pm/funn/${createQueryStringFromObject('?')({
              slakteDatoFrom: dayjs()
                .subtract(365, 'days')
                .format('YYYY-MM-DD'),
              eftanummer: eftanummer,
              funnIds: pmFunnIds.join(','),
              expand: ['observasjoner', 'bildeIds'],
              size: 100,
            })}`,
            state
          )(of(state))
        )
      }
    }
  )

  return requests
}

export const populateMeldingsHistorikkWithFunnResponse = (
  funnResponse,
  populertMattilsynMeldingList
): IMelding[] => {
  let amFunnList: (IAmFunn | IPmFunn)[] = []
  let pmFunnList: (IAmFunn | IPmFunn)[] = []

  funnResponse.forEach((r) => {
    if (r?._embedded?.amFunnList) {
      amFunnList = [...amFunnList, ...r._embedded.amFunnList]
    } else if (r?._embedded?.pmFunnList) {
      pmFunnList = [...pmFunnList, ...r._embedded.pmFunnList]
    }
  })

  amFunnList = amFunnList.map((funn) => ({
    ...funn,
    isAmFunn: true,
  }))
  pmFunnList = pmFunnList.map((funn) => ({
    ...funn,
    isAmFunn: false,
  }))

  return populertMattilsynMeldingList.map((melding) => {
    const amFunn = melding.amFunnIds.map((id) =>
      amFunnList.find((funn) => funn.funnId === id)
    )
    const pmFunn = melding.pmFunnIds.map((id) =>
      pmFunnList.find((funn) => funn.funnId === id)
    )
    const funnList = [...amFunn, ...pmFunn]
    return {
      ...melding,
      sistRedigert: dayjs(melding.sistRedigert),
      funnList: funnList.filter((funn) => funn),
    }
  })
}
