import * as R from 'ramda'

export const addLoadingStatusToList = (
  id: string,
  loading: boolean,
  error: any,
  state: any
) => {
  const newIndexList = R.pipe(R.path(['index']), R.append(id), R.uniq)(state)

  const newDataList = R.pipe(R.path(['data']), (data) =>
    R.mergeLeft({
      [id]: {
        loading: loading,
        error: error,
        data: data?.[id]?.data,
      },
    })(data)
  )(state)

  return {
    data: newDataList,
    index: newIndexList,
    loading: true,
    error: undefined,
  }
}
