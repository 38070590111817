import * as R from 'ramda';
import React from 'react';
import dayjs from 'dayjs';
import { GodkjenningerEmpty, GodkjenningerView } from './view';
const sortByDescription = R.sortBy(R.compose(R.toLower, R.prop('description')));
const seperateInactiveGodkjenninger = godkjenninger => R.pipe(R.groupBy(godkjenning => {
  return !godkjenning.tilDato ? 'activeGodkjenninger' : dayjs(godkjenning.tilDato).isBefore(dayjs()) ? 'expiredGodkjenninger' : 'activeGodkjenninger';
}), R.mergeRight({
  activeGodkjenninger: [],
  expiredGodkjenninger: []
}), R.evolve({
  activeGodkjenninger: sortByDescription,
  expiredGodkjenninger: sortByDescription
}))(godkjenninger);
interface IInputProps {
  godkjenninger: any;
  showExpiredGodkjenninger: boolean;
  onClickExpiredGodkjenninger: () => void;
}
const Godkjenninger: React.FC<IInputProps> = ({
  godkjenninger,
  showExpiredGodkjenninger,
  onClickExpiredGodkjenninger
}) => {
  const {
    activeGodkjenninger,
    expiredGodkjenninger
  } = seperateInactiveGodkjenninger(godkjenninger);
  if (godkjenninger && godkjenninger.length === 0) {
    return <GodkjenningerEmpty />;
  } else {
    return <GodkjenningerView activeGodkjenninger={activeGodkjenninger} expiredGodkjenninger={expiredGodkjenninger} onClickExpiredGodkjenninger={onClickExpiredGodkjenninger} showExpiredGodkjenninger={showExpiredGodkjenninger} />;
  }
};
export default Godkjenninger;