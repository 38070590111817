import { Observable, of } from 'rxjs'
import { mergeMap, map } from 'rxjs/operators'
import localForage from 'localforage'
import 'localforage-getitems'

export interface INotificationAction {
  action: string
}

// eslint-disable-next-line @typescript-eslint/naming-convention
// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export interface IDBNotification {
  [id: string]: INotificationAction
}

const databaseName = 'notifications'

const offlineStagedActions = localForage.createInstance({
  name: databaseName,
  storeName: 'staged_actions',
  version: 1.0,
})

export const dropDatabase = (): Observable<string> => {
  const instances = [offlineStagedActions]
  return of(instances).pipe(
    mergeMap((instance) => instance),
    mergeMap((instance) => instance.clear()),
    map(() => 'DATABASE DELETED')
  )
}

const getStagedActions = (action: string) =>
  new Observable<IDBNotification[]>((obs$) => {
    offlineStagedActions
      .getItems()
      .then((results) => {
        const stagedActions = Object.keys(results)
          .filter((id) => results[id].action === action)
          .map((id) => ({
            id,
            ...results[id],
          }))
        obs$.next(stagedActions || [])
        obs$.complete()
      })
      .catch((err) => {
        obs$.error(
          new Error(`Error while getting deleted from indexedDB: ${err}`)
        )
      })
  })

const stageAction = (id: string, action: INotificationAction) =>
  new Observable<string>((obs$) => {
    offlineStagedActions.setItem(id, action, (err) => {
      if (err) {
        obs$.error(err)
      } else {
        obs$.next(id)
        obs$.complete()
      }
    })
  })

const removeStagedAction = (id: string) =>
  new Observable<string>((obs$) => {
    offlineStagedActions.removeItem(id, (err) => {
      if (err) {
        obs$.error(err)
      } else {
        obs$.next(id)
        obs$.complete()
      }
    })
  })

const removeNotificationStagedAction = (id: string) =>
  of(id).pipe(mergeMap((id) => removeStagedAction(id)))

const stageNotificationForDeletion = (id: string) =>
  stageAction(id, { action: 'DELETE' })

const stageNotificationForSeen = (id: string) =>
  stageAction(id, { action: 'SEEN' })

export default {
  dropDatabase,
  getStagedActions,
  removeNotificationStagedAction,
  stageNotificationForDeletion,
  stageNotificationForSeen,
}
