import React, { useCallback, useEffect, useState } from 'react';
import { Button, CheckboxItem, Column, ContrastBox, Header, LoadingDots, RadioButtonList, Row, Text } from '@mattilsynet/mt-ui';
import { parseSakTitleToHTML } from '../../../common/parse-string';
import { kvitteringActions } from '../../../ducks/kvittering/actions';
import { useTypedSelector } from '../../../common/custom-hooks';
import { IStoreState } from '../../../reducers/types';
import { useDispatch } from 'react-redux';
import { kvitteringStatusEnum, NONE, SAVING } from '../../../ducks/kvittering/types';
import { ArchiveModal, HjemmelModal, InfoModal, SkjermingModal } from '../../../modals';
import { kodeverk } from '../../../features/kodeverk';
import { IRemoteKodeverk } from '../../../features/kodeverk/store/types';
import { isValidSak } from '../utils';
import { originalTittel, skjermOrdWithinBrackets } from '../../../modals/skjerming-modal/utils';
import { fetchKvitteringArkivInfo, updateSkjermedeOrd } from '../../../ducks/arkivering-status/actions';
import { ISkjermedeOrdWithSelected } from '../../../ducks/arkivering-status/types';
import { InfoModalWithoutConfirmation } from '../../../modals/info-modal';
import { dangerToast, useSingleToast, offlineInHjemmelSelect, offlineOnArchiveKvittering, offlineOnSkjermMottaker, offlineOnUnntaOffentlighet, arkiveringTemporaryDisabled } from '../../../common/toast';
import { TextWithValidation } from '../../../components/text-with-validation';
import { KjaeledyrholdWarning } from '../../../components/kjaeledyrhold-warning';
import { ITilsynsobjekt } from '../../../features/tilsynsobjekter/types';
import { isArkivertStatus } from '../../../utils/kvittering';
import '../style.css';
import { TilsynsdatoPicker } from '../../../components/tilsynsdato-picker';
import { useTKNavigate } from '../../../common/navigation';
import { TOGGLES, useSafeUnleashWithoutAnnoyingBugs } from '../../../common/unleashToggles';
import { getSakData, useSak } from '../../../features/sak';
enum SkjermingAvMottakerOptions {
  SKAL_SKJERMES = '1',
  SKAL_IKKE_SKJERMES = '0',
}
const getSakNumber = (noarksakAar?: string, noarksakSekvensnummer?: string) => {
  if (noarksakAar && noarksakSekvensnummer) {
    return `${noarksakAar}/${noarksakSekvensnummer}`;
  }
  return undefined;
};
export const ArkiveringSlide = () => {
  const navigate = useTKNavigate();
  const singleToast = useSingleToast();
  const {
    isEnabled: isTKArkiveringEnabled
  } = useSafeUnleashWithoutAnnoyingBugs(TOGGLES.tkArkivering);
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline);
  const currentKvittering = useTypedSelector((state: IStoreState) => state.kvittering.selectedKvittering.data);
  const currentKvitteringSavingStatus = useTypedSelector((state: IStoreState) => state.kvittering.selectedKvittering.status);
  const sakNumber = getSakNumber(currentKvittering.noarksakAar, currentKvittering.noarksakSekvensnummer);
  const {
    data: sak,
    isLoading: isSakLoading,
    isError: isSakError,
    error: sakError
  } = useSak(sakNumber);
  const currentSak = getSakData(sak, isSakLoading, isSakError, sakError);
  const arkivInfo = useTypedSelector((state: IStoreState) => state.arkiveringStatus.arkivInfo);
  const isPrivatperson = currentKvittering.virksomhetsOrgNr === undefined;
  const showKjaeledyrholdWarning = isPrivatperson && currentKvittering.tilsynsobjekter?.map((tilsynsobjekt: ITilsynsobjekt) => tilsynsobjekt.aktivitetsBeskrivelse).includes('Kjæledyrhold');
  const kodeverkResponse = kodeverk.hooks.useKodeverk('TILGANGSHJEMMEL');
  const [isArchiveModalOpen, setIsArchiveModalopen] = useState(false);
  const [isErrorInfoModalOpen, setIsErrorInfoModalOpen] = useState(false);
  const [isNotFoundInfoModalOpen, setIsNotFoundInfoModalOpen] = useState(false);
  const [isHjemmelModalOpen, setIsHjemmelModalOpen] = useState(false);
  const [isSakstittelModalOpen, setIsSakstittelModalOpen] = useState(false);
  const [hjemmelMoreInfo, setHjemmelMoreInfo] = useState<string | undefined>('');
  const [existingHjemmelMoreInfo, setExistingHjemmelMoreInfo] = useState<string | undefined>('');
  const [isErrorSkjermMottaker, setIsErrorSkjermMottaker] = useState(false);
  const [isErrorSkalTaggeVirksomhetsnavn, setIsErrorSkalTaggeVirksomhetsnavn] = useState(false);
  const dispatch = useDispatch();
  const firstTilsynsobjektWithId = currentKvittering.tilsynsobjekter.find(tilsynsobjekt => tilsynsobjekt.tilsynsobjektId !== undefined);

  // todo temporarily dispatch fetchSammenstilling to assure fresh data, might be redundant after validating is in place
  useEffect(() => {
    dispatch(kvitteringActions.fetchSammenstilling());
  }, [dispatch]);
  useEffect(() => {
    if (!isValidSak(currentKvittering, currentSak)) {
      dispatch(fetchKvitteringArkivInfo());
    }
  }, []);
  useEffect(() => {
    if (firstTilsynsobjektWithId) {
      dispatch(kvitteringActions.fetchVirksomhetOrganisasjonsForm(firstTilsynsobjektWithId?.tilsynsobjektId));
      return;
    }
    dispatch(kvitteringActions.fetchVirksomhetOrganisasjonsForm(undefined, currentKvittering.virksomhetsOrgNr ?? ''));
  }, []);
  const isTittelSkjermet = !!arkivInfo?.skjermedeOrd?.length || showKjaeledyrholdWarning;
  const isNewSak = !isValidSak(currentKvittering, currentSak) && !currentSak.sakClosed;
  const onArchive = useCallback((nextStatus?: string) => {
    if (nextStatus === kvitteringStatusEnum.FERDIGSTILT) {
      return dispatch(kvitteringActions.ferdigstillKvittering(currentKvittering.id));
    } else {
      return dispatch(kvitteringActions.arkiverKvittering(currentKvittering.id));
    }
  }, [dispatch, currentKvittering.id]);
  const onCancelModal = (shouldRefreshData: boolean) => {
    if (currentKvittering?.status === kvitteringStatusEnum.FERDIGSTILT) {
      navigate('/');
    }
    if (shouldRefreshData) {
      dispatch(kvitteringActions.fetchSammenstilling());
    }
    setIsArchiveModalopen(false);
  };
  const showHjemmel = !!currentKvittering.unntattOffentlighet || !!currentKvittering.personSkjermet || !!(arkivInfo?.skjermedeOrd && arkivInfo.skjermedeOrd.length > 0);
  const onUnntattOffentlighet = useCallback(() => {
    const {
      unntattOffentlighet,
      hjemmelForUnntattOffentlighet
    } = currentKvittering;
    dispatch(kvitteringActions.updateCurrentKvittering({
      unntattOffentlighet: !unntattOffentlighet,
      hjemmelForUnntattOffentlighet: showHjemmel ? hjemmelForUnntattOffentlighet : ''
    }));
  }, [dispatch, currentKvittering, showHjemmel]);
  const onSkjerming = useCallback((option: SkjermingAvMottakerOptions) => {
    setIsErrorSkjermMottaker(false);
    const {
      hjemmelForUnntattOffentlighet
    } = currentKvittering;
    dispatch(kvitteringActions.updateCurrentKvittering({
      personSkjermet: option === SkjermingAvMottakerOptions.SKAL_SKJERMES,
      hjemmelForUnntattOffentlighet: showHjemmel ? hjemmelForUnntattOffentlighet : ''
    }));
  }, [currentKvittering, showHjemmel, dispatch, setIsErrorSkjermMottaker]);
  const onCancelHjemmelModal = () => {
    setIsHjemmelModalOpen(false);
  };
  const skjermSaksTittel = useCallback((skjermedeOrd: ISkjermedeOrdWithSelected[]) => dispatch(updateSkjermedeOrd(skjermedeOrd)), [dispatch]);
  const onCloseSakstittelModal = (skjermedeOrd: ISkjermedeOrdWithSelected[]) => {
    skjermSaksTittel(skjermedeOrd);
    setIsSakstittelModalOpen(false);
  };
  const onSkjermingSakstittel = (checked: boolean) => {
    if (!checked) {
      skjermSaksTittel([]);
      return;
    }
    const sakstittelOrd = arkivInfo.ord?.map(o => ({
      index: o.index,
      ord: o.ord,
      selected: !isTittelSkjermet
    }));
    skjermSaksTittel(sakstittelOrd ?? []);
  };
  useEffect(() => {
    if (!showHjemmel) {
      dispatch(kvitteringActions.updateCurrentKvittering({
        hjemmelForUnntattOffentlighet: ''
      }));
      setHjemmelMoreInfo('');
    } else {
      const hjemmel = kodeverkResponse?.data?.find(hjemmel => hjemmel.codeString === currentKvittering?.hjemmelForUnntattOffentlighet);
      setHjemmelMoreInfo(hjemmel?.displayNames?.descr_ifelt);
    }
  }, [showHjemmel, kodeverkResponse?.data]);
  const onSelectHjemmel = useCallback((selectedHjemmel: IRemoteKodeverk) => {
    setHjemmelMoreInfo(selectedHjemmel?.displayNames?.descr_ifelt);
    dispatch(kvitteringActions.updateCurrentKvittering({
      hjemmelForUnntattOffentlighet: selectedHjemmel.codeString
    }));
  }, [dispatch, setHjemmelMoreInfo]);
  const archiveStatus = isArkivertStatus(currentKvittering.status) ? currentKvitteringSavingStatus : NONE;
  const onOpprettTilsynskvittering = () => {
    const {
      unntattOffentlighet,
      hjemmelForUnntattOffentlighet,
      personSkjermet
    } = currentKvittering;
    if (isOffline) {
      return singleToast.showToast(offlineOnArchiveKvittering());
    }
    if (!isTKArkiveringEnabled) {
      return singleToast.showToast(arkiveringTemporaryDisabled());
    }
    if ((!!unntattOffentlighet || !!personSkjermet || isTittelSkjermet) && !hjemmelForUnntattOffentlighet) {
      const errorText = 'Du må velge hjemmel før du kan opprette tilsynskvitteringen i arkivet.';
      return dispatch(dangerToast(errorText));
    }
    if (currentSak.sakClosed) {
      return setIsErrorInfoModalOpen(true);
    }
    if (currentSak.sakNotFound) {
      return setIsNotFoundInfoModalOpen(true);
    }
    if (currentKvittering.personSkjermet === undefined) {
      return setIsErrorSkjermMottaker(true);
    }
    if (currentKvittering?.virksomhetNavnSkalTagges === undefined && currentKvittering.orgFormKode === 'ENK' && !currentSak.sakNumber) {
      return setIsErrorSkalTaggeVirksomhetsnavn(true);
    }
    setIsArchiveModalopen(true);
  };
  useEffect(() => {
    if (currentSak.sakHjemmel) {
      setExistingHjemmelMoreInfo(kodeverkResponse?.data?.find(hjemmel => hjemmel.displayNames && hjemmel.displayNames.no === currentSak.sakHjemmel)?.displayNames?.descr_ifelt);
    }
  }, [currentSak.sakHjemmel, kodeverkResponse.data]);
  const renderInfo = () => {
    if (currentSak.sakNotFound) {
      return <Column>
          <Text size="normal" weight="bold">
            Saken du har søkt opp finnes ikke.
          </Text>
          <Text size="normal">
            Det vil derfor opprettes en sak når du oppretter tilsynskvitteringen
            i arkivet.
          </Text>
        </Column>;
    }
    if (currentSak.sakClosed) {
      return <Column>
          <Text size="normal" weight="bold">
            Saken du har søkt opp er ugyldig.
          </Text>
          <Text size="normal">
            Du må enten gjenåpne saken, legge til en gyldig sak, eller fjerne
            saken slik at det opprettes en ny sak når tilsynskvitteringen
            arkiveres.
          </Text>
        </Column>;
    }
    if (currentSak.sakNumber) {
      return <Text size="normal" weight="semibold">
          Tilsynskvitteringen opprettes i arkivet på følgende sak:
        </Text>;
    }
    return <Column data-sentry-element="Column" data-sentry-component="renderInfo" data-sentry-source-file="arkivering-slide.tsx">
        <Text size="normal" weight="bold" data-sentry-element="Text" data-sentry-source-file="arkivering-slide.tsx">
          Du har ikke søkt opp en sak.
        </Text>
        <Text size="normal" data-sentry-element="Text" data-sentry-source-file="arkivering-slide.tsx">
          Det vil derfor opprettes en sak når du oppretter tilsynskvitteringen i
          arkivet.
        </Text>
      </Column>;
  };
  const renderSakInfo = () => {
    // If user has searched for a sak
    if (currentSak.sakNumber && !currentSak.sakNotFound) {
      return <Column fluid spacing={2.5}>
          <div>
            <Text size="normal" weight="bold">
              Saksnummer
            </Text>
            <Text size="normal">{currentSak.sakNumber}</Text>
          </div>
          {currentSak.title && <div>
              <Text size="normal" weight="bold">
                Sakstittel
              </Text>
              <Text as="div" size="normal">
                <div dangerouslySetInnerHTML={{
              __html: parseSakTitleToHTML(originalTittel(currentSak.title))
            }} />
              </Text>
            </div>}
          {existingHjemmelMoreInfo && existingHjemmelMoreInfo?.length > 1 && <div>
              <Text size="normal" weight="bold">
                Saken er unntatt offentlighet
              </Text>
              <Text size="normal">{currentSak.sakHjemmel}</Text>
              <Text size="medium" weight="lighter">
                {existingHjemmelMoreInfo}
              </Text>
            </div>}
        </Column>;
    }
    if (isNewSak) {
      return <Column fluid spacing={0.5}>
          <Text size="normal" weight="bold">
            Sakstittel
          </Text>
          <Text as="div" size="normal">
            <div dangerouslySetInnerHTML={{
            __html: parseSakTitleToHTML(isTittelSkjermet ? skjermOrdWithinBrackets(arkivInfo.sakstittel ?? '') : originalTittel(arkivInfo.sakstittel ?? ''))
          }} />
          </Text>
          <CheckboxItem selected={isTittelSkjermet} onClick={() => onSkjermingSakstittel(!isTittelSkjermet)} disabled={showKjaeledyrholdWarning}>
            <Text size="normal">Sakstittel skal unntas offentlighet</Text>
          </CheckboxItem>
        </Column>;
    }
    return <LoadingDots data-sentry-element="LoadingDots" data-sentry-component="renderSakInfo" data-sentry-source-file="arkivering-slide.tsx" />;
  };
  const renderSkjerming = () => {
    const options = [{
      value: SkjermingAvMottakerOptions.SKAL_SKJERMES,
      label: 'Mottakeren skal skjermes'
    }, {
      value: SkjermingAvMottakerOptions.SKAL_IKKE_SKJERMES,
      label: 'Mottakeren skal ikke skjermes'
    }];
    const value = currentKvittering.personSkjermet !== undefined ? currentKvittering.personSkjermet ? SkjermingAvMottakerOptions.SKAL_SKJERMES : SkjermingAvMottakerOptions.SKAL_IKKE_SKJERMES : undefined;
    return <Column fluid spacing={2.5} data-sentry-element="Column" data-sentry-component="renderSkjerming" data-sentry-source-file="arkivering-slide.tsx">
        <Header as="h2" size="heading3" data-sentry-element="Header" data-sentry-source-file="arkivering-slide.tsx">
          Skjerming av mottaker
        </Header>
        <Row justify="space-between" data-sentry-element="Row" data-sentry-source-file="arkivering-slide.tsx">
          <RadioButtonList disabled={showKjaeledyrholdWarning} options={options} onChange={option => {
          isOffline ? singleToast.showToast(offlineOnSkjermMottaker()) : onSkjerming(option as SkjermingAvMottakerOptions);
        }} value={value} noBackgroundColor hasError={isErrorSkjermMottaker} data-sentry-element="RadioButtonList" data-sentry-source-file="arkivering-slide.tsx" />
        </Row>
        <TextWithValidation errorText={'Utfylling mangler'} value={''} hasError={isErrorSkjermMottaker} withWhiteSpace as="h4" bold data-sentry-element="TextWithValidation" data-sentry-source-file="arkivering-slide.tsx" />
      </Column>;
  };
  const renderSkalTaggeVirksomhet = () => {
    const onChange = value => {
      dispatch(kvitteringActions.updateCurrentKvittering({
        virksomhetNavnSkalTagges: value === '0'
      }));
      setIsErrorSkalTaggeVirksomhetsnavn(false);
    };
    return <Column data-sentry-element="Column" data-sentry-component="renderSkalTaggeVirksomhet" data-sentry-source-file="arkivering-slide.tsx">
        <Text margin={[2, 0, 2, 0]} weight="bold" data-sentry-element="Text" data-sentry-source-file="arkivering-slide.tsx">{`Inneholder virksomhetsnavnet "${currentKvittering.virksomhetsNavn}" andre ord enn personnavn?`}</Text>
        <RadioButtonList options={[{
        value: '1',
        label: 'Ja'
      }, {
        value: '0',
        label: 'Nei, virksomhetsnavnet består kun av innehavers personnavn'
      }]} onChange={value => onChange(value)} noBackgroundColor value={currentKvittering?.virksomhetNavnSkalTagges === undefined ? '' : currentKvittering.virksomhetNavnSkalTagges ? '0' : '1'} hasError={isErrorSkalTaggeVirksomhetsnavn} data-sentry-element="RadioButtonList" data-sentry-source-file="arkivering-slide.tsx" />
        <TextWithValidation errorText={'Utfylling mangler'} value={''} hasError={isErrorSkalTaggeVirksomhetsnavn} withWhiteSpace as="h4" bold data-sentry-element="TextWithValidation" data-sentry-source-file="arkivering-slide.tsx" />
      </Column>;
  };
  const renderUnntattOffentlighet = () => {
    return <Column fluid spacing={2.5} data-sentry-element="Column" data-sentry-component="renderUnntattOffentlighet" data-sentry-source-file="arkivering-slide.tsx">
        <Header as="h2" size="heading3" data-sentry-element="Header" data-sentry-source-file="arkivering-slide.tsx">
          Unnta dokument og/eller bilder fra offentlighet
        </Header>
        <Row justify="space-between" data-sentry-element="Row" data-sentry-source-file="arkivering-slide.tsx">
          <CheckboxItem disabled={showKjaeledyrholdWarning} onClick={() => isOffline ? singleToast.showToast(offlineOnUnntaOffentlighet()) : onUnntattOffentlighet()} selected={!!currentKvittering.unntattOffentlighet} data-sentry-element="CheckboxItem" data-sentry-source-file="arkivering-slide.tsx">
            <Text size="normal" data-sentry-element="Text" data-sentry-source-file="arkivering-slide.tsx">
              Dokumentet og/eller bilder skal unntas offentlighet
            </Text>
          </CheckboxItem>
        </Row>
      </Column>;
  };
  const onHjemmelButton = () => {
    if (isOffline) {
      return singleToast.showToast(offlineInHjemmelSelect());
    }
    setIsHjemmelModalOpen(true);
  };
  const renderHjemmelInfo = () => {
    const isSelectedHjemmel = !!currentKvittering.hjemmelForUnntattOffentlighet;
    const hjemmel = {
      title: isSelectedHjemmel ? 'Hjemmel for unntatt offentlighet er valgt' : 'Velg hjemmel for unntatt offentlighet',
      info: isSelectedHjemmel ? currentKvittering.hjemmelForUnntattOffentlighet : '',
      buttonText: isSelectedHjemmel ? 'Bytt hjemmel' : 'Velg hjemmel',
      moreInfo: hjemmelMoreInfo
    };
    return <Column fluid spacing={2.5} data-sentry-element="Column" data-sentry-component="renderHjemmelInfo" data-sentry-source-file="arkivering-slide.tsx">
        <Header as="h2" size="heading3" data-sentry-element="Header" data-sentry-source-file="arkivering-slide.tsx">
          {hjemmel.title}
        </Header>
        <Column spacing={1} data-sentry-element="Column" data-sentry-source-file="arkivering-slide.tsx">
          <Text size="normal" data-sentry-element="Text" data-sentry-source-file="arkivering-slide.tsx">{hjemmel.info}</Text>
          <Text size="medium" weight="light" data-sentry-element="Text" data-sentry-source-file="arkivering-slide.tsx">
            {hjemmel.moreInfo}
          </Text>
        </Column>
        <Row justify="center" data-sentry-element="Row" data-sentry-source-file="arkivering-slide.tsx">
          <Button width="16em" secondary onClick={onHjemmelButton} data-sentry-element="Button" data-sentry-source-file="arkivering-slide.tsx">
            {hjemmel.buttonText}
          </Button>
        </Row>
      </Column>;
  };
  useEffect(() => {
    const {
      personSkjermet,
      unntattOffentlighet,
      hjemmelForUnntattOffentlighet
    } = currentKvittering;
    const hjemmel = kodeverkResponse?.data?.find((kodeverk: IRemoteKodeverk) => kodeverk.codeString === 'Offl. § 13 første ledd jf fvl. § 13 første ledd nr 1');
    const {
      ord: saksTittelOrd,
      skjermedeOrd
    } = arkivInfo;
    const isSakTitleSkjermet = skjermedeOrd?.length === saksTittelOrd?.length;
    if (showKjaeledyrholdWarning && !personSkjermet) {
      onSkjerming(SkjermingAvMottakerOptions.SKAL_SKJERMES);
    }
    if (showKjaeledyrholdWarning && !unntattOffentlighet) {
      onUnntattOffentlighet();
    }
    if (showKjaeledyrholdWarning && !hjemmelForUnntattOffentlighet && hjemmel) {
      onSelectHjemmel(hjemmel);
    }
    if (showKjaeledyrholdWarning && isNewSak && !isSakTitleSkjermet && saksTittelOrd) {
      skjermSaksTittel(saksTittelOrd?.map((ord: ITilsynskvitteringRemote.SakstittelOrdRes) => ({
        ...ord,
        selected: true
      })));
    }
  }, [showKjaeledyrholdWarning, currentKvittering, arkivInfo, isNewSak, kodeverkResponse?.data, onSkjerming, onUnntattOffentlighet, onSelectHjemmel, skjermSaksTittel]);
  return <>
      <Column spacing={3} fluid key={2} data-sentry-element="Column" data-sentry-source-file="arkivering-slide.tsx">
        {showKjaeledyrholdWarning && <KjaeledyrholdWarning />}
        <Row className="title" data-sentry-element="Row" data-sentry-source-file="arkivering-slide.tsx">
          <Header as="h1" size="heading1" data-sentry-element="Header" data-sentry-source-file="arkivering-slide.tsx">
            Opprettelse av tilsynskvittering i arkivet
          </Header>
        </Row>

        <ContrastBox noBorders backgroundColor="white" data-sentry-element="ContrastBox" data-sentry-source-file="arkivering-slide.tsx">
          <TilsynsdatoPicker currentKvittering={currentKvittering} currentKvitteringSavingStatus={currentKvitteringSavingStatus} data-sentry-element="TilsynsdatoPicker" data-sentry-source-file="arkivering-slide.tsx" />
        </ContrastBox>

        <Column spacing={2} data-sentry-element="Column" data-sentry-source-file="arkivering-slide.tsx">
          <ContrastBox noBorders backgroundColor="white" data-sentry-element="ContrastBox" data-sentry-source-file="arkivering-slide.tsx">
            {renderInfo()}
          </ContrastBox>
          <ContrastBox noBorders backgroundColor="white" data-sentry-element="ContrastBox" data-sentry-source-file="arkivering-slide.tsx">
            {renderSakInfo()}
          </ContrastBox>
          <ContrastBox noBorders backgroundColor="white" data-sentry-element="ContrastBox" data-sentry-source-file="arkivering-slide.tsx">
            {renderSkjerming()}
          </ContrastBox>
          {currentKvittering.orgFormKode === 'ENK' && <ContrastBox noBorders backgroundColor="white">
              {renderSkalTaggeVirksomhet()}
            </ContrastBox>}
          <ContrastBox noBorders backgroundColor="white" data-sentry-element="ContrastBox" data-sentry-source-file="arkivering-slide.tsx">
            {renderUnntattOffentlighet()}
          </ContrastBox>
          {showHjemmel && <ContrastBox noBorders backgroundColor="white">
              {renderHjemmelInfo()}
            </ContrastBox>}
        </Column>
        <Row justify="center" data-sentry-element="Row" data-sentry-source-file="arkivering-slide.tsx">
          <Button large onClick={onOpprettTilsynskvittering} data-sentry-element="Button" data-sentry-source-file="arkivering-slide.tsx">
            Ferdigstill og send ut
          </Button>
        </Row>
      </Column>
      <InfoModalWithoutConfirmation isOpen={isErrorInfoModalOpen} title="Du har valgt en ugyldig sak." info="Du må enten gjenåpne saken, legge til en gyldig sak, eller fjerne saken slik at det opprettes en ny sak når tilsynskvitteringen arkiveres." cancelText="Gå tilbake" onCancel={() => setIsErrorInfoModalOpen(false)} data-sentry-element="InfoModalWithoutConfirmation" data-sentry-source-file="arkivering-slide.tsx" />
      <InfoModal isOpen={isNotFoundInfoModalOpen} title="Saken du har valgt finnes ikke." info="Du må enten gå tilbake for å søke opp en gyldig sak, eller så kan du opprette en ny sak her og nå." confirmText="Opprett ny sak" cancelText="Gå tilbake" onConfirm={() => {
      setIsNotFoundInfoModalOpen(false);
      setIsArchiveModalopen(true);
    }} onCancel={() => setIsNotFoundInfoModalOpen(false)} data-sentry-element="InfoModal" data-sentry-source-file="arkivering-slide.tsx" />
      <ArchiveModal onCancel={onCancelModal} isOpen={isArchiveModalOpen} onArchive={onArchive} archiveStatus={archiveStatus} currentSak={currentSak} sakNumber={sakNumber} tilsynskvitteringId={currentKvittering.id!} isLoading={archiveStatus === SAVING || currentSak.loading} existingHjemmelDesc={existingHjemmelMoreInfo} existingHjemmelName={currentSak.sakHjemmel} chosenHjemmelDesc={hjemmelMoreInfo} chosenHjemmelName={currentKvittering.hjemmelForUnntattOffentlighet} hjemmelChosen={showHjemmel} data-sentry-element="ArchiveModal" data-sentry-source-file="arkivering-slide.tsx" />
      <HjemmelModal isOpen={isHjemmelModalOpen} onCancel={onCancelHjemmelModal} hjemmelList={kodeverkResponse?.data} onSelectHjemmel={onSelectHjemmel} selectedHjemmel={currentKvittering.hjemmelForUnntattOffentlighet} loading={!!kodeverkResponse?.loading} error={!!kodeverkResponse?.error} onRetry={kodeverkResponse.fetchKodeverk} data-sentry-element="HjemmelModal" data-sentry-source-file="arkivering-slide.tsx" />
      {!!arkivInfo.sakstittel && isSakstittelModalOpen && <SkjermingModal isOpen={isSakstittelModalOpen} onOk={skjermedOrd => {
      onCloseSakstittelModal(skjermedOrd);
    }} ord={arkivInfo.ord || []} skjermedeOrd={arkivInfo.skjermedeOrd || []} />}
    </>;
};