import './style.css';
import React, { ChangeEvent, MutableRefObject } from 'react';
import { ALLOWED_IMAGE_FILE_TYPES } from '../../constants';
import { useTypedSelector } from '../../common/custom-hooks';
import { IStoreState } from '../../reducers/types';
interface ICameraProps {
  cameraRef: MutableRefObject<any>;
  pictureTakenAction: (imageFile: File[]) => void;
}
const Camera = ({
  cameraRef,
  pictureTakenAction
}: ICameraProps) => {
  const isMultipleAllowed = useTypedSelector((state: IStoreState) => state.ui.isDesktop);
  const addImage = (e: ChangeEvent<HTMLInputElement>) => {
    const files = (e.target?.files ?? []) as File[];
    // Do not send image if no image is added.
    if (files.length > 0) {
      pictureTakenAction(files);
    }
  };
  return <input multiple={isMultipleAllowed} type="file" id="inputCapture" ref={cameraRef} name="image" accept={ALLOWED_IMAGE_FILE_TYPES.join(', ')} capture="environment" onChange={addImage} aria-hidden={true} aria-label="Ta bilde" data-sentry-component="Camera" data-sentry-source-file="index.tsx" />;
};
export default Camera;